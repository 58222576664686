export const GbcProfile = [
  { inputs: [], stateMutability: 'nonpayable', type: 'constructor' },
  { inputs: [], name: 'AccessControlBadConfirmation', type: 'error' },
  {
    inputs: [
      { internalType: 'address', name: 'account', type: 'address' },
      { internalType: 'bytes32', name: 'neededRole', type: 'bytes32' },
    ],
    name: 'AccessControlUnauthorizedAccount',
    type: 'error',
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'string', name: 'infoKey', type: 'string' }],
    name: 'AddInfoKey',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'string', name: 'username', type: 'string' },
      { indexed: false, internalType: 'address', name: 'avatarAddress', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'avatarId', type: 'uint256' },
      { indexed: false, internalType: 'string[]', name: 'infoKeys', type: 'string[]' },
      { indexed: false, internalType: 'string[]', name: 'infoValues', type: 'string[]' },
    ],
    name: 'CreateProfile',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'string', name: 'infoKey', type: 'string' }],
    name: 'RemoveInfoKey',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { indexed: true, internalType: 'bytes32', name: 'previousAdminRole', type: 'bytes32' },
      { indexed: true, internalType: 'bytes32', name: 'newAdminRole', type: 'bytes32' },
    ],
    name: 'RoleAdminChanged',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { indexed: true, internalType: 'address', name: 'account', type: 'address' },
      { indexed: true, internalType: 'address', name: 'sender', type: 'address' },
    ],
    name: 'RoleGranted',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { indexed: true, internalType: 'address', name: 'account', type: 'address' },
      { indexed: true, internalType: 'address', name: 'sender', type: 'address' },
    ],
    name: 'RoleRevoked',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'avatarAddress', type: 'address' },
      { indexed: false, internalType: 'bool', name: 'valid', type: 'bool' },
    ],
    name: 'SetValidAvatarAddress',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'avatarAddress', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'avatarId', type: 'uint256' },
      { indexed: false, internalType: 'address', name: 'profile', type: 'address' },
    ],
    name: 'UpdateAvatar',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'string[]', name: 'infoKeys', type: 'string[]' },
      { indexed: false, internalType: 'string[]', name: 'infoValues', type: 'string[]' },
      { indexed: false, internalType: 'address', name: 'profile', type: 'address' },
    ],
    name: 'UpdateInfo',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'string', name: 'username', type: 'string' },
      { indexed: false, internalType: 'address', name: 'profile', type: 'address' },
    ],
    name: 'UpdateUsername',
    type: 'event',
  },
  {
    inputs: [],
    name: 'DEFAULT_ADMIN_ROLE',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'PROFILE_ADMIN_ROLE',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'string', name: '_infoKey', type: 'string' }],
    name: 'addInfoKey',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'string', name: '_username', type: 'string' },
      { internalType: 'address', name: '_avatarAddress', type: 'address' },
      { internalType: 'uint256', name: '_avatarId', type: 'uint256' },
      { internalType: 'string[]', name: '_infoKeys', type: 'string[]' },
      { internalType: 'string[]', name: '_infoValues', type: 'string[]' },
    ],
    name: 'createProfile',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_profile', type: 'address' }],
    name: 'getProfile',
    outputs: [
      { internalType: 'string', name: 'username', type: 'string' },
      { internalType: 'address', name: 'avatarAddress', type: 'address' },
      { internalType: 'uint256', name: 'avatarId', type: 'uint256' },
      { internalType: 'string[]', name: 'infoKeys', type: 'string[]' },
      { internalType: 'string[]', name: 'infoValues', type: 'string[]' },
      { internalType: 'uint256', name: 'creation', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }],
    name: 'getRoleAdmin',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { internalType: 'address', name: 'account', type: 'address' },
    ],
    name: 'grantRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { internalType: 'address', name: 'account', type: 'address' },
    ],
    name: 'hasRole',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'infoKeysLength',
    outputs: [{ internalType: 'uint256', name: 'length', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'string', name: '', type: 'string' }],
    name: 'isUsernameUsed',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'isValidAvatar',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: 'registeredInfoKeys',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'string', name: '_infoKey', type: 'string' }],
    name: 'removeInfoKey',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { internalType: 'address', name: 'callerConfirmation', type: 'address' },
    ],
    name: 'renounceRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { internalType: 'address', name: 'account', type: 'address' },
    ],
    name: 'revokeRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_avatarAddress', type: 'address' },
      { internalType: 'bool', name: '_valid', type: 'bool' },
    ],
    name: 'setValidAvatarAddress',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_avatarAddress', type: 'address' },
      { internalType: 'uint256', name: '_avatarId', type: 'uint256' },
    ],
    name: 'updateAvatar',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_profile', type: 'address' },
      { internalType: 'string[]', name: '_infoKeys', type: 'string[]' },
      { internalType: 'string[]', name: '_infoValues', type: 'string[]' },
    ],
    name: 'updateInfo',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'string', name: '_username', type: 'string' },
      { internalType: 'address', name: '_avatarAddress', type: 'address' },
      { internalType: 'uint256', name: '_avatarId', type: 'uint256' },
      { internalType: 'string[]', name: '_infoKeys', type: 'string[]' },
      { internalType: 'string[]', name: '_infoValues', type: 'string[]' },
    ],
    name: 'updateProfile',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_profile', type: 'address' },
      { internalType: 'string', name: '_username', type: 'string' },
    ],
    name: 'updateUsername',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
] as const
