import type { IBerryDisplayTupleMap } from '../types'

// @ts-ignore
const tokenIdAttributeTuple: IBerryDisplayTupleMap[] = [
  [1, 1, 1, 1, 1, 1],
  [2, 2, 2, 2, 2, 2],
  [3, 4, 5, 6, 7, 8],
  [3, 9, 5, 6, 10, 11],
  [12, 4, 5, 13, 14, 15],
  [16, 9, 5, 17, 18, 19],
  [20, 21, 5, 22, 18, 23],
  [24, 25, 5, 26, 18, 27],
  [16, 28, 5, 17, 29, 27],
  [30, 31, 5, 32, 33, 34],
  [3, 35, 5, 36, 37, 38],
  [39, 40, 5, 13, 41, 42],
  [30, 43, 5, 17, 44, 45],
  [46, 9, 5, 13, 18, 47],
  [39, 48, 5, 6, 49, 8],
  [30, 50, 5, 6, 29, 51],
  [24, 52, 5, 53, 54, 35],
  [30, 55, 5, 17, 56, 27],
  [20, 57, 5, 22, 33, 27],
  [39, 58, 5, 53, 49, 27],
  [46, 4, 5, 59, 60, 61],
  [3, 25, 5, 6, 62, 19],
  [46, 55, 5, 63, 64, 65],
  [12, 66, 5, 17, 14, 67],
  [39, 55, 5, 63, 64, 68],
  [24, 69, 5, 22, 64, 70],
  [12, 69, 5, 59, 10, 71],
  [16, 72, 5, 59, 73, 74],
  [39, 35, 5, 26, 75, 76],
  [39, 35, 5, 26, 14, 42],
  [12, 77, 5, 63, 37, 78],
  [16, 79, 5, 13, 75, 80],
  [46, 81, 5, 36, 82, 83],
  [30, 9, 5, 53, 37, 80],
  [12, 48, 5, 63, 14, 83],
  [46, 43, 5, 63, 7, 51],
  [24, 50, 5, 32, 18, 84],
  [16, 81, 5, 59, 18, 45],
  [20, 85, 5, 86, 82, 10],
  [30, 48, 5, 22, 64, 74],
  [12, 87, 5, 22, 14, 88],
  [3, 58, 5, 36, 14, 78],
  [46, 58, 5, 89, 29, 83],
  [39, 58, 5, 59, 14, 74],
  [3, 31, 5, 63, 90, 91],
  [12, 48, 5, 92, 18, 93],
  [46, 10, 5, 17, 94, 45],
  [20, 52, 5, 32, 14, 80],
  [46, 40, 5, 63, 37, 95],
  [3, 96, 5, 6, 10, 97],
  [24, 27, 5, 26, 98, 99],
  [46, 31, 5, 22, 90, 80],
  [16, 4, 5, 53, 100, 83],
  [30, 28, 5, 6, 14, 101],
  [39, 35, 5, 59, 102, 70],
  [12, 69, 5, 36, 29, 45],
  [46, 103, 5, 32, 7, 67],
  [12, 55, 5, 6, 56, 91],
  [46, 104, 5, 17, 73, 105],
  [24, 35, 5, 59, 37, 99],
  [20, 106, 5, 107, 10, 108],
  [16, 87, 5, 107, 44, 109],
  [46, 72, 5, 36, 60, 78],
  [16, 31, 5, 32, 75, 68],
  [12, 110, 5, 26, 90, 108],
  [20, 31, 5, 26, 73, 111],
  [16, 112, 5, 17, 14, 91],
  [16, 48, 5, 36, 18, 78],
  [12, 113, 5, 22, 62, 68],
  [39, 66, 5, 63, 56, 8],
  [39, 28, 5, 13, 114, 97],
  [16, 28, 5, 107, 18, 47],
  [39, 52, 5, 6, 18, 15],
  [46, 28, 5, 32, 37, 11],
  [39, 28, 5, 6, 102, 78],
  [12, 88, 5, 63, 37, 115],
  [39, 113, 5, 36, 62, 109],
  [46, 66, 5, 107, 44, 34],
  [30, 96, 5, 13, 75, 83],
  [39, 116, 5, 63, 14, 117],
  [24, 50, 5, 63, 90, 108],
  [3, 35, 5, 13, 114, 118],
  [46, 48, 5, 17, 73, 74],
  [20, 119, 5, 17, 62, 15],
  [12, 120, 5, 107, 56, 8],
  [3, 69, 5, 13, 102, 83],
  [39, 69, 5, 89, 54, 121],
  [12, 120, 5, 13, 18, 84],
  [30, 43, 5, 13, 62, 27],
  [46, 50, 5, 92, 102, 91],
  [20, 66, 5, 107, 49, 35],
  [16, 119, 5, 53, 62, 122],
  [39, 43, 5, 17, 90, 99],
  [46, 123, 5, 107, 37, 8],
  [46, 66, 5, 59, 124, 78],
  [20, 57, 5, 36, 100, 11],
  [39, 66, 5, 13, 102, 51],
  [46, 58, 5, 17, 37, 115],
  [12, 35, 5, 86, 73, 80],
  [16, 125, 5, 107, 62, 126],
  [16, 55, 5, 32, 100, 91],
  [20, 10, 5, 17, 94, 111],
  [24, 116, 5, 13, 114, 111],
  [46, 69, 5, 107, 102, 35],
  [12, 21, 5, 26, 18, 84],
  [20, 66, 5, 17, 124, 122],
  [3, 31, 5, 92, 33, 122],
  [20, 127, 5, 107, 18, 78],
  [46, 96, 5, 36, 62, 70],
  [3, 103, 5, 26, 29, 11],
  [20, 106, 5, 36, 44, 78],
  [16, 25, 5, 89, 124, 80],
  [16, 31, 5, 6, 18, 97],
  [3, 57, 5, 107, 64, 101],
  [16, 117, 5, 107, 18, 74],
  [30, 104, 5, 53, 73, 42],
  [46, 81, 5, 32, 73, 71],
  [24, 69, 5, 6, 75, 78],
  [20, 96, 5, 22, 102, 10],
  [46, 10, 5, 17, 10, 78],
  [16, 128, 5, 17, 124, 93],
  [24, 96, 5, 59, 29, 80],
  [39, 128, 5, 59, 100, 83],
  [46, 52, 5, 26, 18, 115],
  [39, 9, 5, 17, 18, 67],
  [24, 104, 5, 107, 29, 83],
  [39, 31, 5, 13, 18, 80],
  [3, 116, 5, 107, 73, 121],
  [16, 4, 5, 13, 18, 99],
  [46, 48, 5, 89, 73, 70],
  [46, 119, 5, 17, 49, 45],
  [39, 10, 5, 89, 62, 91],
  [3, 87, 5, 26, 94, 129],
  [16, 81, 5, 22, 94, 76],
  [20, 43, 5, 17, 56, 101],
  [3, 104, 5, 63, 18, 47],
  [24, 128, 5, 89, 18, 45],
  [30, 31, 5, 63, 73, 101],
  [39, 103, 5, 26, 7, 121],
  [16, 4, 5, 36, 73, 8],
  [3, 9, 5, 36, 56, 47],
  [20, 106, 5, 32, 7, 74],
  [12, 28, 5, 89, 37, 91],
  [3, 88, 5, 63, 130, 8],
  [24, 113, 5, 107, 62, 115],
  [30, 57, 5, 6, 102, 131],
  [30, 113, 5, 22, 100, 88],
  [39, 48, 5, 22, 7, 131],
  [3, 113, 5, 6, 29, 51],
  [30, 132, 5, 17, 100, 117],
  [30, 87, 5, 89, 10, 65],
  [12, 96, 5, 36, 49, 121],
  [3, 35, 5, 32, 94, 70],
  [20, 25, 5, 92, 14, 11],
  [39, 35, 5, 36, 33, 74],
  [3, 43, 5, 36, 102, 71],
  [30, 50, 5, 32, 64, 91],
  [20, 58, 5, 89, 100, 95],
  [24, 72, 5, 17, 29, 74],
  [16, 58, 5, 107, 18, 76],
  [16, 133, 5, 36, 100, 47],
  [30, 132, 5, 89, 44, 8],
  [46, 96, 5, 17, 18, 83],
  [16, 117, 5, 22, 73, 134],
  [16, 135, 5, 89, 102, 19],
  [12, 103, 5, 59, 7, 8],
  [16, 28, 5, 53, 62, 34],
  [20, 48, 5, 107, 18, 34],
  [12, 28, 5, 32, 73, 134],
  [16, 66, 5, 59, 44, 93],
  [46, 81, 5, 53, 18, 136],
  [20, 132, 5, 59, 10, 134],
  [3, 66, 5, 63, 44, 70],
  [30, 72, 5, 32, 29, 65],
  [24, 103, 5, 59, 18, 45],
  [46, 66, 5, 36, 33, 51],
  [3, 96, 5, 107, 7, 76],
  [30, 55, 5, 107, 102, 80],
  [30, 50, 5, 107, 102, 47],
  [20, 104, 5, 107, 18, 68],
  [46, 43, 5, 36, 94, 47],
  [30, 48, 5, 36, 54, 111],
  [3, 125, 5, 92, 14, 99],
  [46, 66, 5, 22, 18, 47],
  [3, 104, 5, 36, 102, 118],
  [24, 58, 5, 36, 18, 11],
  [39, 81, 5, 17, 33, 118],
  [30, 43, 5, 53, 124, 115],
  [12, 55, 5, 59, 62, 137],
  [16, 123, 5, 17, 90, 131],
  [16, 69, 5, 17, 100, 78],
  [20, 48, 5, 17, 10, 111],
  [30, 104, 5, 63, 18, 99],
  [20, 85, 5, 22, 90, 105],
  [12, 69, 5, 6, 100, 74],
  [24, 113, 5, 89, 14, 136],
  [20, 43, 5, 13, 82, 35],
  [46, 104, 5, 107, 62, 38],
  [16, 66, 5, 13, 29, 35],
  [24, 69, 5, 107, 18, 88],
  [24, 77, 5, 22, 114, 23],
  [24, 43, 5, 22, 29, 131],
  [46, 35, 5, 6, 56, 138],
  [39, 119, 5, 63, 102, 42],
  [16, 31, 5, 17, 114, 74],
  [46, 31, 5, 107, 18, 19],
  [39, 48, 5, 139, 102, 34],
  [39, 87, 5, 92, 44, 42],
  [46, 58, 5, 86, 54, 10],
  [24, 48, 5, 36, 18, 117],
  [16, 96, 5, 32, 18, 70],
  [16, 140, 5, 36, 29, 74],
  [24, 96, 5, 107, 18, 67],
  [24, 72, 5, 36, 33, 67],
  [30, 9, 5, 107, 18, 10],
  [46, 25, 5, 17, 18, 47],
  [39, 35, 5, 22, 37, 126],
  [46, 31, 5, 89, 14, 61],
  [16, 57, 5, 17, 18, 70],
  [39, 35, 5, 22, 10, 122],
  [16, 58, 5, 13, 75, 121],
  [30, 117, 5, 22, 90, 83],
  [3, 81, 5, 13, 29, 10],
  [3, 141, 5, 17, 64, 80],
  [30, 25, 5, 22, 102, 35],
  [20, 128, 5, 6, 82, 84],
  [16, 69, 5, 92, 82, 8],
  [39, 88, 5, 22, 62, 11],
  [46, 4, 5, 63, 37, 83],
  [30, 119, 5, 107, 114, 121],
  [39, 106, 5, 53, 114, 91],
  [46, 50, 5, 17, 10, 121],
  [12, 133, 5, 13, 18, 108],
  [16, 28, 5, 92, 29, 115],
  [16, 119, 5, 13, 90, 137],
  [16, 27, 5, 17, 54, 83],
  [39, 104, 5, 59, 102, 105],
  [30, 132, 5, 92, 102, 121],
  [12, 52, 5, 22, 100, 71],
  [46, 55, 5, 17, 102, 61],
  [46, 88, 5, 13, 124, 111],
  [20, 140, 5, 53, 18, 27],
  [20, 28, 5, 17, 18, 97],
  [46, 113, 5, 22, 14, 65],
  [30, 140, 5, 32, 56, 47],
  [3, 113, 5, 32, 29, 137],
  [46, 104, 5, 13, 14, 47],
  [30, 112, 5, 92, 102, 99],
  [39, 125, 5, 13, 114, 34],
  [39, 104, 5, 17, 64, 122],
  [20, 48, 5, 26, 44, 99],
  [16, 140, 5, 63, 82, 129],
  [12, 69, 5, 92, 44, 129],
  [46, 31, 5, 32, 56, 117],
  [3, 128, 5, 53, 44, 74],
  [3, 57, 5, 17, 14, 67],
  [16, 43, 5, 36, 100, 138],
  [20, 81, 5, 36, 102, 118],
  [46, 50, 5, 89, 82, 15],
  [3, 58, 5, 32, 18, 76],
  [46, 113, 5, 63, 100, 101],
  [12, 87, 5, 53, 18, 74],
  [30, 96, 5, 59, 56, 108],
  [16, 57, 5, 17, 64, 47],
  [24, 106, 5, 63, 73, 134],
  [3, 119, 5, 32, 54, 19],
  [20, 104, 5, 107, 29, 111],
  [12, 69, 5, 32, 29, 67],
  [30, 112, 5, 32, 49, 74],
  [20, 81, 5, 92, 130, 47],
  [12, 9, 5, 26, 49, 88],
  [16, 27, 5, 32, 44, 11],
  [3, 43, 5, 6, 90, 76],
  [16, 69, 5, 107, 33, 70],
  [16, 66, 5, 63, 94, 131],
  [16, 57, 5, 17, 100, 65],
  [30, 104, 5, 17, 75, 138],
  [24, 28, 5, 59, 114, 65],
  [3, 85, 5, 92, 64, 138],
  [24, 103, 5, 59, 7, 121],
  [24, 57, 5, 13, 114, 122],
  [3, 55, 5, 63, 82, 88],
  [39, 72, 5, 92, 100, 42],
  [3, 116, 5, 6, 29, 38],
  [12, 85, 5, 22, 75, 8],
  [46, 55, 5, 53, 94, 45],
  [16, 35, 5, 6, 33, 99],
  [3, 9, 5, 89, 18, 91],
  [46, 133, 5, 92, 82, 38],
  [3, 77, 5, 107, 14, 83],
  [20, 103, 5, 107, 7, 83],
  [24, 103, 5, 53, 33, 91],
  [46, 106, 5, 59, 18, 78],
  [39, 35, 5, 32, 18, 51],
  [3, 35, 5, 17, 33, 122],
  [39, 31, 5, 59, 90, 109],
  [12, 106, 5, 6, 56, 47],
  [30, 85, 5, 107, 29, 70],
  [12, 113, 5, 6, 56, 67],
  [39, 96, 5, 92, 130, 111],
  [3, 66, 5, 17, 37, 51],
  [46, 40, 5, 36, 130, 65],
  [16, 87, 5, 13, 75, 108],
  [16, 43, 5, 13, 10, 105],
  [30, 140, 5, 92, 33, 10],
  [39, 128, 5, 13, 7, 108],
  [39, 48, 5, 53, 14, 76],
  [3, 96, 5, 36, 62, 8],
  [20, 81, 5, 59, 64, 115],
  [3, 117, 5, 17, 62, 47],
  [24, 25, 5, 107, 100, 111],
  [12, 31, 5, 63, 29, 19],
  [24, 119, 5, 107, 90, 68],
  [16, 110, 5, 107, 18, 61],
  [3, 119, 5, 32, 18, 80],
  [16, 35, 5, 63, 56, 105],
  [12, 58, 5, 13, 73, 78],
  [16, 128, 5, 22, 62, 45],
  [3, 40, 5, 53, 73, 10],
  [16, 27, 5, 17, 60, 45],
  [20, 133, 5, 13, 37, 34],
  [12, 81, 5, 32, 18, 83],
  [46, 48, 5, 53, 54, 117],
  [12, 79, 5, 92, 94, 47],
  [30, 106, 5, 92, 14, 67],
  [3, 96, 5, 107, 44, 88],
  [16, 103, 5, 22, 75, 67],
  [3, 50, 5, 89, 102, 122],
  [24, 57, 5, 13, 64, 126],
  [3, 28, 5, 63, 7, 71],
  [16, 104, 5, 36, 102, 78],
  [46, 57, 5, 13, 10, 115],
  [20, 110, 5, 17, 18, 111],
  [39, 79, 5, 53, 33, 101],
  [20, 31, 5, 92, 33, 38],
  [12, 87, 5, 92, 44, 10],
  [16, 35, 5, 26, 62, 99],
  [39, 31, 5, 63, 33, 111],
  [39, 87, 5, 32, 18, 67],
  [46, 55, 5, 22, 56, 68],
  [16, 48, 5, 17, 29, 111],
  [24, 28, 5, 17, 64, 83],
  [39, 104, 5, 32, 14, 99],
  [46, 25, 5, 13, 75, 88],
  [39, 132, 5, 63, 18, 78],
  [16, 113, 5, 13, 29, 34],
  [3, 119, 5, 13, 62, 122],
  [12, 132, 5, 89, 64, 105],
  [12, 48, 5, 36, 54, 99],
  [39, 72, 5, 13, 62, 121],
  [12, 10, 5, 53, 18, 76],
  [39, 103, 5, 53, 75, 74],
  [46, 79, 5, 107, 10, 47],
  [20, 25, 5, 53, 18, 61],
  [12, 28, 5, 107, 130, 91],
  [16, 52, 5, 36, 64, 65],
  [30, 48, 5, 32, 102, 11],
  [39, 66, 5, 59, 14, 99],
  [39, 21, 5, 107, 18, 67],
  [39, 55, 5, 22, 64, 131],
  [39, 116, 5, 22, 75, 134],
  [46, 69, 5, 13, 33, 67],
  [3, 35, 5, 13, 54, 65],
  [3, 66, 5, 63, 14, 80],
  [12, 81, 5, 17, 90, 65],
  [3, 106, 5, 92, 18, 68],
  [16, 48, 5, 89, 130, 74],
  [16, 104, 5, 6, 100, 10],
  [16, 106, 5, 17, 14, 67],
  [3, 72, 5, 13, 54, 99],
  [20, 81, 5, 36, 56, 122],
  [3, 31, 5, 107, 64, 76],
  [12, 96, 5, 107, 102, 122],
  [20, 28, 5, 63, 44, 67],
  [16, 104, 5, 63, 14, 67],
  [16, 69, 5, 32, 64, 70],
  [12, 57, 5, 107, 73, 74],
  [30, 79, 5, 107, 73, 51],
  [12, 58, 5, 32, 124, 74],
  [3, 96, 5, 36, 94, 131],
  [24, 50, 5, 59, 102, 8],
  [30, 96, 5, 13, 18, 74],
  [12, 81, 5, 17, 18, 10],
  [3, 140, 5, 36, 18, 10],
  [3, 106, 5, 13, 14, 68],
  [30, 35, 5, 107, 44, 65],
  [16, 103, 5, 59, 114, 136],
  [46, 88, 5, 59, 56, 91],
  [39, 87, 5, 36, 94, 78],
  [30, 57, 5, 59, 14, 101],
  [20, 125, 5, 107, 62, 68],
  [30, 106, 5, 13, 64, 122],
  [30, 35, 5, 32, 10, 11],
  [16, 113, 5, 92, 10, 101],
  [46, 103, 5, 92, 33, 115],
  [39, 72, 5, 59, 73, 131],
  [39, 127, 5, 32, 18, 91],
  [39, 132, 5, 63, 37, 111],
  [30, 103, 5, 13, 18, 99],
  [30, 58, 5, 22, 94, 80],
  [12, 57, 5, 139, 64, 131],
  [20, 113, 5, 6, 44, 93],
  [16, 85, 5, 53, 62, 108],
  [20, 31, 5, 92, 82, 91],
  [24, 132, 5, 63, 29, 78],
  [20, 55, 5, 17, 44, 45],
  [39, 57, 5, 107, 37, 111],
  [39, 85, 5, 36, 64, 11],
  [39, 35, 5, 17, 44, 99],
  [39, 112, 5, 22, 18, 51],
  [12, 103, 5, 32, 7, 99],
  [16, 104, 5, 17, 44, 8],
  [30, 10, 5, 107, 94, 99],
  [24, 81, 5, 59, 44, 11],
  [39, 66, 5, 107, 94, 76],
  [16, 25, 5, 17, 102, 35],
  [46, 66, 5, 107, 62, 80],
  [39, 140, 5, 6, 7, 101],
  [24, 52, 5, 92, 56, 115],
  [20, 79, 5, 107, 18, 67],
  [12, 77, 5, 6, 10, 10],
  [16, 112, 5, 63, 10, 35],
  [20, 77, 5, 86, 82, 47],
  [24, 4, 5, 32, 18, 68],
  [24, 66, 5, 59, 10, 93],
  [16, 103, 5, 107, 37, 23],
  [16, 104, 5, 36, 18, 121],
  [39, 135, 5, 107, 75, 101],
  [12, 72, 5, 107, 29, 117],
  [16, 52, 5, 26, 73, 118],
  [16, 140, 5, 6, 100, 67],
  [30, 52, 5, 63, 102, 88],
  [30, 106, 5, 13, 49, 108],
  [46, 113, 5, 53, 56, 118],
  [39, 103, 5, 59, 18, 99],
  [16, 43, 5, 59, 37, 88],
  [39, 27, 5, 17, 18, 111],
  [30, 52, 5, 32, 37, 138],
  [46, 28, 5, 139, 94, 80],
  [16, 106, 5, 107, 18, 95],
  [46, 104, 5, 13, 82, 99],
  [24, 96, 5, 17, 64, 65],
  [39, 103, 5, 17, 37, 122],
  [16, 106, 5, 59, 49, 45],
  [30, 10, 5, 13, 82, 68],
  [30, 4, 5, 13, 7, 51],
  [30, 66, 5, 26, 130, 101],
  [12, 28, 5, 17, 33, 74],
  [20, 81, 5, 107, 82, 74],
  [30, 31, 5, 22, 56, 35],
  [20, 133, 5, 59, 18, 109],
  [16, 117, 5, 22, 62, 111],
  [20, 132, 5, 17, 82, 136],
  [3, 113, 5, 59, 75, 35],
  [3, 27, 5, 36, 62, 108],
  [30, 96, 5, 26, 75, 42],
  [3, 140, 5, 92, 62, 11],
  [16, 113, 5, 32, 82, 76],
  [12, 55, 5, 17, 100, 121],
  [20, 113, 5, 63, 56, 78],
  [3, 85, 5, 17, 94, 97],
  [46, 48, 5, 32, 14, 15],
  [3, 69, 5, 59, 37, 138],
  [20, 31, 5, 53, 18, 42],
  [30, 79, 5, 13, 114, 15],
  [20, 104, 5, 13, 75, 74],
  [3, 35, 5, 92, 75, 45],
  [16, 48, 5, 63, 41, 71],
  [46, 21, 5, 13, 124, 78],
  [46, 58, 5, 89, 18, 65],
  [20, 66, 5, 59, 94, 105],
  [12, 55, 5, 107, 18, 118],
  [3, 120, 5, 26, 100, 8],
  [3, 31, 5, 89, 64, 76],
  [30, 55, 5, 22, 75, 105],
  [12, 132, 5, 53, 130, 108],
  [12, 132, 5, 17, 90, 122],
  [30, 31, 5, 107, 56, 108],
  [20, 119, 5, 13, 14, 108],
  [39, 48, 5, 92, 82, 93],
  [16, 106, 5, 107, 18, 122],
  [24, 48, 5, 26, 29, 137],
  [24, 125, 5, 59, 64, 99],
  [20, 31, 5, 107, 18, 91],
  [3, 69, 5, 89, 75, 67],
  [39, 133, 5, 107, 90, 15],
  [3, 31, 5, 26, 44, 80],
  [3, 27, 5, 17, 82, 71],
  [30, 106, 5, 36, 54, 68],
  [16, 110, 5, 17, 62, 45],
  [12, 85, 5, 6, 73, 115],
  [16, 103, 5, 26, 44, 83],
  [12, 35, 5, 36, 98, 11],
  [46, 135, 5, 32, 7, 10],
  [3, 117, 5, 32, 37, 76],
  [12, 96, 5, 107, 75, 74],
  [39, 50, 5, 59, 7, 68],
  [3, 116, 5, 36, 18, 76],
  [16, 50, 5, 107, 64, 74],
  [3, 135, 5, 107, 73, 108],
  [30, 106, 5, 22, 33, 115],
  [3, 48, 5, 92, 7, 118],
  [39, 57, 5, 13, 100, 76],
  [3, 106, 5, 36, 18, 67],
  [3, 28, 5, 92, 44, 115],
  [24, 104, 5, 17, 18, 129],
  [20, 43, 5, 22, 82, 101],
  [30, 58, 5, 17, 54, 38],
  [12, 50, 5, 107, 33, 27],
  [16, 104, 5, 63, 44, 71],
  [46, 31, 5, 139, 64, 8],
  [39, 28, 5, 89, 33, 84],
  [46, 40, 5, 17, 64, 10],
  [20, 120, 5, 17, 37, 136],
  [3, 55, 5, 63, 130, 8],
  [16, 58, 5, 22, 10, 45],
  [16, 96, 5, 32, 49, 108],
  [30, 104, 5, 36, 29, 105],
  [3, 35, 5, 89, 18, 78],
  [16, 103, 5, 6, 54, 47],
  [20, 58, 5, 107, 130, 68],
  [3, 50, 5, 13, 94, 122],
  [30, 69, 5, 26, 130, 138],
  [46, 125, 5, 32, 14, 11],
  [46, 55, 5, 22, 73, 88],
  [46, 43, 5, 17, 62, 70],
  [24, 9, 5, 89, 37, 115],
  [3, 35, 5, 36, 18, 105],
  [24, 132, 5, 89, 75, 122],
  [3, 113, 5, 92, 54, 68],
  [46, 50, 5, 26, 75, 35],
  [16, 72, 5, 26, 82, 74],
  [46, 132, 5, 17, 10, 108],
  [3, 113, 5, 17, 14, 67],
  [39, 10, 5, 53, 29, 91],
  [16, 66, 5, 36, 37, 80],
  [24, 27, 5, 13, 64, 83],
  [16, 25, 5, 92, 10, 68],
  [39, 103, 5, 17, 29, 80],
  [16, 52, 5, 36, 100, 131],
  [3, 103, 5, 36, 44, 51],
  [20, 31, 5, 107, 114, 74],
  [20, 48, 5, 6, 56, 70],
  [30, 79, 5, 89, 29, 111],
  [16, 106, 5, 107, 18, 131],
  [3, 66, 5, 63, 62, 97],
  [3, 128, 5, 6, 10, 93],
  [20, 27, 5, 17, 82, 108],
  [16, 55, 5, 32, 54, 134],
  [30, 87, 5, 92, 18, 11],
  [24, 96, 5, 6, 7, 131],
  [20, 57, 5, 13, 18, 11],
  [30, 43, 5, 6, 44, 84],
  [12, 58, 5, 22, 102, 42],
  [46, 96, 5, 59, 18, 78],
  [46, 50, 5, 26, 73, 71],
  [3, 103, 5, 32, 94, 11],
  [24, 79, 5, 89, 33, 67],
  [3, 55, 5, 17, 94, 111],
  [20, 85, 5, 63, 49, 70],
  [16, 72, 5, 32, 18, 47],
  [3, 117, 5, 13, 100, 68],
  [16, 4, 5, 17, 94, 45],
  [3, 119, 5, 36, 33, 68],
  [16, 112, 5, 53, 100, 35],
  [16, 116, 5, 89, 62, 67],
  [46, 133, 5, 26, 14, 10],
  [16, 48, 5, 26, 7, 10],
  [16, 120, 5, 26, 56, 8],
  [3, 43, 5, 92, 18, 19],
  [46, 69, 5, 17, 33, 38],
  [20, 113, 5, 36, 18, 99],
  [3, 58, 5, 89, 114, 34],
  [3, 9, 5, 107, 18, 65],
  [20, 132, 5, 32, 7, 65],
  [12, 55, 5, 107, 130, 118],
  [16, 132, 5, 13, 44, 67],
  [39, 112, 5, 6, 18, 70],
  [39, 72, 5, 22, 49, 80],
  [3, 55, 5, 17, 18, 76],
  [3, 112, 5, 17, 94, 61],
  [24, 132, 5, 26, 102, 136],
  [30, 87, 5, 17, 56, 129],
  [24, 135, 5, 63, 7, 47],
  [20, 66, 5, 59, 75, 11],
  [16, 119, 5, 36, 124, 19],
  [16, 88, 5, 17, 10, 67],
  [3, 112, 5, 89, 94, 65],
  [20, 133, 5, 59, 54, 42],
  [3, 128, 5, 6, 114, 8],
  [16, 96, 5, 89, 73, 105],
  [46, 66, 5, 89, 18, 131],
  [16, 125, 5, 6, 114, 121],
  [12, 81, 5, 32, 18, 93],
  [46, 104, 5, 89, 100, 68],
  [3, 58, 5, 26, 64, 10],
  [20, 81, 5, 107, 18, 83],
  [3, 103, 5, 63, 56, 45],
  [30, 50, 5, 26, 64, 67],
  [30, 87, 5, 89, 124, 111],
  [46, 48, 5, 36, 33, 8],
  [12, 120, 5, 89, 18, 61],
  [46, 28, 5, 22, 18, 97],
  [39, 66, 5, 107, 33, 42],
  [3, 96, 5, 13, 124, 91],
  [12, 125, 5, 63, 54, 74],
  [12, 85, 5, 59, 54, 8],
  [12, 117, 5, 22, 18, 74],
  [16, 81, 5, 107, 56, 121],
  [39, 132, 5, 26, 100, 105],
  [3, 35, 5, 139, 10, 78],
  [3, 28, 5, 139, 94, 67],
  [46, 50, 5, 89, 18, 122],
  [16, 113, 5, 59, 94, 34],
  [16, 117, 5, 36, 100, 42],
  [20, 132, 5, 26, 18, 115],
  [30, 119, 5, 107, 114, 70],
  [16, 87, 5, 107, 130, 105],
  [3, 113, 5, 22, 90, 8],
  [39, 133, 5, 59, 75, 8],
  [16, 133, 5, 22, 18, 97],
  [16, 50, 5, 26, 29, 101],
  [46, 119, 5, 6, 18, 115],
  [24, 119, 5, 17, 90, 121],
  [20, 96, 5, 32, 29, 34],
  [16, 119, 5, 26, 33, 129],
  [3, 69, 5, 17, 18, 11],
  [30, 81, 5, 63, 82, 111],
  [39, 43, 5, 59, 94, 61],
  [46, 27, 5, 92, 94, 83],
  [16, 117, 5, 36, 18, 23],
  [24, 48, 5, 63, 18, 23],
  [16, 88, 5, 107, 18, 74],
  [12, 43, 5, 107, 56, 71],
  [20, 77, 5, 17, 18, 67],
  [16, 10, 5, 107, 7, 111],
  [16, 79, 5, 59, 62, 76],
  [30, 85, 5, 22, 73, 126],
  [3, 10, 5, 36, 94, 71],
  [46, 57, 5, 17, 44, 71],
  [3, 135, 5, 22, 82, 76],
  [3, 72, 5, 63, 90, 122],
  [3, 40, 5, 107, 56, 70],
  [12, 28, 5, 89, 114, 91],
  [3, 119, 5, 32, 102, 108],
  [20, 85, 5, 13, 33, 11],
  [12, 58, 5, 107, 124, 80],
  [16, 57, 5, 22, 18, 105],
  [30, 81, 5, 107, 54, 121],
  [12, 57, 5, 89, 18, 78],
  [3, 142, 5, 17, 100, 10],
  [24, 112, 5, 92, 37, 108],
  [24, 128, 5, 26, 44, 10],
  [39, 135, 5, 107, 82, 15],
  [12, 48, 5, 63, 44, 74],
  [12, 43, 5, 59, 94, 65],
  [30, 106, 5, 13, 54, 67],
  [39, 104, 5, 13, 82, 83],
  [12, 35, 5, 92, 73, 70],
  [30, 112, 5, 92, 18, 70],
  [30, 48, 5, 107, 73, 10],
  [12, 40, 5, 107, 62, 10],
  [12, 112, 5, 13, 94, 19],
  [30, 113, 5, 32, 29, 51],
  [46, 127, 5, 139, 49, 45],
  [16, 25, 5, 22, 7, 131],
  [3, 58, 5, 59, 114, 93],
  [3, 113, 5, 63, 54, 67],
  [16, 103, 5, 36, 7, 10],
  [39, 72, 5, 6, 75, 95],
  [30, 66, 5, 63, 54, 68],
  [39, 55, 5, 89, 75, 80],
  [46, 48, 5, 107, 37, 51],
  [30, 43, 5, 6, 18, 67],
  [16, 132, 5, 17, 18, 42],
  [16, 96, 5, 86, 54, 47],
  [3, 112, 5, 59, 10, 15],
  [3, 48, 5, 107, 62, 101],
  [16, 112, 5, 13, 49, 80],
  [16, 81, 5, 36, 54, 84],
  [46, 104, 5, 92, 114, 11],
  [24, 66, 5, 32, 98, 11],
  [20, 55, 5, 89, 100, 122],
  [30, 85, 5, 53, 94, 131],
  [20, 35, 5, 53, 56, 105],
  [16, 43, 5, 36, 130, 78],
  [16, 106, 5, 139, 49, 34],
  [30, 72, 5, 17, 73, 101],
  [39, 69, 5, 36, 102, 137],
  [16, 96, 5, 59, 7, 76],
  [16, 77, 5, 107, 33, 74],
  [16, 10, 5, 86, 102, 65],
  [20, 106, 5, 6, 64, 78],
  [24, 66, 5, 107, 75, 34],
  [12, 66, 5, 6, 10, 88],
  [46, 81, 5, 22, 54, 47],
  [24, 132, 5, 107, 29, 23],
  [16, 35, 5, 92, 90, 11],
  [16, 117, 5, 22, 29, 78],
  [30, 113, 5, 89, 7, 42],
  [24, 77, 5, 13, 54, 108],
  [3, 104, 5, 63, 56, 134],
  [24, 48, 5, 86, 18, 76],
  [12, 87, 5, 6, 100, 91],
  [46, 103, 5, 13, 62, 11],
  [20, 57, 5, 13, 64, 111],
  [16, 28, 5, 107, 73, 93],
  [16, 85, 5, 32, 49, 122],
  [16, 4, 5, 63, 18, 45],
  [30, 116, 5, 63, 7, 68],
  [20, 81, 5, 107, 18, 99],
  [20, 106, 5, 26, 102, 70],
  [46, 81, 5, 13, 18, 99],
  [39, 27, 5, 26, 29, 91],
  [16, 135, 5, 17, 49, 8],
  [39, 96, 5, 107, 56, 78],
  [30, 135, 5, 13, 73, 8],
  [3, 96, 5, 17, 41, 99],
  [16, 66, 5, 6, 44, 101],
  [24, 27, 5, 26, 18, 115],
  [30, 103, 5, 92, 124, 71],
  [16, 120, 5, 17, 44, 126],
  [20, 48, 5, 17, 114, 111],
  [39, 141, 5, 59, 75, 71],
  [30, 81, 5, 32, 37, 111],
  [46, 43, 5, 59, 75, 115],
  [20, 9, 5, 17, 130, 65],
  [24, 132, 5, 13, 64, 101],
  [30, 106, 5, 63, 62, 115],
  [24, 81, 5, 22, 7, 101],
  [46, 28, 5, 36, 62, 74],
  [30, 132, 5, 139, 54, 93],
  [16, 96, 5, 17, 114, 70],
  [30, 66, 5, 6, 14, 97],
  [16, 128, 5, 6, 56, 68],
  [12, 48, 5, 89, 37, 99],
  [39, 35, 5, 22, 7, 51],
  [20, 35, 5, 26, 18, 129],
  [39, 106, 5, 89, 14, 78],
  [12, 135, 5, 17, 14, 11],
  [46, 132, 5, 63, 56, 91],
  [12, 87, 5, 36, 94, 8],
  [3, 48, 5, 92, 82, 38],
  [16, 140, 5, 6, 7, 83],
  [12, 103, 5, 6, 33, 68],
  [24, 106, 5, 92, 90, 108],
  [30, 112, 5, 63, 64, 111],
  [16, 35, 5, 32, 64, 8],
  [39, 117, 5, 59, 18, 71],
  [12, 57, 5, 107, 54, 68],
  [20, 57, 5, 53, 56, 61],
  [46, 4, 5, 17, 82, 101],
  [16, 57, 5, 6, 7, 67],
  [39, 31, 5, 26, 7, 131],
  [12, 66, 5, 89, 64, 70],
  [24, 81, 5, 59, 7, 35],
  [46, 81, 5, 53, 44, 122],
  [12, 50, 5, 6, 18, 45],
  [46, 25, 5, 26, 100, 88],
  [3, 58, 5, 26, 14, 137],
  [3, 52, 5, 89, 102, 105],
  [3, 85, 5, 17, 56, 34],
  [30, 66, 5, 63, 7, 99],
  [20, 28, 5, 92, 29, 108],
  [12, 85, 5, 13, 18, 10],
  [24, 116, 5, 6, 7, 99],
  [30, 104, 5, 36, 14, 71],
  [46, 85, 5, 22, 18, 68],
  [12, 50, 5, 22, 49, 74],
  [12, 48, 5, 107, 130, 97],
  [24, 43, 5, 59, 33, 65],
  [20, 25, 5, 26, 114, 10],
  [20, 113, 5, 92, 75, 23],
  [20, 58, 5, 17, 64, 68],
  [3, 132, 5, 13, 82, 134],
  [12, 58, 5, 59, 37, 8],
  [46, 35, 5, 107, 102, 51],
  [46, 132, 5, 59, 14, 70],
  [3, 112, 5, 107, 62, 38],
  [12, 85, 5, 107, 44, 115],
  [3, 35, 5, 6, 18, 121],
  [39, 123, 5, 32, 49, 84],
  [30, 43, 5, 17, 18, 115],
  [16, 10, 5, 92, 102, 19],
  [30, 81, 5, 6, 18, 76],
  [20, 87, 5, 53, 94, 117],
  [16, 57, 5, 17, 100, 45],
  [3, 52, 5, 17, 14, 95],
  [46, 72, 5, 63, 124, 101],
  [12, 43, 5, 32, 18, 108],
  [24, 87, 5, 63, 82, 136],
  [46, 55, 5, 17, 62, 8],
  [12, 113, 5, 22, 18, 108],
  [3, 103, 5, 6, 54, 93],
  [20, 135, 5, 13, 94, 76],
  [12, 96, 5, 26, 33, 101],
  [30, 31, 5, 53, 14, 51],
  [12, 141, 5, 36, 29, 105],
  [12, 52, 5, 107, 62, 38],
  [3, 72, 5, 89, 62, 70],
  [3, 112, 5, 107, 18, 138],
  [46, 72, 5, 92, 33, 76],
  [20, 43, 5, 63, 44, 105],
  [3, 113, 5, 32, 18, 34],
  [3, 81, 5, 63, 44, 23],
  [16, 66, 5, 17, 90, 91],
  [46, 135, 5, 63, 10, 111],
  [16, 132, 5, 107, 44, 71],
  [3, 87, 5, 17, 54, 122],
  [20, 85, 5, 89, 14, 8],
  [46, 106, 5, 13, 130, 35],
  [30, 132, 5, 63, 18, 131],
  [12, 81, 5, 17, 56, 91],
  [3, 21, 5, 17, 7, 15],
  [39, 103, 5, 107, 44, 115],
  [20, 123, 5, 92, 94, 108],
  [24, 27, 5, 32, 90, 122],
  [3, 28, 5, 36, 7, 95],
  [3, 113, 5, 36, 18, 68],
  [12, 113, 5, 107, 82, 115],
  [39, 85, 5, 89, 33, 11],
  [24, 119, 5, 6, 18, 108],
  [12, 69, 5, 32, 44, 35],
  [39, 35, 5, 107, 18, 8],
  [16, 28, 5, 17, 18, 122],
  [20, 112, 5, 13, 56, 99],
  [12, 116, 5, 89, 7, 71],
  [24, 10, 5, 107, 18, 68],
  [16, 132, 5, 17, 64, 10],
  [20, 58, 5, 32, 64, 10],
  [3, 27, 5, 107, 90, 74],
  [39, 4, 5, 107, 94, 131],
  [3, 104, 5, 6, 124, 67],
  [20, 48, 5, 63, 29, 118],
  [46, 127, 5, 92, 33, 111],
  [20, 103, 5, 92, 62, 131],
  [30, 27, 5, 26, 18, 65],
  [30, 43, 5, 92, 94, 80],
  [46, 48, 5, 17, 60, 115],
  [12, 27, 5, 107, 64, 68],
  [24, 27, 5, 107, 29, 27],
  [46, 112, 5, 53, 130, 129],
  [46, 55, 5, 59, 56, 51],
  [30, 43, 5, 107, 56, 67],
  [16, 10, 5, 26, 56, 105],
  [20, 81, 5, 17, 18, 101],
  [12, 28, 5, 22, 33, 111],
  [24, 55, 5, 13, 14, 8],
  [39, 9, 5, 53, 130, 45],
  [24, 117, 5, 6, 18, 51],
  [39, 35, 5, 92, 18, 126],
  [30, 132, 5, 59, 29, 65],
  [46, 25, 5, 26, 49, 68],
  [3, 96, 5, 59, 130, 71],
  [16, 40, 5, 89, 73, 45],
  [39, 66, 5, 36, 130, 47],
  [3, 125, 5, 63, 14, 122],
  [3, 125, 5, 32, 100, 8],
  [12, 116, 5, 26, 14, 95],
  [16, 106, 5, 63, 75, 34],
  [16, 106, 5, 139, 7, 51],
  [3, 35, 5, 63, 82, 83],
  [12, 110, 5, 59, 130, 10],
  [24, 112, 5, 53, 73, 121],
  [16, 52, 5, 6, 18, 80],
  [12, 50, 5, 63, 18, 51],
  [39, 87, 5, 53, 82, 45],
  [46, 31, 5, 17, 64, 121],
  [16, 141, 5, 107, 14, 65],
  [12, 140, 5, 107, 37, 122],
  [12, 117, 5, 107, 7, 70],
  [30, 58, 5, 26, 75, 122],
  [3, 103, 5, 36, 94, 108],
  [20, 135, 5, 36, 73, 111],
  [16, 66, 5, 63, 73, 67],
  [46, 40, 5, 59, 18, 47],
  [3, 135, 5, 36, 49, 122],
  [46, 110, 5, 63, 54, 83],
  [39, 117, 5, 59, 64, 83],
  [39, 58, 5, 89, 73, 47],
  [46, 119, 5, 6, 37, 91],
  [3, 58, 5, 6, 75, 101],
  [12, 4, 5, 107, 44, 8],
  [24, 31, 5, 17, 90, 65],
  [24, 132, 5, 92, 90, 47],
  [20, 113, 5, 139, 64, 111],
  [16, 106, 5, 89, 14, 51],
  [20, 43, 5, 89, 54, 78],
  [20, 50, 5, 107, 7, 23],
  [12, 35, 5, 36, 37, 109],
  [3, 96, 5, 22, 73, 118],
  [46, 66, 5, 63, 33, 71],
  [20, 58, 5, 86, 18, 109],
  [39, 72, 5, 63, 73, 47],
  [12, 9, 5, 26, 18, 42],
  [3, 104, 5, 26, 10, 117],
  [46, 103, 5, 17, 18, 93],
  [3, 106, 5, 92, 10, 108],
  [20, 117, 5, 32, 7, 78],
  [39, 132, 5, 22, 14, 68],
  [12, 40, 5, 17, 10, 99],
  [46, 55, 5, 92, 18, 91],
  [12, 72, 5, 13, 100, 83],
  [20, 106, 5, 22, 18, 68],
  [39, 43, 5, 59, 82, 11],
  [16, 57, 5, 107, 54, 78],
  [39, 132, 5, 36, 102, 15],
  [12, 103, 5, 92, 18, 93],
  [24, 81, 5, 13, 7, 126],
  [20, 43, 5, 26, 18, 131],
  [3, 125, 5, 17, 18, 111],
  [24, 69, 5, 6, 114, 111],
  [39, 112, 5, 107, 90, 71],
  [12, 40, 5, 32, 90, 118],
  [46, 87, 5, 59, 37, 76],
  [24, 50, 5, 92, 100, 118],
  [39, 43, 5, 22, 29, 111],
  [12, 4, 5, 92, 130, 108],
  [39, 57, 5, 59, 130, 83],
  [39, 106, 5, 53, 56, 70],
  [39, 112, 5, 63, 64, 84],
  [12, 119, 5, 92, 90, 93],
  [3, 104, 5, 17, 7, 68],
  [39, 48, 5, 89, 73, 11],
  [3, 127, 5, 107, 56, 131],
  [12, 35, 5, 17, 29, 76],
  [12, 4, 5, 17, 7, 74],
  [16, 106, 5, 6, 18, 76],
  [30, 120, 5, 32, 94, 101],
  [39, 31, 5, 63, 18, 78],
  [16, 48, 5, 17, 62, 108],
  [39, 57, 5, 89, 82, 74],
  [12, 112, 5, 13, 64, 131],
  [46, 106, 5, 17, 90, 42],
  [20, 96, 5, 59, 37, 74],
  [3, 113, 5, 92, 18, 134],
  [16, 43, 5, 13, 90, 131],
  [3, 55, 5, 36, 100, 71],
  [46, 57, 5, 59, 7, 51],
  [24, 106, 5, 36, 18, 45],
  [46, 96, 5, 6, 62, 111],
  [3, 9, 5, 59, 130, 34],
  [20, 113, 5, 17, 44, 74],
  [46, 141, 5, 13, 62, 71],
  [20, 50, 5, 17, 10, 10],
  [20, 50, 5, 32, 64, 111],
  [46, 128, 5, 13, 75, 118],
  [12, 128, 5, 22, 102, 71],
  [39, 58, 5, 107, 18, 108],
  [12, 77, 5, 92, 18, 105],
  [16, 72, 5, 53, 18, 80],
  [3, 72, 5, 59, 14, 105],
  [3, 52, 5, 89, 18, 122],
  [30, 69, 5, 36, 18, 105],
  [12, 103, 5, 59, 18, 76],
  [46, 81, 5, 107, 130, 101],
  [16, 58, 5, 59, 102, 8],
  [16, 69, 5, 36, 29, 131],
  [16, 31, 5, 36, 64, 121],
  [20, 9, 5, 92, 14, 19],
  [20, 57, 5, 107, 54, 47],
  [24, 87, 5, 26, 94, 78],
  [3, 4, 5, 63, 62, 122],
  [30, 69, 5, 13, 114, 47],
  [46, 119, 5, 17, 54, 101],
  [20, 43, 5, 17, 102, 8],
  [20, 128, 5, 13, 14, 122],
  [16, 127, 5, 89, 100, 105],
  [46, 69, 5, 32, 94, 10],
  [12, 133, 5, 32, 100, 122],
  [3, 117, 5, 32, 62, 122],
  [46, 66, 5, 17, 18, 121],
  [20, 35, 5, 107, 100, 71],
  [30, 96, 5, 26, 33, 99],
  [24, 81, 5, 92, 18, 118],
  [3, 123, 5, 26, 44, 68],
  [24, 31, 5, 13, 102, 27],
  [24, 31, 5, 36, 75, 34],
  [12, 66, 5, 53, 94, 51],
  [16, 28, 5, 63, 18, 71],
  [20, 69, 5, 36, 18, 93],
  [12, 28, 5, 32, 44, 99],
  [30, 57, 5, 13, 7, 131],
  [3, 48, 5, 22, 18, 121],
  [24, 58, 5, 59, 33, 71],
  [24, 128, 5, 22, 49, 8],
  [16, 58, 5, 63, 18, 11],
  [39, 117, 5, 63, 33, 47],
  [12, 31, 5, 26, 73, 134],
  [20, 48, 5, 6, 18, 122],
  [16, 25, 5, 32, 49, 101],
  [24, 81, 5, 89, 18, 101],
  [3, 28, 5, 36, 10, 101],
  [20, 43, 5, 32, 82, 38],
  [12, 81, 5, 36, 100, 71],
  [30, 110, 5, 17, 75, 121],
  [16, 106, 5, 13, 100, 65],
  [39, 43, 5, 36, 41, 27],
  [30, 133, 5, 17, 73, 111],
  [16, 132, 5, 92, 18, 115],
  [30, 58, 5, 17, 56, 70],
  [39, 87, 5, 32, 10, 65],
  [24, 40, 5, 59, 18, 34],
  [24, 55, 5, 107, 75, 111],
  [39, 135, 5, 107, 75, 97],
  [16, 87, 5, 36, 18, 131],
  [30, 120, 5, 22, 37, 83],
  [3, 57, 5, 89, 130, 67],
  [30, 112, 5, 26, 102, 71],
  [20, 31, 5, 89, 37, 35],
  [16, 103, 5, 107, 130, 137],
  [30, 35, 5, 36, 82, 10],
  [3, 96, 5, 22, 62, 47],
  [46, 69, 5, 63, 29, 111],
  [24, 96, 5, 92, 56, 105],
  [16, 27, 5, 17, 94, 129],
  [3, 28, 5, 92, 54, 78],
  [3, 52, 5, 36, 124, 111],
  [16, 96, 5, 36, 100, 10],
  [30, 66, 5, 59, 100, 83],
  [24, 66, 5, 59, 44, 131],
  [46, 9, 5, 6, 18, 122],
  [30, 28, 5, 26, 18, 19],
  [46, 40, 5, 89, 37, 108],
  [20, 55, 5, 22, 14, 122],
  [39, 69, 5, 107, 98, 129],
  [16, 43, 5, 107, 29, 105],
  [16, 55, 5, 22, 33, 68],
  [16, 132, 5, 92, 54, 80],
  [12, 31, 5, 13, 130, 74],
  [3, 85, 5, 17, 18, 122],
  [46, 50, 5, 107, 130, 109],
  [46, 28, 5, 36, 64, 111],
  [46, 50, 5, 107, 18, 74],
  [12, 69, 5, 107, 114, 34],
  [16, 72, 5, 36, 130, 71],
  [3, 69, 5, 6, 29, 70],
  [3, 85, 5, 36, 130, 74],
  [30, 28, 5, 53, 44, 19],
  [20, 43, 5, 32, 18, 111],
  [3, 127, 5, 6, 56, 71],
  [20, 31, 5, 107, 94, 80],
  [20, 21, 5, 17, 54, 34],
  [16, 119, 5, 6, 29, 109],
  [30, 77, 5, 36, 18, 65],
  [20, 35, 5, 22, 90, 68],
  [46, 43, 5, 53, 102, 74],
  [39, 31, 5, 6, 10, 137],
  [12, 81, 5, 22, 73, 74],
  [12, 116, 5, 89, 90, 67],
  [30, 103, 5, 36, 33, 122],
  [39, 66, 5, 36, 18, 115],
  [30, 55, 5, 63, 90, 84],
  [20, 28, 5, 13, 18, 76],
  [39, 50, 5, 13, 56, 76],
  [46, 25, 5, 17, 49, 126],
  [12, 4, 5, 86, 29, 74],
  [30, 88, 5, 13, 18, 76],
  [46, 87, 5, 13, 49, 115],
  [46, 106, 5, 63, 33, 78],
  [39, 55, 5, 89, 75, 121],
  [3, 58, 5, 92, 100, 70],
  [16, 132, 5, 89, 29, 111],
  [12, 69, 5, 6, 54, 10],
  [16, 112, 5, 63, 7, 111],
  [3, 31, 5, 63, 64, 115],
  [39, 132, 5, 17, 7, 134],
  [39, 48, 5, 13, 73, 88],
  [16, 43, 5, 32, 75, 83],
  [16, 103, 5, 32, 75, 74],
  [3, 50, 5, 59, 130, 105],
  [24, 66, 5, 59, 14, 122],
  [12, 103, 5, 89, 98, 35],
  [20, 119, 5, 26, 75, 105],
  [16, 119, 5, 13, 37, 78],
  [46, 81, 5, 89, 94, 99],
  [16, 135, 5, 17, 75, 84],
  [3, 72, 5, 53, 73, 105],
  [3, 43, 5, 89, 54, 35],
  [24, 43, 5, 86, 18, 108],
  [46, 69, 5, 89, 37, 95],
  [20, 77, 5, 107, 130, 76],
  [12, 133, 5, 6, 14, 65],
  [46, 31, 5, 17, 130, 35],
  [39, 9, 5, 92, 18, 71],
  [24, 25, 5, 22, 90, 71],
  [30, 112, 5, 92, 37, 78],
  [39, 77, 5, 92, 41, 15],
  [3, 81, 5, 53, 56, 38],
  [39, 55, 5, 26, 102, 70],
  [12, 66, 5, 6, 73, 78],
  [20, 66, 5, 13, 7, 23],
  [3, 112, 5, 89, 29, 11],
  [3, 57, 5, 26, 102, 131],
  [46, 140, 5, 22, 82, 10],
  [16, 25, 5, 22, 62, 105],
  [24, 133, 5, 17, 82, 122],
  [16, 106, 5, 32, 90, 47],
  [39, 112, 5, 86, 102, 95],
  [30, 50, 5, 107, 114, 122],
  [39, 127, 5, 6, 56, 137],
  [30, 85, 5, 17, 62, 131],
  [12, 112, 5, 59, 10, 47],
  [24, 112, 5, 89, 90, 83],
  [16, 43, 5, 92, 100, 83],
  [24, 104, 5, 59, 7, 65],
  [39, 133, 5, 17, 62, 126],
  [3, 106, 5, 36, 54, 65],
  [46, 72, 5, 17, 100, 80],
  [3, 48, 5, 32, 102, 68],
  [46, 50, 5, 13, 90, 134],
  [39, 43, 5, 26, 114, 45],
  [3, 58, 5, 92, 44, 80],
  [20, 106, 5, 26, 94, 68],
  [12, 81, 5, 92, 7, 115],
  [16, 35, 5, 32, 94, 78],
  [46, 103, 5, 17, 114, 91],
  [3, 116, 5, 6, 54, 8],
  [46, 103, 5, 36, 82, 71],
  [3, 120, 5, 17, 56, 111],
  [3, 79, 5, 107, 73, 65],
  [30, 10, 5, 107, 7, 67],
  [16, 69, 5, 92, 102, 10],
  [39, 79, 5, 6, 37, 126],
  [30, 52, 5, 22, 56, 84],
  [20, 123, 5, 107, 49, 83],
  [20, 81, 5, 59, 82, 121],
  [24, 117, 5, 36, 29, 38],
  [30, 31, 5, 92, 130, 45],
  [24, 66, 5, 26, 64, 111],
  [12, 96, 5, 17, 18, 15],
  [20, 85, 5, 17, 56, 83],
  [12, 27, 5, 6, 62, 80],
  [46, 106, 5, 22, 90, 131],
  [46, 50, 5, 89, 44, 67],
  [16, 35, 5, 6, 7, 19],
  [30, 35, 5, 6, 114, 68],
  [24, 27, 5, 32, 18, 35],
  [39, 119, 5, 32, 44, 137],
  [3, 141, 5, 6, 14, 71],
  [16, 96, 5, 63, 33, 19],
  [39, 104, 5, 92, 94, 70],
  [39, 43, 5, 36, 75, 8],
  [16, 43, 5, 63, 130, 101],
  [16, 127, 5, 13, 33, 95],
  [46, 58, 5, 22, 18, 38],
  [30, 31, 5, 26, 114, 143],
  [20, 77, 5, 32, 18, 138],
  [16, 58, 5, 13, 75, 80],
  [3, 66, 5, 17, 62, 15],
  [20, 112, 5, 13, 114, 129],
  [20, 50, 5, 13, 82, 78],
  [39, 135, 5, 17, 56, 74],
  [30, 135, 5, 26, 62, 47],
  [16, 57, 5, 92, 73, 91],
  [12, 125, 5, 86, 130, 115],
  [20, 81, 5, 53, 14, 42],
  [20, 106, 5, 17, 10, 80],
  [3, 85, 5, 22, 37, 115],
  [16, 103, 5, 32, 100, 34],
  [20, 113, 5, 63, 44, 47],
  [30, 55, 5, 36, 82, 122],
  [46, 28, 5, 59, 29, 101],
  [3, 69, 5, 6, 62, 8],
  [20, 132, 5, 59, 130, 71],
  [24, 28, 5, 6, 56, 91],
  [30, 120, 5, 36, 75, 76],
  [3, 31, 5, 36, 114, 71],
  [30, 10, 5, 36, 102, 11],
  [16, 28, 5, 107, 100, 74],
  [39, 72, 5, 107, 54, 42],
  [20, 81, 5, 22, 18, 137],
  [30, 117, 5, 107, 98, 71],
  [30, 57, 5, 92, 114, 11],
  [3, 48, 5, 63, 102, 67],
  [16, 28, 5, 92, 44, 105],
  [12, 116, 5, 107, 114, 93],
  [39, 57, 5, 17, 102, 45],
  [16, 106, 5, 53, 18, 74],
  [16, 113, 5, 36, 14, 47],
  [39, 57, 5, 32, 29, 131],
  [24, 10, 5, 17, 33, 121],
  [30, 40, 5, 13, 90, 47],
  [12, 40, 5, 13, 49, 11],
  [30, 43, 5, 107, 73, 67],
  [16, 110, 5, 86, 62, 83],
  [3, 69, 5, 107, 33, 71],
  [20, 10, 5, 53, 18, 122],
  [12, 81, 5, 92, 37, 97],
  [16, 104, 5, 32, 130, 23],
  [39, 35, 5, 63, 33, 115],
  [3, 103, 5, 13, 33, 109],
  [30, 112, 5, 13, 44, 137],
  [16, 52, 5, 6, 33, 101],
  [30, 128, 5, 89, 10, 131],
  [20, 57, 5, 26, 94, 134],
  [46, 48, 5, 26, 14, 115],
  [12, 4, 5, 107, 54, 105],
  [46, 81, 5, 59, 100, 71],
  [39, 87, 5, 59, 130, 115],
  [12, 69, 5, 13, 18, 71],
  [3, 132, 5, 13, 62, 68],
  [24, 28, 5, 107, 100, 67],
  [3, 66, 5, 22, 18, 105],
  [46, 103, 5, 63, 102, 67],
  [30, 132, 5, 59, 90, 11],
  [3, 106, 5, 86, 75, 108],
  [39, 66, 5, 17, 18, 84],
  [3, 27, 5, 107, 75, 131],
  [16, 113, 5, 22, 37, 105],
  [16, 31, 5, 53, 54, 65],
  [39, 113, 5, 89, 44, 74],
  [39, 31, 5, 17, 18, 118],
  [30, 113, 5, 89, 44, 111],
  [46, 57, 5, 63, 62, 83],
  [3, 88, 5, 13, 56, 51],
  [3, 66, 5, 13, 64, 78],
  [24, 48, 5, 17, 18, 122],
  [16, 81, 5, 17, 7, 115],
  [46, 50, 5, 53, 7, 34],
  [30, 50, 5, 139, 14, 115],
  [30, 128, 5, 36, 114, 80],
  [12, 66, 5, 6, 18, 83],
  [30, 50, 5, 63, 114, 70],
  [3, 104, 5, 13, 18, 71],
  [20, 119, 5, 59, 33, 27],
  [46, 28, 5, 17, 33, 97],
  [12, 58, 5, 89, 64, 80],
  [30, 43, 5, 22, 75, 137],
  [12, 55, 5, 89, 18, 70],
  [3, 127, 5, 13, 114, 122],
  [20, 55, 5, 92, 29, 115],
  [30, 135, 5, 92, 56, 38],
  [39, 57, 5, 59, 62, 93],
  [16, 57, 5, 63, 29, 121],
  [46, 103, 5, 17, 75, 76],
  [46, 87, 5, 32, 82, 129],
  [3, 48, 5, 63, 82, 129],
  [24, 96, 5, 89, 100, 34],
  [12, 57, 5, 13, 29, 74],
  [46, 85, 5, 107, 94, 115],
  [30, 4, 5, 107, 54, 99],
  [24, 25, 5, 17, 14, 131],
  [30, 58, 5, 92, 18, 11],
  [20, 48, 5, 107, 18, 131],
  [3, 116, 5, 92, 130, 34],
  [39, 127, 5, 17, 33, 78],
  [3, 43, 5, 32, 14, 93],
  [30, 110, 5, 17, 94, 67],
  [12, 104, 5, 92, 114, 23],
  [3, 133, 5, 6, 41, 71],
  [24, 123, 5, 63, 44, 121],
  [30, 141, 5, 6, 33, 131],
  [16, 57, 5, 92, 18, 108],
  [20, 142, 5, 32, 49, 67],
  [30, 113, 5, 32, 44, 101],
  [39, 133, 5, 59, 94, 80],
  [46, 40, 5, 32, 82, 117],
  [39, 50, 5, 59, 54, 108],
  [30, 85, 5, 89, 18, 51],
  [39, 104, 5, 36, 102, 47],
  [16, 72, 5, 36, 100, 47],
  [3, 104, 5, 13, 33, 129],
  [30, 58, 5, 59, 18, 131],
  [16, 21, 5, 22, 18, 109],
  [16, 128, 5, 59, 44, 71],
  [12, 123, 5, 22, 73, 111],
  [24, 140, 5, 59, 44, 99],
  [3, 57, 5, 13, 14, 67],
  [46, 106, 5, 13, 130, 80],
  [20, 85, 5, 32, 62, 10],
  [12, 135, 5, 13, 33, 84],
  [3, 116, 5, 59, 62, 11],
  [3, 31, 5, 32, 75, 47],
  [12, 104, 5, 53, 54, 121],
  [3, 69, 5, 92, 29, 74],
  [16, 113, 5, 63, 18, 93],
  [12, 117, 5, 89, 18, 83],
  [3, 132, 5, 17, 49, 67],
  [20, 57, 5, 17, 33, 38],
  [12, 27, 5, 22, 82, 47],
  [30, 106, 5, 32, 73, 138],
  [16, 96, 5, 6, 7, 83],
  [12, 81, 5, 26, 102, 101],
  [3, 50, 5, 13, 54, 47],
  [46, 57, 5, 17, 100, 111],
  [30, 69, 5, 92, 94, 105],
  [3, 113, 5, 63, 29, 71],
  [12, 112, 5, 89, 75, 101],
  [30, 58, 5, 36, 73, 35],
  [3, 128, 5, 59, 7, 99],
  [20, 96, 5, 59, 64, 68],
  [30, 140, 5, 17, 18, 74],
  [16, 96, 5, 89, 94, 117],
  [30, 40, 5, 6, 37, 131],
  [20, 58, 5, 6, 73, 105],
  [3, 57, 5, 89, 56, 115],
  [3, 133, 5, 26, 56, 10],
  [24, 96, 5, 32, 130, 65],
  [16, 113, 5, 22, 114, 11],
  [16, 25, 5, 26, 29, 84],
  [24, 132, 5, 59, 75, 76],
  [3, 85, 5, 36, 33, 117],
  [30, 10, 5, 6, 18, 91],
  [46, 104, 5, 63, 18, 42],
  [46, 128, 5, 32, 29, 68],
  [3, 9, 5, 13, 29, 67],
  [24, 119, 5, 107, 49, 71],
  [16, 66, 5, 53, 56, 88],
  [16, 96, 5, 107, 14, 19],
  [30, 35, 5, 107, 7, 105],
  [20, 81, 5, 32, 75, 108],
  [12, 58, 5, 59, 75, 121],
  [20, 58, 5, 53, 18, 108],
  [3, 112, 5, 92, 75, 83],
  [12, 31, 5, 63, 29, 91],
  [24, 116, 5, 26, 114, 84],
  [30, 112, 5, 36, 114, 47],
  [12, 72, 5, 26, 37, 111],
  [39, 132, 5, 92, 37, 115],
  [16, 81, 5, 107, 75, 115],
  [16, 69, 5, 86, 44, 34],
  [46, 104, 5, 32, 37, 105],
  [16, 31, 5, 36, 18, 109],
  [39, 104, 5, 32, 94, 8],
  [16, 103, 5, 17, 73, 80],
  [24, 69, 5, 22, 18, 138],
  [20, 4, 5, 13, 62, 78],
  [46, 35, 5, 63, 29, 51],
  [20, 104, 5, 17, 64, 76],
  [39, 85, 5, 17, 98, 27],
  [46, 106, 5, 139, 56, 84],
  [20, 57, 5, 22, 44, 42],
  [12, 116, 5, 92, 33, 122],
  [46, 28, 5, 92, 114, 68],
  [12, 57, 5, 89, 37, 129],
  [12, 116, 5, 6, 75, 35],
  [3, 125, 5, 89, 18, 115],
  [3, 103, 5, 92, 29, 67],
  [24, 28, 5, 92, 7, 76],
  [20, 119, 5, 53, 102, 68],
  [16, 28, 5, 59, 73, 38],
  [3, 104, 5, 107, 14, 91],
  [30, 28, 5, 92, 29, 129],
  [46, 69, 5, 22, 90, 115],
  [3, 112, 5, 22, 114, 47],
  [46, 81, 5, 63, 18, 129],
  [30, 132, 5, 92, 102, 108],
  [46, 103, 5, 63, 73, 111],
  [16, 50, 5, 32, 18, 108],
  [16, 57, 5, 26, 60, 101],
  [12, 125, 5, 32, 56, 131],
  [16, 66, 5, 107, 75, 88],
  [16, 55, 5, 26, 82, 74],
  [24, 79, 5, 17, 62, 67],
  [46, 57, 5, 22, 10, 84],
  [12, 52, 5, 36, 98, 105],
  [3, 69, 5, 17, 94, 65],
  [24, 104, 5, 17, 100, 122],
  [3, 66, 5, 107, 37, 68],
  [30, 72, 5, 32, 33, 74],
  [3, 112, 5, 59, 10, 65],
  [30, 96, 5, 17, 82, 45],
  [24, 66, 5, 36, 102, 67],
  [20, 55, 5, 36, 18, 93],
  [30, 113, 5, 53, 54, 80],
  [20, 119, 5, 22, 37, 34],
  [46, 28, 5, 53, 75, 61],
  [39, 96, 5, 89, 54, 122],
  [12, 72, 5, 22, 18, 42],
  [3, 69, 5, 6, 37, 122],
  [16, 103, 5, 22, 29, 80],
  [46, 120, 5, 17, 75, 97],
  [46, 66, 5, 32, 94, 74],
  [3, 116, 5, 59, 7, 67],
  [39, 55, 5, 92, 102, 131],
  [24, 96, 5, 86, 54, 70],
  [46, 96, 5, 63, 56, 67],
  [3, 72, 5, 92, 18, 109],
  [46, 9, 5, 22, 130, 111],
  [39, 119, 5, 59, 90, 34],
  [16, 21, 5, 17, 130, 27],
  [24, 66, 5, 107, 18, 70],
  [16, 87, 5, 13, 14, 122],
  [46, 31, 5, 63, 73, 68],
  [39, 127, 5, 17, 18, 121],
  [46, 43, 5, 17, 82, 109],
  [16, 4, 5, 6, 114, 115],
  [39, 69, 5, 26, 33, 108],
  [30, 104, 5, 17, 18, 34],
  [30, 57, 5, 17, 75, 99],
  [16, 35, 5, 63, 37, 108],
  [30, 85, 5, 92, 100, 117],
  [3, 85, 5, 26, 56, 67],
  [24, 25, 5, 89, 18, 74],
  [20, 77, 5, 13, 37, 8],
  [46, 112, 5, 6, 56, 8],
  [3, 48, 5, 36, 102, 115],
  [20, 106, 5, 22, 56, 80],
  [3, 133, 5, 26, 29, 42],
  [39, 48, 5, 13, 75, 111],
  [3, 58, 5, 22, 94, 101],
  [3, 106, 5, 107, 56, 105],
  [12, 66, 5, 63, 56, 11],
  [20, 43, 5, 26, 102, 23],
  [46, 117, 5, 89, 37, 47],
  [16, 120, 5, 63, 75, 34],
  [24, 132, 5, 26, 44, 115],
  [3, 28, 5, 36, 33, 45],
  [24, 104, 5, 53, 100, 65],
  [3, 48, 5, 22, 54, 122],
  [20, 85, 5, 32, 62, 99],
  [3, 58, 5, 13, 100, 105],
  [20, 116, 5, 17, 44, 101],
  [30, 69, 5, 53, 29, 108],
  [16, 132, 5, 6, 82, 143],
  [12, 119, 5, 107, 98, 68],
  [16, 135, 5, 22, 18, 83],
  [39, 135, 5, 59, 94, 71],
  [46, 113, 5, 63, 102, 108],
  [30, 96, 5, 59, 41, 76],
  [16, 104, 5, 86, 7, 65],
  [12, 88, 5, 92, 18, 80],
  [16, 104, 5, 36, 56, 76],
  [46, 9, 5, 32, 64, 70],
  [12, 21, 5, 17, 18, 108],
  [20, 27, 5, 63, 94, 129],
  [20, 133, 5, 63, 29, 47],
  [3, 55, 5, 6, 75, 99],
  [12, 104, 5, 53, 54, 83],
  [39, 103, 5, 22, 18, 111],
  [16, 96, 5, 22, 56, 108],
  [24, 119, 5, 107, 75, 91],
  [20, 85, 5, 13, 82, 134],
  [12, 79, 5, 22, 7, 99],
  [12, 28, 5, 13, 10, 84],
  [39, 110, 5, 107, 7, 105],
  [30, 28, 5, 13, 7, 91],
  [30, 27, 5, 86, 102, 93],
  [30, 35, 5, 36, 62, 76],
  [16, 106, 5, 17, 18, 131],
  [46, 52, 5, 17, 73, 105],
  [12, 55, 5, 26, 82, 91],
  [3, 96, 5, 6, 73, 45],
  [39, 133, 5, 107, 100, 115],
  [20, 43, 5, 59, 44, 131],
  [12, 25, 5, 36, 73, 11],
  [20, 31, 5, 26, 82, 67],
  [16, 132, 5, 92, 90, 65],
  [3, 103, 5, 59, 102, 45],
  [24, 120, 5, 13, 7, 105],
  [3, 28, 5, 63, 82, 11],
  [16, 104, 5, 32, 18, 65],
  [30, 43, 5, 26, 56, 71],
  [16, 113, 5, 53, 10, 137],
  [3, 104, 5, 22, 18, 83],
  [3, 110, 5, 63, 102, 68],
  [20, 135, 5, 17, 44, 8],
  [30, 55, 5, 26, 54, 34],
  [3, 57, 5, 107, 130, 23],
  [46, 48, 5, 13, 90, 71],
  [20, 112, 5, 22, 7, 67],
  [24, 113, 5, 107, 44, 71],
  [20, 9, 5, 53, 82, 74],
  [20, 123, 5, 32, 64, 111],
  [3, 103, 5, 6, 18, 105],
  [30, 87, 5, 107, 56, 83],
  [24, 119, 5, 17, 18, 121],
  [3, 88, 5, 107, 18, 122],
  [24, 66, 5, 13, 75, 61],
  [39, 96, 5, 36, 33, 101],
  [12, 50, 5, 32, 18, 111],
  [30, 119, 5, 36, 7, 83],
  [12, 135, 5, 6, 82, 10],
  [16, 69, 5, 17, 54, 91],
  [16, 28, 5, 107, 37, 80],
  [20, 103, 5, 107, 54, 80],
  [12, 113, 5, 36, 64, 68],
  [3, 31, 5, 63, 75, 108],
  [30, 96, 5, 22, 54, 122],
  [24, 25, 5, 32, 49, 67],
  [16, 117, 5, 6, 102, 111],
  [39, 72, 5, 17, 75, 83],
  [20, 96, 5, 36, 98, 8],
  [16, 128, 5, 107, 90, 101],
  [16, 104, 5, 36, 64, 76],
  [20, 104, 5, 32, 94, 117],
  [3, 35, 5, 26, 10, 19],
  [16, 123, 5, 13, 102, 131],
  [3, 133, 5, 92, 18, 35],
  [24, 55, 5, 92, 29, 76],
  [39, 25, 5, 59, 49, 74],
  [20, 57, 5, 53, 90, 111],
  [12, 66, 5, 26, 64, 78],
  [46, 58, 5, 6, 44, 19],
  [46, 81, 5, 92, 7, 137],
  [24, 119, 5, 13, 18, 70],
  [12, 103, 5, 53, 18, 91],
  [16, 113, 5, 22, 75, 71],
  [16, 110, 5, 59, 10, 115],
  [16, 132, 5, 63, 90, 83],
  [16, 58, 5, 13, 100, 67],
  [12, 106, 5, 32, 18, 99],
  [16, 128, 5, 36, 14, 65],
  [46, 142, 5, 89, 124, 68],
  [30, 104, 5, 26, 62, 76],
  [3, 28, 5, 107, 54, 101],
  [12, 103, 5, 92, 100, 138],
  [30, 103, 5, 22, 10, 99],
  [12, 72, 5, 86, 56, 45],
  [3, 58, 5, 107, 54, 83],
  [3, 35, 5, 59, 73, 83],
  [3, 58, 5, 89, 18, 115],
  [20, 43, 5, 36, 18, 47],
  [20, 85, 5, 92, 18, 8],
  [12, 28, 5, 17, 18, 88],
  [16, 106, 5, 63, 7, 70],
  [20, 28, 5, 32, 90, 45],
  [3, 28, 5, 17, 44, 67],
  [46, 50, 5, 36, 124, 138],
  [20, 58, 5, 92, 54, 65],
  [24, 135, 5, 26, 33, 8],
  [46, 81, 5, 17, 18, 93],
  [24, 57, 5, 92, 14, 34],
  [16, 69, 5, 59, 33, 108],
  [16, 113, 5, 36, 18, 8],
  [12, 55, 5, 89, 114, 134],
  [16, 40, 5, 36, 7, 80],
  [46, 140, 5, 107, 56, 35],
  [16, 87, 5, 6, 44, 34],
  [39, 10, 5, 26, 73, 80],
  [39, 52, 5, 92, 94, 111],
  [20, 27, 5, 59, 10, 19],
  [46, 66, 5, 17, 90, 11],
  [39, 112, 5, 26, 56, 74],
  [30, 132, 5, 17, 90, 78],
  [24, 88, 5, 36, 102, 111],
  [3, 40, 5, 139, 49, 61],
  [16, 31, 5, 13, 75, 23],
  [12, 69, 5, 53, 73, 71],
  [20, 25, 5, 36, 100, 121],
  [46, 21, 5, 59, 100, 47],
  [12, 50, 5, 53, 62, 111],
  [46, 104, 5, 13, 73, 115],
  [3, 113, 5, 107, 14, 34],
  [39, 72, 5, 107, 64, 10],
  [20, 119, 5, 32, 37, 11],
  [16, 43, 5, 36, 7, 99],
  [3, 35, 5, 13, 29, 47],
  [12, 48, 5, 32, 56, 111],
  [16, 116, 5, 92, 18, 67],
  [46, 57, 5, 92, 56, 105],
  [39, 87, 5, 36, 7, 83],
  [3, 57, 5, 92, 18, 78],
  [24, 132, 5, 89, 75, 115],
  [12, 21, 5, 6, 18, 101],
  [3, 81, 5, 13, 10, 74],
  [3, 66, 5, 6, 33, 105],
  [3, 79, 5, 22, 94, 10],
  [46, 72, 5, 26, 18, 67],
  [16, 88, 5, 53, 75, 65],
  [3, 66, 5, 17, 7, 105],
  [3, 119, 5, 22, 73, 122],
  [16, 123, 5, 22, 18, 11],
  [3, 4, 5, 17, 54, 8],
  [16, 87, 5, 6, 90, 67],
  [24, 116, 5, 26, 102, 65],
  [30, 4, 5, 22, 33, 122],
  [12, 58, 5, 17, 94, 8],
  [16, 106, 5, 36, 82, 70],
  [20, 77, 5, 13, 102, 121],
  [16, 31, 5, 26, 100, 65],
  [39, 35, 5, 17, 73, 11],
  [3, 48, 5, 32, 56, 126],
  [3, 28, 5, 26, 62, 136],
  [16, 69, 5, 13, 62, 105],
  [30, 135, 5, 17, 102, 27],
  [46, 35, 5, 22, 33, 76],
  [39, 135, 5, 13, 41, 105],
  [16, 55, 5, 6, 37, 129],
  [30, 106, 5, 22, 64, 143],
  [3, 103, 5, 139, 29, 47],
  [46, 96, 5, 6, 54, 11],
  [3, 112, 5, 32, 124, 91],
  [12, 43, 5, 32, 64, 91],
  [16, 66, 5, 59, 10, 118],
  [46, 4, 5, 32, 62, 35],
  [3, 104, 5, 92, 7, 101],
  [3, 25, 5, 26, 14, 121],
  [46, 128, 5, 59, 14, 74],
  [24, 85, 5, 59, 29, 99],
  [46, 113, 5, 92, 18, 136],
  [39, 21, 5, 17, 94, 45],
  [30, 48, 5, 59, 102, 78],
  [30, 55, 5, 22, 82, 8],
  [46, 40, 5, 6, 18, 108],
  [30, 28, 5, 92, 44, 99],
  [39, 66, 5, 17, 33, 108],
  [30, 66, 5, 92, 62, 11],
  [16, 116, 5, 107, 64, 80],
  [39, 27, 5, 17, 18, 115],
  [16, 104, 5, 89, 18, 78],
  [12, 58, 5, 32, 102, 45],
  [16, 117, 5, 17, 90, 11],
  [30, 128, 5, 26, 29, 111],
  [3, 27, 5, 6, 62, 27],
  [20, 106, 5, 17, 56, 83],
  [46, 87, 5, 6, 100, 99],
  [16, 66, 5, 32, 7, 108],
  [3, 119, 5, 13, 18, 27],
  [3, 81, 5, 59, 73, 10],
  [12, 25, 5, 53, 54, 68],
  [12, 55, 5, 13, 33, 78],
  [46, 113, 5, 6, 54, 61],
  [3, 69, 5, 22, 62, 65],
  [46, 52, 5, 17, 18, 99],
  [16, 58, 5, 92, 18, 8],
  [16, 135, 5, 17, 90, 95],
  [12, 104, 5, 63, 18, 67],
  [16, 103, 5, 32, 100, 101],
  [16, 128, 5, 36, 100, 8],
  [39, 88, 5, 6, 49, 108],
  [20, 119, 5, 32, 37, 70],
  [24, 27, 5, 53, 14, 99],
  [12, 106, 5, 107, 14, 67],
  [20, 31, 5, 32, 18, 93],
  [12, 79, 5, 26, 64, 108],
  [46, 104, 5, 59, 18, 108],
  [46, 125, 5, 26, 7, 115],
  [12, 112, 5, 22, 124, 47],
  [30, 119, 5, 32, 62, 91],
  [24, 72, 5, 22, 18, 115],
  [46, 55, 5, 17, 75, 67],
  [24, 112, 5, 89, 18, 97],
  [16, 113, 5, 107, 90, 11],
  [24, 50, 5, 63, 114, 47],
  [46, 66, 5, 6, 18, 68],
  [30, 113, 5, 107, 18, 115],
  [46, 31, 5, 13, 44, 45],
  [3, 58, 5, 92, 75, 27],
  [46, 140, 5, 59, 62, 47],
  [24, 106, 5, 17, 62, 76],
  [46, 25, 5, 26, 44, 134],
  [3, 55, 5, 63, 54, 131],
  [24, 79, 5, 26, 33, 111],
  [39, 81, 5, 17, 49, 115],
  [39, 66, 5, 17, 18, 78],
  [30, 31, 5, 32, 56, 10],
  [16, 40, 5, 107, 14, 143],
  [16, 48, 5, 59, 75, 80],
  [46, 31, 5, 92, 33, 99],
  [20, 96, 5, 36, 60, 83],
  [3, 96, 5, 59, 29, 95],
  [12, 4, 5, 13, 64, 121],
  [3, 112, 5, 17, 130, 78],
  [20, 35, 5, 26, 18, 34],
  [24, 103, 5, 53, 14, 45],
  [3, 87, 5, 36, 64, 34],
  [39, 116, 5, 17, 100, 109],
  [46, 113, 5, 13, 29, 93],
  [39, 119, 5, 107, 18, 105],
  [30, 31, 5, 32, 18, 68],
  [30, 116, 5, 13, 49, 78],
  [12, 57, 5, 13, 130, 45],
  [16, 9, 5, 22, 10, 80],
  [3, 57, 5, 53, 64, 83],
  [30, 28, 5, 36, 33, 78],
  [16, 135, 5, 63, 10, 42],
  [20, 35, 5, 107, 10, 11],
  [24, 58, 5, 32, 49, 70],
  [20, 10, 5, 22, 130, 101],
  [39, 52, 5, 63, 33, 99],
  [24, 50, 5, 63, 54, 70],
  [20, 133, 5, 36, 102, 23],
  [16, 117, 5, 59, 33, 45],
  [46, 72, 5, 107, 90, 67],
  [39, 106, 5, 17, 94, 138],
  [39, 43, 5, 86, 7, 101],
  [20, 117, 5, 107, 7, 131],
  [20, 112, 5, 17, 130, 88],
  [46, 104, 5, 13, 33, 95],
  [16, 31, 5, 92, 44, 74],
  [12, 135, 5, 53, 56, 45],
  [30, 58, 5, 22, 54, 74],
  [16, 119, 5, 6, 62, 111],
  [16, 132, 5, 6, 37, 115],
  [39, 31, 5, 59, 82, 126],
  [20, 35, 5, 89, 18, 47],
  [3, 69, 5, 22, 14, 51],
  [24, 43, 5, 59, 64, 105],
  [3, 66, 5, 36, 18, 83],
  [39, 50, 5, 22, 56, 109],
  [30, 132, 5, 17, 44, 34],
  [20, 119, 5, 13, 75, 35],
  [20, 113, 5, 32, 44, 65],
  [3, 55, 5, 59, 18, 122],
  [24, 25, 5, 53, 62, 76],
  [46, 135, 5, 17, 102, 117],
  [16, 72, 5, 107, 56, 68],
  [30, 57, 5, 6, 100, 109],
  [3, 132, 5, 107, 102, 70],
  [39, 81, 5, 6, 62, 35],
  [24, 104, 5, 92, 64, 122],
  [24, 85, 5, 59, 54, 109],
  [3, 112, 5, 32, 90, 115],
  [24, 112, 5, 107, 37, 108],
  [12, 132, 5, 59, 102, 70],
  [46, 141, 5, 6, 18, 137],
  [20, 35, 5, 53, 62, 45],
  [20, 133, 5, 89, 130, 68],
  [39, 57, 5, 107, 90, 68],
  [39, 81, 5, 17, 18, 76],
  [24, 132, 5, 22, 90, 111],
  [20, 127, 5, 13, 18, 108],
  [20, 55, 5, 59, 37, 10],
  [39, 43, 5, 17, 75, 11],
  [3, 55, 5, 26, 29, 129],
  [46, 48, 5, 59, 100, 117],
  [20, 55, 5, 92, 54, 95],
  [20, 43, 5, 36, 44, 76],
  [30, 110, 5, 53, 114, 67],
  [24, 57, 5, 17, 130, 83],
  [46, 81, 5, 53, 18, 118],
  [30, 113, 5, 36, 82, 71],
  [20, 35, 5, 92, 62, 47],
  [46, 96, 5, 13, 102, 99],
  [16, 96, 5, 63, 114, 83],
  [24, 28, 5, 26, 73, 91],
  [39, 113, 5, 53, 18, 122],
  [39, 35, 5, 13, 18, 122],
  [30, 119, 5, 107, 18, 76],
  [16, 127, 5, 92, 18, 80],
  [12, 103, 5, 107, 82, 65],
  [30, 112, 5, 92, 102, 76],
  [30, 43, 5, 17, 18, 105],
  [24, 48, 5, 17, 7, 11],
  [24, 57, 5, 17, 29, 93],
  [16, 119, 5, 53, 73, 8],
  [3, 27, 5, 53, 18, 88],
  [30, 133, 5, 92, 18, 108],
  [3, 35, 5, 17, 75, 131],
  [46, 128, 5, 17, 64, 115],
  [3, 81, 5, 32, 94, 83],
  [16, 31, 5, 107, 54, 51],
  [24, 31, 5, 36, 7, 134],
  [39, 87, 5, 89, 14, 131],
  [30, 96, 5, 26, 41, 91],
  [3, 116, 5, 26, 18, 115],
  [24, 96, 5, 107, 130, 65],
  [39, 43, 5, 53, 124, 109],
  [20, 110, 5, 53, 18, 15],
  [12, 31, 5, 6, 18, 88],
  [30, 116, 5, 59, 18, 93],
  [39, 106, 5, 89, 14, 131],
  [12, 66, 5, 63, 18, 101],
  [20, 31, 5, 13, 29, 91],
  [16, 28, 5, 107, 73, 91],
  [12, 96, 5, 17, 44, 88],
  [39, 69, 5, 107, 90, 51],
  [39, 43, 5, 59, 94, 34],
  [30, 96, 5, 32, 130, 105],
  [3, 27, 5, 63, 62, 11],
  [24, 123, 5, 17, 14, 71],
  [24, 106, 5, 6, 14, 101],
  [20, 43, 5, 17, 82, 11],
  [12, 103, 5, 63, 62, 68],
  [46, 135, 5, 17, 94, 34],
  [3, 48, 5, 36, 62, 122],
  [39, 48, 5, 36, 37, 74],
  [3, 58, 5, 92, 10, 137],
  [46, 31, 5, 13, 75, 71],
  [20, 103, 5, 13, 73, 101],
  [20, 58, 5, 32, 94, 67],
  [46, 104, 5, 107, 90, 70],
  [3, 133, 5, 59, 100, 10],
  [16, 85, 5, 36, 10, 115],
  [20, 55, 5, 92, 90, 108],
  [20, 103, 5, 26, 37, 45],
  [39, 128, 5, 107, 54, 10],
  [3, 58, 5, 22, 124, 101],
  [3, 119, 5, 17, 37, 138],
  [3, 57, 5, 17, 7, 91],
  [30, 43, 5, 6, 18, 8],
  [39, 21, 5, 22, 90, 109],
  [16, 55, 5, 13, 130, 70],
  [12, 58, 5, 22, 14, 95],
  [24, 57, 5, 92, 100, 47],
  [3, 31, 5, 107, 62, 42],
  [12, 132, 5, 26, 82, 8],
  [46, 81, 5, 107, 7, 34],
  [12, 31, 5, 36, 44, 108],
  [24, 132, 5, 13, 62, 78],
  [12, 117, 5, 17, 90, 61],
  [20, 112, 5, 13, 49, 78],
  [46, 127, 5, 107, 18, 108],
  [20, 133, 5, 63, 33, 67],
  [46, 132, 5, 89, 75, 10],
  [46, 55, 5, 26, 90, 68],
  [20, 50, 5, 92, 98, 80],
  [3, 50, 5, 22, 62, 121],
  [3, 52, 5, 6, 73, 76],
  [3, 113, 5, 26, 94, 115],
  [39, 133, 5, 32, 18, 70],
  [30, 104, 5, 92, 62, 65],
  [16, 132, 5, 107, 10, 34],
  [39, 31, 5, 53, 33, 45],
  [39, 40, 5, 89, 56, 111],
  [39, 28, 5, 32, 18, 83],
  [16, 69, 5, 32, 33, 111],
  [46, 79, 5, 32, 90, 67],
  [3, 79, 5, 63, 102, 138],
  [20, 28, 5, 59, 18, 71],
  [46, 57, 5, 22, 64, 80],
  [16, 112, 5, 17, 94, 10],
  [20, 58, 5, 13, 90, 34],
  [12, 10, 5, 36, 7, 97],
  [24, 123, 5, 13, 37, 122],
  [39, 4, 5, 26, 75, 35],
  [30, 119, 5, 17, 44, 121],
  [3, 31, 5, 17, 56, 74],
  [12, 57, 5, 53, 94, 68],
  [16, 69, 5, 36, 94, 111],
  [30, 58, 5, 59, 33, 101],
  [46, 125, 5, 26, 90, 121],
  [12, 48, 5, 59, 10, 83],
  [3, 106, 5, 13, 18, 67],
  [3, 31, 5, 32, 124, 68],
  [39, 88, 5, 22, 37, 83],
  [20, 28, 5, 22, 130, 115],
  [39, 48, 5, 59, 56, 10],
  [16, 35, 5, 92, 130, 11],
  [24, 48, 5, 6, 54, 115],
  [12, 113, 5, 92, 41, 65],
  [24, 112, 5, 63, 73, 91],
  [16, 50, 5, 92, 102, 95],
  [46, 43, 5, 92, 7, 121],
  [16, 110, 5, 6, 18, 93],
  [39, 87, 5, 32, 64, 121],
  [46, 21, 5, 13, 18, 74],
  [46, 50, 5, 13, 7, 122],
  [39, 127, 5, 36, 54, 65],
  [24, 128, 5, 13, 130, 121],
  [39, 133, 5, 22, 49, 131],
  [20, 58, 5, 13, 18, 68],
  [30, 28, 5, 63, 18, 65],
  [24, 103, 5, 17, 44, 34],
  [24, 35, 5, 17, 73, 80],
  [16, 28, 5, 107, 44, 95],
  [46, 125, 5, 22, 73, 91],
  [46, 4, 5, 36, 75, 121],
  [39, 43, 5, 13, 114, 67],
  [30, 96, 5, 63, 18, 118],
  [20, 110, 5, 13, 82, 99],
  [46, 119, 5, 107, 62, 138],
  [20, 125, 5, 59, 10, 111],
  [30, 113, 5, 107, 18, 11],
  [3, 27, 5, 59, 14, 71],
  [12, 43, 5, 22, 130, 65],
  [30, 123, 5, 17, 33, 71],
  [39, 96, 5, 13, 54, 45],
  [39, 77, 5, 26, 56, 129],
  [16, 58, 5, 26, 49, 68],
  [16, 85, 5, 59, 18, 115],
  [46, 28, 5, 59, 14, 74],
  [46, 55, 5, 13, 90, 91],
  [39, 48, 5, 22, 18, 45],
  [16, 40, 5, 59, 102, 68],
  [46, 28, 5, 92, 73, 47],
  [16, 125, 5, 89, 18, 65],
  [16, 28, 5, 107, 49, 15],
  [24, 35, 5, 89, 44, 78],
  [46, 128, 5, 22, 29, 70],
  [30, 25, 5, 17, 102, 68],
  [39, 10, 5, 36, 100, 111],
  [3, 28, 5, 17, 49, 68],
  [16, 25, 5, 63, 73, 27],
  [16, 35, 5, 22, 18, 122],
  [12, 81, 5, 6, 14, 34],
  [3, 106, 5, 53, 10, 122],
  [16, 4, 5, 92, 18, 34],
  [30, 128, 5, 26, 18, 27],
  [20, 81, 5, 26, 64, 105],
  [24, 28, 5, 13, 100, 76],
  [30, 31, 5, 59, 18, 76],
  [30, 55, 5, 17, 90, 101],
  [3, 135, 5, 6, 90, 111],
  [30, 112, 5, 22, 18, 138],
  [12, 58, 5, 17, 94, 118],
  [46, 43, 5, 32, 62, 68],
  [46, 48, 5, 36, 18, 38],
  [24, 96, 5, 32, 41, 105],
  [16, 35, 5, 89, 33, 10],
  [39, 96, 5, 6, 41, 131],
  [16, 28, 5, 32, 90, 137],
  [3, 58, 5, 63, 18, 71],
  [12, 31, 5, 92, 62, 115],
  [24, 132, 5, 17, 75, 134],
  [24, 141, 5, 59, 90, 121],
  [16, 66, 5, 22, 29, 8],
  [39, 9, 5, 53, 49, 97],
  [30, 81, 5, 107, 18, 122],
  [20, 85, 5, 17, 33, 91],
  [20, 27, 5, 53, 100, 108],
  [20, 133, 5, 63, 100, 76],
  [3, 57, 5, 107, 44, 51],
  [20, 113, 5, 32, 18, 65],
  [30, 104, 5, 17, 33, 80],
  [39, 48, 5, 92, 33, 80],
  [39, 106, 5, 22, 56, 11],
  [16, 72, 5, 53, 75, 11],
  [24, 125, 5, 107, 18, 101],
  [46, 4, 5, 107, 130, 88],
  [24, 43, 5, 26, 75, 83],
  [12, 113, 5, 89, 90, 105],
  [3, 55, 5, 26, 29, 68],
  [3, 79, 5, 63, 54, 71],
  [12, 57, 5, 17, 18, 67],
  [3, 57, 5, 32, 100, 105],
  [20, 43, 5, 13, 18, 68],
  [16, 123, 5, 6, 56, 74],
  [46, 69, 5, 17, 56, 10],
  [39, 132, 5, 32, 94, 109],
  [24, 43, 5, 6, 18, 23],
  [24, 50, 5, 139, 100, 105],
  [20, 113, 5, 59, 18, 35],
  [30, 9, 5, 17, 102, 51],
  [3, 96, 5, 26, 102, 10],
  [46, 104, 5, 92, 18, 134],
  [3, 104, 5, 22, 90, 93],
  [46, 119, 5, 59, 94, 134],
  [20, 87, 5, 13, 37, 67],
  [39, 142, 5, 17, 37, 34],
  [46, 57, 5, 17, 62, 121],
  [24, 55, 5, 13, 100, 34],
  [46, 87, 5, 89, 54, 117],
  [39, 43, 5, 107, 10, 38],
  [3, 55, 5, 89, 98, 134],
  [20, 133, 5, 22, 75, 83],
  [12, 133, 5, 86, 18, 91],
  [12, 66, 5, 89, 14, 101],
  [3, 31, 5, 13, 18, 47],
  [20, 28, 5, 13, 14, 122],
  [46, 104, 5, 32, 100, 67],
  [16, 103, 5, 92, 14, 45],
  [16, 28, 5, 32, 14, 80],
  [24, 125, 5, 92, 33, 34],
  [24, 87, 5, 6, 64, 108],
  [12, 25, 5, 6, 73, 99],
  [16, 35, 5, 36, 64, 99],
  [39, 28, 5, 13, 75, 76],
  [30, 57, 5, 36, 90, 47],
  [3, 104, 5, 22, 44, 111],
  [3, 127, 5, 107, 14, 47],
  [30, 28, 5, 36, 18, 47],
  [16, 66, 5, 53, 33, 19],
  [39, 66, 5, 36, 90, 8],
  [16, 113, 5, 107, 18, 47],
  [46, 113, 5, 6, 56, 121],
  [16, 48, 5, 6, 114, 138],
  [39, 69, 5, 53, 18, 121],
  [24, 55, 5, 13, 75, 111],
  [46, 87, 5, 53, 130, 111],
  [12, 119, 5, 22, 62, 51],
  [20, 52, 5, 63, 94, 67],
  [3, 57, 5, 26, 18, 129],
  [30, 66, 5, 59, 49, 121],
  [30, 48, 5, 6, 54, 27],
  [46, 66, 5, 26, 14, 97],
  [30, 72, 5, 59, 102, 34],
  [3, 110, 5, 17, 64, 108],
  [12, 48, 5, 59, 18, 68],
  [12, 106, 5, 17, 56, 101],
  [3, 103, 5, 6, 54, 108],
  [24, 48, 5, 139, 90, 68],
  [39, 103, 5, 13, 94, 34],
  [46, 43, 5, 107, 54, 122],
  [20, 81, 5, 59, 100, 47],
  [46, 133, 5, 17, 124, 45],
  [12, 106, 5, 17, 44, 45],
  [16, 28, 5, 17, 33, 138],
  [12, 117, 5, 32, 18, 111],
  [39, 119, 5, 22, 14, 15],
  [16, 81, 5, 13, 62, 122],
  [12, 69, 5, 92, 75, 84],
  [3, 116, 5, 53, 73, 76],
  [12, 69, 5, 53, 18, 108],
  [3, 10, 5, 107, 75, 45],
  [30, 132, 5, 89, 18, 93],
  [46, 55, 5, 13, 18, 11],
  [16, 10, 5, 107, 49, 68],
  [20, 66, 5, 22, 37, 105],
  [20, 55, 5, 92, 64, 101],
  [30, 104, 5, 26, 82, 80],
  [39, 104, 5, 92, 100, 71],
  [20, 66, 5, 89, 18, 105],
  [12, 72, 5, 32, 18, 78],
  [3, 132, 5, 92, 54, 11],
  [16, 35, 5, 22, 75, 76],
  [46, 31, 5, 89, 18, 78],
  [16, 58, 5, 53, 7, 67],
  [24, 72, 5, 92, 75, 122],
  [3, 57, 5, 6, 41, 101],
  [12, 113, 5, 89, 73, 76],
  [16, 43, 5, 89, 18, 78],
  [12, 25, 5, 6, 14, 68],
  [20, 55, 5, 107, 64, 136],
  [39, 85, 5, 59, 33, 108],
  [3, 55, 5, 59, 82, 91],
  [12, 55, 5, 22, 18, 45],
  [30, 69, 5, 32, 75, 45],
  [16, 58, 5, 17, 73, 34],
  [12, 50, 5, 6, 44, 105],
  [12, 104, 5, 13, 29, 51],
  [3, 87, 5, 17, 14, 68],
  [39, 106, 5, 86, 75, 108],
  [12, 66, 5, 107, 73, 68],
  [12, 48, 5, 59, 18, 10],
  [16, 132, 5, 92, 94, 95],
  [24, 103, 5, 17, 62, 34],
  [3, 31, 5, 92, 56, 74],
  [16, 4, 5, 32, 37, 65],
  [30, 106, 5, 6, 130, 80],
  [30, 127, 5, 59, 100, 68],
  [30, 119, 5, 36, 18, 68],
  [24, 43, 5, 22, 62, 126],
  [12, 116, 5, 6, 7, 131],
  [39, 25, 5, 32, 82, 76],
  [12, 96, 5, 13, 18, 117],
  [16, 55, 5, 6, 54, 95],
  [16, 119, 5, 17, 7, 137],
  [12, 103, 5, 13, 64, 68],
  [39, 133, 5, 17, 29, 67],
  [30, 55, 5, 107, 102, 122],
  [20, 127, 5, 32, 37, 15],
  [16, 106, 5, 107, 62, 131],
  [24, 77, 5, 6, 56, 117],
  [46, 58, 5, 17, 73, 65],
  [24, 103, 5, 13, 18, 51],
  [39, 55, 5, 59, 94, 67],
  [16, 106, 5, 53, 49, 65],
  [20, 55, 5, 63, 94, 8],
  [12, 52, 5, 32, 18, 84],
  [39, 103, 5, 13, 64, 74],
  [24, 31, 5, 26, 18, 68],
  [30, 69, 5, 32, 54, 61],
  [20, 135, 5, 92, 94, 115],
  [30, 104, 5, 26, 90, 71],
  [3, 35, 5, 26, 29, 8],
  [3, 27, 5, 17, 44, 47],
  [20, 81, 5, 13, 102, 51],
  [12, 106, 5, 13, 90, 10],
  [39, 28, 5, 36, 18, 51],
  [30, 88, 5, 22, 100, 80],
  [46, 66, 5, 53, 82, 136],
  [3, 125, 5, 17, 64, 105],
  [30, 52, 5, 63, 75, 122],
  [3, 69, 5, 36, 114, 91],
  [30, 142, 5, 89, 75, 99],
  [12, 57, 5, 107, 37, 84],
  [12, 133, 5, 6, 37, 15],
  [24, 81, 5, 22, 130, 74],
  [46, 43, 5, 32, 124, 101],
  [46, 48, 5, 17, 56, 34],
  [16, 135, 5, 13, 18, 78],
  [16, 43, 5, 59, 100, 134],
  [30, 142, 5, 36, 54, 121],
  [16, 135, 5, 17, 114, 45],
  [16, 25, 5, 59, 14, 131],
  [46, 69, 5, 26, 73, 10],
  [12, 116, 5, 92, 18, 78],
  [16, 31, 5, 6, 7, 76],
  [20, 28, 5, 53, 37, 101],
  [46, 116, 5, 107, 102, 105],
  [16, 58, 5, 22, 124, 34],
  [3, 112, 5, 13, 130, 99],
  [30, 104, 5, 59, 102, 38],
  [16, 9, 5, 53, 7, 80],
  [39, 128, 5, 22, 62, 131],
  [3, 40, 5, 92, 33, 126],
  [39, 116, 5, 22, 18, 109],
  [16, 66, 5, 53, 18, 109],
  [12, 31, 5, 36, 54, 108],
  [24, 87, 5, 89, 37, 78],
  [20, 66, 5, 89, 37, 78],
  [16, 96, 5, 17, 18, 19],
  [20, 35, 5, 89, 18, 83],
  [30, 77, 5, 17, 7, 97],
  [12, 103, 5, 26, 49, 42],
  [46, 28, 5, 36, 75, 78],
  [46, 116, 5, 22, 14, 91],
  [30, 140, 5, 13, 7, 71],
  [39, 69, 5, 92, 130, 45],
  [30, 28, 5, 53, 64, 23],
  [46, 69, 5, 6, 100, 121],
  [46, 58, 5, 53, 18, 101],
  [30, 81, 5, 36, 56, 34],
  [12, 43, 5, 107, 18, 101],
  [3, 85, 5, 22, 90, 19],
  [16, 96, 5, 13, 130, 34],
  [30, 57, 5, 17, 7, 34],
  [24, 10, 5, 107, 100, 71],
  [3, 48, 5, 92, 73, 19],
  [16, 104, 5, 17, 10, 74],
  [16, 43, 5, 22, 29, 122],
  [39, 113, 5, 22, 90, 78],
  [3, 35, 5, 107, 56, 91],
  [16, 79, 5, 107, 102, 65],
  [16, 96, 5, 92, 73, 35],
  [24, 50, 5, 6, 18, 91],
  [3, 43, 5, 13, 64, 10],
  [3, 140, 5, 107, 29, 10],
  [3, 28, 5, 13, 18, 51],
  [3, 106, 5, 13, 94, 76],
  [3, 140, 5, 22, 90, 80],
  [3, 55, 5, 89, 18, 47],
  [20, 96, 5, 13, 54, 80],
  [3, 69, 5, 17, 82, 74],
  [20, 69, 5, 107, 7, 11],
  [24, 43, 5, 63, 100, 8],
  [46, 106, 5, 53, 29, 111],
  [24, 28, 5, 107, 130, 115],
  [20, 35, 5, 53, 18, 47],
  [39, 133, 5, 89, 54, 78],
  [20, 31, 5, 59, 54, 136],
  [3, 4, 5, 36, 54, 101],
  [16, 55, 5, 17, 18, 91],
  [16, 66, 5, 22, 102, 42],
  [16, 58, 5, 17, 18, 134],
  [3, 96, 5, 26, 33, 70],
  [16, 35, 5, 107, 114, 47],
  [3, 48, 5, 59, 90, 38],
  [3, 125, 5, 92, 130, 122],
  [30, 50, 5, 107, 18, 134],
  [16, 117, 5, 36, 56, 108],
  [3, 103, 5, 59, 44, 23],
  [39, 104, 5, 63, 54, 11],
  [30, 69, 5, 53, 7, 129],
  [3, 79, 5, 13, 33, 111],
  [24, 81, 5, 26, 114, 76],
  [3, 27, 5, 36, 90, 95],
  [20, 27, 5, 22, 14, 10],
  [12, 55, 5, 17, 75, 10],
  [24, 81, 5, 13, 14, 131],
  [46, 4, 5, 26, 64, 91],
  [46, 55, 5, 63, 44, 101],
  [12, 52, 5, 89, 82, 19],
  [16, 27, 5, 63, 94, 61],
  [16, 103, 5, 6, 124, 68],
  [12, 69, 5, 59, 102, 122],
  [46, 96, 5, 17, 37, 11],
  [3, 81, 5, 36, 29, 45],
  [16, 50, 5, 53, 18, 10],
  [46, 113, 5, 89, 29, 35],
  [20, 69, 5, 22, 62, 80],
  [12, 58, 5, 139, 18, 11],
  [12, 57, 5, 59, 130, 70],
  [39, 4, 5, 86, 44, 108],
  [39, 142, 5, 63, 54, 11],
  [12, 119, 5, 92, 64, 99],
  [3, 106, 5, 92, 73, 8],
  [16, 120, 5, 53, 18, 65],
  [24, 4, 5, 22, 100, 121],
  [16, 43, 5, 22, 56, 65],
  [12, 50, 5, 59, 44, 74],
  [24, 113, 5, 107, 102, 68],
  [3, 43, 5, 32, 7, 67],
  [12, 116, 5, 63, 94, 78],
  [3, 48, 5, 17, 56, 47],
  [46, 142, 5, 92, 64, 101],
  [39, 113, 5, 17, 94, 84],
  [24, 81, 5, 13, 18, 74],
  [20, 88, 5, 22, 90, 99],
  [12, 55, 5, 63, 10, 67],
  [3, 58, 5, 26, 37, 99],
  [16, 103, 5, 92, 49, 27],
  [3, 35, 5, 32, 18, 61],
  [16, 55, 5, 89, 37, 68],
  [24, 77, 5, 92, 18, 34],
  [24, 9, 5, 107, 98, 76],
  [39, 52, 5, 63, 114, 23],
  [30, 125, 5, 92, 90, 76],
  [24, 112, 5, 107, 73, 23],
  [30, 10, 5, 36, 130, 97],
  [30, 96, 5, 26, 94, 65],
  [3, 55, 5, 53, 90, 51],
  [24, 81, 5, 13, 37, 117],
  [46, 112, 5, 36, 90, 78],
  [12, 116, 5, 63, 114, 47],
  [30, 104, 5, 107, 90, 91],
  [16, 40, 5, 13, 29, 121],
  [16, 106, 5, 17, 18, 45],
  [12, 69, 5, 17, 18, 109],
  [16, 81, 5, 89, 60, 109],
  [24, 113, 5, 92, 18, 131],
  [39, 43, 5, 36, 90, 70],
  [20, 117, 5, 89, 44, 11],
  [20, 140, 5, 59, 62, 97],
  [24, 48, 5, 107, 18, 34],
  [46, 28, 5, 63, 18, 68],
  [30, 81, 5, 6, 100, 93],
  [16, 103, 5, 13, 90, 115],
  [3, 104, 5, 92, 62, 80],
  [30, 106, 5, 32, 29, 80],
  [16, 31, 5, 13, 54, 78],
  [16, 43, 5, 6, 18, 108],
  [39, 77, 5, 89, 7, 109],
  [3, 112, 5, 59, 29, 121],
  [16, 9, 5, 6, 14, 35],
  [39, 103, 5, 107, 90, 95],
  [46, 66, 5, 22, 10, 101],
  [30, 113, 5, 13, 44, 70],
  [20, 48, 5, 17, 62, 45],
  [46, 81, 5, 13, 18, 51],
  [46, 113, 5, 22, 90, 93],
  [20, 27, 5, 92, 37, 67],
  [3, 55, 5, 22, 100, 138],
  [39, 141, 5, 89, 18, 84],
  [12, 106, 5, 6, 29, 91],
  [3, 69, 5, 89, 18, 65],
  [16, 103, 5, 17, 130, 101],
  [16, 81, 5, 59, 29, 68],
  [12, 104, 5, 107, 10, 11],
  [3, 125, 5, 17, 73, 118],
  [30, 88, 5, 63, 124, 101],
  [46, 48, 5, 32, 102, 8],
  [16, 132, 5, 107, 82, 105],
  [39, 141, 5, 13, 18, 99],
  [3, 135, 5, 32, 18, 65],
  [24, 133, 5, 59, 54, 68],
  [3, 9, 5, 17, 124, 131],
  [39, 69, 5, 63, 18, 99],
  [46, 96, 5, 107, 18, 68],
  [24, 96, 5, 59, 44, 115],
  [30, 40, 5, 107, 7, 118],
  [16, 128, 5, 92, 90, 84],
  [16, 35, 5, 17, 94, 83],
  [12, 132, 5, 92, 64, 83],
  [39, 140, 5, 53, 33, 129],
  [12, 96, 5, 59, 29, 101],
  [16, 25, 5, 63, 94, 108],
  [46, 85, 5, 107, 56, 78],
  [24, 140, 5, 22, 7, 80],
  [20, 85, 5, 6, 44, 68],
  [24, 52, 5, 13, 100, 108],
  [3, 21, 5, 32, 18, 45],
  [20, 72, 5, 6, 64, 131],
  [39, 88, 5, 32, 90, 111],
  [16, 43, 5, 89, 100, 93],
  [16, 119, 5, 36, 7, 80],
  [46, 81, 5, 22, 100, 67],
  [46, 103, 5, 22, 18, 99],
  [20, 141, 5, 53, 73, 27],
  [39, 35, 5, 53, 124, 99],
  [46, 10, 5, 17, 18, 101],
  [24, 113, 5, 32, 14, 34],
  [46, 85, 5, 26, 90, 11],
  [16, 119, 5, 63, 102, 78],
  [3, 104, 5, 6, 100, 34],
  [12, 104, 5, 89, 62, 74],
  [20, 57, 5, 6, 14, 15],
  [16, 57, 5, 6, 98, 138],
  [20, 85, 5, 26, 64, 8],
  [16, 133, 5, 22, 37, 108],
  [20, 85, 5, 92, 18, 68],
  [46, 141, 5, 26, 130, 121],
  [20, 55, 5, 6, 44, 71],
  [20, 106, 5, 139, 62, 117],
  [20, 110, 5, 36, 75, 111],
  [3, 96, 5, 36, 56, 105],
  [3, 81, 5, 53, 94, 91],
  [39, 87, 5, 13, 100, 35],
  [12, 69, 5, 86, 14, 111],
  [16, 28, 5, 107, 18, 111],
  [39, 79, 5, 26, 29, 80],
  [39, 96, 5, 36, 54, 15],
  [3, 55, 5, 92, 18, 91],
  [46, 50, 5, 6, 64, 68],
  [30, 69, 5, 139, 75, 67],
  [46, 28, 5, 26, 37, 137],
  [3, 48, 5, 92, 29, 108],
  [24, 127, 5, 107, 114, 129],
  [24, 28, 5, 13, 102, 122],
  [24, 116, 5, 63, 54, 111],
  [46, 132, 5, 6, 49, 109],
  [3, 25, 5, 13, 18, 83],
  [39, 27, 5, 89, 33, 71],
  [20, 55, 5, 32, 33, 101],
  [46, 35, 5, 13, 64, 93],
  [12, 132, 5, 26, 18, 71],
  [30, 35, 5, 22, 73, 8],
  [30, 81, 5, 63, 73, 134],
  [16, 117, 5, 13, 10, 76],
  [3, 25, 5, 36, 56, 76],
  [30, 116, 5, 92, 100, 45],
  [46, 31, 5, 26, 37, 34],
  [39, 21, 5, 107, 18, 117],
  [46, 66, 5, 59, 75, 71],
  [12, 4, 5, 13, 14, 11],
  [39, 125, 5, 13, 114, 109],
  [46, 132, 5, 22, 73, 80],
  [46, 21, 5, 59, 18, 121],
  [39, 104, 5, 13, 18, 115],
  [3, 55, 5, 36, 82, 19],
  [20, 104, 5, 17, 100, 115],
  [12, 43, 5, 36, 33, 76],
  [12, 69, 5, 107, 94, 121],
  [12, 96, 5, 86, 130, 51],
  [12, 43, 5, 17, 10, 99],
  [16, 28, 5, 32, 75, 91],
  [30, 77, 5, 63, 18, 121],
  [39, 57, 5, 107, 102, 47],
  [16, 9, 5, 59, 18, 109],
  [12, 106, 5, 13, 94, 67],
  [20, 48, 5, 6, 90, 118],
  [3, 25, 5, 13, 130, 111],
  [12, 140, 5, 22, 102, 10],
  [30, 48, 5, 32, 49, 80],
  [46, 50, 5, 53, 82, 122],
  [3, 43, 5, 26, 75, 65],
  [12, 87, 5, 139, 94, 10],
  [12, 50, 5, 36, 18, 68],
  [46, 35, 5, 17, 75, 118],
  [3, 113, 5, 32, 64, 74],
  [46, 50, 5, 17, 29, 115],
  [16, 103, 5, 63, 75, 91],
  [39, 55, 5, 6, 100, 67],
  [3, 58, 5, 89, 18, 47],
  [12, 48, 5, 13, 33, 131],
  [16, 66, 5, 17, 18, 134],
  [3, 50, 5, 13, 130, 11],
  [20, 69, 5, 13, 7, 67],
  [3, 48, 5, 13, 7, 67],
  [39, 52, 5, 17, 62, 61],
  [30, 135, 5, 26, 18, 35],
  [20, 81, 5, 22, 73, 76],
  [24, 81, 5, 92, 54, 99],
  [12, 4, 5, 6, 73, 70],
  [24, 132, 5, 17, 18, 91],
  [39, 48, 5, 107, 75, 105],
  [30, 106, 5, 36, 14, 122],
  [12, 96, 5, 17, 33, 91],
  [24, 35, 5, 26, 18, 68],
  [39, 113, 5, 53, 62, 105],
  [12, 81, 5, 17, 73, 70],
  [3, 35, 5, 107, 102, 35],
  [20, 9, 5, 32, 29, 11],
  [3, 25, 5, 107, 18, 38],
  [12, 57, 5, 59, 49, 109],
  [16, 119, 5, 13, 29, 11],
  [30, 25, 5, 17, 29, 108],
  [39, 66, 5, 92, 100, 99],
  [20, 103, 5, 32, 130, 35],
  [12, 103, 5, 63, 29, 84],
  [20, 104, 5, 53, 7, 47],
  [30, 77, 5, 107, 14, 10],
  [12, 69, 5, 107, 18, 111],
  [12, 55, 5, 22, 64, 70],
  [20, 28, 5, 13, 73, 45],
  [3, 58, 5, 22, 100, 111],
  [39, 69, 5, 13, 94, 76],
  [20, 28, 5, 139, 100, 99],
  [20, 113, 5, 36, 75, 93],
  [12, 43, 5, 59, 94, 129],
  [16, 132, 5, 32, 82, 122],
  [39, 48, 5, 59, 7, 80],
  [3, 81, 5, 32, 75, 97],
  [16, 50, 5, 17, 62, 118],
  [30, 10, 5, 13, 130, 11],
  [3, 81, 5, 63, 64, 84],
  [12, 133, 5, 53, 7, 129],
  [30, 133, 5, 36, 18, 91],
  [12, 40, 5, 22, 62, 83],
  [12, 4, 5, 59, 54, 10],
  [12, 133, 5, 26, 18, 111],
  [20, 40, 5, 17, 18, 80],
  [46, 125, 5, 17, 102, 11],
  [12, 81, 5, 53, 54, 67],
  [16, 104, 5, 22, 41, 101],
  [16, 96, 5, 32, 56, 11],
  [3, 69, 5, 107, 18, 80],
  [16, 133, 5, 17, 37, 101],
  [16, 58, 5, 36, 7, 121],
  [46, 85, 5, 17, 10, 80],
  [20, 81, 5, 26, 124, 108],
  [30, 110, 5, 107, 64, 99],
  [39, 31, 5, 53, 18, 111],
  [30, 31, 5, 26, 41, 68],
  [24, 48, 5, 53, 73, 74],
  [16, 132, 5, 59, 14, 126],
  [16, 69, 5, 59, 18, 97],
  [3, 31, 5, 32, 7, 74],
  [16, 104, 5, 59, 29, 88],
  [16, 28, 5, 92, 29, 71],
  [24, 96, 5, 13, 82, 80],
  [3, 112, 5, 59, 114, 70],
  [20, 132, 5, 53, 18, 99],
  [3, 35, 5, 107, 18, 70],
  [24, 69, 5, 32, 94, 45],
  [46, 43, 5, 13, 75, 91],
  [46, 123, 5, 63, 130, 11],
  [39, 110, 5, 26, 56, 115],
  [3, 50, 5, 53, 29, 34],
  [20, 127, 5, 26, 33, 129],
  [12, 135, 5, 32, 114, 19],
  [46, 113, 5, 17, 18, 71],
  [24, 96, 5, 63, 54, 91],
  [12, 72, 5, 22, 75, 126],
  [46, 132, 5, 107, 56, 35],
  [3, 72, 5, 32, 14, 80],
  [39, 96, 5, 63, 54, 115],
  [16, 43, 5, 22, 41, 99],
  [30, 103, 5, 92, 102, 76],
  [16, 128, 5, 63, 94, 71],
  [20, 104, 5, 63, 100, 34],
  [16, 48, 5, 6, 33, 71],
  [24, 10, 5, 92, 102, 74],
  [30, 50, 5, 32, 18, 45],
  [3, 69, 5, 89, 94, 108],
  [16, 104, 5, 107, 73, 84],
  [39, 28, 5, 53, 75, 83],
  [12, 119, 5, 22, 54, 131],
  [39, 96, 5, 107, 64, 115],
  [46, 85, 5, 13, 10, 45],
  [12, 48, 5, 53, 130, 131],
  [3, 69, 5, 36, 130, 122],
  [30, 25, 5, 17, 102, 118],
  [46, 43, 5, 59, 29, 38],
  [30, 112, 5, 32, 10, 71],
  [46, 123, 5, 107, 130, 105],
  [46, 28, 5, 22, 18, 88],
  [12, 140, 5, 63, 7, 10],
  [20, 9, 5, 89, 73, 131],
  [39, 9, 5, 13, 18, 93],
  [16, 69, 5, 32, 37, 10],
  [46, 113, 5, 6, 44, 68],
  [3, 4, 5, 53, 56, 11],
  [12, 72, 5, 63, 73, 68],
  [16, 9, 5, 26, 90, 105],
  [24, 106, 5, 107, 94, 68],
  [16, 110, 5, 17, 37, 109],
  [46, 103, 5, 13, 102, 74],
  [46, 10, 5, 36, 60, 10],
  [39, 132, 5, 107, 10, 84],
  [24, 103, 5, 86, 98, 45],
  [39, 140, 5, 36, 62, 111],
  [3, 69, 5, 13, 7, 8],
  [20, 135, 5, 89, 102, 122],
  [16, 135, 5, 53, 7, 51],
  [3, 57, 5, 13, 7, 68],
  [16, 55, 5, 139, 114, 8],
  [39, 104, 5, 17, 7, 74],
  [30, 85, 5, 6, 100, 122],
  [12, 50, 5, 89, 75, 70],
  [3, 87, 5, 89, 54, 83],
  [16, 110, 5, 53, 100, 11],
  [16, 127, 5, 13, 102, 45],
  [12, 48, 5, 36, 44, 88],
  [24, 96, 5, 13, 10, 126],
  [20, 35, 5, 13, 82, 134],
  [30, 117, 5, 89, 75, 35],
  [30, 119, 5, 92, 29, 111],
  [46, 50, 5, 63, 75, 45],
  [16, 119, 5, 59, 33, 91],
  [39, 28, 5, 17, 54, 47],
  [12, 35, 5, 63, 10, 45],
  [24, 43, 5, 59, 100, 131],
  [3, 25, 5, 22, 64, 70],
  [30, 58, 5, 32, 18, 11],
  [3, 50, 5, 36, 98, 105],
  [16, 85, 5, 89, 44, 134],
  [30, 81, 5, 17, 18, 71],
  [12, 58, 5, 32, 56, 74],
  [3, 116, 5, 13, 18, 67],
  [24, 57, 5, 17, 54, 74],
  [30, 85, 5, 92, 94, 10],
  [3, 57, 5, 107, 49, 65],
  [46, 4, 5, 59, 44, 76],
  [20, 31, 5, 22, 124, 68],
  [3, 55, 5, 92, 64, 74],
  [16, 135, 5, 22, 37, 129],
  [39, 48, 5, 53, 18, 68],
  [24, 27, 5, 107, 90, 8],
  [46, 104, 5, 89, 90, 80],
  [3, 116, 5, 26, 33, 74],
  [3, 103, 5, 17, 14, 105],
  [30, 106, 5, 89, 29, 47],
  [39, 43, 5, 89, 130, 74],
  [30, 52, 5, 6, 62, 45],
  [3, 48, 5, 22, 130, 67],
  [16, 140, 5, 13, 44, 111],
  [3, 55, 5, 59, 29, 71],
  [12, 96, 5, 36, 14, 78],
  [39, 117, 5, 32, 130, 80],
  [16, 103, 5, 63, 56, 67],
  [16, 125, 5, 32, 33, 61],
  [12, 10, 5, 139, 18, 111],
  [12, 69, 5, 59, 14, 67],
  [20, 132, 5, 92, 29, 134],
  [39, 132, 5, 17, 18, 93],
  [46, 35, 5, 17, 44, 88],
  [46, 69, 5, 13, 114, 80],
  [12, 31, 5, 13, 64, 111],
  [16, 35, 5, 13, 102, 138],
  [12, 72, 5, 6, 7, 99],
  [3, 113, 5, 107, 18, 131],
  [39, 77, 5, 32, 82, 11],
  [24, 79, 5, 53, 54, 45],
  [30, 104, 5, 63, 90, 101],
  [3, 28, 5, 17, 102, 91],
  [39, 96, 5, 26, 18, 45],
  [30, 87, 5, 107, 7, 93],
  [16, 103, 5, 17, 64, 122],
  [16, 81, 5, 36, 18, 80],
  [39, 58, 5, 63, 10, 122],
  [24, 69, 5, 6, 29, 19],
  [46, 125, 5, 92, 64, 11],
  [30, 117, 5, 6, 37, 99],
  [20, 69, 5, 107, 18, 42],
  [16, 112, 5, 36, 130, 45],
  [30, 27, 5, 26, 44, 70],
  [30, 28, 5, 17, 7, 129],
  [16, 55, 5, 13, 44, 108],
  [24, 50, 5, 92, 7, 109],
  [12, 81, 5, 107, 54, 99],
  [12, 81, 5, 6, 102, 131],
  [30, 55, 5, 13, 18, 76],
  [3, 25, 5, 26, 64, 131],
  [20, 57, 5, 17, 29, 80],
  [16, 133, 5, 36, 54, 115],
  [3, 66, 5, 92, 44, 47],
  [30, 88, 5, 13, 18, 131],
  [20, 35, 5, 36, 18, 45],
  [30, 48, 5, 13, 100, 45],
  [46, 81, 5, 59, 94, 34],
  [3, 106, 5, 92, 56, 61],
  [16, 69, 5, 17, 18, 68],
  [16, 66, 5, 13, 82, 88],
  [39, 87, 5, 17, 18, 74],
  [30, 58, 5, 63, 130, 8],
  [30, 113, 5, 17, 90, 45],
  [16, 81, 5, 22, 18, 35],
  [3, 55, 5, 32, 90, 91],
  [3, 116, 5, 22, 64, 97],
  [16, 81, 5, 107, 90, 67],
  [16, 135, 5, 17, 94, 80],
  [30, 50, 5, 107, 64, 80],
  [39, 104, 5, 32, 10, 35],
  [16, 96, 5, 63, 56, 118],
  [20, 52, 5, 63, 37, 10],
  [3, 66, 5, 32, 10, 71],
  [16, 85, 5, 59, 41, 111],
  [3, 113, 5, 6, 82, 138],
  [3, 28, 5, 6, 114, 78],
  [30, 48, 5, 59, 33, 91],
  [12, 106, 5, 26, 18, 84],
  [16, 57, 5, 26, 54, 101],
  [12, 52, 5, 59, 7, 78],
  [30, 113, 5, 59, 82, 131],
  [20, 119, 5, 32, 33, 101],
  [46, 81, 5, 89, 94, 91],
  [3, 25, 5, 36, 7, 15],
  [24, 66, 5, 17, 102, 68],
  [24, 103, 5, 26, 10, 129],
  [24, 58, 5, 13, 44, 117],
  [12, 43, 5, 92, 33, 101],
  [16, 85, 5, 6, 90, 122],
  [30, 50, 5, 53, 102, 143],
  [46, 110, 5, 53, 10, 138],
  [12, 132, 5, 92, 18, 121],
  [24, 106, 5, 26, 18, 111],
  [30, 21, 5, 17, 94, 101],
  [3, 96, 5, 13, 100, 70],
  [20, 135, 5, 6, 73, 109],
  [39, 66, 5, 17, 75, 118],
  [39, 66, 5, 17, 14, 99],
  [3, 87, 5, 26, 14, 99],
  [3, 55, 5, 6, 49, 131],
  [12, 103, 5, 36, 18, 11],
  [46, 31, 5, 63, 100, 34],
  [46, 103, 5, 17, 75, 115],
  [46, 132, 5, 22, 18, 76],
  [39, 43, 5, 59, 56, 65],
  [16, 113, 5, 107, 75, 70],
  [3, 140, 5, 13, 18, 71],
  [16, 140, 5, 6, 73, 131],
  [24, 50, 5, 89, 18, 121],
  [46, 106, 5, 17, 75, 83],
  [3, 57, 5, 6, 54, 118],
  [3, 48, 5, 13, 18, 101],
  [16, 69, 5, 13, 18, 45],
  [46, 27, 5, 26, 18, 76],
  [46, 66, 5, 92, 75, 131],
  [16, 58, 5, 107, 54, 76],
  [46, 66, 5, 59, 7, 111],
  [46, 127, 5, 22, 7, 101],
  [46, 72, 5, 6, 10, 108],
  [12, 9, 5, 6, 124, 27],
  [30, 31, 5, 22, 18, 78],
  [3, 55, 5, 107, 130, 34],
  [3, 142, 5, 89, 14, 47],
  [16, 58, 5, 17, 64, 131],
  [16, 104, 5, 59, 114, 111],
  [20, 113, 5, 89, 94, 11],
  [24, 69, 5, 92, 18, 101],
  [46, 9, 5, 22, 100, 11],
  [24, 132, 5, 59, 33, 35],
  [46, 112, 5, 92, 54, 115],
  [30, 117, 5, 13, 18, 42],
  [12, 135, 5, 92, 29, 105],
  [3, 88, 5, 36, 7, 70],
  [16, 103, 5, 86, 49, 10],
  [3, 43, 5, 22, 54, 11],
  [39, 79, 5, 32, 44, 131],
  [3, 69, 5, 13, 98, 83],
  [46, 69, 5, 6, 37, 138],
  [20, 77, 5, 6, 18, 95],
  [12, 52, 5, 59, 56, 47],
  [12, 119, 5, 6, 75, 93],
  [12, 28, 5, 22, 102, 68],
  [39, 25, 5, 17, 37, 76],
  [30, 4, 5, 13, 44, 84],
  [30, 142, 5, 13, 18, 83],
  [20, 113, 5, 17, 98, 74],
  [3, 43, 5, 107, 33, 45],
  [16, 35, 5, 22, 90, 65],
  [20, 132, 5, 36, 75, 83],
  [30, 50, 5, 13, 18, 76],
  [30, 35, 5, 63, 18, 122],
  [24, 55, 5, 6, 64, 95],
  [12, 58, 5, 36, 124, 111],
  [24, 58, 5, 13, 75, 76],
  [24, 40, 5, 36, 102, 27],
  [46, 116, 5, 32, 18, 91],
  [3, 123, 5, 53, 10, 74],
  [46, 9, 5, 92, 49, 117],
  [3, 127, 5, 63, 94, 122],
  [39, 123, 5, 22, 18, 97],
  [12, 96, 5, 13, 18, 109],
  [39, 104, 5, 53, 14, 8],
  [30, 69, 5, 59, 29, 45],
  [20, 79, 5, 17, 102, 67],
  [3, 113, 5, 13, 18, 129],
  [46, 72, 5, 36, 130, 34],
  [16, 113, 5, 32, 75, 131],
  [46, 52, 5, 13, 18, 47],
  [3, 112, 5, 63, 18, 109],
  [24, 103, 5, 59, 18, 121],
  [16, 106, 5, 6, 18, 115],
  [3, 96, 5, 32, 44, 10],
  [46, 85, 5, 36, 10, 126],
  [39, 113, 5, 63, 18, 105],
  [46, 140, 5, 63, 130, 8],
  [46, 81, 5, 107, 75, 101],
  [20, 55, 5, 22, 82, 10],
  [12, 113, 5, 53, 10, 131],
  [12, 4, 5, 36, 94, 129],
  [3, 58, 5, 17, 102, 88],
  [3, 142, 5, 17, 100, 45],
  [30, 9, 5, 89, 64, 15],
  [16, 128, 5, 36, 29, 51],
  [46, 140, 5, 92, 54, 42],
  [16, 133, 5, 107, 18, 10],
  [24, 112, 5, 92, 64, 11],
  [24, 88, 5, 13, 18, 91],
  [3, 103, 5, 107, 33, 121],
  [46, 141, 5, 107, 130, 10],
  [39, 43, 5, 63, 100, 8],
  [39, 28, 5, 92, 37, 34],
  [3, 55, 5, 92, 54, 47],
  [39, 69, 5, 107, 18, 108],
  [24, 21, 5, 17, 49, 71],
  [20, 28, 5, 107, 18, 91],
  [12, 103, 5, 107, 100, 38],
  [30, 81, 5, 92, 75, 83],
  [3, 117, 5, 53, 49, 61],
  [39, 31, 5, 6, 44, 115],
  [39, 106, 5, 107, 94, 80],
  [3, 43, 5, 63, 49, 88],
  [30, 112, 5, 26, 90, 8],
  [24, 66, 5, 92, 64, 71],
  [16, 81, 5, 17, 100, 10],
  [16, 57, 5, 92, 82, 126],
  [16, 125, 5, 92, 18, 136],
  [30, 104, 5, 89, 56, 65],
  [3, 69, 5, 22, 7, 70],
  [12, 57, 5, 92, 64, 34],
  [12, 106, 5, 17, 18, 83],
  [30, 113, 5, 59, 18, 74],
  [12, 28, 5, 59, 10, 11],
  [3, 31, 5, 36, 29, 76],
  [46, 135, 5, 107, 18, 10],
  [12, 66, 5, 6, 18, 47],
  [30, 128, 5, 36, 75, 74],
  [39, 132, 5, 36, 100, 80],
  [3, 132, 5, 36, 14, 111],
  [30, 128, 5, 63, 94, 71],
  [39, 112, 5, 36, 18, 122],
  [20, 52, 5, 22, 62, 65],
  [46, 69, 5, 17, 94, 68],
  [16, 127, 5, 89, 37, 71],
  [39, 85, 5, 89, 56, 47],
  [16, 58, 5, 92, 54, 71],
  [16, 27, 5, 32, 75, 10],
  [3, 55, 5, 63, 82, 71],
  [16, 81, 5, 13, 18, 108],
  [39, 104, 5, 89, 64, 101],
  [24, 132, 5, 89, 82, 115],
  [20, 106, 5, 59, 29, 68],
  [30, 43, 5, 59, 94, 115],
  [24, 66, 5, 53, 18, 65],
  [12, 28, 5, 22, 44, 99],
  [30, 66, 5, 89, 18, 80],
  [24, 50, 5, 17, 7, 91],
  [30, 85, 5, 17, 100, 131],
  [46, 10, 5, 63, 62, 117],
  [12, 96, 5, 26, 114, 99],
  [24, 112, 5, 36, 10, 10],
  [24, 87, 5, 32, 18, 137],
  [24, 48, 5, 59, 130, 91],
  [46, 66, 5, 63, 56, 71],
  [46, 66, 5, 59, 37, 51],
  [12, 104, 5, 22, 54, 34],
  [20, 48, 5, 89, 14, 65],
  [39, 119, 5, 26, 82, 45],
  [24, 85, 5, 92, 82, 109],
  [30, 104, 5, 107, 18, 45],
  [46, 31, 5, 13, 102, 97],
  [46, 40, 5, 139, 37, 111],
  [24, 112, 5, 13, 7, 80],
  [46, 128, 5, 26, 18, 121],
  [30, 52, 5, 63, 98, 68],
  [12, 10, 5, 36, 100, 45],
  [20, 104, 5, 92, 18, 99],
  [24, 132, 5, 13, 44, 78],
  [30, 104, 5, 26, 18, 8],
  [20, 55, 5, 53, 75, 27],
  [30, 48, 5, 32, 14, 83],
  [39, 112, 5, 13, 62, 70],
  [46, 31, 5, 17, 49, 131],
  [24, 116, 5, 107, 114, 121],
  [30, 66, 5, 59, 18, 109],
  [12, 69, 5, 17, 82, 65],
  [39, 141, 5, 17, 44, 91],
  [30, 69, 5, 53, 29, 97],
  [12, 43, 5, 107, 37, 115],
  [20, 103, 5, 26, 94, 71],
  [20, 104, 5, 92, 100, 19],
  [46, 110, 5, 89, 82, 45],
  [16, 66, 5, 6, 18, 8],
  [39, 104, 5, 32, 64, 34],
  [16, 55, 5, 36, 14, 34],
  [3, 25, 5, 89, 54, 95],
  [3, 123, 5, 26, 29, 122],
  [16, 142, 5, 59, 64, 19],
  [20, 123, 5, 6, 33, 108],
  [16, 66, 5, 89, 44, 78],
  [12, 57, 5, 22, 33, 91],
  [16, 106, 5, 63, 64, 34],
  [3, 87, 5, 92, 44, 11],
  [30, 50, 5, 26, 18, 99],
  [16, 66, 5, 107, 18, 134],
  [20, 112, 5, 92, 73, 117],
  [39, 57, 5, 63, 33, 99],
  [12, 43, 5, 32, 14, 34],
  [30, 48, 5, 36, 18, 99],
  [39, 28, 5, 107, 98, 88],
  [39, 28, 5, 26, 18, 67],
  [46, 48, 5, 89, 73, 10],
  [39, 58, 5, 59, 90, 111],
  [16, 10, 5, 92, 14, 47],
  [39, 4, 5, 107, 54, 11],
  [12, 72, 5, 63, 114, 67],
  [39, 66, 5, 32, 18, 136],
  [16, 106, 5, 17, 94, 105],
  [39, 31, 5, 59, 18, 67],
  [46, 81, 5, 36, 18, 76],
  [39, 58, 5, 63, 14, 109],
  [12, 103, 5, 86, 62, 67],
  [20, 133, 5, 92, 56, 93],
  [20, 104, 5, 89, 56, 83],
  [46, 43, 5, 13, 130, 10],
  [3, 103, 5, 22, 18, 74],
  [46, 79, 5, 92, 94, 65],
  [12, 113, 5, 107, 44, 108],
  [16, 112, 5, 59, 7, 23],
  [16, 81, 5, 53, 7, 71],
  [30, 85, 5, 22, 10, 67],
  [3, 88, 5, 92, 98, 45],
  [39, 85, 5, 53, 29, 111],
  [12, 77, 5, 22, 54, 78],
  [24, 113, 5, 22, 18, 11],
  [24, 103, 5, 6, 82, 70],
  [3, 112, 5, 17, 102, 76],
  [16, 69, 5, 89, 64, 35],
  [46, 135, 5, 13, 18, 47],
  [12, 140, 5, 22, 100, 83],
  [30, 104, 5, 17, 73, 115],
  [39, 52, 5, 13, 14, 126],
  [12, 57, 5, 59, 33, 10],
  [3, 10, 5, 53, 18, 51],
  [3, 4, 5, 13, 33, 10],
  [39, 96, 5, 92, 44, 76],
  [46, 4, 5, 107, 82, 10],
  [24, 132, 5, 22, 37, 108],
  [12, 77, 5, 26, 18, 68],
  [39, 119, 5, 13, 82, 131],
  [3, 57, 5, 22, 82, 45],
  [24, 103, 5, 92, 44, 108],
  [46, 28, 5, 6, 62, 131],
  [39, 50, 5, 107, 130, 108],
  [46, 66, 5, 59, 7, 51],
  [16, 106, 5, 59, 18, 99],
  [46, 132, 5, 107, 56, 101],
  [20, 31, 5, 26, 14, 122],
  [3, 103, 5, 6, 49, 47],
  [3, 119, 5, 26, 7, 8],
  [16, 48, 5, 92, 18, 138],
  [16, 43, 5, 13, 14, 97],
  [3, 132, 5, 107, 100, 35],
  [30, 141, 5, 92, 64, 101],
  [46, 77, 5, 53, 114, 101],
  [12, 113, 5, 6, 10, 34],
  [20, 123, 5, 59, 56, 115],
  [46, 81, 5, 59, 90, 97],
  [12, 106, 5, 107, 82, 91],
  [16, 48, 5, 22, 64, 115],
  [12, 52, 5, 107, 7, 67],
  [3, 135, 5, 17, 18, 11],
  [12, 85, 5, 36, 29, 35],
  [30, 113, 5, 13, 18, 118],
  [24, 31, 5, 89, 49, 27],
  [39, 104, 5, 13, 54, 76],
  [3, 31, 5, 6, 94, 121],
  [20, 52, 5, 89, 64, 131],
  [3, 40, 5, 13, 18, 8],
  [12, 28, 5, 139, 7, 23],
  [16, 106, 5, 89, 44, 8],
  [46, 110, 5, 36, 18, 34],
  [39, 66, 5, 107, 73, 78],
  [39, 43, 5, 32, 37, 126],
  [46, 79, 5, 32, 49, 74],
  [3, 88, 5, 107, 64, 111],
  [24, 96, 5, 6, 18, 115],
  [3, 58, 5, 89, 37, 78],
  [16, 28, 5, 17, 62, 84],
  [46, 43, 5, 36, 14, 115],
  [12, 31, 5, 17, 7, 68],
  [12, 35, 5, 53, 56, 78],
  [39, 103, 5, 36, 18, 76],
  [3, 55, 5, 6, 14, 78],
  [16, 35, 5, 17, 114, 68],
  [20, 81, 5, 22, 75, 121],
  [12, 106, 5, 53, 14, 51],
  [24, 104, 5, 59, 29, 121],
  [24, 88, 5, 26, 18, 138],
  [39, 43, 5, 139, 18, 99],
  [39, 104, 5, 32, 37, 11],
  [16, 85, 5, 59, 73, 67],
  [24, 66, 5, 17, 18, 11],
  [20, 55, 5, 17, 18, 27],
  [39, 66, 5, 13, 64, 93],
  [3, 21, 5, 107, 18, 105],
  [12, 81, 5, 32, 18, 80],
  [30, 81, 5, 17, 82, 99],
  [24, 9, 5, 22, 124, 121],
  [24, 52, 5, 59, 56, 109],
  [46, 9, 5, 17, 82, 121],
  [46, 112, 5, 92, 82, 8],
  [20, 135, 5, 32, 62, 138],
  [3, 141, 5, 32, 18, 68],
  [39, 106, 5, 89, 18, 105],
  [16, 66, 5, 17, 54, 121],
  [24, 25, 5, 107, 114, 118],
  [39, 55, 5, 59, 41, 93],
  [30, 106, 5, 6, 64, 38],
  [3, 58, 5, 13, 100, 134],
  [16, 113, 5, 107, 90, 38],
  [39, 58, 5, 36, 7, 131],
  [39, 103, 5, 22, 18, 91],
  [3, 119, 5, 139, 102, 10],
  [3, 133, 5, 63, 54, 47],
  [30, 50, 5, 6, 56, 74],
  [12, 69, 5, 17, 56, 65],
  [39, 57, 5, 17, 82, 108],
  [39, 69, 5, 32, 94, 138],
  [24, 81, 5, 17, 73, 91],
  [16, 58, 5, 59, 62, 115],
  [46, 72, 5, 92, 62, 121],
  [20, 96, 5, 6, 33, 65],
  [39, 43, 5, 22, 130, 91],
  [30, 140, 5, 32, 18, 129],
  [16, 55, 5, 13, 29, 83],
  [24, 128, 5, 36, 18, 88],
  [46, 31, 5, 22, 18, 68],
  [16, 125, 5, 107, 130, 8],
  [46, 35, 5, 6, 62, 45],
  [46, 132, 5, 89, 49, 93],
  [46, 48, 5, 107, 56, 108],
  [39, 113, 5, 107, 18, 61],
  [20, 112, 5, 22, 90, 105],
  [12, 85, 5, 32, 18, 78],
  [46, 66, 5, 32, 7, 122],
  [16, 87, 5, 6, 18, 118],
  [3, 77, 5, 26, 18, 68],
  [46, 87, 5, 92, 114, 74],
  [30, 21, 5, 6, 33, 91],
  [46, 106, 5, 13, 75, 51],
  [16, 35, 5, 22, 14, 10],
  [46, 50, 5, 107, 56, 76],
  [3, 35, 5, 53, 18, 109],
  [39, 85, 5, 53, 37, 101],
  [16, 112, 5, 107, 100, 45],
  [12, 66, 5, 59, 54, 10],
  [12, 52, 5, 13, 33, 78],
  [24, 77, 5, 17, 94, 131],
  [12, 4, 5, 13, 37, 131],
  [30, 35, 5, 59, 62, 115],
  [16, 142, 5, 63, 29, 80],
  [3, 50, 5, 17, 90, 74],
  [46, 128, 5, 63, 102, 65],
  [12, 28, 5, 107, 73, 101],
  [30, 52, 5, 107, 62, 84],
  [20, 106, 5, 32, 33, 91],
  [46, 31, 5, 59, 18, 45],
  [20, 58, 5, 92, 64, 51],
  [3, 113, 5, 26, 18, 131],
  [16, 117, 5, 89, 7, 47],
  [16, 96, 5, 107, 94, 99],
  [46, 66, 5, 32, 75, 121],
  [16, 35, 5, 89, 100, 11],
  [39, 113, 5, 36, 44, 74],
  [12, 106, 5, 26, 29, 109],
  [3, 106, 5, 6, 94, 76],
  [30, 31, 5, 107, 75, 99],
  [39, 79, 5, 89, 82, 76],
  [12, 132, 5, 59, 82, 111],
  [16, 125, 5, 53, 18, 34],
  [16, 48, 5, 17, 102, 74],
  [16, 132, 5, 26, 100, 42],
  [16, 140, 5, 36, 124, 35],
  [46, 112, 5, 107, 29, 129],
  [30, 104, 5, 53, 18, 131],
  [46, 55, 5, 13, 90, 122],
  [46, 52, 5, 53, 37, 80],
  [24, 85, 5, 59, 94, 83],
  [12, 66, 5, 63, 100, 70],
  [3, 50, 5, 17, 62, 138],
  [3, 96, 5, 6, 18, 34],
  [30, 57, 5, 22, 94, 131],
  [3, 27, 5, 13, 94, 74],
  [39, 96, 5, 107, 73, 42],
  [30, 141, 5, 13, 7, 122],
  [39, 27, 5, 59, 18, 115],
  [3, 48, 5, 107, 56, 83],
  [12, 52, 5, 63, 73, 74],
  [12, 10, 5, 6, 75, 83],
  [46, 69, 5, 22, 29, 108],
  [12, 43, 5, 17, 75, 115],
  [3, 50, 5, 17, 100, 68],
  [3, 25, 5, 6, 29, 137],
  [46, 52, 5, 59, 44, 67],
  [3, 141, 5, 22, 102, 65],
  [12, 28, 5, 22, 29, 71],
  [12, 69, 5, 32, 90, 71],
  [3, 69, 5, 26, 82, 131],
  [16, 69, 5, 63, 37, 109],
  [16, 133, 5, 92, 37, 70],
  [46, 135, 5, 89, 18, 34],
  [12, 104, 5, 32, 18, 47],
  [16, 43, 5, 139, 14, 8],
  [16, 113, 5, 13, 18, 8],
  [30, 58, 5, 26, 37, 131],
  [20, 52, 5, 17, 75, 45],
  [12, 25, 5, 107, 14, 80],
  [3, 106, 5, 32, 82, 11],
  [16, 31, 5, 36, 33, 67],
  [20, 50, 5, 22, 62, 45],
  [30, 96, 5, 22, 18, 108],
  [3, 69, 5, 86, 44, 80],
  [39, 55, 5, 17, 56, 45],
  [39, 112, 5, 36, 7, 115],
  [46, 81, 5, 59, 29, 11],
  [20, 66, 5, 22, 56, 137],
  [20, 72, 5, 89, 7, 91],
  [12, 25, 5, 17, 18, 108],
  [24, 87, 5, 13, 7, 88],
  [3, 132, 5, 53, 14, 67],
  [20, 112, 5, 53, 64, 91],
  [24, 104, 5, 59, 18, 67],
  [3, 50, 5, 32, 130, 131],
  [20, 103, 5, 89, 37, 101],
  [20, 133, 5, 63, 37, 67],
  [39, 50, 5, 63, 62, 68],
  [16, 132, 5, 107, 130, 122],
  [16, 119, 5, 17, 98, 101],
  [20, 43, 5, 13, 94, 84],
  [39, 117, 5, 92, 18, 70],
  [24, 96, 5, 32, 33, 138],
  [30, 48, 5, 63, 33, 99],
  [16, 31, 5, 107, 54, 99],
  [39, 55, 5, 26, 7, 134],
  [30, 135, 5, 36, 94, 115],
  [12, 57, 5, 26, 94, 47],
  [46, 43, 5, 22, 18, 108],
  [16, 27, 5, 32, 56, 122],
  [39, 58, 5, 22, 73, 138],
  [46, 104, 5, 63, 82, 8],
  [39, 25, 5, 17, 54, 47],
  [12, 132, 5, 13, 62, 70],
  [3, 35, 5, 13, 14, 101],
  [16, 87, 5, 13, 33, 45],
  [16, 106, 5, 92, 82, 10],
  [3, 140, 5, 107, 94, 97],
  [24, 25, 5, 53, 7, 121],
  [30, 110, 5, 107, 73, 91],
  [46, 113, 5, 17, 18, 45],
  [39, 123, 5, 32, 54, 117],
  [3, 57, 5, 36, 90, 8],
  [39, 55, 5, 92, 114, 121],
  [30, 35, 5, 17, 10, 76],
  [30, 87, 5, 92, 29, 122],
  [30, 103, 5, 32, 18, 27],
  [24, 128, 5, 6, 82, 99],
  [12, 112, 5, 32, 44, 131],
  [46, 4, 5, 107, 18, 88],
  [20, 106, 5, 36, 54, 47],
  [46, 104, 5, 13, 37, 121],
  [20, 28, 5, 92, 102, 74],
  [3, 106, 5, 17, 56, 74],
  [16, 142, 5, 6, 18, 126],
  [3, 69, 5, 17, 75, 74],
  [20, 35, 5, 53, 100, 122],
  [30, 69, 5, 63, 102, 68],
  [3, 87, 5, 53, 64, 101],
  [3, 110, 5, 17, 102, 74],
  [12, 132, 5, 13, 29, 118],
  [16, 133, 5, 63, 18, 109],
  [46, 58, 5, 13, 7, 71],
  [3, 104, 5, 53, 100, 80],
  [16, 119, 5, 22, 102, 115],
  [16, 125, 5, 107, 73, 105],
  [12, 55, 5, 107, 18, 34],
  [20, 48, 5, 26, 18, 8],
  [20, 113, 5, 89, 54, 35],
  [3, 104, 5, 13, 14, 115],
  [30, 55, 5, 22, 54, 118],
  [12, 79, 5, 17, 102, 131],
  [24, 96, 5, 26, 102, 34],
  [39, 117, 5, 13, 18, 61],
  [30, 81, 5, 26, 56, 83],
  [46, 55, 5, 36, 37, 35],
  [12, 35, 5, 13, 54, 10],
  [20, 9, 5, 17, 100, 105],
  [46, 132, 5, 63, 75, 117],
  [3, 113, 5, 92, 94, 51],
  [30, 117, 5, 17, 49, 91],
  [12, 106, 5, 17, 62, 99],
  [30, 106, 5, 17, 33, 101],
  [39, 28, 5, 63, 18, 8],
  [16, 77, 5, 92, 90, 101],
  [16, 72, 5, 32, 90, 68],
  [30, 72, 5, 107, 18, 38],
  [3, 50, 5, 32, 90, 99],
  [24, 69, 5, 32, 73, 91],
  [46, 96, 5, 17, 14, 68],
  [16, 66, 5, 92, 100, 8],
  [39, 88, 5, 13, 130, 47],
  [12, 140, 5, 13, 90, 8],
  [30, 104, 5, 17, 18, 80],
  [16, 55, 5, 26, 100, 67],
  [46, 79, 5, 22, 60, 126],
  [24, 85, 5, 17, 82, 131],
  [24, 77, 5, 26, 102, 65],
  [16, 112, 5, 13, 102, 101],
  [12, 10, 5, 63, 33, 76],
  [39, 58, 5, 6, 44, 67],
  [12, 106, 5, 17, 37, 131],
  [16, 55, 5, 92, 49, 91],
  [30, 128, 5, 6, 18, 67],
  [16, 103, 5, 32, 94, 115],
  [24, 79, 5, 36, 94, 70],
  [39, 58, 5, 36, 29, 93],
  [46, 57, 5, 13, 75, 76],
  [3, 103, 5, 26, 37, 101],
  [24, 104, 5, 32, 14, 129],
  [16, 85, 5, 32, 10, 23],
  [3, 119, 5, 53, 37, 67],
  [30, 104, 5, 63, 114, 45],
  [16, 57, 5, 107, 54, 111],
  [39, 79, 5, 32, 18, 76],
  [12, 40, 5, 13, 73, 108],
  [30, 40, 5, 36, 90, 101],
  [39, 103, 5, 89, 82, 74],
  [24, 35, 5, 6, 75, 84],
  [39, 96, 5, 89, 114, 84],
  [39, 25, 5, 63, 94, 131],
  [16, 31, 5, 22, 73, 71],
  [39, 69, 5, 13, 37, 51],
  [46, 112, 5, 22, 100, 91],
  [30, 28, 5, 6, 29, 76],
  [24, 48, 5, 92, 62, 80],
  [12, 87, 5, 92, 90, 137],
  [16, 43, 5, 59, 33, 99],
  [12, 48, 5, 86, 18, 67],
  [39, 48, 5, 22, 56, 11],
  [39, 88, 5, 59, 60, 101],
  [39, 132, 5, 63, 90, 11],
  [46, 133, 5, 92, 82, 143],
  [46, 96, 5, 89, 18, 84],
  [46, 85, 5, 17, 44, 101],
  [20, 66, 5, 63, 49, 88],
  [24, 28, 5, 32, 33, 76],
  [16, 140, 5, 92, 14, 83],
  [30, 69, 5, 32, 7, 105],
  [20, 28, 5, 6, 18, 88],
  [46, 50, 5, 63, 75, 71],
  [12, 52, 5, 13, 130, 71],
  [24, 35, 5, 17, 54, 108],
  [39, 28, 5, 53, 29, 126],
  [16, 27, 5, 13, 33, 126],
  [39, 112, 5, 13, 64, 74],
  [20, 128, 5, 92, 29, 131],
  [39, 110, 5, 107, 7, 101],
  [24, 135, 5, 26, 18, 45],
  [24, 132, 5, 22, 33, 47],
  [39, 21, 5, 107, 7, 8],
  [20, 69, 5, 22, 64, 91],
  [16, 106, 5, 6, 130, 8],
  [20, 43, 5, 26, 82, 61],
  [20, 10, 5, 36, 90, 74],
  [16, 55, 5, 53, 124, 65],
  [46, 50, 5, 63, 10, 47],
  [12, 140, 5, 59, 62, 45],
  [20, 31, 5, 17, 7, 35],
  [12, 69, 5, 92, 10, 78],
  [3, 127, 5, 22, 37, 67],
  [12, 85, 5, 13, 102, 65],
  [39, 85, 5, 6, 56, 117],
  [30, 52, 5, 59, 10, 131],
  [46, 9, 5, 6, 75, 34],
  [24, 25, 5, 26, 64, 68],
  [46, 27, 5, 59, 10, 115],
  [46, 52, 5, 53, 62, 131],
  [16, 103, 5, 6, 18, 91],
  [12, 106, 5, 63, 75, 74],
  [20, 132, 5, 6, 102, 74],
  [46, 35, 5, 92, 29, 74],
  [30, 116, 5, 6, 44, 91],
  [16, 81, 5, 17, 44, 121],
  [46, 96, 5, 53, 75, 99],
  [3, 88, 5, 92, 18, 78],
  [16, 88, 5, 13, 10, 23],
  [12, 28, 5, 53, 82, 65],
  [24, 103, 5, 32, 44, 78],
  [16, 113, 5, 92, 18, 68],
  [30, 50, 5, 36, 37, 70],
  [20, 57, 5, 59, 44, 71],
  [20, 135, 5, 6, 14, 71],
  [16, 66, 5, 107, 18, 88],
  [46, 135, 5, 89, 18, 117],
  [39, 113, 5, 53, 18, 108],
  [46, 123, 5, 59, 56, 122],
  [39, 127, 5, 26, 94, 10],
  [39, 69, 5, 63, 18, 121],
  [20, 69, 5, 63, 54, 99],
  [12, 128, 5, 36, 54, 71],
  [30, 50, 5, 36, 14, 138],
  [46, 125, 5, 36, 130, 70],
  [46, 43, 5, 53, 14, 91],
  [20, 106, 5, 107, 90, 51],
  [46, 31, 5, 107, 7, 47],
  [30, 127, 5, 17, 7, 65],
  [20, 103, 5, 36, 18, 131],
  [46, 31, 5, 92, 18, 137],
  [16, 110, 5, 89, 29, 109],
  [3, 112, 5, 17, 75, 78],
  [20, 117, 5, 36, 33, 122],
  [3, 55, 5, 26, 130, 42],
  [16, 112, 5, 13, 62, 115],
  [24, 106, 5, 107, 33, 71],
  [20, 72, 5, 26, 14, 8],
  [46, 112, 5, 22, 18, 51],
  [20, 57, 5, 32, 75, 11],
  [39, 96, 5, 89, 18, 109],
  [16, 66, 5, 26, 29, 88],
  [16, 57, 5, 13, 64, 88],
  [12, 104, 5, 22, 18, 99],
  [39, 120, 5, 53, 37, 115],
  [39, 25, 5, 89, 18, 74],
  [20, 50, 5, 59, 18, 67],
  [16, 103, 5, 17, 29, 34],
  [3, 69, 5, 17, 44, 121],
  [20, 104, 5, 6, 29, 108],
  [3, 140, 5, 32, 18, 47],
  [20, 79, 5, 36, 56, 67],
  [20, 43, 5, 53, 94, 117],
  [3, 87, 5, 59, 18, 8],
  [46, 55, 5, 53, 114, 131],
  [39, 66, 5, 36, 49, 99],
  [39, 132, 5, 17, 124, 99],
  [16, 50, 5, 32, 75, 95],
  [16, 113, 5, 17, 94, 65],
  [3, 43, 5, 6, 62, 111],
  [30, 43, 5, 92, 73, 126],
  [3, 48, 5, 107, 62, 122],
  [20, 77, 5, 6, 98, 47],
  [46, 104, 5, 32, 54, 99],
  [46, 57, 5, 63, 7, 68],
  [16, 35, 5, 26, 44, 71],
  [16, 57, 5, 89, 18, 65],
  [46, 88, 5, 59, 90, 35],
  [16, 81, 5, 6, 90, 105],
  [30, 48, 5, 36, 37, 80],
  [16, 85, 5, 26, 18, 131],
  [16, 31, 5, 107, 130, 65],
  [12, 35, 5, 63, 82, 129],
  [3, 43, 5, 36, 62, 111],
  [20, 50, 5, 32, 54, 105],
  [30, 52, 5, 17, 29, 83],
  [39, 48, 5, 17, 73, 74],
  [3, 135, 5, 17, 75, 71],
  [30, 69, 5, 13, 90, 84],
  [16, 119, 5, 32, 18, 34],
  [16, 58, 5, 92, 10, 8],
  [3, 57, 5, 59, 130, 137],
  [30, 35, 5, 22, 54, 15],
  [39, 96, 5, 92, 56, 101],
  [39, 35, 5, 107, 49, 83],
  [20, 52, 5, 107, 54, 122],
  [16, 48, 5, 89, 75, 11],
  [30, 113, 5, 107, 29, 115],
  [24, 50, 5, 32, 37, 91],
  [30, 103, 5, 59, 29, 67],
  [30, 58, 5, 107, 98, 136],
  [24, 72, 5, 89, 82, 15],
  [46, 69, 5, 107, 130, 109],
  [24, 4, 5, 59, 44, 45],
  [16, 28, 5, 59, 33, 67],
  [30, 106, 5, 59, 102, 67],
  [24, 119, 5, 17, 62, 65],
  [12, 21, 5, 59, 62, 70],
  [46, 48, 5, 92, 18, 68],
  [20, 52, 5, 13, 54, 111],
  [16, 110, 5, 32, 33, 108],
  [46, 77, 5, 22, 14, 71],
  [46, 35, 5, 63, 56, 108],
  [12, 69, 5, 92, 18, 105],
  [24, 21, 5, 92, 90, 70],
  [39, 106, 5, 6, 73, 78],
  [3, 50, 5, 26, 49, 47],
  [16, 55, 5, 17, 7, 65],
  [3, 123, 5, 59, 33, 121],
  [12, 119, 5, 26, 18, 68],
  [16, 135, 5, 107, 54, 35],
  [39, 43, 5, 26, 73, 83],
  [39, 135, 5, 32, 18, 34],
  [39, 31, 5, 92, 18, 68],
  [3, 72, 5, 107, 33, 80],
  [3, 140, 5, 22, 82, 47],
  [24, 72, 5, 17, 18, 27],
  [12, 28, 5, 13, 100, 122],
  [20, 40, 5, 63, 94, 76],
  [30, 35, 5, 89, 49, 91],
  [16, 85, 5, 92, 73, 45],
  [16, 48, 5, 13, 10, 109],
  [3, 48, 5, 26, 18, 11],
  [3, 120, 5, 63, 44, 51],
  [12, 25, 5, 107, 18, 93],
  [39, 117, 5, 107, 100, 131],
  [39, 43, 5, 22, 18, 121],
  [30, 69, 5, 92, 18, 131],
  [12, 28, 5, 6, 33, 65],
  [30, 31, 5, 26, 14, 78],
  [30, 28, 5, 59, 94, 91],
  [12, 72, 5, 32, 37, 10],
  [12, 81, 5, 6, 18, 15],
  [24, 85, 5, 59, 73, 131],
  [3, 85, 5, 6, 18, 8],
  [39, 106, 5, 6, 62, 122],
  [12, 66, 5, 17, 18, 84],
  [39, 43, 5, 6, 29, 45],
  [30, 132, 5, 107, 18, 65],
  [24, 88, 5, 107, 130, 34],
  [24, 69, 5, 13, 18, 91],
  [46, 55, 5, 13, 73, 111],
  [24, 10, 5, 26, 10, 51],
  [12, 119, 5, 36, 37, 34],
  [3, 50, 5, 36, 37, 78],
  [3, 104, 5, 17, 7, 131],
  [39, 40, 5, 36, 44, 45],
  [39, 96, 5, 36, 18, 74],
  [16, 88, 5, 32, 73, 137],
  [39, 55, 5, 26, 124, 70],
  [39, 112, 5, 13, 64, 23],
  [24, 113, 5, 107, 82, 108],
  [20, 55, 5, 89, 14, 111],
  [3, 106, 5, 22, 33, 78],
  [30, 104, 5, 22, 44, 45],
  [30, 96, 5, 36, 94, 51],
  [3, 10, 5, 32, 49, 78],
  [46, 104, 5, 59, 18, 27],
  [16, 69, 5, 13, 10, 101],
  [46, 43, 5, 13, 62, 65],
  [12, 103, 5, 13, 56, 138],
  [30, 85, 5, 26, 10, 67],
  [12, 125, 5, 92, 18, 91],
  [46, 106, 5, 17, 54, 71],
  [3, 106, 5, 92, 54, 47],
  [46, 132, 5, 53, 75, 10],
  [30, 110, 5, 92, 54, 47],
  [12, 106, 5, 32, 33, 51],
  [3, 28, 5, 53, 18, 15],
  [20, 50, 5, 32, 75, 111],
  [16, 4, 5, 22, 10, 65],
  [46, 117, 5, 107, 18, 11],
  [3, 66, 5, 22, 33, 51],
  [20, 132, 5, 92, 94, 99],
  [20, 43, 5, 32, 18, 88],
  [46, 72, 5, 6, 29, 93],
  [16, 55, 5, 59, 130, 101],
  [3, 87, 5, 22, 14, 111],
  [30, 81, 5, 59, 90, 71],
  [12, 58, 5, 22, 56, 122],
  [39, 119, 5, 17, 82, 108],
  [16, 43, 5, 22, 33, 70],
  [12, 125, 5, 63, 90, 38],
  [3, 57, 5, 22, 18, 47],
  [16, 50, 5, 13, 29, 91],
  [20, 31, 5, 36, 73, 68],
  [16, 31, 5, 92, 130, 143],
  [20, 132, 5, 6, 130, 34],
  [24, 52, 5, 6, 37, 115],
  [3, 104, 5, 107, 102, 111],
  [16, 106, 5, 36, 62, 67],
  [39, 58, 5, 107, 18, 34],
  [16, 52, 5, 53, 18, 78],
  [16, 57, 5, 59, 62, 117],
  [46, 113, 5, 107, 44, 121],
  [30, 35, 5, 17, 102, 47],
  [39, 88, 5, 107, 114, 34],
  [30, 141, 5, 17, 94, 27],
  [16, 48, 5, 26, 102, 101],
  [24, 57, 5, 92, 18, 129],
  [39, 133, 5, 17, 54, 45],
  [46, 57, 5, 63, 7, 76],
  [20, 35, 5, 59, 75, 105],
  [12, 57, 5, 107, 82, 83],
  [46, 9, 5, 17, 90, 74],
  [3, 103, 5, 22, 94, 65],
  [20, 10, 5, 17, 49, 101],
  [16, 106, 5, 89, 100, 45],
  [12, 21, 5, 63, 18, 91],
  [16, 55, 5, 17, 18, 38],
  [12, 9, 5, 36, 18, 115],
  [12, 116, 5, 63, 64, 45],
  [20, 55, 5, 59, 10, 108],
  [3, 40, 5, 32, 124, 47],
  [39, 66, 5, 17, 73, 11],
  [30, 117, 5, 32, 18, 93],
  [12, 81, 5, 89, 56, 122],
  [3, 104, 5, 107, 114, 91],
  [20, 69, 5, 63, 18, 121],
  [46, 113, 5, 89, 94, 84],
  [16, 96, 5, 59, 124, 80],
  [3, 116, 5, 107, 90, 65],
  [30, 69, 5, 13, 62, 34],
  [3, 57, 5, 26, 41, 101],
  [46, 119, 5, 59, 54, 126],
  [24, 103, 5, 107, 37, 76],
  [16, 58, 5, 92, 75, 10],
  [46, 132, 5, 22, 29, 34],
  [3, 35, 5, 92, 90, 38],
  [39, 103, 5, 17, 18, 65],
  [30, 35, 5, 13, 90, 122],
  [3, 106, 5, 92, 29, 117],
  [16, 96, 5, 59, 64, 15],
  [16, 58, 5, 63, 75, 8],
  [46, 135, 5, 32, 18, 91],
  [39, 142, 5, 6, 14, 93],
  [39, 103, 5, 6, 130, 15],
  [3, 28, 5, 6, 14, 117],
  [20, 112, 5, 22, 29, 70],
  [46, 48, 5, 17, 29, 122],
  [30, 81, 5, 59, 18, 45],
  [12, 106, 5, 6, 82, 67],
  [30, 116, 5, 59, 64, 51],
  [24, 135, 5, 89, 29, 78],
  [46, 57, 5, 26, 98, 115],
  [12, 104, 5, 92, 18, 136],
  [16, 57, 5, 89, 100, 83],
  [39, 81, 5, 36, 14, 131],
  [30, 119, 5, 32, 29, 131],
  [46, 40, 5, 13, 94, 76],
  [30, 35, 5, 36, 56, 70],
  [12, 119, 5, 17, 102, 101],
  [20, 132, 5, 86, 18, 78],
  [39, 87, 5, 13, 102, 93],
  [16, 127, 5, 53, 130, 35],
  [39, 142, 5, 63, 33, 131],
  [3, 133, 5, 13, 102, 129],
  [3, 58, 5, 59, 14, 74],
  [46, 132, 5, 26, 14, 74],
  [20, 25, 5, 92, 14, 71],
  [16, 85, 5, 63, 33, 67],
  [39, 28, 5, 107, 98, 131],
  [30, 43, 5, 36, 18, 76],
  [46, 27, 5, 17, 18, 71],
  [24, 28, 5, 26, 18, 10],
  [39, 141, 5, 63, 44, 111],
  [39, 57, 5, 53, 64, 99],
  [16, 72, 5, 63, 14, 47],
  [46, 27, 5, 17, 37, 109],
  [3, 132, 5, 17, 94, 65],
  [30, 35, 5, 89, 49, 80],
  [3, 125, 5, 36, 75, 109],
  [3, 43, 5, 53, 10, 78],
  [39, 103, 5, 92, 18, 51],
  [39, 106, 5, 59, 75, 126],
  [12, 106, 5, 26, 56, 80],
  [30, 119, 5, 17, 56, 108],
  [16, 125, 5, 107, 100, 83],
  [24, 50, 5, 26, 130, 68],
  [46, 81, 5, 107, 7, 121],
  [16, 57, 5, 107, 29, 115],
  [46, 88, 5, 92, 14, 65],
  [16, 104, 5, 107, 82, 76],
  [46, 35, 5, 6, 90, 111],
  [16, 113, 5, 92, 33, 99],
  [16, 52, 5, 107, 14, 74],
  [30, 66, 5, 32, 44, 115],
  [16, 50, 5, 6, 10, 78],
  [12, 88, 5, 89, 56, 126],
  [3, 120, 5, 17, 18, 74],
  [3, 72, 5, 63, 75, 129],
  [16, 133, 5, 63, 82, 51],
  [46, 96, 5, 86, 90, 99],
  [3, 66, 5, 53, 7, 83],
  [30, 43, 5, 63, 94, 126],
  [16, 135, 5, 89, 29, 38],
  [24, 103, 5, 63, 94, 34],
  [3, 31, 5, 89, 18, 65],
  [24, 55, 5, 6, 44, 15],
  [24, 132, 5, 17, 64, 108],
  [20, 27, 5, 17, 56, 99],
  [12, 31, 5, 6, 100, 143],
  [16, 57, 5, 107, 10, 71],
  [24, 85, 5, 22, 124, 10],
  [3, 133, 5, 59, 73, 83],
  [46, 27, 5, 13, 54, 34],
  [39, 25, 5, 13, 18, 78],
  [3, 28, 5, 36, 130, 51],
  [3, 141, 5, 63, 14, 121],
  [16, 43, 5, 107, 18, 47],
  [20, 113, 5, 13, 94, 68],
  [24, 69, 5, 17, 10, 118],
  [39, 85, 5, 89, 124, 78],
  [46, 119, 5, 32, 18, 11],
  [39, 31, 5, 92, 114, 71],
  [20, 116, 5, 22, 100, 129],
  [39, 81, 5, 13, 62, 74],
  [30, 113, 5, 63, 100, 117],
  [46, 55, 5, 17, 82, 8],
  [46, 112, 5, 32, 90, 121],
  [20, 31, 5, 107, 37, 84],
  [16, 96, 5, 59, 37, 111],
  [39, 77, 5, 13, 73, 122],
  [16, 87, 5, 107, 73, 97],
  [46, 85, 5, 59, 64, 105],
  [46, 21, 5, 92, 56, 78],
  [46, 133, 5, 6, 64, 68],
  [16, 106, 5, 107, 90, 91],
  [16, 112, 5, 92, 33, 65],
  [12, 43, 5, 32, 10, 83],
  [30, 55, 5, 17, 100, 93],
  [20, 96, 5, 36, 14, 108],
  [16, 43, 5, 107, 64, 65],
  [3, 113, 5, 6, 29, 122],
  [3, 87, 5, 107, 37, 115],
  [16, 27, 5, 53, 56, 11],
  [30, 27, 5, 92, 44, 51],
  [16, 81, 5, 32, 18, 117],
  [46, 66, 5, 92, 18, 68],
  [46, 52, 5, 53, 18, 78],
  [39, 48, 5, 107, 82, 121],
  [16, 81, 5, 26, 18, 131],
  [24, 104, 5, 32, 100, 61],
  [46, 119, 5, 139, 14, 137],
  [3, 132, 5, 59, 56, 122],
  [30, 120, 5, 89, 54, 51],
  [16, 133, 5, 6, 102, 109],
  [30, 81, 5, 92, 56, 131],
  [30, 106, 5, 17, 102, 138],
  [20, 57, 5, 107, 54, 76],
  [3, 96, 5, 107, 33, 131],
  [24, 113, 5, 17, 75, 105],
  [20, 112, 5, 22, 18, 121],
  [46, 104, 5, 63, 124, 115],
  [24, 128, 5, 36, 124, 121],
  [30, 106, 5, 13, 90, 99],
  [24, 50, 5, 107, 54, 68],
  [30, 43, 5, 107, 82, 74],
  [46, 9, 5, 92, 124, 105],
  [24, 104, 5, 26, 102, 8],
  [24, 106, 5, 22, 18, 38],
  [12, 103, 5, 17, 82, 80],
  [20, 119, 5, 22, 33, 101],
  [39, 58, 5, 6, 73, 101],
  [46, 66, 5, 63, 7, 76],
  [16, 48, 5, 17, 94, 65],
  [12, 4, 5, 13, 82, 47],
  [39, 48, 5, 53, 14, 8],
  [46, 113, 5, 17, 37, 95],
  [3, 31, 5, 53, 44, 68],
  [3, 55, 5, 89, 75, 118],
  [3, 50, 5, 63, 82, 88],
  [16, 72, 5, 26, 56, 99],
  [16, 106, 5, 107, 44, 138],
  [30, 43, 5, 6, 73, 99],
  [30, 50, 5, 59, 56, 74],
  [20, 81, 5, 13, 37, 88],
  [20, 127, 5, 17, 90, 105],
  [20, 31, 5, 32, 37, 99],
  [30, 116, 5, 6, 64, 8],
  [46, 66, 5, 17, 114, 122],
  [12, 77, 5, 36, 54, 137],
  [39, 50, 5, 17, 44, 11],
  [20, 4, 5, 32, 124, 42],
  [12, 85, 5, 17, 130, 115],
  [20, 69, 5, 107, 7, 101],
  [20, 66, 5, 17, 18, 71],
  [39, 96, 5, 59, 100, 91],
  [12, 106, 5, 26, 56, 68],
  [39, 52, 5, 32, 29, 45],
  [46, 85, 5, 107, 100, 11],
  [12, 85, 5, 107, 33, 51],
  [12, 57, 5, 13, 7, 99],
  [30, 57, 5, 17, 29, 34],
  [30, 72, 5, 22, 44, 122],
  [46, 120, 5, 17, 62, 47],
  [16, 31, 5, 92, 54, 10],
  [46, 50, 5, 6, 33, 122],
  [3, 81, 5, 89, 56, 137],
  [12, 103, 5, 59, 37, 121],
  [12, 103, 5, 6, 102, 122],
  [20, 35, 5, 92, 100, 65],
  [46, 96, 5, 107, 75, 74],
  [30, 66, 5, 26, 73, 91],
  [39, 106, 5, 59, 54, 108],
  [30, 113, 5, 32, 102, 122],
  [16, 35, 5, 59, 73, 108],
  [3, 106, 5, 107, 62, 129],
  [16, 50, 5, 26, 114, 91],
  [30, 43, 5, 26, 64, 8],
  [30, 69, 5, 89, 64, 111],
  [30, 104, 5, 92, 73, 8],
  [39, 120, 5, 89, 90, 131],
  [30, 52, 5, 13, 44, 108],
  [46, 58, 5, 32, 18, 34],
  [20, 43, 5, 107, 75, 35],
  [16, 72, 5, 86, 18, 38],
  [30, 66, 5, 59, 18, 122],
  [24, 35, 5, 107, 102, 71],
  [20, 81, 5, 89, 130, 91],
  [30, 43, 5, 107, 64, 45],
  [24, 85, 5, 26, 102, 45],
  [24, 72, 5, 59, 33, 67],
  [3, 9, 5, 17, 73, 121],
  [20, 87, 5, 53, 90, 108],
  [46, 119, 5, 36, 94, 45],
  [24, 66, 5, 17, 44, 38],
  [30, 58, 5, 107, 29, 68],
  [3, 35, 5, 89, 102, 70],
  [24, 9, 5, 53, 44, 83],
  [3, 25, 5, 17, 14, 35],
  [16, 85, 5, 13, 54, 70],
  [24, 141, 5, 107, 18, 101],
  [46, 96, 5, 59, 18, 70],
  [46, 58, 5, 32, 18, 138],
  [12, 120, 5, 6, 49, 19],
  [3, 43, 5, 36, 18, 70],
  [16, 50, 5, 59, 64, 47],
  [30, 35, 5, 13, 18, 131],
  [39, 57, 5, 89, 90, 23],
  [3, 112, 5, 22, 62, 129],
  [16, 57, 5, 13, 130, 47],
  [46, 57, 5, 92, 18, 101],
  [3, 123, 5, 26, 75, 45],
  [20, 79, 5, 26, 90, 131],
  [30, 48, 5, 36, 7, 80],
  [39, 57, 5, 36, 130, 74],
  [39, 123, 5, 92, 75, 121],
  [24, 112, 5, 22, 18, 101],
  [39, 66, 5, 63, 94, 118],
  [24, 35, 5, 53, 33, 122],
  [30, 81, 5, 32, 82, 117],
  [30, 77, 5, 13, 18, 84],
  [3, 81, 5, 63, 33, 126],
  [39, 35, 5, 107, 49, 11],
  [3, 113, 5, 89, 7, 51],
  [16, 116, 5, 22, 73, 115],
  [30, 125, 5, 107, 94, 136],
  [46, 116, 5, 17, 18, 136],
  [30, 58, 5, 32, 75, 80],
  [16, 106, 5, 107, 10, 47],
  [46, 140, 5, 6, 33, 8],
  [30, 35, 5, 17, 62, 74],
  [30, 31, 5, 32, 49, 8],
  [16, 106, 5, 17, 7, 111],
  [16, 112, 5, 63, 7, 67],
  [30, 72, 5, 59, 56, 47],
  [12, 43, 5, 22, 7, 115],
  [24, 110, 5, 36, 100, 80],
  [12, 35, 5, 22, 90, 67],
  [46, 103, 5, 22, 18, 51],
  [46, 58, 5, 89, 14, 99],
  [12, 110, 5, 17, 29, 122],
  [46, 43, 5, 17, 114, 131],
  [30, 85, 5, 6, 54, 34],
  [46, 127, 5, 13, 18, 45],
  [39, 103, 5, 32, 33, 111],
  [12, 87, 5, 32, 10, 10],
  [12, 57, 5, 36, 64, 91],
  [46, 69, 5, 63, 10, 93],
  [39, 40, 5, 63, 73, 61],
  [12, 79, 5, 53, 102, 74],
  [16, 66, 5, 13, 33, 45],
  [24, 113, 5, 63, 7, 83],
  [12, 55, 5, 107, 29, 108],
  [20, 28, 5, 32, 29, 108],
  [20, 9, 5, 63, 18, 105],
  [30, 81, 5, 59, 18, 111],
  [46, 35, 5, 92, 29, 117],
  [12, 116, 5, 32, 90, 74],
  [46, 43, 5, 13, 44, 34],
  [24, 55, 5, 59, 49, 95],
  [30, 106, 5, 107, 75, 84],
  [3, 79, 5, 22, 7, 118],
  [39, 87, 5, 13, 82, 45],
  [20, 52, 5, 59, 73, 45],
  [3, 10, 5, 17, 75, 47],
  [3, 119, 5, 36, 64, 51],
  [30, 96, 5, 107, 18, 101],
  [46, 119, 5, 32, 18, 45],
  [16, 132, 5, 107, 18, 111],
  [12, 113, 5, 17, 29, 131],
  [24, 66, 5, 17, 7, 78],
  [16, 55, 5, 53, 18, 68],
  [20, 112, 5, 107, 75, 101],
  [24, 79, 5, 89, 130, 93],
  [24, 50, 5, 59, 124, 34],
  [16, 113, 5, 89, 18, 80],
  [20, 52, 5, 6, 75, 131],
  [24, 69, 5, 22, 130, 111],
  [46, 140, 5, 22, 130, 122],
  [24, 57, 5, 26, 29, 11],
  [3, 55, 5, 36, 44, 65],
  [12, 72, 5, 107, 56, 45],
  [16, 57, 5, 22, 73, 108],
  [3, 87, 5, 13, 29, 99],
  [24, 48, 5, 89, 64, 76],
  [16, 81, 5, 13, 102, 11],
  [39, 48, 5, 107, 18, 78],
  [12, 27, 5, 107, 94, 8],
  [12, 112, 5, 107, 18, 15],
  [12, 57, 5, 32, 56, 34],
  [20, 104, 5, 89, 98, 137],
  [39, 28, 5, 59, 18, 71],
  [39, 128, 5, 107, 75, 122],
  [16, 117, 5, 107, 7, 93],
  [3, 55, 5, 32, 64, 65],
  [30, 28, 5, 22, 18, 121],
  [12, 43, 5, 36, 114, 99],
  [24, 128, 5, 89, 10, 11],
  [20, 28, 5, 17, 18, 118],
  [16, 35, 5, 6, 56, 108],
  [16, 77, 5, 36, 56, 134],
  [46, 52, 5, 17, 130, 42],
  [24, 85, 5, 13, 7, 78],
  [20, 69, 5, 63, 114, 115],
  [24, 69, 5, 32, 29, 38],
  [16, 116, 5, 107, 56, 38],
  [30, 58, 5, 89, 94, 134],
  [16, 57, 5, 6, 18, 8],
  [3, 9, 5, 32, 124, 115],
  [16, 103, 5, 32, 100, 118],
  [20, 127, 5, 22, 73, 34],
  [20, 50, 5, 63, 100, 71],
  [12, 72, 5, 13, 18, 78],
  [3, 141, 5, 32, 14, 108],
  [20, 55, 5, 17, 18, 11],
  [20, 69, 5, 22, 54, 91],
  [12, 55, 5, 59, 18, 83],
  [20, 106, 5, 13, 94, 134],
  [12, 112, 5, 36, 49, 34],
  [46, 141, 5, 92, 14, 8],
  [12, 50, 5, 32, 130, 88],
  [39, 96, 5, 89, 33, 121],
  [16, 132, 5, 92, 73, 47],
  [3, 69, 5, 89, 75, 115],
  [12, 66, 5, 36, 7, 74],
  [12, 112, 5, 92, 94, 45],
  [39, 58, 5, 59, 18, 68],
  [20, 135, 5, 17, 90, 131],
  [46, 58, 5, 26, 10, 131],
  [39, 112, 5, 22, 7, 101],
  [12, 72, 5, 6, 18, 83],
  [3, 58, 5, 89, 114, 70],
  [16, 66, 5, 107, 33, 38],
  [24, 35, 5, 59, 14, 91],
  [16, 87, 5, 59, 56, 115],
  [3, 58, 5, 17, 18, 61],
  [20, 103, 5, 92, 54, 67],
  [16, 52, 5, 92, 90, 97],
  [16, 116, 5, 13, 14, 71],
  [46, 31, 5, 89, 75, 122],
  [30, 31, 5, 32, 64, 126],
  [39, 50, 5, 6, 7, 122],
  [12, 72, 5, 26, 54, 65],
  [39, 9, 5, 6, 75, 15],
  [16, 55, 5, 13, 114, 115],
  [3, 57, 5, 63, 18, 8],
  [20, 35, 5, 59, 33, 71],
  [30, 28, 5, 13, 18, 68],
  [39, 112, 5, 17, 14, 99],
  [39, 10, 5, 53, 90, 115],
  [39, 113, 5, 32, 7, 74],
  [16, 117, 5, 107, 102, 42],
  [30, 113, 5, 107, 18, 95],
  [30, 103, 5, 59, 54, 34],
  [24, 48, 5, 17, 130, 42],
  [24, 52, 5, 63, 18, 68],
  [46, 133, 5, 32, 33, 76],
  [12, 85, 5, 6, 18, 70],
  [39, 58, 5, 59, 90, 95],
  [39, 88, 5, 107, 73, 137],
  [24, 119, 5, 36, 41, 38],
  [39, 79, 5, 59, 56, 8],
  [39, 77, 5, 107, 124, 70],
  [16, 57, 5, 17, 14, 68],
  [16, 133, 5, 17, 18, 99],
  [3, 116, 5, 59, 90, 84],
  [46, 55, 5, 36, 7, 83],
  [46, 69, 5, 32, 98, 34],
  [30, 55, 5, 59, 102, 91],
  [39, 116, 5, 32, 100, 134],
  [24, 28, 5, 107, 7, 76],
  [24, 87, 5, 32, 37, 83],
  [30, 85, 5, 6, 62, 68],
  [16, 85, 5, 59, 73, 138],
  [39, 119, 5, 26, 18, 84],
  [46, 58, 5, 6, 114, 121],
  [3, 128, 5, 107, 90, 138],
  [20, 31, 5, 36, 102, 131],
  [30, 28, 5, 107, 54, 115],
  [46, 69, 5, 26, 29, 65],
  [30, 104, 5, 13, 98, 108],
  [30, 25, 5, 26, 18, 65],
  [46, 55, 5, 59, 73, 68],
  [46, 28, 5, 32, 29, 45],
  [46, 55, 5, 59, 75, 76],
  [16, 119, 5, 26, 37, 138],
  [39, 104, 5, 17, 44, 101],
  [3, 48, 5, 53, 64, 122],
  [39, 103, 5, 6, 14, 67],
  [12, 123, 5, 17, 75, 34],
  [46, 57, 5, 17, 33, 91],
  [20, 35, 5, 26, 10, 111],
  [39, 28, 5, 36, 130, 76],
  [12, 57, 5, 92, 130, 84],
  [46, 9, 5, 63, 18, 101],
  [46, 119, 5, 36, 18, 47],
  [3, 81, 5, 107, 114, 122],
  [30, 4, 5, 107, 14, 47],
  [16, 31, 5, 92, 94, 131],
  [16, 87, 5, 59, 37, 34],
  [16, 48, 5, 59, 37, 126],
  [12, 28, 5, 22, 29, 8],
  [16, 27, 5, 13, 114, 45],
  [20, 104, 5, 107, 18, 10],
  [30, 48, 5, 63, 102, 74],
  [39, 88, 5, 86, 10, 115],
  [3, 50, 5, 59, 73, 47],
  [30, 50, 5, 53, 18, 80],
  [20, 106, 5, 17, 18, 68],
  [16, 85, 5, 36, 44, 80],
  [46, 96, 5, 26, 94, 105],
  [20, 66, 5, 89, 102, 70],
  [3, 58, 5, 6, 100, 47],
  [24, 85, 5, 63, 62, 126],
  [39, 69, 5, 17, 18, 117],
  [46, 104, 5, 13, 14, 23],
  [30, 106, 5, 17, 124, 11],
  [30, 104, 5, 13, 94, 71],
  [12, 85, 5, 6, 62, 15],
  [46, 50, 5, 22, 54, 11],
  [24, 27, 5, 107, 56, 131],
  [30, 27, 5, 92, 18, 47],
  [24, 28, 5, 26, 94, 71],
  [39, 110, 5, 17, 14, 65],
  [30, 48, 5, 26, 94, 111],
  [12, 57, 5, 17, 14, 35],
  [20, 85, 5, 6, 90, 91],
  [16, 103, 5, 22, 102, 70],
  [30, 110, 5, 107, 10, 99],
  [144, 144, 144, 144, 144, 144],
  [46, 48, 5, 36, 100, 105],
  [16, 28, 5, 32, 130, 108],
  [30, 69, 5, 17, 37, 80],
  [3, 119, 5, 32, 62, 42],
  [20, 52, 5, 89, 44, 78],
  [39, 55, 5, 17, 18, 47],
  [46, 87, 5, 107, 100, 129],
  [3, 96, 5, 6, 29, 8],
  [30, 66, 5, 89, 7, 80],
  [20, 43, 5, 17, 18, 67],
  [3, 104, 5, 26, 130, 83],
  [39, 106, 5, 17, 18, 131],
  [46, 69, 5, 32, 94, 109],
  [30, 57, 5, 6, 114, 83],
  [16, 96, 5, 53, 130, 129],
  [16, 52, 5, 6, 10, 78],
  [12, 48, 5, 89, 54, 11],
  [39, 120, 5, 26, 100, 34],
  [39, 104, 5, 107, 44, 65],
  [46, 25, 5, 107, 62, 105],
  [12, 66, 5, 86, 29, 10],
  [20, 55, 5, 63, 73, 8],
  [24, 57, 5, 22, 18, 105],
  [24, 96, 5, 13, 14, 70],
  [12, 35, 5, 63, 29, 8],
  [3, 141, 5, 89, 56, 65],
  [46, 66, 5, 17, 73, 74],
  [24, 43, 5, 13, 82, 83],
  [24, 128, 5, 6, 18, 35],
  [46, 55, 5, 107, 14, 109],
  [12, 132, 5, 107, 62, 42],
  [20, 132, 5, 32, 90, 67],
  [20, 57, 5, 59, 100, 47],
  [16, 123, 5, 22, 102, 8],
  [24, 57, 5, 63, 7, 134],
  [3, 21, 5, 13, 33, 129],
  [24, 55, 5, 6, 29, 67],
  [39, 133, 5, 36, 18, 8],
  [16, 31, 5, 17, 10, 67],
  [24, 132, 5, 107, 82, 131],
  [24, 87, 5, 107, 37, 115],
  [30, 10, 5, 13, 18, 35],
  [20, 66, 5, 36, 41, 121],
  [16, 55, 5, 22, 75, 8],
  [16, 127, 5, 59, 14, 19],
  [46, 116, 5, 22, 62, 34],
  [46, 128, 5, 6, 94, 91],
  [39, 28, 5, 17, 18, 70],
  [16, 119, 5, 6, 37, 68],
  [30, 133, 5, 63, 37, 76],
  [24, 57, 5, 6, 7, 47],
  [16, 96, 5, 92, 44, 118],
  [46, 66, 5, 13, 7, 88],
  [12, 87, 5, 17, 18, 71],
  [30, 96, 5, 36, 18, 121],
  [16, 142, 5, 22, 102, 47],
  [16, 25, 5, 22, 64, 27],
  [46, 50, 5, 53, 29, 91],
  [20, 87, 5, 92, 73, 78],
  [16, 57, 5, 36, 62, 101],
  [3, 81, 5, 17, 37, 117],
  [39, 104, 5, 26, 100, 65],
  [12, 4, 5, 59, 82, 34],
  [39, 25, 5, 6, 56, 118],
  [16, 57, 5, 139, 29, 117],
  [24, 88, 5, 32, 44, 115],
  [12, 110, 5, 22, 18, 10],
  [3, 79, 5, 13, 75, 109],
  [3, 31, 5, 36, 56, 34],
  [12, 28, 5, 13, 18, 71],
  [16, 81, 5, 13, 64, 11],
  [12, 50, 5, 32, 102, 105],
  [39, 113, 5, 107, 94, 61],
  [39, 104, 5, 59, 73, 47],
  [39, 123, 5, 107, 73, 11],
  [39, 66, 5, 92, 90, 111],
  [39, 66, 5, 92, 75, 68],
  [20, 132, 5, 6, 102, 83],
  [24, 28, 5, 107, 54, 115],
  [46, 9, 5, 32, 102, 84],
  [3, 113, 5, 13, 44, 136],
  [3, 87, 5, 92, 56, 15],
  [16, 35, 5, 107, 94, 115],
  [30, 120, 5, 92, 102, 74],
  [46, 119, 5, 22, 75, 45],
  [16, 88, 5, 6, 54, 61],
  [24, 43, 5, 63, 29, 117],
  [12, 81, 5, 63, 29, 11],
  [16, 27, 5, 53, 100, 131],
  [46, 43, 5, 13, 124, 121],
  [24, 31, 5, 13, 56, 68],
  [16, 112, 5, 6, 18, 122],
  [3, 125, 5, 36, 130, 122],
  [20, 69, 5, 13, 18, 105],
  [12, 28, 5, 59, 18, 68],
  [20, 103, 5, 92, 90, 68],
  [16, 88, 5, 17, 100, 78],
  [30, 87, 5, 63, 18, 109],
  [16, 141, 5, 92, 7, 47],
  [16, 119, 5, 22, 82, 11],
  [16, 69, 5, 59, 44, 101],
  [46, 81, 5, 17, 14, 27],
  [20, 40, 5, 53, 64, 121],
  [3, 69, 5, 17, 7, 80],
  [46, 133, 5, 59, 18, 131],
  [16, 57, 5, 22, 82, 11],
  [46, 81, 5, 17, 94, 105],
  [16, 58, 5, 92, 33, 99],
  [20, 58, 5, 13, 18, 11],
  [46, 28, 5, 63, 64, 105],
  [16, 140, 5, 32, 29, 65],
  [46, 87, 5, 13, 64, 99],
  [24, 50, 5, 17, 29, 35],
  [39, 72, 5, 13, 18, 74],
  [39, 43, 5, 17, 18, 61],
  [20, 48, 5, 59, 7, 122],
  [24, 104, 5, 53, 64, 101],
  [39, 66, 5, 107, 100, 117],
  [20, 57, 5, 13, 94, 115],
  [12, 55, 5, 26, 100, 97],
  [16, 58, 5, 63, 18, 126],
  [16, 135, 5, 22, 64, 131],
  [12, 57, 5, 26, 10, 111],
  [12, 123, 5, 17, 82, 122],
  [16, 57, 5, 6, 102, 8],
  [46, 35, 5, 63, 130, 83],
  [12, 28, 5, 89, 18, 122],
  [46, 104, 5, 17, 44, 108],
  [30, 119, 5, 63, 10, 118],
  [30, 55, 5, 53, 29, 88],
  [16, 48, 5, 86, 94, 78],
  [39, 113, 5, 6, 18, 131],
  [3, 43, 5, 63, 124, 131],
  [24, 128, 5, 92, 73, 126],
  [24, 119, 5, 89, 90, 74],
  [3, 43, 5, 6, 73, 65],
  [46, 43, 5, 92, 56, 105],
  [30, 72, 5, 107, 56, 76],
  [30, 35, 5, 17, 102, 45],
  [3, 132, 5, 36, 75, 121],
  [46, 58, 5, 107, 37, 129],
  [30, 119, 5, 17, 18, 38],
  [3, 104, 5, 63, 29, 51],
  [12, 88, 5, 92, 90, 108],
  [39, 25, 5, 6, 75, 70],
  [46, 81, 5, 32, 18, 88],
  [46, 125, 5, 22, 56, 78],
  [12, 132, 5, 89, 18, 38],
  [3, 132, 5, 22, 18, 105],
  [12, 57, 5, 89, 7, 71],
  [16, 69, 5, 13, 14, 121],
  [16, 104, 5, 107, 130, 131],
  [46, 116, 5, 107, 33, 80],
  [12, 10, 5, 139, 75, 83],
  [46, 119, 5, 107, 10, 76],
  [3, 132, 5, 36, 49, 108],
  [46, 113, 5, 59, 54, 118],
  [46, 113, 5, 36, 130, 137],
  [3, 132, 5, 36, 124, 99],
  [39, 72, 5, 17, 73, 108],
  [16, 66, 5, 26, 14, 91],
  [16, 106, 5, 107, 73, 70],
  [20, 31, 5, 53, 49, 131],
  [39, 125, 5, 139, 62, 19],
  [16, 55, 5, 53, 75, 34],
  [30, 106, 5, 17, 18, 71],
  [16, 81, 5, 6, 37, 11],
  [46, 52, 5, 13, 18, 34],
  [12, 96, 5, 26, 114, 99],
  [12, 113, 5, 6, 44, 138],
  [39, 81, 5, 92, 75, 34],
  [20, 4, 5, 17, 18, 115],
  [24, 55, 5, 92, 7, 111],
  [46, 69, 5, 32, 130, 122],
  [46, 52, 5, 59, 10, 121],
  [12, 120, 5, 17, 29, 131],
  [3, 72, 5, 36, 54, 91],
  [39, 50, 5, 17, 102, 129],
  [3, 57, 5, 36, 73, 38],
  [12, 50, 5, 92, 130, 115],
  [24, 43, 5, 13, 49, 91],
  [16, 112, 5, 13, 7, 45],
  [3, 127, 5, 17, 94, 122],
  [3, 57, 5, 59, 62, 68],
  [16, 135, 5, 13, 73, 76],
  [16, 72, 5, 32, 73, 105],
  [39, 57, 5, 36, 54, 10],
  [16, 48, 5, 17, 64, 83],
  [39, 58, 5, 107, 114, 101],
  [16, 48, 5, 36, 18, 76],
  [16, 66, 5, 13, 18, 68],
  [12, 113, 5, 107, 14, 138],
  [12, 31, 5, 17, 56, 67],
  [24, 132, 5, 17, 18, 80],
  [16, 116, 5, 59, 14, 121],
  [3, 113, 5, 53, 14, 67],
  [3, 119, 5, 107, 90, 122],
  [20, 28, 5, 17, 94, 8],
  [20, 55, 5, 63, 18, 76],
  [30, 112, 5, 36, 10, 51],
  [39, 31, 5, 107, 90, 122],
  [3, 66, 5, 63, 90, 105],
  [12, 25, 5, 13, 75, 68],
  [12, 128, 5, 26, 29, 47],
  [24, 81, 5, 92, 37, 10],
  [24, 66, 5, 32, 29, 137],
  [24, 27, 5, 26, 29, 76],
  [20, 116, 5, 63, 18, 108],
  [16, 31, 5, 63, 29, 91],
  [46, 43, 5, 63, 100, 47],
  [16, 116, 5, 139, 33, 91],
  [3, 58, 5, 13, 33, 99],
  [39, 117, 5, 92, 64, 95],
  [39, 103, 5, 107, 73, 78],
  [39, 55, 5, 17, 14, 138],
  [12, 96, 5, 17, 114, 91],
  [16, 104, 5, 107, 37, 74],
  [16, 106, 5, 13, 82, 80],
  [20, 104, 5, 59, 90, 83],
  [12, 40, 5, 22, 54, 83],
  [3, 55, 5, 26, 73, 105],
  [39, 57, 5, 22, 14, 105],
  [3, 72, 5, 13, 18, 70],
  [24, 81, 5, 36, 102, 74],
  [30, 81, 5, 53, 62, 99],
  [20, 85, 5, 36, 102, 91],
  [3, 48, 5, 26, 7, 67],
  [46, 40, 5, 92, 33, 136],
  [16, 35, 5, 36, 14, 105],
  [3, 57, 5, 53, 90, 47],
  [30, 66, 5, 17, 82, 11],
  [39, 113, 5, 107, 64, 91],
  [3, 55, 5, 22, 54, 11],
  [3, 88, 5, 86, 73, 99],
  [20, 21, 5, 107, 100, 47],
  [12, 35, 5, 6, 62, 80],
  [3, 35, 5, 13, 37, 108],
  [30, 87, 5, 92, 14, 10],
  [3, 81, 5, 107, 56, 91],
  [24, 52, 5, 59, 90, 76],
  [24, 106, 5, 32, 33, 122],
  [20, 35, 5, 89, 56, 126],
  [39, 66, 5, 92, 33, 78],
  [12, 96, 5, 26, 94, 108],
  [46, 123, 5, 6, 18, 115],
  [39, 52, 5, 17, 14, 131],
  [16, 113, 5, 107, 18, 70],
  [46, 79, 5, 6, 73, 80],
  [30, 55, 5, 36, 100, 71],
  [3, 117, 5, 92, 82, 122],
  [3, 4, 5, 53, 130, 51],
  [46, 132, 5, 13, 18, 80],
  [46, 81, 5, 17, 54, 71],
  [16, 52, 5, 89, 56, 83],
  [24, 125, 5, 17, 62, 137],
  [3, 104, 5, 32, 54, 65],
  [16, 48, 5, 17, 18, 74],
  [16, 72, 5, 13, 14, 47],
  [12, 128, 5, 22, 18, 111],
  [20, 25, 5, 63, 90, 71],
  [12, 103, 5, 59, 18, 121],
  [12, 58, 5, 13, 18, 68],
  [16, 69, 5, 59, 82, 76],
  [16, 132, 5, 32, 14, 108],
  [30, 35, 5, 36, 18, 71],
  [12, 113, 5, 22, 18, 108],
  [30, 25, 5, 17, 90, 83],
  [39, 112, 5, 59, 29, 105],
  [46, 127, 5, 26, 130, 70],
  [39, 72, 5, 17, 100, 51],
  [39, 132, 5, 63, 90, 34],
  [39, 85, 5, 92, 44, 95],
  [3, 113, 5, 6, 114, 99],
  [3, 35, 5, 107, 64, 84],
  [24, 81, 5, 63, 64, 91],
  [30, 55, 5, 17, 18, 131],
  [20, 9, 5, 92, 56, 45],
  [20, 40, 5, 107, 62, 136],
  [39, 66, 5, 6, 62, 74],
  [39, 132, 5, 26, 114, 10],
  [20, 110, 5, 32, 102, 70],
  [24, 133, 5, 92, 29, 78],
  [16, 55, 5, 63, 44, 47],
  [20, 35, 5, 92, 7, 38],
  [20, 104, 5, 53, 102, 91],
  [12, 48, 5, 53, 18, 101],
  [3, 123, 5, 63, 102, 122],
  [30, 48, 5, 32, 18, 10],
  [3, 28, 5, 92, 64, 45],
  [16, 58, 5, 22, 29, 121],
  [16, 55, 5, 26, 14, 117],
  [3, 40, 5, 59, 73, 8],
  [30, 132, 5, 36, 18, 34],
  [3, 81, 5, 86, 62, 65],
  [16, 119, 5, 26, 18, 10],
  [39, 123, 5, 13, 75, 67],
  [46, 25, 5, 53, 44, 34],
  [20, 48, 5, 6, 100, 68],
  [16, 133, 5, 6, 56, 108],
  [3, 103, 5, 92, 18, 101],
  [20, 57, 5, 107, 18, 11],
  [3, 132, 5, 17, 94, 11],
  [39, 120, 5, 36, 130, 76],
  [39, 57, 5, 13, 100, 74],
  [16, 106, 5, 63, 44, 71],
  [39, 66, 5, 26, 18, 105],
  [16, 133, 5, 107, 18, 45],
  [3, 132, 5, 86, 18, 122],
  [12, 35, 5, 36, 100, 121],
  [20, 85, 5, 89, 64, 95],
  [16, 43, 5, 26, 29, 105],
  [20, 50, 5, 36, 62, 105],
  [24, 117, 5, 13, 130, 34],
  [46, 69, 5, 22, 10, 95],
  [46, 116, 5, 89, 14, 83],
  [46, 58, 5, 17, 49, 134],
  [39, 113, 5, 107, 14, 11],
  [30, 48, 5, 53, 90, 47],
  [12, 57, 5, 13, 18, 65],
  [3, 66, 5, 107, 94, 84],
  [3, 133, 5, 92, 29, 91],
  [20, 52, 5, 92, 18, 129],
  [16, 55, 5, 17, 37, 71],
  [3, 69, 5, 17, 18, 111],
  [20, 57, 5, 32, 94, 76],
  [16, 135, 5, 107, 90, 78],
  [39, 133, 5, 63, 18, 70],
  [12, 52, 5, 107, 18, 138],
  [3, 66, 5, 59, 64, 45],
  [30, 43, 5, 22, 54, 47],
  [20, 132, 5, 13, 14, 10],
  [20, 55, 5, 92, 18, 126],
  [16, 25, 5, 89, 18, 105],
  [39, 81, 5, 59, 75, 67],
  [46, 113, 5, 22, 114, 126],
  [39, 104, 5, 22, 14, 83],
  [3, 117, 5, 36, 54, 111],
  [3, 72, 5, 59, 7, 91],
  [16, 48, 5, 32, 18, 45],
  [16, 112, 5, 92, 82, 61],
  [16, 48, 5, 13, 62, 10],
  [46, 52, 5, 26, 75, 138],
  [12, 21, 5, 22, 37, 108],
  [3, 125, 5, 17, 37, 121],
  [20, 66, 5, 6, 64, 129],
  [30, 141, 5, 36, 82, 83],
  [24, 110, 5, 36, 100, 91],
  [16, 43, 5, 6, 114, 99],
  [46, 40, 5, 107, 33, 11],
  [16, 58, 5, 107, 73, 95],
  [39, 119, 5, 17, 114, 47],
  [12, 116, 5, 107, 64, 99],
  [16, 132, 5, 86, 18, 121],
  [39, 57, 5, 89, 14, 122],
  [16, 35, 5, 36, 56, 8],
  [16, 135, 5, 22, 18, 138],
  [46, 133, 5, 17, 62, 138],
  [24, 140, 5, 89, 29, 71],
  [3, 85, 5, 26, 64, 74],
  [30, 25, 5, 17, 64, 68],
  [12, 125, 5, 36, 98, 122],
  [24, 119, 5, 107, 114, 68],
  [3, 58, 5, 6, 18, 99],
  [46, 96, 5, 17, 14, 47],
  [30, 57, 5, 92, 10, 115],
  [16, 103, 5, 17, 62, 115],
  [16, 85, 5, 36, 18, 83],
  [3, 104, 5, 63, 124, 11],
  [39, 103, 5, 36, 82, 34],
  [12, 55, 5, 32, 18, 99],
  [12, 104, 5, 53, 54, 8],
  [3, 112, 5, 92, 130, 27],
  [12, 21, 5, 17, 94, 93],
  [39, 81, 5, 17, 7, 129],
  [39, 104, 5, 107, 14, 71],
  [16, 119, 5, 107, 75, 34],
  [20, 141, 5, 6, 44, 61],
  [12, 40, 5, 6, 100, 99],
  [39, 132, 5, 92, 56, 47],
  [3, 40, 5, 89, 18, 99],
  [3, 4, 5, 17, 90, 35],
  [3, 48, 5, 13, 100, 122],
  [12, 69, 5, 53, 54, 109],
  [30, 87, 5, 63, 124, 105],
  [30, 43, 5, 59, 44, 35],
  [30, 142, 5, 32, 44, 45],
  [16, 10, 5, 63, 124, 10],
  [20, 104, 5, 17, 14, 105],
  [16, 104, 5, 36, 18, 138],
  [46, 48, 5, 107, 82, 137],
  [39, 116, 5, 13, 62, 122],
  [3, 140, 5, 92, 62, 122],
  [24, 43, 5, 107, 10, 70],
  [16, 58, 5, 6, 102, 70],
  [20, 55, 5, 36, 14, 74],
  [20, 50, 5, 107, 18, 47],
  [39, 40, 5, 17, 130, 76],
  [39, 104, 5, 36, 114, 11],
  [30, 43, 5, 92, 94, 101],
  [30, 87, 5, 22, 82, 10],
  [46, 43, 5, 17, 94, 138],
  [20, 50, 5, 86, 44, 95],
  [16, 133, 5, 59, 10, 61],
  [16, 141, 5, 63, 18, 105],
  [3, 112, 5, 92, 75, 10],
  [20, 135, 5, 13, 44, 121],
  [16, 106, 5, 6, 90, 51],
  [3, 119, 5, 36, 94, 117],
  [12, 28, 5, 17, 14, 91],
  [39, 57, 5, 13, 49, 35],
  [46, 9, 5, 53, 54, 101],
  [20, 55, 5, 13, 130, 137],
  [3, 96, 5, 107, 29, 99],
  [30, 50, 5, 86, 98, 134],
  [3, 104, 5, 22, 56, 111],
  [30, 72, 5, 107, 130, 45],
  [20, 81, 5, 36, 18, 35],
  [24, 123, 5, 59, 33, 105],
  [16, 72, 5, 107, 33, 47],
  [3, 40, 5, 13, 114, 84],
  [46, 66, 5, 26, 90, 34],
  [3, 81, 5, 59, 56, 35],
  [12, 132, 5, 89, 56, 105],
  [3, 132, 5, 59, 130, 136],
  [39, 125, 5, 32, 18, 131],
  [24, 85, 5, 6, 90, 121],
  [16, 31, 5, 17, 62, 47],
  [20, 112, 5, 32, 54, 34],
  [30, 48, 5, 36, 130, 76],
  [3, 87, 5, 17, 18, 11],
  [3, 52, 5, 107, 94, 76],
  [24, 112, 5, 59, 75, 95],
  [16, 117, 5, 6, 14, 101],
  [20, 106, 5, 92, 114, 91],
  [24, 43, 5, 17, 90, 108],
  [30, 106, 5, 13, 94, 65],
  [16, 106, 5, 92, 94, 65],
  [16, 113, 5, 13, 29, 131],
  [3, 69, 5, 53, 14, 76],
  [30, 119, 5, 107, 49, 115],
  [3, 48, 5, 32, 41, 83],
  [39, 132, 5, 32, 7, 84],
  [16, 112, 5, 13, 54, 35],
  [46, 112, 5, 107, 7, 78],
  [24, 117, 5, 107, 124, 71],
  [24, 110, 5, 89, 64, 11],
  [46, 81, 5, 107, 33, 61],
  [30, 52, 5, 92, 75, 115],
  [3, 72, 5, 92, 98, 115],
  [16, 48, 5, 59, 94, 76],
  [39, 87, 5, 6, 102, 68],
  [30, 127, 5, 92, 90, 91],
  [16, 88, 5, 17, 54, 51],
  [24, 133, 5, 89, 18, 105],
  [39, 40, 5, 63, 75, 71],
  [39, 113, 5, 26, 7, 117],
  [20, 28, 5, 36, 82, 47],
  [3, 66, 5, 13, 90, 91],
  [3, 112, 5, 63, 56, 76],
  [30, 27, 5, 86, 114, 111],
  [39, 106, 5, 107, 14, 11],
  [30, 128, 5, 32, 56, 70],
  [30, 66, 5, 92, 33, 74],
  [3, 69, 5, 59, 82, 47],
  [12, 27, 5, 107, 29, 8],
  [3, 106, 5, 59, 75, 38],
  [39, 31, 5, 107, 18, 131],
  [16, 43, 5, 17, 54, 115],
  [3, 28, 5, 17, 33, 108],
  [12, 104, 5, 17, 29, 126],
  [3, 43, 5, 86, 56, 109],
  [16, 50, 5, 6, 98, 19],
  [46, 140, 5, 22, 75, 99],
  [30, 35, 5, 59, 102, 65],
  [39, 127, 5, 36, 54, 74],
  [12, 55, 5, 59, 37, 121],
  [3, 57, 5, 13, 37, 101],
  [3, 28, 5, 107, 10, 78],
  [24, 113, 5, 63, 130, 91],
  [39, 57, 5, 26, 18, 71],
  [30, 106, 5, 6, 10, 45],
  [16, 103, 5, 92, 29, 8],
  [16, 69, 5, 36, 64, 65],
  [16, 81, 5, 107, 18, 93],
  [3, 85, 5, 107, 75, 134],
  [39, 85, 5, 59, 90, 97],
  [46, 66, 5, 59, 130, 101],
  [24, 81, 5, 32, 90, 101],
  [39, 50, 5, 13, 94, 47],
  [20, 88, 5, 26, 44, 111],
  [46, 119, 5, 63, 75, 65],
  [24, 28, 5, 53, 64, 118],
  [20, 119, 5, 22, 73, 68],
  [3, 28, 5, 59, 54, 80],
  [46, 10, 5, 17, 75, 99],
  [12, 58, 5, 6, 64, 68],
  [30, 43, 5, 59, 18, 105],
  [16, 50, 5, 22, 18, 74],
  [30, 116, 5, 13, 64, 122],
  [24, 113, 5, 13, 10, 47],
  [3, 43, 5, 13, 114, 23],
  [30, 119, 5, 26, 124, 91],
  [3, 81, 5, 6, 62, 115],
  [20, 28, 5, 59, 100, 131],
  [20, 52, 5, 92, 102, 118],
  [3, 132, 5, 107, 90, 134],
  [12, 128, 5, 36, 62, 84],
  [3, 48, 5, 17, 64, 93],
  [46, 123, 5, 36, 18, 101],
  [12, 52, 5, 53, 62, 19],
  [3, 81, 5, 36, 33, 38],
  [20, 55, 5, 36, 90, 8],
  [12, 81, 5, 36, 130, 74],
  [20, 132, 5, 89, 37, 70],
  [39, 142, 5, 63, 64, 42],
  [20, 125, 5, 36, 94, 47],
  [20, 27, 5, 17, 102, 19],
  [46, 96, 5, 32, 124, 88],
  [39, 57, 5, 107, 29, 83],
  [46, 35, 5, 6, 18, 8],
  [30, 28, 5, 59, 90, 74],
  [12, 9, 5, 53, 114, 108],
  [46, 57, 5, 107, 18, 83],
  [12, 141, 5, 17, 130, 78],
  [3, 96, 5, 17, 29, 68],
  [3, 96, 5, 107, 82, 34],
  [30, 81, 5, 36, 10, 101],
  [46, 55, 5, 107, 18, 80],
  [16, 106, 5, 36, 10, 68],
  [20, 113, 5, 63, 94, 71],
  [39, 55, 5, 13, 100, 76],
  [30, 113, 5, 36, 62, 83],
  [16, 69, 5, 13, 82, 34],
  [46, 104, 5, 26, 49, 10],
  [16, 28, 5, 59, 114, 8],
  [12, 119, 5, 59, 114, 11],
  [46, 66, 5, 139, 64, 137],
  [30, 125, 5, 22, 18, 99],
  [46, 127, 5, 13, 18, 65],
  [30, 66, 5, 53, 37, 93],
  [3, 28, 5, 59, 90, 78],
  [20, 113, 5, 53, 94, 126],
  [16, 28, 5, 89, 37, 34],
  [20, 132, 5, 59, 94, 117],
  [12, 69, 5, 59, 62, 97],
  [24, 58, 5, 13, 130, 74],
  [39, 113, 5, 107, 18, 134],
  [20, 28, 5, 17, 18, 97],
  [30, 55, 5, 26, 90, 34],
  [3, 112, 5, 32, 82, 83],
  [39, 141, 5, 59, 33, 42],
  [24, 132, 5, 89, 54, 131],
  [16, 58, 5, 13, 54, 10],
  [24, 28, 5, 107, 130, 8],
  [3, 119, 5, 17, 18, 8],
  [3, 79, 5, 13, 90, 122],
  [30, 112, 5, 63, 18, 121],
  [46, 69, 5, 17, 18, 91],
  [3, 28, 5, 59, 130, 11],
  [30, 103, 5, 22, 64, 105],
  [3, 50, 5, 107, 130, 51],
  [30, 69, 5, 107, 82, 71],
  [16, 35, 5, 13, 64, 76],
  [39, 88, 5, 6, 94, 105],
  [20, 81, 5, 32, 90, 93],
  [24, 50, 5, 13, 44, 80],
  [20, 55, 5, 36, 18, 11],
  [39, 103, 5, 6, 44, 78],
  [30, 96, 5, 63, 18, 71],
  [24, 66, 5, 26, 18, 101],
  [39, 116, 5, 13, 102, 47],
  [3, 103, 5, 17, 75, 126],
  [3, 48, 5, 63, 44, 80],
  [39, 72, 5, 26, 94, 111],
  [46, 110, 5, 107, 102, 91],
  [16, 132, 5, 17, 94, 99],
  [24, 106, 5, 139, 18, 71],
  [39, 35, 5, 63, 33, 138],
  [16, 125, 5, 13, 98, 111],
  [39, 141, 5, 53, 18, 47],
  [3, 119, 5, 17, 49, 84],
  [46, 112, 5, 32, 44, 93],
  [46, 119, 5, 53, 7, 70],
  [39, 28, 5, 17, 10, 93],
  [30, 96, 5, 59, 18, 74],
  [39, 57, 5, 22, 54, 95],
  [12, 123, 5, 32, 56, 101],
  [3, 21, 5, 59, 54, 95],
  [39, 52, 5, 59, 102, 61],
  [16, 57, 5, 59, 94, 108],
  [39, 48, 5, 63, 94, 71],
  [3, 104, 5, 92, 44, 115],
  [39, 96, 5, 17, 94, 71],
  [30, 85, 5, 32, 62, 138],
  [16, 35, 5, 36, 29, 10],
  [39, 96, 5, 63, 75, 65],
  [20, 133, 5, 89, 90, 115],
  [24, 112, 5, 59, 14, 121],
  [24, 66, 5, 13, 102, 8],
  [46, 35, 5, 22, 7, 34],
  [30, 132, 5, 59, 7, 101],
  [3, 113, 5, 32, 18, 78],
  [12, 40, 5, 32, 54, 99],
  [3, 66, 5, 36, 7, 74],
  [46, 87, 5, 89, 18, 47],
  [3, 31, 5, 17, 29, 67],
  [12, 106, 5, 32, 56, 35],
  [3, 55, 5, 36, 94, 51],
  [39, 135, 5, 59, 18, 99],
  [12, 128, 5, 26, 56, 70],
  [46, 69, 5, 107, 75, 51],
  [46, 104, 5, 92, 124, 74],
  [16, 81, 5, 13, 73, 121],
  [12, 28, 5, 6, 37, 121],
  [30, 132, 5, 53, 7, 83],
  [30, 125, 5, 92, 73, 71],
  [3, 135, 5, 26, 29, 105],
  [3, 48, 5, 32, 100, 34],
  [46, 58, 5, 63, 33, 8],
  [16, 140, 5, 36, 102, 109],
  [39, 141, 5, 26, 75, 35],
  [16, 58, 5, 36, 29, 88],
  [39, 69, 5, 36, 64, 11],
  [3, 81, 5, 59, 18, 99],
  [16, 66, 5, 92, 14, 67],
  [3, 132, 5, 17, 29, 51],
  [46, 112, 5, 63, 54, 67],
  [24, 72, 5, 32, 18, 134],
  [16, 43, 5, 17, 7, 121],
  [30, 43, 5, 107, 54, 78],
  [3, 104, 5, 63, 114, 11],
  [3, 104, 5, 26, 102, 15],
  [12, 66, 5, 22, 18, 67],
  [3, 125, 5, 13, 37, 131],
  [30, 57, 5, 13, 130, 105],
  [39, 103, 5, 13, 29, 122],
  [30, 88, 5, 53, 82, 34],
  [16, 43, 5, 17, 90, 65],
  [20, 106, 5, 53, 44, 78],
  [46, 4, 5, 86, 114, 118],
  [12, 28, 5, 59, 7, 8],
  [3, 57, 5, 22, 29, 83],
  [39, 48, 5, 32, 75, 47],
  [46, 85, 5, 107, 44, 108],
  [20, 48, 5, 63, 130, 99],
  [39, 35, 5, 92, 94, 99],
  [12, 103, 5, 22, 54, 74],
  [20, 128, 5, 17, 64, 15],
  [39, 28, 5, 86, 102, 47],
  [3, 127, 5, 36, 100, 83],
  [3, 28, 5, 32, 49, 83],
  [46, 110, 5, 89, 73, 121],
  [30, 125, 5, 53, 10, 78],
  [16, 96, 5, 22, 18, 84],
  [16, 106, 5, 13, 62, 84],
  [24, 103, 5, 92, 100, 111],
  [16, 48, 5, 6, 18, 101],
  [12, 50, 5, 89, 130, 95],
  [46, 120, 5, 92, 29, 122],
  [20, 28, 5, 59, 29, 108],
  [39, 104, 5, 107, 64, 34],
  [46, 125, 5, 107, 56, 70],
  [3, 110, 5, 26, 75, 121],
  [20, 96, 5, 17, 82, 45],
  [24, 66, 5, 13, 44, 51],
  [12, 123, 5, 26, 10, 78],
  [24, 104, 5, 107, 130, 19],
  [39, 106, 5, 13, 90, 51],
  [16, 28, 5, 63, 98, 99],
  [12, 88, 5, 22, 54, 71],
  [16, 28, 5, 17, 75, 131],
  [12, 21, 5, 17, 90, 71],
  [39, 48, 5, 22, 102, 67],
  [39, 79, 5, 6, 18, 115],
  [20, 25, 5, 13, 18, 93],
  [16, 96, 5, 6, 18, 47],
  [3, 132, 5, 92, 44, 99],
  [30, 43, 5, 107, 64, 74],
  [39, 58, 5, 63, 7, 131],
  [39, 31, 5, 63, 94, 91],
  [12, 85, 5, 22, 29, 108],
  [46, 103, 5, 26, 18, 91],
  [46, 43, 5, 107, 18, 71],
  [24, 57, 5, 92, 37, 76],
  [39, 81, 5, 36, 54, 108],
  [12, 140, 5, 17, 41, 83],
  [3, 104, 5, 107, 100, 105],
  [12, 35, 5, 89, 102, 83],
  [46, 85, 5, 13, 10, 10],
  [3, 87, 5, 63, 7, 121],
  [46, 69, 5, 22, 62, 34],
  [16, 69, 5, 63, 54, 108],
  [12, 72, 5, 139, 33, 76],
  [12, 10, 5, 53, 33, 78],
  [39, 104, 5, 107, 18, 45],
  [16, 103, 5, 92, 18, 99],
  [39, 69, 5, 17, 130, 122],
  [46, 58, 5, 59, 18, 8],
  [46, 43, 5, 6, 18, 131],
  [39, 31, 5, 63, 114, 47],
  [30, 25, 5, 107, 14, 121],
  [39, 106, 5, 13, 18, 68],
  [30, 58, 5, 89, 102, 45],
  [46, 52, 5, 6, 130, 70],
  [12, 40, 5, 92, 64, 99],
  [16, 112, 5, 13, 18, 45],
  [46, 140, 5, 6, 64, 91],
  [39, 35, 5, 107, 7, 115],
  [20, 116, 5, 6, 56, 19],
  [16, 28, 5, 17, 37, 99],
  [24, 113, 5, 6, 130, 97],
  [46, 52, 5, 36, 102, 83],
  [3, 128, 5, 53, 75, 105],
  [20, 96, 5, 59, 102, 71],
  [3, 103, 5, 17, 37, 34],
  [20, 116, 5, 63, 18, 137],
  [16, 31, 5, 32, 10, 70],
  [16, 43, 5, 32, 29, 80],
  [39, 112, 5, 17, 62, 91],
  [39, 104, 5, 59, 37, 70],
  [20, 79, 5, 63, 14, 11],
  [16, 133, 5, 6, 7, 11],
  [30, 81, 5, 17, 54, 74],
  [3, 48, 5, 22, 102, 105],
  [16, 106, 5, 6, 10, 101],
  [20, 119, 5, 6, 100, 105],
  [16, 81, 5, 22, 33, 35],
  [24, 113, 5, 13, 62, 74],
  [16, 96, 5, 32, 56, 34],
  [3, 140, 5, 6, 62, 68],
  [46, 43, 5, 107, 7, 109],
  [12, 31, 5, 6, 33, 35],
  [39, 96, 5, 32, 90, 105],
  [12, 116, 5, 17, 94, 121],
  [46, 110, 5, 22, 54, 99],
  [12, 72, 5, 53, 94, 95],
  [39, 133, 5, 107, 14, 76],
  [30, 119, 5, 107, 75, 101],
  [16, 116, 5, 32, 62, 74],
  [46, 88, 5, 107, 75, 129],
  [39, 104, 5, 32, 56, 76],
  [12, 57, 5, 26, 29, 67],
  [3, 116, 5, 63, 90, 80],
  [20, 140, 5, 89, 18, 83],
  [30, 35, 5, 36, 102, 138],
  [46, 104, 5, 6, 94, 97],
  [30, 77, 5, 13, 18, 76],
  [20, 104, 5, 53, 29, 95],
  [30, 66, 5, 17, 90, 10],
  [39, 85, 5, 63, 44, 122],
  [39, 132, 5, 107, 10, 74],
  [46, 85, 5, 107, 56, 23],
  [46, 96, 5, 92, 56, 68],
  [39, 57, 5, 89, 82, 101],
  [46, 31, 5, 36, 44, 131],
  [46, 117, 5, 92, 90, 76],
  [20, 57, 5, 107, 18, 35],
  [3, 66, 5, 107, 102, 105],
  [46, 52, 5, 107, 18, 47],
  [46, 133, 5, 89, 44, 111],
  [46, 125, 5, 22, 14, 10],
  [39, 58, 5, 13, 18, 108],
  [46, 28, 5, 59, 29, 83],
  [46, 113, 5, 32, 124, 108],
  [16, 28, 5, 13, 41, 35],
  [30, 43, 5, 13, 14, 10],
  [30, 35, 5, 13, 64, 108],
  [16, 85, 5, 53, 49, 8],
  [30, 103, 5, 86, 130, 71],
  [12, 135, 5, 59, 18, 121],
  [16, 106, 5, 92, 54, 95],
  [46, 28, 5, 92, 75, 101],
  [16, 87, 5, 32, 18, 137],
  [46, 55, 5, 26, 94, 101],
  [12, 113, 5, 36, 62, 80],
  [39, 119, 5, 89, 18, 121],
  [24, 128, 5, 6, 37, 65],
  [24, 87, 5, 107, 18, 121],
  [30, 135, 5, 63, 90, 111],
  [12, 119, 5, 107, 114, 11],
  [46, 140, 5, 63, 54, 83],
  [12, 113, 5, 32, 82, 70],
  [3, 31, 5, 89, 56, 47],
  [24, 135, 5, 36, 14, 84],
  [30, 48, 5, 13, 64, 70],
  [12, 113, 5, 26, 90, 137],
  [20, 85, 5, 139, 29, 111],
  [16, 35, 5, 59, 44, 80],
  [12, 112, 5, 89, 54, 115],
  [20, 57, 5, 59, 37, 8],
  [12, 55, 5, 92, 100, 68],
  [24, 110, 5, 36, 73, 105],
  [24, 106, 5, 17, 10, 115],
  [39, 104, 5, 32, 7, 91],
  [24, 96, 5, 26, 114, 67],
  [46, 50, 5, 26, 94, 129],
  [30, 127, 5, 17, 130, 76],
  [16, 140, 5, 53, 56, 111],
  [39, 31, 5, 36, 18, 11],
  [20, 10, 5, 17, 62, 47],
  [12, 28, 5, 6, 7, 51],
  [24, 58, 5, 22, 49, 51],
  [20, 125, 5, 26, 7, 126],
  [12, 66, 5, 107, 54, 38],
  [16, 106, 5, 36, 37, 47],
  [30, 48, 5, 92, 100, 78],
  [46, 50, 5, 17, 18, 126],
  [16, 9, 5, 107, 94, 122],
  [3, 81, 5, 22, 7, 105],
  [16, 132, 5, 22, 18, 115],
  [46, 113, 5, 17, 33, 8],
  [20, 43, 5, 17, 18, 10],
  [16, 69, 5, 17, 100, 11],
  [16, 85, 5, 92, 37, 111],
  [12, 140, 5, 92, 94, 111],
  [39, 113, 5, 89, 18, 45],
  [46, 119, 5, 92, 64, 84],
  [12, 87, 5, 22, 98, 71],
  [16, 57, 5, 86, 102, 117],
  [16, 113, 5, 13, 114, 131],
  [16, 103, 5, 17, 18, 91],
  [24, 57, 5, 17, 18, 111],
  [24, 50, 5, 53, 10, 122],
  [20, 96, 5, 86, 102, 117],
  [39, 57, 5, 32, 73, 97],
  [24, 116, 5, 59, 54, 47],
  [20, 106, 5, 92, 18, 15],
  [12, 103, 5, 36, 75, 115],
  [39, 132, 5, 22, 114, 65],
  [20, 77, 5, 53, 18, 42],
  [3, 50, 5, 59, 18, 78],
  [3, 104, 5, 13, 130, 70],
  [20, 106, 5, 13, 7, 105],
  [12, 112, 5, 59, 14, 51],
  [39, 87, 5, 17, 62, 34],
  [30, 48, 5, 63, 7, 11],
  [30, 25, 5, 6, 18, 47],
  [46, 58, 5, 63, 37, 109],
  [39, 25, 5, 63, 130, 8],
  [46, 81, 5, 17, 18, 80],
  [16, 58, 5, 36, 130, 95],
  [12, 69, 5, 59, 10, 99],
  [16, 106, 5, 59, 114, 65],
  [12, 66, 5, 26, 18, 71],
  [46, 10, 5, 22, 94, 8],
  [3, 40, 5, 22, 7, 111],
  [12, 55, 5, 17, 102, 34],
  [20, 28, 5, 26, 75, 101],
  [12, 106, 5, 13, 29, 68],
  [30, 132, 5, 63, 7, 67],
  [30, 28, 5, 92, 100, 91],
  [30, 104, 5, 13, 75, 108],
  [20, 52, 5, 89, 49, 71],
  [30, 25, 5, 13, 100, 129],
  [46, 52, 5, 13, 18, 122],
  [3, 25, 5, 6, 29, 8],
  [16, 35, 5, 92, 18, 121],
  [46, 31, 5, 63, 14, 68],
  [46, 66, 5, 13, 56, 99],
  [30, 127, 5, 63, 98, 65],
  [30, 72, 5, 107, 14, 83],
  [16, 43, 5, 92, 75, 74],
  [24, 135, 5, 92, 114, 61],
  [16, 35, 5, 6, 94, 115],
  [20, 48, 5, 17, 114, 99],
  [30, 119, 5, 107, 18, 74],
  [30, 116, 5, 6, 29, 101],
  [46, 48, 5, 32, 82, 83],
  [3, 66, 5, 59, 114, 19],
  [12, 112, 5, 6, 82, 121],
  [20, 31, 5, 6, 7, 35],
  [24, 113, 5, 13, 130, 126],
  [39, 25, 5, 17, 18, 45],
  [12, 96, 5, 89, 130, 70],
  [39, 123, 5, 13, 29, 93],
  [3, 140, 5, 13, 7, 111],
  [24, 88, 5, 17, 130, 122],
  [46, 79, 5, 6, 33, 34],
  [16, 140, 5, 36, 94, 111],
  [12, 81, 5, 36, 73, 47],
  [16, 81, 5, 6, 18, 99],
  [20, 55, 5, 59, 98, 74],
  [3, 55, 5, 6, 18, 121],
  [39, 110, 5, 59, 64, 131],
  [30, 69, 5, 36, 102, 108],
  [46, 66, 5, 13, 14, 83],
  [20, 27, 5, 17, 44, 74],
  [12, 113, 5, 17, 100, 131],
  [24, 81, 5, 92, 114, 105],
  [20, 57, 5, 107, 75, 91],
  [3, 120, 5, 107, 7, 108],
  [30, 50, 5, 17, 18, 45],
  [46, 103, 5, 13, 10, 121],
  [12, 48, 5, 63, 18, 122],
  [16, 141, 5, 22, 73, 111],
  [24, 88, 5, 36, 7, 71],
  [46, 133, 5, 26, 75, 131],
  [12, 79, 5, 6, 18, 101],
  [16, 57, 5, 36, 73, 97],
  [39, 10, 5, 22, 75, 121],
  [30, 81, 5, 63, 90, 34],
  [16, 123, 5, 89, 10, 115],
  [12, 27, 5, 107, 56, 74],
  [3, 9, 5, 107, 10, 121],
  [16, 87, 5, 92, 54, 65],
  [30, 50, 5, 13, 73, 67],
  [46, 48, 5, 53, 44, 115],
  [46, 10, 5, 13, 18, 88],
  [30, 142, 5, 13, 102, 108],
  [12, 103, 5, 86, 90, 101],
  [12, 132, 5, 92, 90, 8],
  [20, 116, 5, 107, 10, 68],
  [30, 128, 5, 89, 7, 137],
  [12, 141, 5, 22, 7, 101],
  [30, 27, 5, 53, 37, 121],
  [46, 48, 5, 92, 64, 131],
  [30, 35, 5, 22, 54, 93],
  [16, 132, 5, 36, 7, 61],
  [39, 35, 5, 6, 44, 121],
  [30, 81, 5, 59, 102, 83],
  [20, 133, 5, 107, 18, 115],
  [39, 58, 5, 26, 75, 121],
  [46, 9, 5, 17, 56, 138],
  [16, 77, 5, 92, 14, 71],
  [16, 57, 5, 17, 18, 71],
  [16, 104, 5, 32, 82, 136],
  [46, 50, 5, 107, 124, 80],
  [16, 25, 5, 13, 90, 80],
  [46, 10, 5, 36, 10, 91],
  [12, 55, 5, 17, 100, 76],
  [24, 135, 5, 26, 102, 121],
  [24, 43, 5, 6, 33, 108],
  [3, 85, 5, 13, 18, 122],
  [30, 50, 5, 92, 37, 68],
  [3, 104, 5, 17, 18, 65],
  [12, 106, 5, 53, 18, 70],
  [16, 135, 5, 22, 56, 138],
  [3, 50, 5, 26, 18, 68],
  [30, 40, 5, 92, 90, 71],
  [46, 52, 5, 63, 18, 71],
  [3, 58, 5, 63, 18, 121],
  [39, 112, 5, 6, 100, 99],
  [3, 117, 5, 89, 56, 35],
  [30, 58, 5, 92, 33, 101],
  [24, 85, 5, 13, 18, 27],
  [30, 9, 5, 22, 82, 27],
  [46, 116, 5, 59, 14, 76],
  [3, 117, 5, 89, 90, 11],
  [20, 113, 5, 36, 18, 108],
  [30, 27, 5, 36, 75, 101],
  [30, 43, 5, 22, 62, 71],
  [20, 87, 5, 22, 130, 101],
  [46, 132, 5, 59, 29, 101],
  [24, 81, 5, 26, 56, 91],
  [46, 66, 5, 17, 49, 122],
  [20, 88, 5, 17, 94, 78],
  [12, 81, 5, 6, 18, 83],
  [3, 112, 5, 92, 100, 67],
  [3, 48, 5, 17, 90, 68],
  [16, 132, 5, 92, 94, 35],
  [39, 50, 5, 6, 49, 78],
  [20, 113, 5, 107, 100, 131],
  [3, 81, 5, 32, 124, 83],
  [30, 117, 5, 22, 124, 78],
  [20, 113, 5, 86, 56, 129],
  [16, 96, 5, 22, 62, 11],
  [30, 85, 5, 32, 10, 99],
  [12, 85, 5, 36, 73, 111],
  [3, 69, 5, 17, 33, 101],
  [46, 117, 5, 17, 33, 67],
  [30, 72, 5, 13, 18, 71],
  [39, 112, 5, 63, 94, 111],
  [39, 25, 5, 89, 49, 131],
  [3, 85, 5, 32, 56, 35],
  [46, 69, 5, 107, 64, 74],
  [16, 88, 5, 32, 18, 121],
  [3, 66, 5, 32, 49, 67],
  [46, 103, 5, 36, 100, 115],
  [3, 141, 5, 92, 75, 35],
  [20, 35, 5, 89, 54, 34],
  [30, 119, 5, 32, 64, 109],
  [16, 58, 5, 59, 73, 10],
  [12, 52, 5, 17, 114, 111],
  [12, 85, 5, 89, 102, 105],
  [20, 57, 5, 32, 56, 91],
  [20, 40, 5, 36, 18, 101],
  [24, 128, 5, 17, 82, 115],
  [46, 106, 5, 13, 100, 10],
  [46, 103, 5, 107, 94, 65],
  [12, 135, 5, 26, 37, 131],
  [3, 55, 5, 32, 18, 108],
  [3, 104, 5, 59, 33, 42],
  [16, 48, 5, 63, 33, 67],
  [24, 133, 5, 26, 64, 93],
  [12, 113, 5, 107, 56, 121],
  [3, 117, 5, 22, 90, 68],
  [46, 48, 5, 36, 29, 38],
  [16, 81, 5, 17, 94, 121],
  [3, 128, 5, 26, 37, 15],
  [39, 120, 5, 13, 130, 129],
  [12, 112, 5, 89, 73, 68],
  [12, 57, 5, 22, 82, 15],
  [24, 58, 5, 32, 100, 121],
  [39, 132, 5, 107, 62, 101],
  [46, 57, 5, 22, 114, 34],
  [30, 87, 5, 36, 37, 101],
  [30, 57, 5, 63, 29, 99],
  [24, 4, 5, 13, 18, 131],
  [20, 48, 5, 6, 82, 68],
  [20, 58, 5, 26, 82, 95],
  [12, 96, 5, 59, 18, 83],
  [30, 110, 5, 26, 18, 129],
  [20, 132, 5, 22, 75, 101],
  [3, 106, 5, 22, 124, 65],
  [3, 96, 5, 13, 75, 51],
  [20, 132, 5, 92, 56, 70],
  [39, 57, 5, 22, 44, 93],
  [16, 69, 5, 59, 18, 11],
  [30, 35, 5, 63, 114, 34],
  [39, 113, 5, 22, 75, 23],
  [3, 96, 5, 107, 37, 80],
  [16, 58, 5, 32, 18, 80],
  [39, 69, 5, 36, 82, 67],
  [3, 104, 5, 17, 44, 10],
  [3, 104, 5, 59, 18, 51],
  [20, 87, 5, 17, 29, 111],
  [3, 116, 5, 107, 18, 115],
  [24, 48, 5, 26, 44, 111],
  [16, 103, 5, 6, 62, 108],
  [39, 104, 5, 36, 10, 47],
  [16, 43, 5, 17, 98, 126],
  [20, 104, 5, 36, 75, 74],
  [3, 119, 5, 17, 94, 67],
  [16, 135, 5, 32, 18, 138],
  [20, 85, 5, 32, 10, 109],
  [3, 81, 5, 32, 100, 70],
  [3, 77, 5, 63, 64, 80],
  [24, 66, 5, 63, 18, 65],
  [12, 119, 5, 13, 54, 117],
  [12, 96, 5, 59, 73, 10],
  [16, 96, 5, 22, 100, 115],
  [12, 50, 5, 17, 37, 71],
  [46, 88, 5, 59, 37, 68],
  [46, 58, 5, 86, 10, 83],
  [12, 79, 5, 17, 49, 138],
  [39, 140, 5, 6, 102, 131],
  [30, 106, 5, 139, 37, 34],
  [24, 77, 5, 32, 100, 101],
  [24, 66, 5, 107, 114, 47],
  [46, 35, 5, 53, 62, 47],
  [39, 132, 5, 59, 100, 99],
  [30, 35, 5, 107, 29, 129],
  [46, 140, 5, 59, 18, 51],
  [30, 66, 5, 26, 10, 45],
  [16, 106, 5, 32, 100, 74],
  [12, 58, 5, 89, 29, 65],
  [39, 10, 5, 89, 130, 143],
  [20, 48, 5, 36, 62, 134],
  [39, 116, 5, 17, 18, 76],
  [30, 35, 5, 13, 114, 70],
  [12, 31, 5, 13, 33, 121],
  [30, 21, 5, 22, 54, 134],
  [20, 96, 5, 17, 29, 11],
  [16, 72, 5, 107, 102, 83],
  [16, 104, 5, 59, 14, 78],
  [3, 88, 5, 26, 64, 71],
  [12, 85, 5, 53, 18, 78],
  [46, 55, 5, 59, 49, 35],
  [39, 81, 5, 17, 7, 68],
  [12, 113, 5, 107, 14, 138],
  [16, 31, 5, 13, 44, 71],
  [30, 141, 5, 13, 33, 108],
  [30, 57, 5, 59, 14, 105],
  [16, 96, 5, 26, 73, 118],
  [3, 85, 5, 92, 62, 70],
  [30, 87, 5, 22, 130, 91],
  [16, 57, 5, 17, 64, 76],
  [30, 69, 5, 26, 44, 71],
  [12, 140, 5, 107, 18, 91],
  [39, 123, 5, 6, 64, 111],
  [12, 9, 5, 22, 29, 93],
  [12, 55, 5, 22, 18, 121],
  [46, 50, 5, 22, 7, 65],
  [20, 66, 5, 59, 114, 108],
  [20, 66, 5, 107, 10, 134],
  [16, 112, 5, 107, 33, 65],
  [24, 9, 5, 26, 7, 80],
  [46, 31, 5, 17, 73, 10],
  [3, 57, 5, 17, 102, 109],
  [12, 106, 5, 6, 7, 99],
  [30, 133, 5, 17, 41, 137],
  [46, 81, 5, 13, 10, 11],
  [39, 27, 5, 6, 18, 115],
  [30, 103, 5, 63, 18, 35],
  [16, 132, 5, 26, 124, 108],
  [20, 140, 5, 26, 7, 45],
  [30, 31, 5, 89, 94, 111],
  [20, 58, 5, 6, 33, 74],
  [39, 57, 5, 107, 100, 115],
  [16, 117, 5, 92, 44, 105],
  [30, 57, 5, 36, 37, 105],
  [16, 104, 5, 36, 73, 27],
  [46, 85, 5, 32, 75, 111],
  [46, 55, 5, 63, 33, 108],
  [20, 120, 5, 63, 18, 34],
  [16, 81, 5, 6, 18, 115],
  [16, 4, 5, 92, 18, 11],
  [16, 55, 5, 26, 44, 47],
  [16, 40, 5, 107, 18, 84],
  [12, 69, 5, 32, 7, 83],
  [46, 43, 5, 32, 37, 27],
  [16, 88, 5, 6, 18, 117],
  [16, 141, 5, 92, 18, 136],
  [12, 27, 5, 13, 14, 93],
  [16, 113, 5, 92, 82, 74],
  [3, 104, 5, 6, 7, 101],
  [12, 50, 5, 92, 18, 93],
  [46, 119, 5, 89, 7, 23],
  [16, 35, 5, 22, 75, 11],
  [3, 112, 5, 36, 82, 11],
  [16, 123, 5, 107, 100, 11],
  [16, 133, 5, 107, 37, 70],
  [3, 103, 5, 53, 10, 11],
  [46, 48, 5, 32, 82, 131],
  [39, 96, 5, 59, 7, 115],
  [3, 113, 5, 89, 29, 45],
  [46, 135, 5, 13, 94, 122],
  [24, 58, 5, 53, 18, 80],
  [3, 112, 5, 17, 94, 8],
  [24, 69, 5, 26, 14, 76],
  [30, 55, 5, 26, 37, 65],
  [12, 135, 5, 17, 18, 11],
  [3, 27, 5, 13, 62, 91],
  [46, 117, 5, 139, 18, 47],
  [30, 81, 5, 32, 130, 78],
  [24, 106, 5, 22, 114, 11],
  [46, 43, 5, 13, 44, 88],
  [3, 135, 5, 6, 14, 70],
  [46, 87, 5, 36, 90, 67],
  [30, 66, 5, 107, 33, 91],
  [46, 55, 5, 6, 64, 70],
  [3, 40, 5, 59, 33, 93],
  [20, 57, 5, 36, 100, 117],
  [12, 104, 5, 107, 10, 111],
  [12, 106, 5, 59, 100, 80],
  [24, 25, 5, 32, 102, 71],
  [12, 66, 5, 6, 75, 80],
  [30, 112, 5, 13, 75, 131],
  [30, 88, 5, 59, 18, 122],
  [16, 69, 5, 22, 18, 137],
  [12, 106, 5, 53, 54, 67],
  [12, 43, 5, 36, 18, 68],
  [12, 96, 5, 59, 33, 67],
  [30, 85, 5, 89, 98, 101],
  [16, 31, 5, 17, 56, 97],
  [46, 48, 5, 17, 18, 137],
  [46, 48, 5, 59, 14, 68],
  [16, 116, 5, 63, 18, 129],
  [46, 10, 5, 17, 18, 129],
  [3, 72, 5, 107, 114, 122],
  [24, 58, 5, 32, 18, 65],
  [12, 119, 5, 89, 64, 38],
  [3, 112, 5, 63, 33, 19],
  [24, 58, 5, 13, 82, 74],
  [12, 58, 5, 107, 62, 91],
  [3, 48, 5, 92, 56, 70],
  [24, 113, 5, 89, 18, 115],
  [46, 31, 5, 22, 37, 80],
  [24, 135, 5, 17, 29, 99],
  [24, 57, 5, 13, 130, 78],
  [16, 48, 5, 26, 14, 129],
  [24, 125, 5, 86, 94, 78],
  [3, 31, 5, 89, 14, 11],
  [30, 48, 5, 59, 7, 74],
  [20, 103, 5, 17, 18, 74],
  [46, 43, 5, 59, 54, 71],
  [24, 133, 5, 13, 54, 131],
  [20, 10, 5, 13, 114, 95],
  [16, 113, 5, 86, 18, 78],
  [3, 9, 5, 22, 100, 108],
  [46, 119, 5, 32, 90, 131],
  [46, 116, 5, 59, 100, 47],
  [16, 103, 5, 89, 18, 99],
  [3, 69, 5, 89, 130, 91],
  [12, 52, 5, 32, 7, 115],
  [46, 128, 5, 13, 75, 68],
  [46, 125, 5, 139, 14, 8],
  [16, 112, 5, 17, 64, 47],
  [39, 119, 5, 63, 90, 83],
  [39, 40, 5, 107, 7, 101],
  [39, 43, 5, 6, 54, 122],
  [3, 87, 5, 6, 90, 71],
  [3, 106, 5, 13, 10, 8],
  [20, 96, 5, 89, 64, 105],
  [12, 106, 5, 107, 14, 115],
  [30, 69, 5, 17, 29, 97],
  [46, 43, 5, 22, 82, 121],
  [30, 31, 5, 13, 18, 70],
  [46, 113, 5, 22, 73, 136],
  [30, 55, 5, 13, 29, 105],
  [16, 135, 5, 107, 7, 93],
  [3, 43, 5, 13, 14, 68],
  [30, 48, 5, 63, 49, 80],
  [12, 106, 5, 89, 90, 131],
  [3, 55, 5, 22, 7, 105],
  [3, 81, 5, 6, 14, 35],
  [12, 140, 5, 89, 10, 117],
  [30, 31, 5, 36, 54, 65],
  [39, 113, 5, 17, 18, 121],
  [20, 135, 5, 26, 37, 70],
  [24, 119, 5, 6, 29, 45],
  [20, 66, 5, 63, 44, 65],
  [46, 55, 5, 36, 18, 8],
  [16, 69, 5, 107, 98, 68],
  [46, 135, 5, 6, 44, 80],
  [30, 113, 5, 92, 100, 91],
  [3, 43, 5, 26, 130, 61],
  [24, 4, 5, 139, 124, 34],
  [24, 66, 5, 13, 124, 111],
  [46, 132, 5, 22, 73, 71],
  [46, 106, 5, 13, 18, 51],
  [46, 104, 5, 32, 54, 84],
  [12, 69, 5, 53, 18, 8],
  [3, 69, 5, 6, 75, 45],
  [3, 43, 5, 107, 62, 88],
  [3, 106, 5, 22, 56, 118],
  [39, 132, 5, 6, 7, 91],
  [16, 10, 5, 6, 82, 68],
  [20, 66, 5, 13, 100, 111],
  [20, 43, 5, 107, 64, 118],
  [39, 43, 5, 89, 54, 117],
  [20, 113, 5, 59, 94, 38],
  [16, 57, 5, 6, 100, 121],
  [39, 112, 5, 36, 114, 143],
  [39, 106, 5, 32, 7, 65],
  [24, 110, 5, 32, 18, 27],
  [39, 27, 5, 107, 7, 131],
  [39, 55, 5, 92, 98, 83],
  [3, 72, 5, 13, 94, 71],
  [12, 31, 5, 92, 62, 137],
  [30, 50, 5, 36, 75, 83],
  [24, 28, 5, 59, 94, 68],
  [39, 127, 5, 36, 18, 97],
  [3, 116, 5, 53, 102, 71],
  [3, 48, 5, 92, 64, 105],
  [3, 132, 5, 86, 49, 105],
  [30, 120, 5, 6, 102, 83],
  [20, 55, 5, 63, 29, 137],
  [3, 66, 5, 26, 73, 91],
  [16, 66, 5, 107, 73, 83],
  [12, 119, 5, 22, 130, 111],
  [3, 48, 5, 6, 18, 80],
  [30, 69, 5, 13, 90, 45],
  [3, 127, 5, 107, 18, 74],
  [30, 133, 5, 26, 98, 47],
  [20, 58, 5, 36, 75, 99],
  [30, 132, 5, 32, 75, 95],
  [20, 81, 5, 17, 7, 105],
  [3, 96, 5, 53, 90, 88],
  [12, 48, 5, 89, 82, 10],
  [3, 58, 5, 17, 10, 78],
  [39, 79, 5, 17, 56, 122],
  [20, 31, 5, 107, 10, 111],
  [39, 55, 5, 36, 33, 108],
  [39, 113, 5, 22, 130, 93],
  [3, 104, 5, 89, 18, 35],
  [46, 40, 5, 107, 18, 47],
  [39, 69, 5, 17, 18, 122],
  [24, 132, 5, 36, 18, 111],
  [3, 48, 5, 17, 54, 83],
  [12, 57, 5, 26, 18, 83],
  [12, 43, 5, 22, 94, 47],
  [3, 113, 5, 22, 90, 76],
  [39, 10, 5, 32, 29, 108],
  [20, 28, 5, 22, 100, 121],
  [20, 66, 5, 36, 49, 11],
  [20, 110, 5, 107, 37, 122],
  [39, 133, 5, 17, 18, 11],
  [30, 50, 5, 92, 73, 67],
  [39, 113, 5, 86, 7, 47],
  [39, 28, 5, 92, 29, 95],
  [3, 69, 5, 92, 18, 45],
  [24, 69, 5, 26, 14, 97],
  [24, 125, 5, 26, 100, 108],
  [20, 85, 5, 17, 124, 34],
  [39, 85, 5, 6, 130, 11],
  [24, 48, 5, 32, 130, 11],
  [16, 132, 5, 53, 44, 122],
  [20, 81, 5, 13, 73, 105],
  [39, 40, 5, 59, 82, 138],
  [12, 57, 5, 22, 90, 34],
  [16, 104, 5, 89, 124, 27],
  [16, 48, 5, 36, 90, 109],
  [16, 43, 5, 13, 37, 68],
  [39, 28, 5, 107, 18, 122],
  [16, 66, 5, 89, 114, 34],
  [16, 43, 5, 36, 124, 121],
  [12, 132, 5, 17, 62, 10],
  [3, 58, 5, 17, 33, 131],
  [24, 25, 5, 59, 18, 131],
  [12, 72, 5, 107, 90, 67],
  [12, 117, 5, 32, 114, 8],
  [12, 28, 5, 13, 56, 99],
  [39, 28, 5, 59, 90, 122],
  [39, 25, 5, 17, 14, 88],
  [20, 117, 5, 89, 18, 134],
  [24, 72, 5, 17, 18, 10],
  [16, 55, 5, 36, 75, 101],
  [39, 40, 5, 59, 90, 118],
  [16, 125, 5, 32, 37, 68],
  [39, 52, 5, 107, 18, 109],
  [12, 88, 5, 22, 18, 108],
  [30, 103, 5, 22, 114, 70],
  [20, 28, 5, 53, 73, 101],
  [16, 57, 5, 13, 130, 91],
  [3, 66, 5, 107, 37, 45],
  [24, 96, 5, 92, 10, 109],
  [30, 113, 5, 63, 90, 83],
  [3, 69, 5, 63, 18, 83],
  [16, 106, 5, 107, 73, 47],
  [20, 43, 5, 22, 18, 10],
  [3, 110, 5, 36, 7, 67],
  [30, 106, 5, 32, 44, 122],
  [3, 31, 5, 13, 10, 74],
  [3, 43, 5, 17, 130, 47],
  [16, 128, 5, 32, 37, 34],
  [20, 85, 5, 26, 102, 45],
  [39, 127, 5, 63, 18, 23],
  [16, 9, 5, 6, 10, 71],
  [30, 55, 5, 63, 90, 129],
  [39, 31, 5, 107, 37, 65],
  [30, 31, 5, 32, 54, 83],
  [46, 103, 5, 32, 54, 34],
  [12, 112, 5, 89, 7, 101],
  [20, 52, 5, 6, 94, 83],
  [12, 66, 5, 36, 7, 101],
  [20, 4, 5, 92, 64, 19],
  [46, 112, 5, 107, 98, 131],
  [39, 106, 5, 22, 44, 80],
  [46, 85, 5, 26, 18, 115],
  [12, 113, 5, 63, 18, 126],
  [3, 25, 5, 89, 54, 68],
  [12, 50, 5, 92, 54, 51],
  [24, 106, 5, 89, 54, 51],
  [20, 10, 5, 17, 73, 138],
  [16, 52, 5, 59, 14, 34],
  [39, 57, 5, 53, 54, 111],
  [3, 48, 5, 63, 73, 101],
  [24, 48, 5, 92, 18, 115],
  [12, 103, 5, 59, 82, 67],
  [20, 117, 5, 36, 29, 131],
  [3, 132, 5, 107, 73, 118],
  [3, 66, 5, 17, 44, 109],
  [12, 85, 5, 107, 64, 83],
  [46, 55, 5, 59, 18, 126],
  [3, 77, 5, 53, 14, 11],
  [24, 113, 5, 6, 102, 34],
  [39, 4, 5, 107, 62, 11],
  [46, 96, 5, 89, 18, 61],
  [16, 28, 5, 6, 29, 131],
  [3, 27, 5, 22, 54, 83],
  [30, 31, 5, 107, 56, 68],
  [12, 66, 5, 89, 56, 45],
  [3, 77, 5, 107, 33, 11],
  [24, 133, 5, 107, 64, 68],
  [12, 127, 5, 107, 29, 51],
  [12, 35, 5, 26, 7, 71],
  [24, 72, 5, 22, 100, 108],
  [12, 87, 5, 32, 18, 74],
  [24, 66, 5, 32, 18, 80],
  [30, 87, 5, 22, 56, 105],
  [20, 132, 5, 17, 64, 134],
  [30, 66, 5, 17, 98, 111],
  [16, 133, 5, 22, 18, 10],
  [24, 113, 5, 89, 114, 78],
  [3, 104, 5, 17, 14, 45],
  [20, 116, 5, 26, 130, 78],
  [3, 57, 5, 63, 18, 35],
  [46, 9, 5, 26, 29, 131],
  [16, 113, 5, 22, 75, 83],
  [30, 113, 5, 32, 75, 67],
  [30, 58, 5, 13, 130, 121],
  [46, 87, 5, 89, 94, 131],
  [24, 57, 5, 6, 44, 70],
  [12, 113, 5, 59, 29, 78],
  [24, 112, 5, 22, 18, 51],
  [20, 133, 5, 107, 49, 136],
  [20, 69, 5, 17, 29, 138],
  [12, 52, 5, 13, 90, 121],
  [16, 106, 5, 17, 10, 65],
  [46, 4, 5, 22, 54, 83],
  [30, 66, 5, 13, 102, 109],
  [39, 96, 5, 36, 7, 51],
  [3, 110, 5, 53, 100, 134],
  [46, 103, 5, 6, 18, 19],
  [46, 96, 5, 59, 44, 71],
  [3, 81, 5, 107, 64, 83],
  [46, 55, 5, 36, 33, 84],
  [3, 85, 5, 53, 73, 65],
  [24, 81, 5, 59, 14, 105],
  [3, 27, 5, 36, 37, 84],
  [3, 96, 5, 13, 94, 137],
  [39, 103, 5, 32, 33, 67],
  [46, 85, 5, 17, 64, 51],
  [20, 119, 5, 13, 18, 71],
  [39, 113, 5, 36, 124, 121],
  [16, 135, 5, 107, 62, 115],
  [39, 66, 5, 36, 75, 131],
  [46, 141, 5, 92, 75, 91],
  [46, 140, 5, 107, 90, 95],
  [16, 112, 5, 6, 18, 76],
  [30, 103, 5, 13, 44, 47],
  [16, 106, 5, 13, 18, 111],
  [39, 50, 5, 17, 18, 109],
  [20, 50, 5, 36, 75, 121],
  [30, 35, 5, 17, 73, 138],
  [39, 103, 5, 6, 94, 99],
  [24, 28, 5, 22, 100, 108],
  [3, 52, 5, 13, 62, 122],
  [16, 43, 5, 59, 54, 109],
  [3, 35, 5, 59, 56, 111],
  [20, 133, 5, 63, 44, 101],
  [3, 55, 5, 139, 90, 65],
  [39, 58, 5, 53, 82, 131],
  [12, 58, 5, 6, 33, 99],
  [46, 128, 5, 13, 130, 101],
  [24, 50, 5, 59, 56, 67],
  [30, 31, 5, 22, 29, 76],
  [12, 77, 5, 92, 62, 45],
  [46, 113, 5, 63, 94, 131],
  [24, 113, 5, 22, 73, 101],
  [46, 31, 5, 63, 73, 51],
  [16, 104, 5, 92, 18, 67],
  [46, 112, 5, 107, 44, 83],
  [20, 52, 5, 107, 29, 78],
  [24, 69, 5, 26, 114, 45],
  [30, 48, 5, 6, 56, 121],
  [3, 48, 5, 59, 90, 101],
  [3, 88, 5, 6, 37, 122],
  [12, 79, 5, 36, 56, 121],
  [30, 103, 5, 17, 100, 121],
  [3, 132, 5, 53, 29, 10],
  [3, 31, 5, 36, 54, 47],
  [16, 140, 5, 6, 10, 47],
  [24, 35, 5, 6, 7, 78],
  [46, 69, 5, 6, 82, 115],
  [30, 52, 5, 32, 75, 67],
  [16, 112, 5, 92, 7, 99],
  [16, 106, 5, 92, 14, 67],
  [46, 9, 5, 107, 100, 42],
  [20, 48, 5, 107, 44, 101],
  [46, 25, 5, 32, 82, 138],
  [3, 43, 5, 6, 62, 108],
  [20, 55, 5, 26, 102, 67],
  [12, 113, 5, 63, 54, 47],
  [12, 87, 5, 53, 73, 10],
  [3, 55, 5, 107, 18, 68],
  [3, 104, 5, 89, 14, 61],
  [16, 66, 5, 59, 18, 137],
  [30, 104, 5, 13, 41, 109],
  [16, 120, 5, 22, 130, 76],
  [16, 58, 5, 13, 82, 108],
  [3, 69, 5, 63, 14, 61],
  [30, 106, 5, 36, 33, 8],
  [30, 43, 5, 32, 18, 70],
  [30, 57, 5, 92, 90, 68],
  [16, 72, 5, 26, 62, 67],
  [39, 35, 5, 13, 33, 61],
  [3, 119, 5, 6, 44, 47],
  [20, 112, 5, 17, 18, 80],
  [20, 119, 5, 22, 94, 111],
  [12, 125, 5, 107, 54, 71],
  [30, 77, 5, 13, 64, 111],
  [24, 96, 5, 63, 100, 70],
  [39, 81, 5, 63, 14, 131],
  [12, 119, 5, 59, 18, 93],
  [3, 43, 5, 26, 44, 67],
  [20, 43, 5, 36, 18, 51],
  [46, 43, 5, 92, 14, 91],
  [3, 113, 5, 92, 102, 108],
  [46, 52, 5, 6, 56, 122],
  [39, 85, 5, 26, 100, 11],
  [16, 106, 5, 22, 100, 45],
  [12, 96, 5, 36, 114, 71],
  [39, 28, 5, 13, 44, 51],
  [39, 96, 5, 92, 56, 34],
  [39, 106, 5, 32, 18, 101],
  [16, 55, 5, 63, 100, 131],
  [46, 50, 5, 36, 37, 11],
  [12, 43, 5, 92, 18, 45],
  [3, 35, 5, 36, 90, 71],
  [12, 28, 5, 63, 18, 88],
  [46, 28, 5, 6, 94, 111],
  [20, 96, 5, 13, 54, 101],
  [46, 57, 5, 59, 56, 65],
  [16, 27, 5, 36, 33, 19],
  [46, 135, 5, 6, 64, 38],
  [24, 35, 5, 26, 64, 83],
  [39, 140, 5, 63, 73, 65],
  [46, 119, 5, 13, 18, 8],
  [12, 87, 5, 53, 100, 129],
  [30, 123, 5, 32, 82, 101],
  [30, 50, 5, 92, 62, 51],
  [12, 128, 5, 17, 18, 70],
  [12, 69, 5, 6, 18, 67],
  [16, 55, 5, 53, 56, 80],
  [46, 96, 5, 13, 54, 111],
  [16, 104, 5, 107, 100, 11],
  [46, 50, 5, 92, 130, 74],
  [20, 96, 5, 26, 33, 10],
  [20, 55, 5, 92, 90, 105],
  [30, 50, 5, 32, 37, 19],
  [39, 10, 5, 17, 14, 45],
  [12, 66, 5, 22, 75, 68],
  [12, 103, 5, 59, 29, 88],
  [16, 85, 5, 92, 54, 91],
  [46, 119, 5, 32, 100, 121],
  [39, 85, 5, 17, 44, 47],
  [3, 72, 5, 17, 64, 11],
  [16, 52, 5, 22, 64, 45],
  [3, 72, 5, 22, 98, 109],
  [12, 117, 5, 86, 37, 19],
  [30, 58, 5, 26, 18, 95],
  [3, 128, 5, 59, 90, 122],
  [39, 96, 5, 92, 90, 76],
  [3, 58, 5, 13, 73, 122],
  [39, 104, 5, 86, 29, 74],
  [39, 28, 5, 107, 18, 47],
  [3, 4, 5, 36, 73, 8],
  [20, 52, 5, 63, 18, 129],
  [24, 58, 5, 53, 37, 111],
  [24, 69, 5, 63, 90, 47],
  [3, 48, 5, 92, 73, 91],
  [46, 57, 5, 53, 102, 78],
  [46, 113, 5, 89, 64, 47],
  [3, 69, 5, 89, 18, 71],
  [30, 35, 5, 59, 90, 108],
  [12, 4, 5, 107, 18, 129],
  [30, 31, 5, 17, 100, 45],
  [30, 35, 5, 107, 98, 117],
  [20, 113, 5, 22, 18, 121],
  [16, 48, 5, 13, 29, 134],
  [16, 52, 5, 13, 18, 121],
  [46, 72, 5, 53, 54, 115],
  [16, 48, 5, 92, 7, 78],
  [16, 119, 5, 89, 18, 71],
  [30, 55, 5, 107, 94, 101],
  [12, 43, 5, 59, 75, 126],
  [39, 4, 5, 17, 82, 67],
  [39, 119, 5, 63, 94, 76],
  [12, 28, 5, 13, 90, 11],
  [3, 113, 5, 32, 100, 47],
  [24, 113, 5, 89, 56, 91],
  [20, 127, 5, 89, 100, 19],
  [3, 77, 5, 53, 62, 99],
  [12, 31, 5, 17, 18, 131],
  [12, 48, 5, 36, 64, 67],
  [24, 66, 5, 36, 124, 105],
  [3, 119, 5, 17, 56, 67],
  [46, 104, 5, 36, 29, 11],
  [39, 116, 5, 6, 90, 19],
  [20, 133, 5, 36, 44, 76],
  [39, 96, 5, 107, 102, 10],
  [30, 132, 5, 13, 56, 45],
  [30, 43, 5, 13, 7, 11],
  [16, 85, 5, 107, 94, 105],
  [16, 43, 5, 59, 64, 76],
  [3, 85, 5, 17, 100, 122],
  [39, 142, 5, 6, 82, 137],
  [20, 142, 5, 107, 130, 111],
  [20, 119, 5, 36, 7, 138],
  [30, 35, 5, 59, 82, 138],
  [30, 50, 5, 63, 102, 137],
  [16, 69, 5, 6, 90, 8],
  [16, 25, 5, 32, 130, 80],
  [20, 85, 5, 32, 56, 115],
  [46, 52, 5, 13, 18, 76],
  [24, 113, 5, 26, 18, 137],
  [3, 128, 5, 59, 18, 65],
  [39, 31, 5, 26, 90, 78],
  [46, 132, 5, 107, 18, 71],
  [20, 119, 5, 22, 62, 68],
  [3, 58, 5, 13, 130, 138],
  [46, 116, 5, 17, 44, 76],
  [3, 81, 5, 63, 37, 47],
  [46, 35, 5, 6, 73, 11],
  [3, 104, 5, 26, 56, 111],
  [16, 135, 5, 92, 94, 67],
  [12, 27, 5, 32, 7, 131],
  [30, 72, 5, 32, 44, 122],
  [46, 69, 5, 32, 56, 67],
  [3, 113, 5, 32, 73, 76],
  [3, 125, 5, 36, 18, 131],
  [20, 119, 5, 22, 90, 88],
  [30, 28, 5, 53, 54, 68],
  [20, 48, 5, 107, 90, 51],
  [24, 127, 5, 17, 18, 47],
  [12, 141, 5, 53, 18, 83],
  [24, 96, 5, 92, 102, 65],
  [20, 79, 5, 32, 18, 83],
  [3, 31, 5, 53, 56, 42],
  [20, 52, 5, 6, 130, 76],
  [24, 140, 5, 17, 33, 8],
  [30, 104, 5, 32, 18, 8],
  [39, 141, 5, 32, 18, 42],
  [12, 28, 5, 107, 82, 93],
  [3, 135, 5, 36, 56, 74],
  [12, 35, 5, 36, 7, 67],
  [20, 40, 5, 13, 94, 68],
  [16, 50, 5, 107, 37, 101],
  [12, 110, 5, 92, 94, 68],
  [20, 103, 5, 17, 33, 99],
  [16, 21, 5, 32, 29, 95],
  [30, 104, 5, 53, 33, 122],
  [30, 57, 5, 17, 114, 105],
  [20, 110, 5, 13, 33, 68],
  [39, 21, 5, 13, 94, 84],
  [12, 112, 5, 107, 102, 78],
  [30, 31, 5, 36, 10, 121],
  [16, 48, 5, 36, 18, 138],
  [3, 133, 5, 59, 56, 70],
  [20, 43, 5, 26, 114, 118],
  [46, 48, 5, 63, 94, 105],
  [46, 48, 5, 32, 102, 10],
  [20, 141, 5, 107, 18, 131],
  [24, 31, 5, 32, 90, 65],
  [3, 140, 5, 17, 73, 19],
  [12, 135, 5, 107, 44, 8],
  [3, 77, 5, 36, 18, 80],
  [46, 55, 5, 6, 98, 10],
  [30, 103, 5, 92, 124, 74],
  [46, 112, 5, 36, 54, 78],
  [3, 35, 5, 22, 18, 65],
  [46, 69, 5, 63, 102, 8],
  [24, 85, 5, 32, 54, 8],
  [39, 28, 5, 107, 14, 118],
  [12, 69, 5, 36, 64, 45],
  [24, 135, 5, 107, 94, 45],
  [12, 112, 5, 17, 90, 76],
  [16, 27, 5, 22, 94, 129],
  [30, 104, 5, 107, 62, 76],
  [3, 85, 5, 32, 10, 78],
  [12, 103, 5, 36, 44, 10],
  [3, 55, 5, 36, 94, 101],
  [30, 96, 5, 92, 18, 35],
  [46, 125, 5, 107, 90, 68],
  [12, 132, 5, 89, 37, 68],
  [16, 88, 5, 13, 102, 19],
  [16, 104, 5, 107, 18, 76],
  [3, 85, 5, 89, 102, 51],
  [3, 140, 5, 89, 94, 80],
  [39, 103, 5, 6, 54, 83],
  [39, 113, 5, 17, 124, 76],
  [20, 128, 5, 92, 64, 136],
  [30, 35, 5, 17, 33, 99],
  [20, 110, 5, 59, 18, 68],
  [46, 72, 5, 92, 73, 83],
  [24, 43, 5, 32, 102, 19],
  [3, 72, 5, 59, 75, 99],
  [39, 52, 5, 107, 10, 88],
  [39, 31, 5, 6, 7, 65],
  [46, 69, 5, 22, 94, 45],
  [3, 55, 5, 32, 56, 115],
  [30, 96, 5, 53, 54, 115],
  [12, 116, 5, 92, 73, 111],
  [39, 27, 5, 6, 54, 99],
  [16, 85, 5, 36, 64, 129],
  [39, 103, 5, 92, 10, 115],
  [30, 43, 5, 53, 100, 88],
  [20, 116, 5, 107, 10, 65],
  [3, 50, 5, 32, 18, 51],
  [16, 112, 5, 53, 54, 10],
  [20, 133, 5, 13, 18, 105],
  [46, 57, 5, 63, 7, 129],
  [24, 112, 5, 92, 14, 47],
  [24, 43, 5, 6, 18, 68],
  [16, 25, 5, 86, 114, 108],
  [16, 21, 5, 6, 102, 45],
  [12, 79, 5, 17, 18, 8],
  [3, 43, 5, 6, 10, 65],
  [46, 58, 5, 22, 102, 131],
  [39, 110, 5, 53, 73, 99],
  [16, 87, 5, 36, 10, 45],
  [46, 66, 5, 63, 33, 45],
  [46, 66, 5, 6, 64, 115],
  [16, 119, 5, 13, 37, 109],
  [16, 57, 5, 13, 62, 78],
  [46, 25, 5, 89, 102, 68],
  [30, 132, 5, 6, 64, 27],
  [46, 103, 5, 26, 18, 76],
  [20, 116, 5, 63, 82, 108],
  [39, 43, 5, 13, 33, 111],
  [46, 112, 5, 26, 37, 67],
  [39, 31, 5, 6, 102, 105],
  [16, 31, 5, 17, 75, 101],
  [3, 21, 5, 32, 73, 126],
  [20, 58, 5, 59, 75, 80],
  [20, 50, 5, 13, 100, 91],
  [24, 113, 5, 6, 14, 137],
  [20, 66, 5, 13, 64, 61],
  [16, 96, 5, 26, 64, 8],
  [20, 110, 5, 13, 54, 74],
  [30, 85, 5, 53, 94, 134],
  [12, 4, 5, 139, 90, 134],
  [39, 25, 5, 53, 56, 11],
  [12, 50, 5, 13, 10, 47],
  [46, 58, 5, 92, 75, 138],
  [20, 85, 5, 53, 37, 80],
  [3, 116, 5, 92, 18, 67],
  [46, 104, 5, 13, 64, 19],
  [39, 50, 5, 17, 100, 84],
  [3, 85, 5, 22, 49, 111],
  [12, 66, 5, 17, 18, 97],
  [24, 125, 5, 89, 33, 101],
  [12, 117, 5, 107, 7, 115],
  [16, 132, 5, 36, 62, 108],
  [20, 72, 5, 6, 18, 74],
  [16, 106, 5, 22, 102, 143],
  [3, 69, 5, 17, 64, 121],
  [39, 127, 5, 13, 90, 65],
  [30, 10, 5, 53, 18, 34],
  [16, 87, 5, 59, 14, 84],
  [46, 55, 5, 63, 56, 42],
  [16, 9, 5, 13, 33, 47],
  [24, 66, 5, 107, 73, 108],
  [30, 142, 5, 107, 18, 10],
  [20, 110, 5, 92, 100, 8],
  [12, 96, 5, 6, 75, 34],
  [20, 113, 5, 6, 10, 42],
  [3, 125, 5, 13, 114, 80],
  [3, 57, 5, 13, 37, 71],
  [16, 112, 5, 13, 94, 134],
  [12, 55, 5, 59, 100, 131],
  [39, 69, 5, 13, 102, 11],
  [20, 43, 5, 92, 54, 15],
  [12, 106, 5, 32, 7, 45],
  [3, 85, 5, 107, 29, 111],
  [20, 35, 5, 26, 130, 71],
  [24, 55, 5, 17, 114, 76],
  [46, 113, 5, 107, 7, 68],
  [16, 58, 5, 6, 18, 80],
  [20, 55, 5, 26, 73, 84],
  [20, 112, 5, 22, 33, 121],
  [20, 31, 5, 92, 56, 11],
  [39, 28, 5, 107, 94, 99],
  [30, 81, 5, 17, 75, 45],
  [16, 106, 5, 89, 75, 131],
  [30, 106, 5, 22, 100, 131],
  [16, 31, 5, 13, 73, 108],
  [39, 69, 5, 22, 100, 105],
  [39, 55, 5, 32, 114, 88],
  [3, 128, 5, 17, 75, 15],
  [30, 43, 5, 107, 94, 101],
  [39, 125, 5, 22, 54, 80],
  [16, 104, 5, 59, 73, 11],
  [20, 58, 5, 22, 114, 38],
  [3, 10, 5, 89, 54, 70],
  [16, 69, 5, 107, 62, 80],
  [39, 72, 5, 17, 75, 8],
  [39, 119, 5, 22, 18, 121],
  [3, 72, 5, 36, 44, 11],
  [16, 132, 5, 26, 18, 65],
  [16, 55, 5, 63, 18, 74],
  [39, 104, 5, 63, 33, 51],
  [12, 31, 5, 22, 10, 47],
  [20, 57, 5, 63, 18, 67],
  [16, 132, 5, 107, 18, 105],
  [16, 52, 5, 63, 82, 108],
  [20, 104, 5, 32, 7, 78],
  [3, 57, 5, 26, 18, 131],
  [16, 81, 5, 17, 130, 8],
  [46, 57, 5, 92, 10, 78],
  [46, 4, 5, 13, 49, 118],
  [39, 66, 5, 6, 73, 121],
  [3, 117, 5, 32, 75, 10],
  [12, 87, 5, 13, 56, 78],
  [16, 43, 5, 36, 82, 108],
  [16, 81, 5, 92, 33, 47],
  [3, 40, 5, 59, 56, 91],
  [46, 31, 5, 6, 54, 45],
  [3, 85, 5, 32, 75, 8],
  [46, 28, 5, 13, 90, 118],
  [24, 96, 5, 107, 10, 108],
  [24, 57, 5, 17, 18, 129],
  [20, 103, 5, 59, 73, 47],
  [24, 104, 5, 32, 33, 76],
  [46, 48, 5, 63, 56, 11],
  [16, 133, 5, 53, 94, 121],
  [30, 141, 5, 92, 18, 51],
  [39, 85, 5, 26, 56, 34],
  [3, 28, 5, 92, 100, 8],
  [3, 25, 5, 36, 56, 131],
  [12, 113, 5, 107, 62, 118],
  [39, 9, 5, 22, 18, 70],
  [12, 77, 5, 63, 44, 91],
  [3, 132, 5, 107, 18, 19],
  [3, 69, 5, 53, 18, 131],
  [39, 43, 5, 36, 100, 70],
  [3, 72, 5, 17, 49, 65],
  [12, 25, 5, 32, 54, 129],
  [16, 48, 5, 17, 75, 10],
  [30, 132, 5, 22, 29, 97],
  [3, 120, 5, 59, 10, 131],
  [16, 69, 5, 53, 37, 15],
  [24, 81, 5, 53, 90, 105],
  [3, 55, 5, 92, 75, 47],
  [39, 69, 5, 92, 37, 35],
  [3, 58, 5, 17, 102, 67],
  [30, 35, 5, 89, 18, 83],
  [24, 135, 5, 26, 102, 108],
  [12, 125, 5, 107, 29, 108],
  [46, 132, 5, 6, 124, 99],
  [3, 55, 5, 36, 94, 23],
  [24, 117, 5, 36, 7, 109],
  [3, 135, 5, 13, 114, 115],
  [3, 117, 5, 107, 54, 83],
  [39, 85, 5, 36, 64, 131],
  [24, 28, 5, 32, 18, 74],
  [12, 132, 5, 26, 56, 101],
  [30, 104, 5, 59, 33, 34],
  [16, 119, 5, 26, 75, 47],
  [16, 69, 5, 92, 75, 71],
  [30, 106, 5, 17, 14, 131],
  [39, 25, 5, 32, 10, 121],
  [3, 48, 5, 36, 54, 91],
  [46, 106, 5, 32, 94, 121],
  [46, 66, 5, 92, 18, 11],
  [46, 110, 5, 17, 44, 122],
  [3, 9, 5, 17, 94, 45],
  [39, 120, 5, 22, 130, 99],
  [30, 96, 5, 92, 73, 27],
  [20, 103, 5, 107, 14, 111],
  [16, 35, 5, 107, 75, 108],
  [16, 69, 5, 36, 18, 121],
  [46, 35, 5, 32, 7, 80],
  [3, 88, 5, 86, 100, 42],
  [30, 31, 5, 6, 130, 131],
  [16, 85, 5, 36, 64, 70],
  [20, 50, 5, 59, 14, 97],
  [30, 28, 5, 63, 18, 35],
  [46, 81, 5, 63, 37, 83],
  [46, 69, 5, 59, 18, 121],
  [46, 57, 5, 53, 124, 105],
  [3, 66, 5, 32, 18, 10],
  [30, 66, 5, 92, 100, 108],
  [46, 72, 5, 59, 82, 105],
  [16, 110, 5, 17, 64, 65],
  [3, 25, 5, 13, 82, 70],
  [3, 113, 5, 26, 62, 76],
  [20, 132, 5, 92, 18, 68],
  [16, 69, 5, 53, 90, 27],
  [24, 31, 5, 26, 82, 45],
  [12, 132, 5, 92, 62, 121],
  [16, 25, 5, 17, 130, 47],
  [20, 69, 5, 36, 75, 27],
  [3, 106, 5, 13, 33, 74],
  [39, 35, 5, 13, 49, 71],
  [16, 27, 5, 36, 82, 11],
  [39, 120, 5, 22, 54, 11],
  [24, 4, 5, 89, 10, 11],
  [3, 50, 5, 63, 7, 65],
  [46, 58, 5, 6, 14, 51],
  [3, 103, 5, 59, 44, 51],
  [20, 69, 5, 13, 29, 97],
  [30, 128, 5, 13, 18, 80],
  [30, 96, 5, 63, 64, 51],
  [30, 58, 5, 89, 130, 131],
  [24, 31, 5, 26, 49, 138],
  [20, 96, 5, 13, 29, 65],
  [46, 31, 5, 22, 75, 35],
  [46, 112, 5, 107, 102, 117],
  [12, 117, 5, 92, 29, 8],
  [30, 57, 5, 107, 75, 121],
  [46, 57, 5, 13, 64, 111],
  [3, 119, 5, 17, 130, 109],
  [46, 96, 5, 107, 14, 8],
  [30, 117, 5, 32, 14, 108],
  [39, 50, 5, 63, 64, 84],
  [30, 127, 5, 26, 54, 74],
  [46, 28, 5, 86, 10, 88],
  [12, 85, 5, 32, 49, 8],
  [30, 66, 5, 92, 18, 78],
  [16, 31, 5, 36, 37, 101],
  [39, 119, 5, 32, 56, 74],
  [12, 66, 5, 17, 62, 99],
  [16, 31, 5, 89, 44, 76],
  [46, 66, 5, 92, 102, 35],
  [12, 31, 5, 13, 44, 108],
  [20, 117, 5, 63, 37, 65],
  [16, 85, 5, 13, 37, 71],
  [39, 81, 5, 26, 100, 65],
  [30, 50, 5, 17, 130, 76],
  [39, 113, 5, 92, 56, 11],
  [3, 119, 5, 63, 75, 70],
  [46, 96, 5, 107, 73, 93],
  [46, 113, 5, 107, 54, 105],
  [12, 10, 5, 13, 18, 80],
  [3, 4, 5, 107, 18, 34],
  [16, 28, 5, 107, 14, 99],
  [3, 4, 5, 17, 100, 83],
  [12, 50, 5, 32, 114, 99],
  [20, 112, 5, 17, 64, 101],
  [3, 116, 5, 22, 62, 10],
  [16, 123, 5, 17, 90, 97],
  [30, 50, 5, 17, 18, 122],
  [30, 110, 5, 92, 56, 23],
  [39, 31, 5, 139, 14, 131],
  [39, 103, 5, 26, 33, 51],
  [12, 69, 5, 6, 37, 68],
  [16, 104, 5, 36, 33, 80],
  [12, 66, 5, 32, 114, 67],
  [46, 113, 5, 59, 7, 65],
  [30, 43, 5, 107, 18, 134],
  [30, 128, 5, 107, 100, 121],
  [20, 48, 5, 13, 18, 38],
  [12, 43, 5, 53, 64, 122],
  [46, 35, 5, 63, 49, 138],
  [3, 128, 5, 17, 73, 10],
  [20, 28, 5, 6, 62, 47],
  [24, 113, 5, 6, 73, 70],
  [16, 40, 5, 32, 44, 67],
  [46, 35, 5, 63, 49, 109],
  [16, 57, 5, 36, 102, 19],
  [16, 128, 5, 6, 102, 121],
  [39, 4, 5, 92, 114, 80],
  [3, 72, 5, 6, 130, 121],
  [16, 4, 5, 107, 10, 97],
  [12, 48, 5, 107, 33, 91],
  [30, 132, 5, 13, 44, 47],
  [30, 31, 5, 17, 54, 131],
  [20, 10, 5, 63, 114, 91],
  [20, 43, 5, 17, 7, 47],
  [46, 50, 5, 6, 100, 121],
  [30, 123, 5, 107, 14, 129],
  [3, 55, 5, 59, 18, 10],
  [20, 66, 5, 107, 33, 117],
  [16, 103, 5, 6, 124, 91],
  [30, 103, 5, 92, 100, 70],
  [12, 104, 5, 13, 73, 68],
  [39, 28, 5, 107, 73, 42],
  [16, 58, 5, 26, 73, 97],
  [20, 58, 5, 13, 54, 76],
  [3, 21, 5, 6, 102, 8],
  [30, 57, 5, 17, 64, 11],
  [3, 135, 5, 92, 29, 38],
  [16, 48, 5, 107, 102, 15],
  [3, 28, 5, 17, 18, 101],
  [12, 85, 5, 26, 90, 34],
  [16, 57, 5, 92, 64, 27],
  [24, 85, 5, 22, 18, 65],
  [39, 66, 5, 36, 7, 136],
  [16, 55, 5, 17, 7, 108],
  [46, 119, 5, 107, 18, 23],
  [16, 133, 5, 36, 114, 10],
  [46, 96, 5, 63, 56, 8],
  [3, 96, 5, 36, 75, 74],
  [30, 103, 5, 13, 100, 71],
  [12, 106, 5, 13, 102, 38],
  [12, 79, 5, 92, 18, 131],
  [12, 140, 5, 36, 18, 109],
  [39, 28, 5, 26, 94, 76],
  [3, 69, 5, 107, 75, 71],
  [16, 106, 5, 107, 100, 99],
  [16, 132, 5, 53, 114, 108],
  [39, 10, 5, 59, 18, 88],
  [30, 31, 5, 92, 18, 71],
  [16, 28, 5, 13, 18, 51],
  [46, 117, 5, 26, 18, 11],
  [12, 35, 5, 26, 33, 91],
  [46, 43, 5, 22, 73, 10],
  [24, 110, 5, 36, 18, 105],
  [46, 88, 5, 63, 18, 101],
  [20, 113, 5, 107, 56, 76],
  [20, 31, 5, 89, 18, 78],
  [12, 141, 5, 17, 82, 74],
  [30, 128, 5, 32, 18, 19],
  [30, 141, 5, 13, 18, 83],
  [16, 113, 5, 6, 100, 83],
  [16, 140, 5, 22, 94, 99],
  [3, 104, 5, 107, 14, 78],
  [12, 50, 5, 36, 10, 80],
  [3, 28, 5, 92, 29, 78],
  [20, 132, 5, 107, 114, 19],
  [30, 81, 5, 86, 90, 99],
  [16, 43, 5, 36, 64, 68],
  [3, 104, 5, 17, 100, 78],
  [46, 113, 5, 89, 18, 99],
  [30, 28, 5, 22, 82, 71],
  [24, 125, 5, 13, 18, 47],
  [24, 66, 5, 92, 124, 122],
  [12, 123, 5, 17, 14, 131],
  [20, 21, 5, 59, 90, 108],
  [3, 113, 5, 53, 14, 45],
  [39, 28, 5, 107, 33, 34],
  [46, 119, 5, 107, 7, 70],
  [39, 31, 5, 22, 18, 76],
  [3, 140, 5, 92, 18, 76],
  [20, 132, 5, 17, 90, 45],
  [16, 43, 5, 36, 54, 47],
  [3, 117, 5, 32, 7, 91],
  [16, 77, 5, 6, 49, 45],
  [39, 57, 5, 107, 18, 45],
  [39, 55, 5, 6, 49, 67],
  [30, 104, 5, 89, 82, 34],
  [30, 81, 5, 36, 14, 121],
  [20, 135, 5, 26, 56, 121],
  [12, 28, 5, 53, 10, 138],
  [24, 140, 5, 89, 18, 131],
  [12, 9, 5, 13, 18, 68],
  [3, 31, 5, 63, 18, 47],
  [30, 96, 5, 22, 64, 83],
  [20, 40, 5, 6, 29, 137],
  [46, 50, 5, 13, 102, 80],
  [46, 69, 5, 17, 33, 115],
  [30, 48, 5, 13, 18, 11],
  [24, 31, 5, 53, 100, 122],
  [39, 43, 5, 89, 75, 91],
  [16, 141, 5, 26, 7, 105],
  [16, 81, 5, 13, 10, 83],
  [46, 57, 5, 32, 18, 121],
  [39, 77, 5, 36, 62, 11],
  [30, 10, 5, 36, 62, 122],
  [24, 113, 5, 22, 62, 115],
  [20, 50, 5, 17, 62, 121],
  [3, 119, 5, 107, 102, 105],
  [12, 40, 5, 63, 18, 68],
  [46, 128, 5, 13, 90, 88],
  [39, 132, 5, 32, 7, 51],
  [46, 103, 5, 6, 82, 83],
  [12, 103, 5, 32, 82, 10],
  [30, 117, 5, 92, 75, 71],
  [30, 135, 5, 89, 49, 8],
  [3, 66, 5, 89, 29, 47],
  [46, 106, 5, 107, 82, 99],
  [16, 106, 5, 107, 64, 99],
  [3, 58, 5, 26, 64, 105],
  [16, 96, 5, 36, 102, 68],
  [16, 132, 5, 22, 7, 138],
  [12, 69, 5, 59, 102, 80],
  [12, 58, 5, 17, 90, 109],
  [46, 66, 5, 107, 10, 80],
  [3, 72, 5, 89, 18, 8],
  [39, 43, 5, 13, 29, 11],
  [16, 140, 5, 13, 49, 51],
  [16, 81, 5, 13, 10, 129],
  [46, 31, 5, 13, 44, 8],
  [16, 57, 5, 26, 90, 80],
  [3, 132, 5, 36, 73, 131],
  [46, 50, 5, 32, 98, 78],
  [16, 50, 5, 22, 37, 67],
  [16, 48, 5, 17, 94, 108],
  [46, 88, 5, 36, 90, 131],
  [24, 31, 5, 59, 54, 105],
  [39, 35, 5, 17, 56, 83],
  [16, 35, 5, 13, 10, 115],
  [16, 57, 5, 17, 124, 122],
  [20, 50, 5, 63, 56, 105],
  [39, 66, 5, 26, 18, 122],
  [12, 50, 5, 89, 62, 122],
  [30, 113, 5, 13, 49, 8],
  [39, 125, 5, 13, 18, 11],
  [3, 55, 5, 32, 94, 115],
  [16, 40, 5, 107, 18, 51],
  [30, 96, 5, 13, 100, 136],
  [46, 106, 5, 22, 102, 68],
  [3, 77, 5, 6, 7, 10],
  [39, 35, 5, 22, 18, 138],
  [24, 55, 5, 17, 54, 27],
  [16, 55, 5, 17, 44, 11],
  [3, 116, 5, 59, 75, 131],
  [16, 28, 5, 59, 10, 47],
  [20, 133, 5, 13, 94, 34],
  [12, 132, 5, 92, 56, 117],
  [39, 52, 5, 107, 33, 91],
  [3, 28, 5, 89, 94, 65],
  [16, 119, 5, 63, 18, 105],
  [46, 112, 5, 6, 44, 80],
  [30, 50, 5, 6, 130, 74],
  [46, 123, 5, 17, 130, 78],
  [39, 132, 5, 63, 94, 67],
  [3, 43, 5, 17, 33, 122],
  [46, 125, 5, 107, 56, 70],
  [20, 43, 5, 63, 54, 8],
  [20, 103, 5, 32, 18, 47],
  [12, 40, 5, 63, 7, 19],
  [20, 85, 5, 107, 62, 117],
  [16, 96, 5, 17, 33, 134],
  [24, 35, 5, 13, 94, 65],
  [24, 9, 5, 6, 62, 108],
  [16, 43, 5, 13, 54, 83],
  [16, 25, 5, 89, 56, 93],
  [12, 40, 5, 59, 94, 121],
  [30, 69, 5, 107, 18, 51],
  [30, 72, 5, 36, 18, 35],
  [30, 135, 5, 107, 18, 78],
  [46, 43, 5, 59, 98, 105],
  [20, 123, 5, 17, 33, 70],
  [39, 140, 5, 17, 75, 137],
  [16, 88, 5, 59, 130, 138],
  [39, 57, 5, 17, 114, 108],
  [24, 119, 5, 6, 73, 67],
  [46, 106, 5, 63, 56, 80],
  [3, 50, 5, 22, 54, 38],
  [39, 132, 5, 86, 29, 115],
  [24, 119, 5, 13, 64, 68],
  [12, 116, 5, 59, 18, 101],
  [30, 48, 5, 17, 130, 99],
  [20, 104, 5, 59, 73, 74],
  [3, 140, 5, 36, 64, 47],
  [20, 117, 5, 89, 33, 65],
  [3, 48, 5, 17, 75, 76],
  [20, 125, 5, 26, 18, 111],
  [39, 72, 5, 36, 100, 8],
  [46, 125, 5, 22, 44, 11],
  [30, 113, 5, 36, 73, 83],
  [39, 132, 5, 22, 18, 138],
  [30, 112, 5, 53, 75, 111],
  [12, 96, 5, 13, 75, 137],
  [3, 77, 5, 92, 62, 101],
  [16, 81, 5, 92, 29, 122],
  [3, 132, 5, 89, 100, 45],
  [16, 85, 5, 89, 18, 105],
  [12, 117, 5, 89, 18, 91],
  [46, 57, 5, 86, 18, 78],
  [39, 103, 5, 13, 56, 10],
  [3, 43, 5, 36, 73, 134],
  [46, 72, 5, 17, 64, 78],
  [30, 72, 5, 13, 102, 45],
  [39, 9, 5, 26, 64, 108],
  [30, 28, 5, 13, 7, 111],
  [16, 57, 5, 59, 75, 47],
  [39, 55, 5, 59, 18, 99],
  [12, 55, 5, 63, 56, 111],
  [16, 48, 5, 63, 29, 45],
  [39, 28, 5, 36, 102, 34],
  [24, 135, 5, 26, 75, 131],
  [3, 66, 5, 22, 54, 93],
  [16, 113, 5, 13, 94, 47],
  [12, 52, 5, 6, 100, 83],
  [46, 81, 5, 6, 18, 115],
  [16, 96, 5, 17, 73, 78],
  [12, 85, 5, 92, 29, 45],
  [30, 106, 5, 139, 18, 45],
  [12, 132, 5, 59, 18, 67],
  [30, 96, 5, 107, 73, 74],
  [46, 48, 5, 63, 75, 67],
  [16, 43, 5, 63, 18, 105],
  [24, 79, 5, 92, 14, 137],
  [16, 52, 5, 6, 130, 78],
  [46, 35, 5, 89, 100, 105],
  [12, 125, 5, 32, 14, 111],
  [24, 125, 5, 36, 54, 117],
  [24, 119, 5, 17, 18, 83],
  [16, 58, 5, 63, 56, 121],
  [46, 103, 5, 6, 18, 131],
  [30, 85, 5, 89, 18, 71],
  [24, 120, 5, 26, 18, 10],
  [20, 28, 5, 107, 82, 105],
  [46, 50, 5, 22, 29, 71],
  [12, 132, 5, 92, 94, 70],
  [20, 123, 5, 63, 18, 121],
  [30, 96, 5, 6, 18, 23],
  [20, 9, 5, 36, 56, 65],
  [46, 79, 5, 6, 54, 68],
  [20, 28, 5, 107, 64, 136],
  [20, 133, 5, 63, 29, 34],
  [30, 27, 5, 13, 18, 137],
  [3, 25, 5, 89, 56, 115],
  [16, 57, 5, 89, 75, 108],
  [46, 103, 5, 89, 33, 115],
  [20, 128, 5, 36, 82, 65],
  [46, 28, 5, 26, 33, 68],
  [3, 120, 5, 32, 14, 67],
  [3, 104, 5, 59, 14, 108],
  [30, 140, 5, 13, 56, 71],
  [30, 125, 5, 107, 124, 83],
  [46, 104, 5, 63, 56, 78],
  [3, 35, 5, 22, 49, 97],
  [30, 140, 5, 92, 54, 115],
  [46, 112, 5, 53, 37, 11],
  [46, 106, 5, 6, 82, 108],
  [46, 119, 5, 59, 18, 10],
  [30, 123, 5, 107, 75, 35],
  [30, 4, 5, 6, 18, 8],
  [16, 79, 5, 36, 29, 143],
  [46, 113, 5, 13, 94, 101],
  [30, 58, 5, 26, 18, 97],
  [46, 135, 5, 63, 14, 121],
  [30, 69, 5, 36, 114, 47],
  [39, 28, 5, 36, 64, 117],
  [20, 35, 5, 89, 62, 143],
  [30, 119, 5, 59, 90, 111],
  [12, 113, 5, 86, 37, 65],
  [12, 66, 5, 13, 102, 42],
  [16, 79, 5, 92, 102, 78],
  [39, 31, 5, 59, 114, 35],
  [39, 77, 5, 59, 94, 47],
  [12, 96, 5, 107, 82, 118],
  [3, 81, 5, 59, 64, 118],
  [39, 48, 5, 6, 73, 8],
  [3, 31, 5, 59, 56, 101],
  [12, 85, 5, 89, 100, 19],
  [3, 117, 5, 13, 62, 23],
  [20, 123, 5, 6, 7, 99],
  [39, 55, 5, 17, 37, 93],
  [24, 57, 5, 107, 124, 76],
  [24, 50, 5, 36, 7, 34],
  [3, 106, 5, 107, 14, 19],
  [3, 117, 5, 6, 94, 67],
  [12, 88, 5, 17, 73, 45],
  [12, 48, 5, 92, 100, 71],
  [46, 106, 5, 26, 102, 115],
  [20, 119, 5, 26, 18, 108],
  [30, 72, 5, 13, 44, 34],
  [46, 55, 5, 92, 10, 71],
  [20, 104, 5, 59, 18, 10],
  [16, 104, 5, 17, 14, 27],
  [16, 104, 5, 6, 10, 45],
  [39, 4, 5, 6, 10, 95],
  [3, 85, 5, 17, 100, 134],
  [30, 117, 5, 89, 54, 34],
  [3, 28, 5, 26, 90, 68],
  [46, 81, 5, 26, 102, 111],
  [3, 112, 5, 22, 14, 47],
  [16, 55, 5, 36, 82, 83],
  [39, 106, 5, 17, 102, 76],
  [39, 48, 5, 17, 56, 80],
  [12, 113, 5, 26, 7, 88],
  [30, 69, 5, 92, 64, 101],
  [3, 81, 5, 107, 33, 68],
  [39, 81, 5, 107, 44, 95],
  [16, 87, 5, 107, 14, 131],
  [30, 106, 5, 92, 29, 118],
  [16, 28, 5, 22, 75, 68],
  [30, 123, 5, 17, 90, 65],
  [39, 103, 5, 32, 56, 68],
  [46, 116, 5, 36, 62, 118],
  [20, 27, 5, 6, 33, 74],
  [3, 69, 5, 13, 7, 65],
  [39, 140, 5, 36, 73, 74],
  [20, 140, 5, 53, 18, 91],
  [24, 88, 5, 59, 44, 34],
  [16, 135, 5, 59, 64, 105],
  [39, 106, 5, 92, 18, 76],
  [3, 112, 5, 22, 100, 109],
  [24, 72, 5, 53, 90, 117],
  [24, 52, 5, 17, 82, 38],
  [46, 106, 5, 89, 56, 93],
  [30, 55, 5, 13, 29, 115],
  [20, 66, 5, 32, 18, 126],
  [46, 113, 5, 13, 100, 65],
  [20, 106, 5, 59, 18, 11],
  [12, 28, 5, 59, 62, 51],
  [30, 48, 5, 107, 56, 76],
  [46, 81, 5, 59, 18, 108],
  [46, 103, 5, 63, 44, 51],
  [24, 140, 5, 92, 54, 111],
  [12, 112, 5, 36, 18, 45],
  [30, 28, 5, 17, 73, 76],
  [12, 25, 5, 59, 37, 105],
  [16, 103, 5, 92, 75, 74],
  [46, 57, 5, 6, 44, 80],
  [16, 57, 5, 36, 18, 67],
  [46, 10, 5, 59, 94, 101],
  [12, 117, 5, 92, 90, 93],
  [12, 116, 5, 59, 14, 74],
  [16, 132, 5, 59, 90, 80],
  [39, 87, 5, 22, 75, 45],
  [24, 141, 5, 17, 14, 61],
  [39, 104, 5, 17, 33, 47],
  [24, 40, 5, 17, 18, 88],
  [3, 48, 5, 36, 18, 51],
  [16, 128, 5, 63, 49, 111],
  [24, 58, 5, 26, 82, 115],
  [39, 106, 5, 17, 62, 88],
  [39, 57, 5, 6, 124, 80],
  [12, 28, 5, 59, 29, 71],
  [39, 113, 5, 26, 62, 97],
  [3, 125, 5, 6, 75, 61],
  [46, 31, 5, 36, 102, 129],
  [24, 128, 5, 13, 100, 45],
  [12, 106, 5, 63, 64, 70],
  [39, 132, 5, 13, 14, 10],
  [20, 9, 5, 139, 44, 115],
  [46, 117, 5, 17, 18, 74],
  [3, 57, 5, 107, 82, 68],
  [39, 132, 5, 107, 75, 118],
  [3, 55, 5, 53, 54, 115],
  [46, 35, 5, 92, 114, 84],
  [24, 106, 5, 13, 18, 78],
  [30, 25, 5, 17, 102, 93],
  [3, 66, 5, 36, 18, 8],
  [3, 117, 5, 53, 7, 8],
  [30, 117, 5, 32, 33, 118],
  [3, 27, 5, 32, 14, 108],
  [3, 35, 5, 22, 100, 138],
  [30, 50, 5, 107, 29, 93],
  [30, 119, 5, 92, 33, 126],
  [12, 57, 5, 22, 64, 91],
  [20, 135, 5, 26, 37, 74],
  [39, 31, 5, 6, 114, 108],
  [3, 119, 5, 139, 49, 45],
  [12, 117, 5, 17, 64, 121],
  [24, 127, 5, 89, 64, 67],
  [16, 106, 5, 107, 54, 78],
  [46, 66, 5, 13, 10, 137],
  [3, 21, 5, 6, 102, 68],
  [3, 27, 5, 32, 49, 121],
  [3, 72, 5, 13, 18, 99],
  [16, 96, 5, 107, 33, 122],
  [39, 127, 5, 6, 90, 131],
  [20, 141, 5, 92, 124, 71],
  [30, 79, 5, 89, 44, 105],
  [20, 104, 5, 26, 56, 45],
  [30, 87, 5, 89, 64, 80],
  [3, 119, 5, 17, 100, 117],
  [30, 35, 5, 107, 62, 68],
  [39, 58, 5, 17, 64, 99],
  [16, 40, 5, 32, 54, 101],
  [3, 106, 5, 32, 44, 11],
  [46, 50, 5, 59, 56, 111],
  [46, 85, 5, 89, 56, 71],
  [20, 135, 5, 107, 62, 34],
  [46, 132, 5, 89, 14, 111],
  [24, 103, 5, 107, 62, 27],
  [20, 69, 5, 86, 14, 91],
  [39, 50, 5, 6, 94, 117],
  [30, 52, 5, 107, 64, 93],
  [12, 119, 5, 32, 18, 76],
  [16, 48, 5, 22, 102, 118],
  [20, 81, 5, 17, 64, 70],
  [12, 28, 5, 13, 90, 122],
  [30, 66, 5, 17, 124, 118],
  [30, 40, 5, 89, 100, 84],
  [12, 43, 5, 17, 114, 70],
  [46, 113, 5, 107, 82, 11],
  [3, 117, 5, 63, 90, 91],
  [3, 88, 5, 59, 64, 93],
  [3, 25, 5, 26, 82, 111],
  [24, 133, 5, 17, 62, 45],
  [3, 28, 5, 6, 7, 122],
  [39, 66, 5, 32, 90, 122],
  [30, 106, 5, 26, 10, 45],
  [39, 48, 5, 36, 90, 47],
  [30, 55, 5, 32, 130, 65],
  [12, 55, 5, 17, 18, 71],
  [16, 140, 5, 22, 49, 8],
  [24, 85, 5, 36, 64, 71],
  [46, 43, 5, 32, 102, 83],
  [16, 4, 5, 139, 94, 108],
  [16, 113, 5, 89, 114, 131],
  [20, 66, 5, 63, 49, 68],
  [16, 79, 5, 59, 18, 111],
  [20, 96, 5, 6, 18, 74],
  [20, 28, 5, 13, 10, 111],
  [46, 66, 5, 26, 7, 71],
  [3, 112, 5, 32, 18, 131],
  [20, 119, 5, 92, 64, 121],
  [16, 43, 5, 107, 14, 67],
  [20, 87, 5, 32, 73, 78],
  [30, 66, 5, 22, 29, 11],
  [16, 140, 5, 6, 102, 99],
  [12, 132, 5, 92, 94, 83],
  [30, 141, 5, 107, 54, 71],
  [30, 57, 5, 107, 90, 70],
  [46, 35, 5, 59, 33, 129],
  [46, 35, 5, 92, 75, 71],
  [3, 28, 5, 92, 14, 71],
  [20, 132, 5, 53, 29, 74],
  [12, 69, 5, 63, 54, 19],
  [12, 106, 5, 26, 49, 45],
  [12, 113, 5, 107, 100, 68],
  [12, 79, 5, 107, 102, 99],
  [16, 69, 5, 89, 100, 65],
  [3, 31, 5, 22, 102, 115],
  [39, 28, 5, 17, 14, 109],
  [39, 77, 5, 22, 54, 121],
  [12, 119, 5, 6, 10, 78],
  [30, 66, 5, 63, 102, 122],
  [3, 113, 5, 32, 56, 8],
  [30, 58, 5, 22, 14, 88],
  [30, 96, 5, 89, 29, 47],
  [12, 55, 5, 53, 37, 131],
  [24, 57, 5, 13, 90, 78],
  [30, 79, 5, 17, 102, 78],
  [30, 116, 5, 53, 18, 23],
  [46, 120, 5, 22, 54, 108],
  [24, 57, 5, 6, 102, 95],
  [30, 113, 5, 92, 82, 8],
  [46, 28, 5, 26, 29, 45],
  [16, 48, 5, 13, 10, 99],
  [30, 113, 5, 59, 18, 131],
  [39, 58, 5, 22, 56, 105],
  [20, 104, 5, 92, 124, 131],
  [20, 31, 5, 92, 10, 51],
  [16, 25, 5, 13, 49, 99],
  [16, 106, 5, 13, 114, 11],
  [145, 145, 145, 145, 145, 145],
  [3, 103, 5, 17, 37, 65],
  [46, 103, 5, 92, 124, 131],
  [24, 43, 5, 22, 7, 8],
  [46, 112, 5, 36, 41, 122],
  [16, 119, 5, 92, 64, 23],
  [12, 123, 5, 89, 37, 105],
  [12, 119, 5, 92, 54, 65],
  [3, 43, 5, 63, 54, 129],
  [16, 119, 5, 13, 44, 126],
  [3, 96, 5, 13, 18, 122],
  [12, 35, 5, 59, 10, 8],
  [12, 125, 5, 53, 29, 70],
  [39, 103, 5, 59, 18, 109],
  [24, 106, 5, 32, 29, 108],
  [12, 142, 5, 107, 75, 122],
  [46, 119, 5, 63, 62, 76],
  [46, 57, 5, 107, 64, 137],
  [46, 72, 5, 92, 29, 115],
  [39, 48, 5, 17, 114, 47],
  [3, 106, 5, 26, 37, 68],
  [3, 58, 5, 89, 90, 138],
  [46, 125, 5, 6, 56, 47],
  [12, 66, 5, 92, 90, 143],
  [46, 9, 5, 53, 33, 101],
  [39, 120, 5, 22, 18, 35],
  [20, 52, 5, 32, 62, 61],
  [12, 133, 5, 22, 100, 70],
  [12, 72, 5, 22, 114, 93],
  [39, 31, 5, 13, 18, 67],
  [20, 103, 5, 13, 64, 122],
  [24, 57, 5, 6, 75, 109],
  [30, 55, 5, 6, 124, 129],
  [3, 48, 5, 92, 102, 27],
  [30, 104, 5, 89, 90, 122],
  [24, 128, 5, 26, 7, 67],
  [12, 106, 5, 6, 37, 131],
  [20, 66, 5, 36, 18, 84],
  [39, 112, 5, 22, 18, 47],
  [46, 120, 5, 17, 90, 122],
  [24, 81, 5, 107, 90, 67],
  [3, 35, 5, 32, 18, 80],
  [46, 110, 5, 36, 94, 129],
  [24, 52, 5, 22, 54, 76],
  [20, 85, 5, 32, 130, 74],
  [16, 43, 5, 32, 18, 91],
  [30, 106, 5, 59, 90, 108],
  [12, 31, 5, 13, 18, 115],
  [3, 119, 5, 6, 14, 84],
  [3, 43, 5, 107, 18, 88],
  [46, 72, 5, 13, 75, 122],
  [3, 96, 5, 59, 75, 74],
  [16, 104, 5, 13, 33, 45],
  [12, 57, 5, 63, 44, 80],
  [12, 57, 5, 6, 33, 109],
  [12, 58, 5, 92, 62, 115],
  [16, 127, 5, 92, 18, 95],
  [24, 31, 5, 6, 94, 99],
  [20, 120, 5, 59, 73, 10],
  [24, 57, 5, 63, 18, 8],
  [46, 25, 5, 107, 73, 70],
  [16, 4, 5, 89, 33, 67],
  [3, 57, 5, 17, 56, 121],
  [12, 55, 5, 63, 102, 67],
  [16, 48, 5, 63, 64, 67],
  [20, 66, 5, 13, 33, 115],
  [12, 48, 5, 36, 98, 111],
  [3, 133, 5, 17, 100, 99],
  [46, 110, 5, 53, 18, 11],
  [3, 116, 5, 32, 75, 34],
  [46, 128, 5, 17, 75, 121],
  [39, 58, 5, 17, 73, 95],
  [46, 69, 5, 59, 33, 118],
  [39, 132, 5, 36, 54, 129],
  [24, 31, 5, 6, 33, 101],
  [46, 28, 5, 107, 44, 70],
  [24, 117, 5, 22, 29, 74],
  [16, 48, 5, 92, 44, 105],
  [24, 103, 5, 26, 98, 111],
  [12, 123, 5, 36, 18, 8],
  [46, 88, 5, 92, 18, 34],
  [16, 104, 5, 13, 44, 47],
  [3, 21, 5, 59, 18, 10],
  [39, 52, 5, 89, 75, 122],
  [12, 96, 5, 26, 94, 121],
  [16, 117, 5, 6, 29, 65],
  [3, 48, 5, 17, 33, 91],
  [12, 72, 5, 63, 75, 118],
  [39, 69, 5, 36, 94, 84],
  [20, 58, 5, 32, 90, 65],
  [30, 132, 5, 17, 56, 105],
  [3, 125, 5, 36, 130, 137],
  [46, 66, 5, 53, 18, 105],
  [20, 87, 5, 89, 82, 38],
  [39, 43, 5, 32, 75, 101],
  [24, 113, 5, 6, 29, 15],
  [39, 35, 5, 17, 56, 108],
  [20, 50, 5, 22, 37, 131],
  [3, 43, 5, 22, 82, 11],
  [12, 66, 5, 13, 33, 8],
  [24, 135, 5, 36, 75, 65],
  [16, 140, 5, 22, 98, 101],
  [39, 69, 5, 63, 7, 11],
  [46, 135, 5, 17, 90, 101],
  [20, 58, 5, 59, 64, 117],
  [30, 103, 5, 6, 54, 131],
  [3, 110, 5, 107, 56, 99],
  [3, 4, 5, 17, 37, 93],
  [16, 85, 5, 63, 64, 67],
  [30, 43, 5, 17, 10, 80],
  [30, 104, 5, 17, 94, 95],
  [16, 113, 5, 6, 18, 136],
  [3, 43, 5, 36, 73, 71],
  [46, 25, 5, 6, 14, 45],
  [24, 31, 5, 63, 14, 108],
  [12, 28, 5, 17, 54, 71],
  [3, 125, 5, 17, 18, 70],
  [20, 50, 5, 17, 102, 115],
  [24, 4, 5, 17, 18, 91],
  [46, 141, 5, 107, 94, 109],
  [3, 79, 5, 107, 18, 122],
  [16, 43, 5, 107, 75, 83],
  [12, 123, 5, 59, 33, 74],
  [39, 81, 5, 6, 14, 99],
  [16, 58, 5, 13, 114, 70],
  [3, 96, 5, 6, 18, 76],
  [46, 87, 5, 13, 18, 11],
  [30, 119, 5, 17, 54, 67],
  [39, 103, 5, 92, 44, 115],
  [16, 58, 5, 6, 44, 108],
  [39, 43, 5, 107, 14, 74],
  [46, 66, 5, 6, 44, 74],
  [20, 25, 5, 59, 14, 38],
  [3, 87, 5, 89, 100, 74],
  [20, 132, 5, 89, 54, 45],
  [20, 119, 5, 17, 90, 134],
  [24, 50, 5, 17, 29, 74],
  [39, 35, 5, 17, 102, 76],
  [46, 106, 5, 17, 62, 137],
  [46, 48, 5, 36, 90, 35],
  [20, 40, 5, 17, 75, 80],
  [46, 58, 5, 89, 75, 137],
  [46, 104, 5, 6, 100, 101],
  [46, 123, 5, 36, 44, 15],
  [39, 69, 5, 36, 56, 138],
  [46, 103, 5, 13, 10, 88],
  [39, 113, 5, 63, 114, 27],
  [3, 85, 5, 36, 18, 91],
  [39, 43, 5, 36, 90, 93],
  [46, 66, 5, 89, 102, 70],
  [16, 27, 5, 32, 54, 131],
  [16, 57, 5, 26, 29, 115],
  [46, 119, 5, 13, 18, 34],
  [16, 69, 5, 139, 73, 80],
  [20, 132, 5, 107, 18, 122],
  [39, 31, 5, 53, 18, 91],
  [39, 66, 5, 26, 7, 80],
  [12, 69, 5, 107, 124, 88],
  [46, 27, 5, 36, 14, 61],
  [46, 52, 5, 22, 102, 71],
  [16, 81, 5, 63, 14, 99],
  [24, 141, 5, 107, 41, 67],
  [30, 58, 5, 36, 102, 80],
  [12, 50, 5, 107, 18, 78],
  [39, 104, 5, 36, 56, 108],
  [16, 31, 5, 17, 14, 45],
  [20, 57, 5, 22, 18, 70],
  [30, 135, 5, 36, 18, 95],
  [39, 128, 5, 92, 37, 137],
  [12, 31, 5, 6, 94, 71],
  [39, 141, 5, 32, 98, 131],
  [30, 28, 5, 26, 100, 105],
  [3, 112, 5, 107, 94, 65],
  [39, 72, 5, 13, 82, 76],
  [20, 69, 5, 32, 73, 83],
  [12, 110, 5, 32, 44, 101],
  [12, 43, 5, 107, 7, 121],
  [46, 50, 5, 17, 90, 93],
  [46, 113, 5, 6, 37, 71],
  [3, 27, 5, 92, 14, 76],
  [30, 128, 5, 6, 7, 105],
  [46, 125, 5, 6, 18, 131],
  [46, 55, 5, 17, 124, 88],
  [3, 81, 5, 53, 33, 47],
  [16, 69, 5, 17, 82, 93],
  [30, 133, 5, 92, 73, 111],
  [24, 110, 5, 36, 62, 111],
  [20, 106, 5, 32, 124, 67],
  [16, 132, 5, 92, 62, 111],
  [16, 117, 5, 13, 18, 105],
  [46, 96, 5, 22, 54, 78],
  [3, 140, 5, 13, 94, 45],
  [46, 140, 5, 89, 37, 76],
  [24, 81, 5, 107, 130, 83],
  [30, 104, 5, 13, 18, 95],
  [39, 106, 5, 26, 44, 74],
  [24, 116, 5, 63, 18, 15],
  [16, 57, 5, 59, 64, 136],
  [39, 25, 5, 92, 64, 131],
  [30, 57, 5, 107, 7, 121],
  [12, 106, 5, 59, 102, 70],
  [39, 96, 5, 107, 10, 101],
  [20, 117, 5, 6, 62, 23],
  [30, 52, 5, 22, 102, 68],
  [16, 66, 5, 53, 75, 91],
  [16, 85, 5, 92, 7, 80],
  [3, 69, 5, 107, 56, 95],
  [30, 35, 5, 92, 75, 84],
  [12, 140, 5, 22, 18, 95],
  [16, 87, 5, 92, 33, 99],
  [16, 50, 5, 6, 73, 129],
  [3, 66, 5, 22, 10, 65],
  [12, 72, 5, 53, 44, 99],
  [30, 106, 5, 53, 18, 122],
  [20, 103, 5, 63, 62, 34],
  [46, 106, 5, 36, 29, 121],
  [3, 57, 5, 6, 73, 111],
  [3, 85, 5, 26, 33, 111],
  [39, 132, 5, 59, 33, 68],
  [24, 132, 5, 6, 90, 10],
  [12, 96, 5, 32, 94, 105],
  [46, 55, 5, 22, 14, 67],
  [20, 48, 5, 22, 29, 99],
  [39, 48, 5, 107, 102, 99],
  [16, 88, 5, 17, 82, 118],
  [16, 104, 5, 59, 114, 91],
  [3, 72, 5, 107, 14, 91],
  [3, 50, 5, 86, 56, 105],
  [20, 35, 5, 17, 82, 93],
  [3, 135, 5, 107, 62, 27],
  [16, 106, 5, 17, 37, 131],
  [30, 132, 5, 13, 124, 71],
  [20, 50, 5, 13, 18, 74],
  [39, 35, 5, 59, 94, 51],
  [16, 113, 5, 36, 49, 51],
  [39, 125, 5, 107, 49, 105],
  [46, 48, 5, 139, 18, 83],
  [30, 21, 5, 92, 33, 34],
  [46, 43, 5, 17, 49, 91],
  [16, 40, 5, 53, 10, 95],
  [30, 35, 5, 22, 114, 122],
  [16, 96, 5, 92, 94, 15],
  [46, 132, 5, 6, 37, 84],
  [12, 25, 5, 32, 62, 23],
  [39, 132, 5, 17, 18, 78],
  [3, 58, 5, 13, 100, 11],
  [3, 58, 5, 17, 14, 105],
  [20, 112, 5, 17, 82, 111],
  [30, 104, 5, 26, 18, 65],
  [16, 31, 5, 6, 82, 34],
  [24, 10, 5, 63, 62, 105],
  [3, 48, 5, 17, 14, 45],
  [39, 48, 5, 63, 82, 71],
  [20, 119, 5, 6, 94, 84],
  [3, 50, 5, 36, 56, 67],
  [46, 35, 5, 26, 18, 65],
  [20, 81, 5, 32, 54, 137],
  [16, 31, 5, 32, 64, 67],
  [46, 104, 5, 107, 75, 83],
  [20, 43, 5, 139, 62, 83],
  [30, 48, 5, 53, 54, 115],
  [3, 120, 5, 86, 56, 8],
  [3, 106, 5, 89, 18, 47],
  [3, 87, 5, 92, 29, 83],
  [30, 35, 5, 107, 73, 115],
  [3, 52, 5, 107, 14, 134],
  [30, 119, 5, 59, 100, 34],
  [24, 96, 5, 107, 64, 68],
  [46, 104, 5, 6, 64, 10],
  [16, 116, 5, 17, 14, 10],
  [12, 127, 5, 92, 75, 10],
  [39, 57, 5, 13, 102, 115],
  [24, 43, 5, 17, 18, 108],
  [30, 57, 5, 6, 54, 51],
  [3, 112, 5, 17, 44, 11],
  [30, 88, 5, 53, 18, 68],
  [39, 87, 5, 32, 37, 65],
  [30, 85, 5, 13, 7, 117],
  [3, 112, 5, 22, 102, 115],
  [24, 31, 5, 63, 114, 83],
  [30, 106, 5, 36, 94, 136],
  [30, 81, 5, 32, 114, 122],
  [20, 48, 5, 32, 18, 35],
  [24, 127, 5, 6, 14, 10],
  [24, 43, 5, 63, 56, 83],
  [39, 96, 5, 22, 7, 122],
  [46, 141, 5, 22, 100, 121],
  [16, 58, 5, 26, 73, 122],
  [16, 48, 5, 13, 73, 84],
  [46, 66, 5, 92, 18, 71],
  [3, 81, 5, 6, 114, 138],
  [16, 55, 5, 53, 94, 80],
  [39, 106, 5, 36, 33, 78],
  [20, 123, 5, 6, 90, 131],
  [39, 31, 5, 89, 29, 117],
  [20, 40, 5, 17, 90, 118],
  [16, 103, 5, 26, 14, 8],
  [39, 69, 5, 6, 18, 121],
  [12, 55, 5, 32, 94, 105],
  [3, 35, 5, 17, 18, 138],
  [20, 57, 5, 59, 33, 71],
  [30, 69, 5, 53, 100, 131],
  [46, 28, 5, 92, 10, 8],
  [39, 43, 5, 59, 100, 76],
  [30, 25, 5, 89, 7, 10],
  [39, 79, 5, 17, 94, 115],
  [46, 69, 5, 36, 54, 35],
  [3, 31, 5, 92, 56, 78],
  [30, 55, 5, 6, 114, 131],
  [46, 85, 5, 36, 114, 11],
  [16, 66, 5, 26, 56, 68],
  [12, 57, 5, 107, 64, 11],
  [46, 27, 5, 26, 14, 105],
  [24, 87, 5, 36, 18, 93],
  [39, 110, 5, 89, 37, 45],
  [20, 66, 5, 53, 33, 47],
  [16, 57, 5, 59, 75, 108],
  [12, 140, 5, 32, 130, 70],
  [24, 113, 5, 32, 54, 121],
  [12, 31, 5, 32, 29, 76],
  [39, 119, 5, 6, 29, 138],
  [46, 81, 5, 6, 18, 78],
  [16, 69, 5, 59, 73, 134],
  [12, 57, 5, 26, 29, 34],
  [24, 43, 5, 89, 130, 8],
  [39, 106, 5, 92, 7, 10],
  [20, 55, 5, 22, 64, 71],
  [12, 35, 5, 32, 33, 111],
  [12, 52, 5, 22, 18, 105],
  [39, 106, 5, 32, 124, 68],
  [12, 87, 5, 22, 75, 65],
  [46, 72, 5, 92, 33, 65],
  [3, 104, 5, 17, 82, 71],
  [46, 113, 5, 26, 73, 97],
  [16, 104, 5, 63, 82, 15],
  [3, 35, 5, 32, 75, 71],
  [20, 123, 5, 92, 94, 8],
  [16, 48, 5, 92, 18, 34],
  [24, 48, 5, 59, 62, 27],
  [46, 113, 5, 92, 124, 131],
  [12, 132, 5, 26, 7, 115],
  [16, 35, 5, 107, 64, 131],
  [30, 106, 5, 63, 130, 122],
  [46, 35, 5, 36, 100, 97],
  [30, 52, 5, 32, 94, 99],
  [16, 125, 5, 32, 44, 115],
  [16, 135, 5, 17, 7, 78],
  [39, 85, 5, 59, 49, 95],
  [46, 103, 5, 26, 18, 93],
  [3, 103, 5, 32, 14, 11],
  [30, 57, 5, 17, 82, 74],
  [46, 57, 5, 6, 18, 105],
  [24, 81, 5, 107, 18, 11],
  [3, 106, 5, 107, 18, 121],
  [12, 35, 5, 32, 14, 101],
  [3, 81, 5, 6, 18, 83],
  [3, 35, 5, 13, 100, 10],
  [3, 113, 5, 107, 100, 101],
  [30, 35, 5, 59, 29, 74],
  [39, 128, 5, 59, 29, 68],
  [16, 28, 5, 59, 7, 34],
  [12, 85, 5, 17, 82, 91],
  [30, 106, 5, 107, 18, 115],
  [12, 112, 5, 36, 14, 83],
  [46, 106, 5, 6, 82, 108],
  [3, 69, 5, 59, 114, 83],
  [20, 48, 5, 13, 75, 108],
  [46, 85, 5, 13, 90, 76],
  [30, 96, 5, 59, 33, 121],
  [39, 106, 5, 32, 7, 115],
  [3, 135, 5, 17, 18, 121],
  [3, 52, 5, 26, 18, 122],
  [3, 31, 5, 107, 44, 8],
  [46, 117, 5, 92, 7, 68],
  [20, 57, 5, 13, 90, 78],
  [24, 10, 5, 36, 82, 74],
  [39, 43, 5, 22, 75, 105],
  [16, 141, 5, 22, 82, 34],
  [24, 128, 5, 63, 18, 83],
  [24, 28, 5, 107, 54, 74],
  [20, 50, 5, 63, 49, 23],
  [24, 119, 5, 6, 18, 121],
  [3, 106, 5, 13, 114, 38],
  [16, 103, 5, 89, 75, 71],
  [39, 35, 5, 59, 73, 108],
  [39, 69, 5, 36, 18, 122],
  [16, 81, 5, 6, 75, 8],
  [16, 88, 5, 36, 33, 88],
  [16, 135, 5, 32, 54, 19],
  [20, 88, 5, 32, 73, 74],
  [39, 123, 5, 17, 44, 131],
  [30, 103, 5, 22, 75, 74],
  [12, 81, 5, 17, 130, 83],
  [20, 81, 5, 17, 75, 101],
  [24, 116, 5, 53, 44, 111],
  [3, 72, 5, 17, 37, 8],
  [3, 43, 5, 26, 64, 91],
  [16, 31, 5, 22, 100, 45],
  [46, 113, 5, 107, 124, 111],
  [12, 48, 5, 13, 90, 101],
  [16, 127, 5, 36, 44, 131],
  [3, 31, 5, 63, 7, 131],
  [16, 103, 5, 13, 62, 111],
  [30, 55, 5, 17, 124, 121],
  [20, 35, 5, 59, 90, 83],
  [16, 66, 5, 6, 94, 19],
  [24, 110, 5, 63, 29, 51],
  [16, 106, 5, 13, 10, 45],
  [12, 9, 5, 13, 7, 71],
  [39, 31, 5, 22, 82, 143],
  [30, 50, 5, 36, 29, 10],
  [20, 27, 5, 92, 18, 71],
  [39, 58, 5, 107, 54, 23],
  [3, 119, 5, 89, 56, 68],
  [39, 43, 5, 59, 73, 105],
  [39, 55, 5, 89, 82, 91],
  [24, 81, 5, 17, 56, 117],
  [30, 135, 5, 17, 102, 35],
  [39, 10, 5, 6, 44, 11],
  [20, 81, 5, 63, 62, 138],
  [3, 10, 5, 17, 7, 35],
  [24, 48, 5, 13, 18, 70],
  [16, 40, 5, 17, 114, 67],
  [46, 132, 5, 22, 90, 83],
  [12, 104, 5, 17, 114, 65],
  [24, 81, 5, 59, 102, 76],
  [46, 4, 5, 17, 64, 10],
  [24, 103, 5, 6, 18, 95],
  [20, 125, 5, 92, 18, 111],
  [3, 35, 5, 63, 73, 109],
  [16, 81, 5, 107, 100, 45],
  [3, 35, 5, 22, 90, 65],
  [24, 141, 5, 107, 75, 8],
  [16, 104, 5, 59, 10, 61],
  [12, 87, 5, 92, 64, 11],
  [12, 142, 5, 59, 98, 121],
  [3, 112, 5, 17, 130, 10],
  [30, 58, 5, 53, 90, 8],
  [3, 96, 5, 36, 10, 99],
  [12, 119, 5, 53, 18, 99],
  [46, 132, 5, 17, 64, 71],
  [20, 112, 5, 63, 14, 143],
  [16, 125, 5, 26, 54, 65],
  [46, 43, 5, 6, 114, 45],
  [39, 120, 5, 13, 114, 34],
  [24, 50, 5, 26, 75, 101],
  [20, 127, 5, 36, 54, 61],
  [39, 132, 5, 22, 18, 76],
  [12, 72, 5, 32, 90, 108],
  [46, 31, 5, 32, 73, 11],
  [39, 66, 5, 17, 94, 10],
  [16, 133, 5, 13, 7, 78],
  [3, 28, 5, 107, 18, 35],
  [3, 133, 5, 63, 44, 137],
  [16, 57, 5, 13, 37, 83],
  [12, 66, 5, 22, 18, 101],
  [24, 48, 5, 36, 54, 129],
  [16, 72, 5, 86, 75, 19],
  [3, 117, 5, 32, 7, 76],
  [16, 48, 5, 17, 18, 105],
  [3, 103, 5, 63, 56, 71],
  [20, 77, 5, 32, 10, 122],
  [39, 140, 5, 107, 54, 80],
  [12, 25, 5, 17, 94, 47],
  [46, 112, 5, 17, 10, 78],
  [16, 58, 5, 89, 10, 138],
  [20, 112, 5, 26, 90, 137],
  [46, 50, 5, 89, 18, 11],
  [30, 55, 5, 22, 114, 80],
  [16, 58, 5, 17, 37, 80],
  [24, 96, 5, 6, 64, 67],
  [16, 135, 5, 92, 29, 129],
  [16, 125, 5, 17, 64, 111],
  [16, 112, 5, 26, 37, 83],
  [46, 117, 5, 89, 54, 121],
  [20, 103, 5, 107, 64, 108],
  [12, 125, 5, 26, 75, 83],
  [3, 119, 5, 13, 82, 76],
  [20, 55, 5, 59, 130, 122],
  [24, 85, 5, 107, 7, 101],
  [16, 106, 5, 36, 37, 45],
  [3, 88, 5, 22, 33, 101],
  [46, 104, 5, 59, 114, 78],
  [30, 31, 5, 22, 18, 122],
  [46, 81, 5, 92, 100, 84],
  [20, 48, 5, 13, 62, 129],
  [24, 123, 5, 36, 54, 99],
  [24, 58, 5, 107, 44, 121],
  [24, 27, 5, 36, 98, 108],
  [20, 55, 5, 17, 44, 115],
  [30, 43, 5, 107, 18, 11],
  [30, 81, 5, 89, 73, 105],
  [12, 116, 5, 63, 82, 121],
  [12, 104, 5, 13, 73, 115],
  [39, 66, 5, 17, 62, 108],
  [39, 69, 5, 92, 18, 129],
  [3, 35, 5, 22, 18, 138],
  [12, 31, 5, 89, 10, 42],
  [20, 72, 5, 13, 29, 51],
  [30, 4, 5, 92, 62, 78],
  [39, 35, 5, 89, 54, 8],
  [46, 103, 5, 13, 29, 8],
  [12, 140, 5, 13, 10, 143],
  [30, 110, 5, 6, 62, 8],
  [46, 55, 5, 92, 62, 99],
  [3, 31, 5, 26, 33, 76],
  [16, 140, 5, 92, 114, 70],
  [46, 57, 5, 22, 114, 83],
  [12, 69, 5, 17, 18, 35],
  [30, 96, 5, 92, 90, 34],
  [3, 128, 5, 89, 44, 71],
  [30, 50, 5, 32, 18, 115],
  [30, 106, 5, 107, 56, 97],
  [24, 123, 5, 13, 62, 45],
  [16, 81, 5, 139, 62, 111],
  [20, 58, 5, 63, 64, 42],
  [39, 25, 5, 107, 75, 121],
  [12, 10, 5, 17, 75, 67],
  [3, 31, 5, 63, 18, 8],
  [16, 58, 5, 32, 73, 34],
  [46, 125, 5, 107, 33, 129],
  [16, 4, 5, 6, 37, 105],
  [39, 21, 5, 63, 62, 99],
  [24, 43, 5, 59, 62, 71],
  [3, 52, 5, 26, 7, 68],
  [30, 31, 5, 63, 18, 70],
  [39, 113, 5, 92, 75, 42],
  [30, 35, 5, 92, 102, 70],
  [20, 106, 5, 13, 56, 10],
  [24, 81, 5, 22, 18, 83],
  [30, 66, 5, 53, 94, 131],
  [12, 128, 5, 36, 62, 121],
  [12, 55, 5, 6, 64, 91],
  [3, 133, 5, 107, 29, 80],
  [39, 58, 5, 22, 44, 105],
  [46, 50, 5, 107, 64, 115],
  [3, 57, 5, 26, 75, 137],
  [20, 81, 5, 13, 18, 68],
  [39, 85, 5, 59, 33, 99],
  [46, 31, 5, 107, 18, 27],
  [16, 132, 5, 36, 10, 91],
  [20, 112, 5, 63, 33, 131],
  [24, 135, 5, 22, 64, 121],
  [24, 58, 5, 53, 18, 118],
  [3, 27, 5, 6, 7, 118],
  [3, 66, 5, 36, 98, 138],
  [24, 106, 5, 92, 29, 78],
  [30, 140, 5, 53, 94, 68],
  [12, 125, 5, 36, 73, 80],
  [30, 57, 5, 59, 18, 111],
  [20, 10, 5, 13, 18, 27],
  [16, 133, 5, 59, 54, 67],
  [30, 81, 5, 107, 18, 45],
  [12, 58, 5, 17, 56, 83],
  [3, 28, 5, 22, 82, 8],
  [20, 81, 5, 32, 18, 105],
  [16, 50, 5, 17, 44, 99],
  [46, 57, 5, 17, 124, 19],
  [3, 106, 5, 17, 82, 108],
  [30, 112, 5, 36, 130, 143],
  [16, 72, 5, 17, 33, 68],
  [39, 128, 5, 107, 18, 51],
  [16, 58, 5, 6, 64, 35],
  [39, 58, 5, 59, 29, 101],
  [39, 141, 5, 36, 18, 78],
  [12, 57, 5, 63, 49, 76],
  [39, 81, 5, 89, 62, 74],
  [20, 79, 5, 22, 44, 67],
  [3, 106, 5, 32, 64, 74],
  [39, 113, 5, 107, 18, 131],
  [3, 43, 5, 92, 114, 138],
  [3, 117, 5, 36, 29, 111],
  [24, 31, 5, 59, 102, 67],
  [12, 128, 5, 139, 33, 34],
  [3, 69, 5, 86, 64, 121],
  [39, 88, 5, 6, 54, 38],
  [20, 85, 5, 26, 54, 47],
  [3, 103, 5, 59, 10, 68],
  [30, 55, 5, 17, 44, 47],
  [30, 85, 5, 59, 130, 34],
  [16, 43, 5, 17, 73, 115],
  [46, 50, 5, 32, 94, 117],
  [12, 88, 5, 32, 18, 19],
  [16, 81, 5, 6, 10, 78],
  [16, 87, 5, 22, 18, 68],
  [3, 48, 5, 6, 18, 97],
  [46, 58, 5, 107, 44, 76],
  [46, 106, 5, 63, 64, 74],
  [20, 140, 5, 36, 75, 47],
  [12, 28, 5, 22, 18, 108],
  [20, 28, 5, 26, 10, 108],
  [16, 28, 5, 17, 49, 45],
  [20, 104, 5, 13, 102, 101],
  [3, 81, 5, 59, 18, 11],
  [3, 79, 5, 13, 10, 101],
  [39, 57, 5, 107, 44, 99],
  [3, 77, 5, 17, 44, 115],
  [30, 113, 5, 89, 94, 51],
  [20, 125, 5, 107, 64, 111],
  [24, 27, 5, 6, 33, 47],
  [24, 106, 5, 89, 18, 83],
  [3, 27, 5, 139, 102, 117],
  [30, 104, 5, 17, 114, 10],
  [3, 58, 5, 63, 18, 99],
  [46, 50, 5, 6, 18, 108],
  [12, 119, 5, 6, 18, 51],
  [24, 57, 5, 63, 18, 101],
  [16, 57, 5, 17, 82, 47],
  [30, 106, 5, 92, 130, 131],
  [24, 125, 5, 17, 82, 121],
  [46, 104, 5, 22, 94, 71],
  [39, 110, 5, 32, 75, 78],
  [12, 113, 5, 36, 73, 91],
  [24, 43, 5, 22, 102, 78],
  [3, 66, 5, 107, 37, 78],
  [39, 50, 5, 86, 18, 95],
  [24, 141, 5, 13, 37, 117],
  [39, 66, 5, 36, 64, 78],
  [16, 117, 5, 107, 98, 71],
  [20, 85, 5, 107, 44, 78],
  [39, 43, 5, 17, 29, 137],
  [39, 31, 5, 13, 29, 70],
  [20, 119, 5, 59, 10, 129],
  [3, 66, 5, 26, 29, 134],
  [24, 57, 5, 63, 100, 115],
  [46, 103, 5, 63, 62, 11],
  [20, 72, 5, 6, 73, 67],
  [16, 140, 5, 22, 18, 38],
  [30, 4, 5, 17, 18, 129],
  [3, 113, 5, 17, 90, 91],
  [39, 96, 5, 89, 18, 11],
  [24, 96, 5, 59, 18, 8],
  [16, 21, 5, 13, 75, 108],
  [30, 77, 5, 6, 75, 115],
  [12, 72, 5, 36, 114, 27],
  [16, 10, 5, 26, 114, 67],
  [30, 96, 5, 26, 44, 45],
  [46, 66, 5, 92, 44, 83],
  [16, 79, 5, 36, 29, 71],
  [46, 4, 5, 107, 18, 11],
  [16, 112, 5, 92, 114, 115],
  [3, 58, 5, 13, 18, 8],
  [16, 69, 5, 36, 54, 99],
  [12, 112, 5, 26, 37, 101],
  [46, 81, 5, 63, 62, 76],
  [30, 85, 5, 17, 18, 8],
  [46, 120, 5, 36, 44, 138],
  [12, 81, 5, 26, 14, 67],
  [46, 112, 5, 22, 124, 131],
  [20, 55, 5, 26, 18, 91],
  [39, 87, 5, 36, 102, 76],
  [3, 57, 5, 17, 14, 34],
  [16, 106, 5, 36, 44, 42],
  [39, 103, 5, 53, 10, 45],
  [39, 66, 5, 26, 56, 115],
  [16, 43, 5, 13, 62, 65],
  [20, 103, 5, 26, 14, 74],
  [24, 106, 5, 22, 64, 38],
  [12, 103, 5, 107, 102, 47],
  [16, 112, 5, 107, 33, 137],
  [12, 85, 5, 6, 18, 88],
  [16, 119, 5, 6, 33, 10],
  [46, 31, 5, 36, 29, 8],
  [30, 116, 5, 32, 14, 76],
  [30, 132, 5, 32, 10, 122],
  [30, 85, 5, 17, 102, 95],
  [16, 43, 5, 89, 18, 80],
  [3, 125, 5, 63, 18, 23],
  [16, 35, 5, 89, 64, 34],
  [30, 50, 5, 17, 29, 42],
  [16, 25, 5, 17, 44, 122],
  [20, 57, 5, 92, 94, 101],
  [3, 55, 5, 26, 18, 34],
  [3, 52, 5, 32, 18, 34],
  [3, 57, 5, 36, 29, 111],
  [24, 132, 5, 107, 10, 121],
  [3, 55, 5, 6, 56, 68],
  [3, 69, 5, 17, 18, 108],
  [39, 81, 5, 92, 56, 122],
  [16, 57, 5, 6, 18, 111],
  [46, 50, 5, 17, 7, 65],
  [16, 58, 5, 92, 29, 68],
  [16, 85, 5, 92, 7, 122],
  [20, 55, 5, 89, 49, 35],
  [12, 87, 5, 89, 114, 11],
  [39, 35, 5, 36, 18, 11],
  [20, 142, 5, 89, 64, 35],
  [39, 141, 5, 92, 54, 71],
  [20, 9, 5, 17, 18, 8],
  [24, 66, 5, 92, 130, 34],
  [3, 25, 5, 22, 60, 118],
  [39, 50, 5, 13, 54, 105],
  [30, 113, 5, 32, 102, 122],
  [30, 50, 5, 32, 82, 126],
  [24, 31, 5, 17, 7, 51],
  [3, 96, 5, 32, 64, 91],
  [30, 117, 5, 53, 94, 121],
  [24, 25, 5, 17, 18, 78],
  [12, 120, 5, 22, 102, 67],
  [24, 81, 5, 92, 18, 97],
  [30, 57, 5, 13, 14, 68],
  [30, 55, 5, 36, 44, 68],
  [3, 106, 5, 92, 18, 65],
  [20, 106, 5, 17, 49, 65],
  [16, 55, 5, 17, 18, 91],
  [3, 116, 5, 107, 94, 134],
  [3, 87, 5, 92, 44, 65],
  [16, 50, 5, 6, 18, 45],
  [30, 112, 5, 107, 29, 68],
  [12, 4, 5, 59, 18, 126],
  [24, 52, 5, 107, 102, 95],
  [46, 119, 5, 26, 37, 131],
  [39, 25, 5, 22, 18, 91],
  [20, 142, 5, 32, 44, 70],
  [16, 117, 5, 63, 100, 99],
  [12, 119, 5, 107, 10, 35],
  [16, 58, 5, 6, 7, 83],
  [16, 142, 5, 13, 114, 137],
  [3, 31, 5, 22, 14, 8],
  [46, 96, 5, 107, 44, 97],
  [3, 141, 5, 6, 49, 68],
  [30, 132, 5, 6, 37, 138],
  [3, 104, 5, 63, 49, 99],
  [20, 69, 5, 32, 64, 131],
  [39, 43, 5, 107, 18, 84],
  [24, 104, 5, 26, 90, 131],
  [39, 106, 5, 36, 100, 76],
  [12, 85, 5, 17, 37, 76],
  [46, 28, 5, 13, 29, 93],
  [30, 52, 5, 86, 14, 68],
  [3, 128, 5, 92, 114, 45],
  [3, 96, 5, 63, 44, 101],
  [39, 87, 5, 59, 102, 51],
  [39, 9, 5, 92, 94, 115],
  [30, 135, 5, 13, 37, 111],
  [12, 125, 5, 26, 10, 131],
  [46, 113, 5, 17, 33, 122],
  [46, 104, 5, 36, 90, 10],
  [16, 96, 5, 6, 82, 78],
  [3, 103, 5, 17, 56, 42],
  [30, 96, 5, 107, 114, 67],
  [39, 142, 5, 22, 100, 122],
  [3, 55, 5, 92, 37, 35],
  [39, 133, 5, 17, 100, 105],
  [3, 66, 5, 107, 100, 51],
  [3, 25, 5, 22, 33, 137],
  [16, 135, 5, 17, 62, 10],
  [30, 81, 5, 26, 14, 88],
  [12, 43, 5, 63, 100, 91],
  [3, 117, 5, 107, 29, 122],
  [12, 50, 5, 22, 18, 83],
  [3, 31, 5, 32, 7, 45],
  [24, 58, 5, 89, 29, 47],
  [16, 72, 5, 32, 62, 34],
  [12, 55, 5, 86, 130, 111],
  [16, 57, 5, 36, 54, 83],
  [16, 77, 5, 53, 75, 108],
  [46, 55, 5, 32, 94, 108],
  [12, 132, 5, 89, 37, 76],
  [46, 96, 5, 53, 73, 34],
  [16, 25, 5, 59, 10, 78],
  [12, 119, 5, 17, 7, 10],
  [24, 113, 5, 63, 94, 23],
  [20, 43, 5, 53, 75, 74],
  [20, 66, 5, 53, 94, 65],
  [3, 40, 5, 63, 56, 115],
  [12, 135, 5, 17, 100, 93],
  [16, 43, 5, 107, 56, 78],
  [39, 135, 5, 107, 7, 105],
  [12, 69, 5, 6, 37, 111],
  [39, 135, 5, 36, 82, 23],
  [20, 132, 5, 107, 82, 42],
  [3, 96, 5, 17, 114, 47],
  [39, 43, 5, 59, 64, 101],
  [3, 72, 5, 17, 124, 42],
  [3, 113, 5, 17, 94, 95],
  [39, 66, 5, 26, 102, 10],
  [20, 69, 5, 107, 18, 8],
  [16, 27, 5, 17, 18, 80],
  [20, 127, 5, 26, 18, 84],
  [16, 35, 5, 107, 102, 80],
  [16, 31, 5, 6, 82, 74],
  [20, 106, 5, 59, 7, 61],
  [39, 27, 5, 59, 18, 122],
  [39, 113, 5, 107, 49, 34],
  [30, 116, 5, 107, 33, 131],
  [46, 27, 5, 63, 94, 122],
  [12, 35, 5, 26, 54, 34],
  [30, 119, 5, 36, 73, 34],
  [3, 31, 5, 13, 49, 108],
  [16, 79, 5, 53, 102, 99],
  [30, 28, 5, 53, 29, 121],
  [30, 96, 5, 63, 90, 15],
  [16, 28, 5, 22, 90, 10],
  [3, 48, 5, 59, 37, 122],
  [39, 110, 5, 107, 54, 74],
  [20, 132, 5, 63, 18, 10],
  [20, 31, 5, 107, 37, 76],
  [16, 58, 5, 26, 18, 51],
  [3, 106, 5, 53, 18, 93],
  [3, 43, 5, 36, 130, 42],
  [16, 103, 5, 17, 73, 74],
  [12, 119, 5, 139, 18, 99],
  [30, 31, 5, 89, 14, 83],
  [20, 69, 5, 32, 18, 76],
  [16, 87, 5, 59, 10, 118],
  [30, 123, 5, 13, 18, 8],
  [46, 66, 5, 59, 73, 70],
  [39, 48, 5, 59, 18, 8],
  [24, 69, 5, 53, 7, 111],
  [39, 28, 5, 36, 90, 68],
  [3, 103, 5, 63, 49, 34],
  [20, 55, 5, 63, 102, 105],
  [46, 9, 5, 26, 7, 105],
  [12, 31, 5, 53, 37, 65],
  [20, 35, 5, 13, 18, 10],
  [46, 106, 5, 53, 18, 65],
  [3, 66, 5, 22, 102, 93],
  [16, 28, 5, 92, 18, 115],
  [3, 81, 5, 13, 75, 47],
  [20, 133, 5, 63, 73, 108],
  [12, 87, 5, 86, 94, 8],
  [16, 66, 5, 59, 56, 10],
  [24, 48, 5, 36, 29, 71],
  [39, 35, 5, 22, 114, 71],
  [16, 85, 5, 63, 14, 115],
  [3, 50, 5, 107, 75, 121],
  [20, 69, 5, 107, 62, 35],
  [3, 106, 5, 63, 100, 78],
  [16, 52, 5, 17, 73, 71],
  [30, 119, 5, 6, 73, 99],
  [46, 110, 5, 63, 29, 111],
  [39, 9, 5, 92, 73, 74],
  [3, 50, 5, 53, 18, 8],
  [39, 58, 5, 89, 18, 65],
  [3, 110, 5, 89, 114, 83],
  [39, 88, 5, 17, 102, 131],
  [20, 43, 5, 89, 62, 74],
  [24, 21, 5, 13, 33, 71],
  [39, 96, 5, 17, 94, 134],
  [16, 88, 5, 92, 18, 11],
  [46, 103, 5, 13, 130, 108],
  [24, 140, 5, 36, 54, 101],
  [20, 81, 5, 139, 29, 76],
  [16, 72, 5, 92, 64, 42],
  [30, 10, 5, 36, 100, 10],
  [30, 106, 5, 107, 18, 134],
  [3, 96, 5, 59, 18, 71],
  [20, 55, 5, 92, 100, 101],
  [16, 10, 5, 89, 7, 91],
  [12, 48, 5, 36, 130, 83],
  [16, 117, 5, 22, 75, 71],
  [20, 48, 5, 89, 7, 42],
  [3, 57, 5, 26, 18, 45],
  [3, 52, 5, 22, 18, 8],
  [12, 85, 5, 59, 18, 99],
  [12, 119, 5, 107, 18, 105],
  [30, 120, 5, 17, 54, 35],
  [46, 58, 5, 92, 73, 74],
  [16, 66, 5, 36, 54, 80],
  [3, 57, 5, 22, 94, 91],
  [46, 103, 5, 17, 73, 70],
  [20, 27, 5, 53, 49, 108],
  [46, 96, 5, 22, 62, 65],
  [3, 35, 5, 6, 44, 137],
  [30, 132, 5, 26, 102, 35],
  [12, 43, 5, 53, 130, 93],
  [16, 135, 5, 17, 56, 47],
  [24, 58, 5, 6, 33, 88],
  [3, 132, 5, 59, 94, 68],
  [46, 113, 5, 92, 64, 84],
  [20, 43, 5, 22, 73, 84],
  [20, 55, 5, 36, 18, 74],
  [16, 123, 5, 32, 18, 38],
  [12, 55, 5, 107, 56, 99],
  [12, 103, 5, 53, 56, 108],
  [12, 85, 5, 107, 29, 76],
  [46, 66, 5, 32, 100, 105],
  [24, 96, 5, 6, 7, 71],
  [30, 106, 5, 107, 18, 83],
  [20, 28, 5, 22, 7, 78],
  [3, 79, 5, 22, 18, 51],
  [20, 106, 5, 89, 114, 131],
  [46, 119, 5, 107, 130, 101],
  [16, 133, 5, 53, 100, 121],
  [3, 55, 5, 13, 7, 38],
  [16, 72, 5, 89, 7, 76],
  [46, 106, 5, 17, 54, 67],
  [12, 50, 5, 36, 10, 109],
  [46, 85, 5, 107, 37, 11],
  [3, 43, 5, 107, 18, 10],
  [12, 119, 5, 107, 18, 83],
  [12, 85, 5, 17, 130, 121],
  [12, 28, 5, 13, 18, 97],
  [46, 119, 5, 36, 33, 138],
  [39, 31, 5, 63, 102, 99],
  [20, 113, 5, 13, 64, 42],
  [24, 58, 5, 17, 14, 15],
  [24, 50, 5, 6, 56, 80],
  [20, 48, 5, 17, 62, 78],
  [24, 110, 5, 13, 64, 74],
  [30, 104, 5, 22, 18, 80],
  [39, 128, 5, 13, 33, 138],
  [16, 133, 5, 6, 33, 108],
  [30, 31, 5, 17, 10, 45],
  [39, 58, 5, 36, 18, 74],
  [46, 128, 5, 6, 7, 65],
  [12, 58, 5, 26, 62, 27],
  [16, 81, 5, 17, 100, 121],
  [12, 112, 5, 63, 33, 117],
  [3, 106, 5, 36, 54, 8],
  [16, 50, 5, 17, 14, 51],
  [39, 57, 5, 17, 18, 84],
  [3, 48, 5, 63, 114, 122],
  [39, 106, 5, 17, 90, 65],
  [46, 31, 5, 13, 90, 97],
  [12, 50, 5, 92, 29, 67],
  [30, 119, 5, 89, 18, 111],
  [16, 66, 5, 107, 90, 131],
  [46, 113, 5, 107, 114, 121],
  [20, 85, 5, 26, 100, 47],
  [24, 125, 5, 59, 18, 10],
  [3, 119, 5, 36, 44, 80],
  [3, 31, 5, 32, 102, 131],
  [20, 104, 5, 92, 130, 45],
  [12, 128, 5, 107, 90, 99],
  [20, 79, 5, 89, 56, 117],
  [46, 106, 5, 36, 18, 34],
  [46, 106, 5, 107, 90, 34],
  [3, 87, 5, 13, 100, 105],
  [12, 119, 5, 17, 18, 35],
  [16, 28, 5, 6, 18, 121],
  [30, 72, 5, 139, 29, 101],
  [46, 81, 5, 26, 64, 68],
  [16, 112, 5, 6, 56, 108],
  [24, 27, 5, 63, 29, 78],
  [24, 133, 5, 92, 100, 70],
  [24, 81, 5, 53, 37, 47],
  [20, 69, 5, 13, 7, 10],
  [20, 52, 5, 22, 14, 67],
  [24, 96, 5, 107, 102, 111],
  [16, 81, 5, 107, 33, 65],
  [24, 113, 5, 26, 75, 121],
  [3, 48, 5, 26, 100, 70],
  [16, 96, 5, 6, 54, 115],
  [3, 133, 5, 26, 102, 74],
  [30, 25, 5, 92, 64, 78],
  [12, 55, 5, 92, 7, 121],
  [16, 128, 5, 32, 18, 71],
  [30, 135, 5, 17, 18, 80],
  [46, 48, 5, 59, 29, 45],
  [30, 55, 5, 36, 100, 65],
  [3, 28, 5, 63, 10, 47],
  [12, 116, 5, 92, 29, 19],
  [20, 96, 5, 36, 44, 65],
  [3, 55, 5, 13, 18, 109],
  [30, 58, 5, 107, 64, 70],
  [16, 25, 5, 13, 7, 35],
  [3, 58, 5, 17, 56, 111],
  [39, 135, 5, 17, 29, 76],
  [20, 66, 5, 32, 94, 71],
  [12, 81, 5, 36, 56, 47],
  [12, 66, 5, 26, 14, 71],
  [46, 81, 5, 13, 64, 71],
  [46, 58, 5, 22, 130, 70],
  [46, 28, 5, 92, 7, 91],
  [30, 135, 5, 17, 62, 34],
  [39, 28, 5, 59, 54, 118],
  [46, 58, 5, 63, 29, 23],
  [39, 142, 5, 26, 18, 83],
  [3, 69, 5, 22, 62, 84],
  [16, 66, 5, 13, 7, 78],
  [46, 10, 5, 89, 62, 115],
  [20, 87, 5, 17, 29, 35],
  [3, 113, 5, 13, 100, 65],
  [46, 57, 5, 107, 75, 78],
  [16, 31, 5, 32, 37, 47],
  [20, 72, 5, 107, 54, 27],
  [39, 35, 5, 92, 54, 134],
  [30, 35, 5, 36, 62, 47],
  [12, 66, 5, 89, 44, 38],
  [16, 128, 5, 17, 44, 91],
  [46, 66, 5, 63, 18, 45],
  [39, 57, 5, 53, 54, 143],
  [20, 40, 5, 36, 90, 118],
  [24, 113, 5, 89, 37, 80],
  [20, 112, 5, 22, 37, 97],
  [24, 55, 5, 26, 64, 138],
  [20, 104, 5, 89, 64, 70],
  [16, 57, 5, 26, 56, 97],
  [24, 133, 5, 89, 54, 74],
  [46, 119, 5, 13, 7, 111],
  [16, 43, 5, 6, 49, 78],
  [16, 52, 5, 63, 94, 121],
  [30, 106, 5, 32, 54, 45],
  [30, 48, 5, 22, 56, 71],
  [39, 119, 5, 89, 18, 101],
  [20, 31, 5, 36, 10, 121],
  [16, 132, 5, 36, 56, 78],
  [46, 48, 5, 32, 102, 99],
  [24, 48, 5, 59, 33, 131],
  [16, 28, 5, 59, 62, 122],
  [20, 55, 5, 22, 10, 35],
  [12, 57, 5, 22, 130, 99],
  [16, 52, 5, 63, 14, 111],
  [16, 106, 5, 36, 54, 10],
  [3, 79, 5, 86, 75, 105],
  [30, 81, 5, 107, 102, 71],
  [16, 69, 5, 89, 56, 99],
  [16, 116, 5, 86, 18, 10],
  [16, 4, 5, 32, 14, 83],
  [20, 25, 5, 89, 54, 68],
  [24, 127, 5, 59, 90, 70],
  [30, 58, 5, 63, 10, 118],
  [3, 106, 5, 92, 100, 105],
  [24, 28, 5, 32, 10, 76],
  [30, 88, 5, 86, 75, 45],
  [12, 58, 5, 6, 102, 11],
  [46, 103, 5, 53, 114, 71],
  [46, 52, 5, 6, 18, 126],
  [46, 69, 5, 22, 14, 68],
  [30, 31, 5, 26, 64, 122],
  [39, 104, 5, 13, 100, 8],
  [12, 55, 5, 92, 62, 8],
  [20, 113, 5, 22, 49, 80],
  [3, 81, 5, 53, 29, 122],
  [46, 142, 5, 6, 124, 138],
  [16, 113, 5, 13, 82, 10],
  [20, 48, 5, 17, 100, 97],
  [20, 31, 5, 17, 82, 19],
  [12, 135, 5, 63, 18, 67],
  [39, 31, 5, 13, 10, 138],
  [20, 113, 5, 107, 73, 111],
  [30, 48, 5, 26, 54, 27],
  [46, 133, 5, 59, 18, 34],
  [16, 27, 5, 59, 94, 74],
  [46, 72, 5, 6, 29, 111],
  [24, 106, 5, 59, 18, 34],
  [39, 96, 5, 59, 64, 71],
  [30, 112, 5, 13, 130, 80],
  [30, 25, 5, 92, 14, 101],
  [12, 21, 5, 63, 100, 71],
  [30, 113, 5, 89, 90, 47],
  [20, 25, 5, 26, 102, 10],
  [12, 35, 5, 59, 44, 15],
  [39, 132, 5, 17, 73, 47],
  [16, 104, 5, 89, 54, 65],
  [20, 133, 5, 107, 33, 76],
  [20, 119, 5, 26, 64, 109],
  [46, 55, 5, 32, 14, 95],
  [46, 66, 5, 17, 10, 78],
  [3, 69, 5, 36, 82, 45],
  [3, 117, 5, 17, 73, 111],
  [39, 87, 5, 53, 94, 80],
  [12, 66, 5, 107, 18, 8],
  [46, 72, 5, 92, 10, 51],
  [39, 96, 5, 17, 102, 121],
  [46, 96, 5, 13, 18, 108],
  [3, 120, 5, 89, 37, 99],
  [39, 119, 5, 13, 130, 137],
  [20, 85, 5, 17, 90, 76],
  [12, 117, 5, 22, 18, 99],
  [12, 106, 5, 17, 18, 78],
  [46, 35, 5, 6, 56, 45],
  [16, 135, 5, 6, 75, 35],
  [3, 69, 5, 107, 18, 91],
  [3, 103, 5, 63, 62, 129],
  [46, 31, 5, 86, 44, 38],
  [30, 88, 5, 107, 29, 68],
  [3, 66, 5, 13, 54, 108],
  [16, 50, 5, 13, 14, 108],
  [3, 10, 5, 139, 14, 93],
  [12, 50, 5, 63, 7, 105],
  [3, 103, 5, 107, 64, 35],
  [12, 110, 5, 17, 18, 131],
  [3, 52, 5, 6, 102, 8],
  [16, 28, 5, 13, 18, 134],
  [46, 106, 5, 22, 62, 74],
  [24, 72, 5, 89, 7, 99],
  [3, 28, 5, 26, 82, 65],
  [16, 66, 5, 17, 64, 70],
  [20, 35, 5, 107, 82, 91],
  [30, 69, 5, 26, 102, 76],
  [39, 35, 5, 92, 37, 76],
  [3, 141, 5, 6, 18, 105],
  [30, 48, 5, 13, 75, 8],
  [30, 10, 5, 63, 44, 74],
  [39, 106, 5, 59, 18, 101],
  [3, 4, 5, 22, 14, 115],
  [3, 112, 5, 63, 73, 23],
  [20, 135, 5, 36, 44, 117],
  [12, 87, 5, 107, 130, 99],
  [16, 87, 5, 92, 64, 115],
  [39, 66, 5, 13, 102, 108],
  [12, 27, 5, 13, 73, 67],
  [39, 113, 5, 63, 75, 122],
  [16, 116, 5, 13, 102, 8],
  [3, 113, 5, 53, 75, 71],
  [16, 103, 5, 17, 37, 83],
  [24, 55, 5, 26, 18, 97],
  [46, 81, 5, 22, 18, 74],
  [3, 112, 5, 107, 37, 111],
  [30, 96, 5, 107, 64, 131],
  [30, 103, 5, 89, 62, 47],
  [12, 31, 5, 63, 73, 68],
  [12, 81, 5, 17, 10, 91],
  [30, 43, 5, 26, 56, 8],
  [16, 96, 5, 59, 114, 35],
  [12, 142, 5, 17, 29, 80],
  [39, 58, 5, 26, 7, 67],
  [12, 110, 5, 26, 90, 15],
  [46, 113, 5, 89, 18, 67],
  [16, 104, 5, 63, 33, 101],
  [16, 119, 5, 89, 90, 101],
  [39, 27, 5, 63, 64, 71],
  [24, 123, 5, 107, 33, 35],
  [30, 35, 5, 107, 114, 137],
  [30, 81, 5, 6, 18, 47],
  [12, 87, 5, 59, 75, 67],
  [46, 133, 5, 17, 98, 78],
  [12, 103, 5, 22, 94, 65],
  [46, 85, 5, 107, 100, 101],
  [39, 132, 5, 13, 90, 115],
  [46, 35, 5, 59, 130, 80],
  [46, 58, 5, 53, 64, 91],
  [20, 113, 5, 26, 14, 80],
  [12, 119, 5, 17, 130, 84],
  [30, 96, 5, 107, 7, 121],
  [24, 117, 5, 107, 102, 121],
  [24, 55, 5, 36, 73, 51],
  [24, 43, 5, 53, 18, 51],
  [16, 55, 5, 86, 44, 137],
  [46, 112, 5, 53, 62, 111],
  [39, 77, 5, 86, 54, 76],
  [24, 28, 5, 17, 130, 42],
  [16, 57, 5, 17, 10, 129],
  [46, 57, 5, 17, 18, 101],
  [24, 85, 5, 6, 56, 105],
  [16, 133, 5, 63, 64, 118],
  [39, 66, 5, 22, 90, 67],
  [39, 141, 5, 26, 14, 8],
  [20, 119, 5, 32, 62, 78],
  [24, 31, 5, 92, 100, 38],
  [46, 81, 5, 36, 33, 129],
  [20, 104, 5, 53, 14, 111],
  [20, 50, 5, 36, 73, 78],
  [3, 128, 5, 13, 54, 115],
  [3, 106, 5, 17, 82, 91],
  [3, 88, 5, 6, 37, 10],
  [3, 87, 5, 36, 54, 111],
  [46, 43, 5, 36, 100, 47],
  [12, 88, 5, 6, 18, 80],
  [3, 104, 5, 92, 100, 95],
  [12, 128, 5, 92, 102, 126],
  [3, 133, 5, 22, 33, 65],
  [24, 9, 5, 59, 100, 137],
  [3, 66, 5, 17, 82, 95],
  [16, 66, 5, 92, 56, 68],
  [3, 125, 5, 89, 10, 8],
  [24, 58, 5, 13, 62, 65],
  [3, 25, 5, 36, 18, 137],
  [12, 28, 5, 36, 62, 11],
  [46, 135, 5, 89, 18, 34],
  [16, 133, 5, 32, 114, 11],
  [46, 25, 5, 17, 130, 88],
  [24, 58, 5, 53, 7, 131],
  [46, 4, 5, 36, 94, 70],
  [46, 28, 5, 17, 18, 74],
  [16, 103, 5, 22, 64, 47],
  [24, 113, 5, 22, 62, 61],
  [46, 50, 5, 13, 64, 38],
  [39, 106, 5, 22, 18, 99],
  [30, 79, 5, 92, 18, 80],
  [39, 52, 5, 63, 18, 91],
  [39, 104, 5, 89, 64, 91],
  [46, 43, 5, 63, 100, 23],
  [3, 96, 5, 36, 33, 95],
  [24, 81, 5, 32, 14, 34],
  [30, 113, 5, 63, 75, 27],
  [16, 50, 5, 26, 54, 71],
  [16, 55, 5, 107, 130, 47],
  [24, 87, 5, 36, 130, 34],
  [20, 104, 5, 22, 124, 121],
  [16, 104, 5, 59, 14, 45],
  [39, 31, 5, 6, 90, 131],
  [3, 119, 5, 107, 49, 27],
  [20, 28, 5, 22, 18, 101],
  [39, 35, 5, 92, 29, 65],
  [3, 104, 5, 107, 29, 15],
  [30, 96, 5, 17, 18, 111],
  [16, 35, 5, 6, 100, 99],
  [39, 113, 5, 13, 29, 68],
  [30, 66, 5, 22, 7, 11],
  [12, 48, 5, 6, 10, 121],
  [20, 104, 5, 59, 75, 34],
  [12, 48, 5, 107, 75, 122],
  [3, 81, 5, 17, 7, 121],
  [46, 28, 5, 63, 102, 11],
  [46, 140, 5, 17, 54, 74],
  [12, 119, 5, 32, 14, 65],
  [39, 57, 5, 6, 44, 35],
  [16, 66, 5, 53, 94, 99],
  [12, 69, 5, 17, 18, 8],
  [12, 48, 5, 107, 102, 126],
  [16, 55, 5, 107, 64, 131],
  [3, 88, 5, 92, 73, 99],
  [30, 66, 5, 22, 18, 115],
  [46, 48, 5, 22, 18, 137],
  [39, 66, 5, 107, 82, 34],
  [16, 35, 5, 32, 90, 97],
  [12, 40, 5, 86, 75, 122],
  [30, 55, 5, 17, 114, 34],
  [30, 58, 5, 63, 94, 51],
  [16, 55, 5, 89, 7, 68],
  [12, 112, 5, 139, 100, 122],
  [39, 96, 5, 59, 56, 80],
  [39, 104, 5, 63, 75, 99],
  [3, 48, 5, 53, 33, 109],
  [12, 103, 5, 89, 18, 122],
  [20, 69, 5, 6, 37, 11],
  [39, 132, 5, 59, 18, 45],
  [30, 132, 5, 6, 18, 138],
  [24, 120, 5, 17, 100, 105],
  [46, 104, 5, 36, 18, 101],
  [3, 9, 5, 63, 29, 8],
  [46, 103, 5, 92, 90, 34],
  [16, 85, 5, 26, 94, 8],
  [46, 85, 5, 89, 56, 65],
  [30, 85, 5, 107, 14, 105],
  [3, 58, 5, 92, 73, 115],
  [12, 35, 5, 92, 18, 95],
  [12, 110, 5, 13, 130, 121],
  [46, 119, 5, 13, 10, 121],
  [30, 58, 5, 107, 14, 65],
  [20, 69, 5, 53, 33, 38],
  [16, 128, 5, 107, 64, 76],
  [39, 72, 5, 59, 90, 68],
  [16, 117, 5, 53, 130, 45],
  [3, 106, 5, 13, 54, 84],
  [39, 135, 5, 13, 18, 27],
  [39, 69, 5, 36, 18, 118],
  [20, 96, 5, 92, 102, 80],
  [20, 69, 5, 6, 56, 108],
  [30, 112, 5, 13, 75, 47],
  [46, 104, 5, 89, 100, 70],
  [20, 79, 5, 13, 64, 91],
  [12, 35, 5, 107, 62, 10],
  [30, 48, 5, 92, 102, 122],
  [39, 48, 5, 32, 62, 10],
  [3, 10, 5, 107, 33, 134],
  [3, 116, 5, 92, 124, 78],
  [46, 58, 5, 63, 18, 78],
  [39, 110, 5, 63, 100, 65],
  [46, 9, 5, 36, 56, 74],
  [30, 96, 5, 13, 90, 118],
  [3, 128, 5, 36, 10, 8],
  [3, 57, 5, 22, 33, 84],
  [16, 35, 5, 36, 7, 42],
  [3, 50, 5, 26, 98, 121],
  [12, 116, 5, 32, 7, 78],
  [30, 28, 5, 36, 130, 51],
  [46, 132, 5, 17, 7, 83],
  [12, 28, 5, 63, 44, 122],
  [16, 35, 5, 53, 62, 10],
  [20, 104, 5, 92, 62, 70],
  [20, 77, 5, 92, 18, 93],
  [12, 43, 5, 26, 130, 143],
  [3, 96, 5, 59, 82, 109],
  [46, 58, 5, 17, 100, 76],
  [3, 117, 5, 36, 62, 111],
  [24, 133, 5, 89, 94, 65],
  [46, 106, 5, 107, 73, 78],
  [3, 103, 5, 107, 114, 101],
  [12, 66, 5, 63, 64, 65],
  [20, 110, 5, 92, 94, 11],
  [46, 66, 5, 26, 18, 105],
  [16, 96, 5, 32, 102, 35],
  [46, 103, 5, 92, 7, 15],
  [3, 123, 5, 17, 33, 78],
  [3, 35, 5, 36, 94, 8],
  [30, 48, 5, 92, 10, 111],
  [3, 72, 5, 32, 18, 38],
  [12, 55, 5, 17, 14, 74],
  [46, 125, 5, 22, 14, 93],
  [20, 31, 5, 53, 94, 91],
  [20, 119, 5, 36, 18, 117],
  [30, 31, 5, 17, 18, 45],
  [20, 57, 5, 107, 49, 122],
  [46, 117, 5, 13, 49, 115],
  [30, 31, 5, 92, 114, 131],
  [20, 66, 5, 107, 62, 11],
  [30, 35, 5, 17, 64, 74],
  [30, 96, 5, 17, 102, 134],
  [16, 132, 5, 59, 41, 68],
  [16, 10, 5, 89, 14, 91],
  [20, 113, 5, 6, 29, 74],
  [20, 117, 5, 89, 73, 117],
  [16, 81, 5, 89, 7, 65],
  [46, 79, 5, 92, 18, 76],
  [12, 43, 5, 6, 14, 74],
  [30, 85, 5, 36, 18, 8],
  [20, 10, 5, 17, 18, 8],
  [46, 52, 5, 107, 130, 121],
  [24, 48, 5, 6, 10, 111],
  [20, 116, 5, 13, 33, 111],
  [39, 81, 5, 17, 62, 122],
  [20, 110, 5, 6, 10, 10],
  [46, 50, 5, 53, 18, 108],
  [16, 28, 5, 13, 94, 131],
  [20, 9, 5, 32, 18, 8],
  [30, 50, 5, 36, 49, 101],
  [39, 106, 5, 32, 44, 84],
  [24, 48, 5, 32, 82, 108],
  [30, 35, 5, 53, 18, 80],
  [30, 103, 5, 63, 73, 99],
  [46, 43, 5, 32, 14, 111],
  [12, 104, 5, 59, 54, 108],
  [3, 96, 5, 26, 56, 97],
  [24, 104, 5, 22, 102, 71],
  [16, 116, 5, 26, 75, 76],
  [20, 132, 5, 36, 94, 71],
  [20, 128, 5, 22, 56, 134],
  [3, 50, 5, 6, 18, 74],
  [12, 113, 5, 63, 130, 122],
  [16, 112, 5, 32, 18, 70],
  [20, 72, 5, 36, 56, 42],
  [16, 113, 5, 63, 75, 34],
  [3, 55, 5, 107, 82, 34],
  [24, 10, 5, 107, 64, 67],
  [39, 52, 5, 13, 100, 71],
  [24, 48, 5, 26, 18, 71],
  [16, 132, 5, 26, 73, 11],
  [20, 104, 5, 22, 18, 111],
  [16, 55, 5, 89, 90, 115],
  [30, 57, 5, 36, 62, 137],
  [16, 96, 5, 6, 18, 71],
  [16, 103, 5, 17, 82, 122],
  [12, 128, 5, 59, 73, 126],
  [46, 104, 5, 17, 14, 91],
  [39, 50, 5, 59, 44, 83],
  [20, 40, 5, 13, 18, 76],
  [30, 106, 5, 53, 54, 61],
  [16, 132, 5, 59, 33, 129],
  [16, 28, 5, 22, 44, 122],
  [30, 132, 5, 59, 100, 74],
  [30, 127, 5, 26, 18, 35],
  [30, 35, 5, 13, 44, 136],
  [20, 69, 5, 32, 114, 45],
  [20, 48, 5, 107, 37, 23],
  [3, 87, 5, 22, 102, 80],
  [12, 21, 5, 53, 100, 137],
  [30, 43, 5, 6, 100, 74],
  [24, 88, 5, 6, 18, 99],
  [30, 72, 5, 6, 44, 38],
  [12, 112, 5, 13, 62, 67],
  [3, 66, 5, 107, 18, 105],
  [20, 31, 5, 32, 64, 134],
  [39, 55, 5, 6, 100, 45],
  [12, 81, 5, 36, 62, 91],
  [12, 66, 5, 13, 64, 23],
  [20, 72, 5, 53, 33, 68],
  [3, 132, 5, 17, 44, 115],
  [30, 88, 5, 36, 94, 121],
  [30, 106, 5, 89, 41, 71],
  [3, 81, 5, 63, 37, 68],
  [12, 119, 5, 63, 44, 99],
  [12, 117, 5, 92, 100, 27],
  [46, 48, 5, 107, 10, 34],
  [16, 57, 5, 22, 102, 122],
  [3, 4, 5, 89, 94, 35],
  [46, 48, 5, 92, 49, 91],
  [16, 55, 5, 59, 18, 136],
  [39, 96, 5, 59, 98, 115],
  [16, 113, 5, 6, 62, 97],
  [3, 140, 5, 17, 64, 121],
  [24, 66, 5, 22, 18, 68],
  [20, 116, 5, 17, 56, 74],
  [46, 52, 5, 36, 75, 95],
  [24, 87, 5, 17, 94, 35],
  [39, 28, 5, 17, 7, 70],
  [39, 133, 5, 17, 54, 97],
  [3, 69, 5, 107, 90, 67],
  [3, 140, 5, 92, 56, 121],
  [39, 31, 5, 17, 18, 67],
  [16, 120, 5, 22, 124, 19],
  [20, 106, 5, 107, 18, 80],
  [12, 10, 5, 22, 18, 61],
  [24, 43, 5, 22, 73, 35],
  [46, 28, 5, 59, 14, 105],
  [20, 120, 5, 26, 64, 67],
  [3, 104, 5, 26, 62, 67],
  [16, 69, 5, 92, 10, 129],
  [30, 133, 5, 92, 44, 35],
  [16, 119, 5, 32, 18, 97],
  [20, 57, 5, 36, 18, 131],
  [12, 81, 5, 32, 14, 47],
  [3, 103, 5, 32, 29, 101],
  [46, 28, 5, 92, 29, 71],
  [3, 96, 5, 22, 18, 51],
  [30, 57, 5, 6, 18, 19],
  [39, 104, 5, 13, 49, 108],
  [12, 132, 5, 36, 124, 122],
  [3, 43, 5, 17, 100, 108],
  [12, 69, 5, 26, 18, 34],
  [3, 28, 5, 6, 10, 80],
  [12, 48, 5, 13, 62, 111],
  [39, 21, 5, 22, 114, 131],
  [16, 58, 5, 89, 18, 83],
  [39, 66, 5, 22, 18, 76],
  [30, 106, 5, 92, 75, 137],
  [46, 120, 5, 53, 18, 34],
  [16, 132, 5, 17, 54, 101],
  [16, 119, 5, 17, 29, 70],
  [16, 81, 5, 92, 10, 88],
  [3, 88, 5, 53, 94, 27],
  [16, 57, 5, 92, 7, 111],
  [30, 96, 5, 107, 18, 8],
  [30, 40, 5, 107, 54, 111],
  [39, 128, 5, 92, 14, 65],
  [12, 50, 5, 32, 54, 11],
  [30, 106, 5, 22, 14, 76],
  [30, 113, 5, 22, 37, 65],
  [30, 106, 5, 36, 7, 80],
  [24, 116, 5, 107, 100, 101],
  [39, 43, 5, 36, 114, 65],
  [30, 50, 5, 32, 62, 109],
  [12, 43, 5, 53, 37, 65],
  [30, 120, 5, 92, 64, 76],
  [12, 57, 5, 32, 18, 10],
  [16, 55, 5, 13, 18, 61],
  [16, 104, 5, 32, 75, 117],
  [39, 55, 5, 13, 94, 80],
  [3, 112, 5, 53, 102, 91],
  [39, 81, 5, 63, 7, 45],
  [24, 57, 5, 32, 114, 118],
  [16, 119, 5, 107, 90, 80],
  [46, 112, 5, 22, 37, 68],
  [16, 103, 5, 17, 7, 42],
  [12, 55, 5, 92, 62, 76],
  [30, 66, 5, 22, 114, 68],
  [39, 120, 5, 17, 62, 91],
  [30, 88, 5, 32, 56, 131],
  [46, 113, 5, 17, 18, 10],
  [20, 25, 5, 59, 75, 61],
  [3, 104, 5, 107, 33, 115],
  [3, 28, 5, 59, 100, 71],
  [39, 113, 5, 89, 75, 45],
  [30, 88, 5, 63, 102, 76],
  [12, 87, 5, 36, 90, 83],
  [20, 132, 5, 92, 7, 65],
  [20, 120, 5, 86, 18, 27],
  [39, 50, 5, 6, 18, 80],
  [12, 119, 5, 92, 10, 84],
  [12, 69, 5, 92, 54, 83],
  [24, 85, 5, 107, 56, 34],
  [16, 104, 5, 17, 98, 121],
  [30, 133, 5, 22, 82, 97],
  [20, 52, 5, 22, 54, 99],
  [3, 106, 5, 17, 33, 11],
  [16, 106, 5, 6, 82, 42],
  [20, 72, 5, 26, 94, 83],
  [39, 87, 5, 92, 102, 80],
  [12, 48, 5, 22, 33, 108],
  [39, 55, 5, 89, 100, 51],
  [16, 119, 5, 32, 14, 99],
  [16, 35, 5, 13, 18, 91],
  [16, 69, 5, 22, 29, 109],
  [16, 135, 5, 92, 82, 35],
  [39, 72, 5, 26, 90, 108],
  [3, 43, 5, 26, 62, 83],
  [20, 48, 5, 63, 114, 65],
  [16, 119, 5, 13, 56, 78],
  [16, 27, 5, 26, 114, 47],
  [12, 140, 5, 17, 33, 95],
  [16, 69, 5, 89, 130, 122],
  [30, 55, 5, 92, 82, 143],
  [39, 66, 5, 92, 37, 83],
  [46, 28, 5, 63, 100, 74],
  [39, 55, 5, 107, 44, 34],
  [3, 31, 5, 17, 64, 88],
  [39, 50, 5, 59, 18, 131],
  [16, 88, 5, 13, 98, 131],
  [20, 4, 5, 59, 102, 111],
  [30, 58, 5, 22, 62, 67],
  [24, 125, 5, 22, 18, 91],
  [24, 142, 5, 22, 56, 19],
  [39, 110, 5, 36, 29, 47],
  [12, 35, 5, 107, 62, 99],
  [3, 55, 5, 63, 75, 47],
  [24, 106, 5, 107, 130, 34],
  [3, 25, 5, 36, 14, 8],
  [12, 69, 5, 63, 64, 68],
  [20, 132, 5, 6, 7, 68],
  [24, 48, 5, 59, 75, 93],
  [16, 9, 5, 22, 18, 74],
  [3, 69, 5, 59, 54, 83],
  [24, 96, 5, 22, 75, 34],
  [46, 135, 5, 26, 130, 47],
  [20, 104, 5, 59, 62, 47],
  [3, 104, 5, 6, 18, 38],
  [3, 104, 5, 26, 94, 47],
  [46, 81, 5, 13, 100, 129],
  [30, 125, 5, 6, 7, 101],
  [16, 142, 5, 32, 64, 80],
  [46, 66, 5, 13, 49, 122],
  [46, 66, 5, 32, 37, 101],
  [30, 31, 5, 26, 64, 78],
  [12, 57, 5, 36, 100, 51],
  [46, 28, 5, 89, 37, 105],
  [12, 43, 5, 26, 82, 34],
  [16, 57, 5, 59, 18, 91],
  [20, 43, 5, 32, 14, 108],
  [16, 55, 5, 59, 94, 99],
  [39, 87, 5, 17, 7, 15],
  [39, 87, 5, 107, 18, 97],
  [24, 77, 5, 36, 14, 61],
  [12, 50, 5, 22, 64, 91],
  [46, 50, 5, 6, 64, 91],
  [12, 69, 5, 92, 18, 27],
  [16, 96, 5, 32, 18, 34],
  [3, 81, 5, 59, 18, 83],
  [16, 28, 5, 32, 102, 99],
  [12, 77, 5, 92, 10, 121],
  [24, 66, 5, 63, 82, 47],
  [30, 43, 5, 17, 14, 121],
  [20, 28, 5, 32, 14, 131],
  [30, 50, 5, 17, 90, 65],
  [3, 85, 5, 17, 102, 115],
  [46, 88, 5, 53, 64, 70],
  [39, 79, 5, 63, 62, 80],
  [20, 103, 5, 26, 41, 76],
  [12, 119, 5, 86, 73, 11],
  [16, 50, 5, 13, 7, 10],
  [30, 28, 5, 107, 62, 8],
  [16, 123, 5, 53, 73, 111],
  [3, 135, 5, 53, 56, 8],
  [20, 50, 5, 6, 18, 19],
  [16, 50, 5, 13, 7, 108],
  [3, 106, 5, 59, 44, 88],
  [24, 69, 5, 22, 98, 143],
  [46, 4, 5, 36, 37, 34],
  [30, 48, 5, 6, 18, 67],
  [12, 110, 5, 32, 114, 65],
  [30, 50, 5, 32, 73, 45],
  [16, 57, 5, 92, 64, 134],
  [3, 103, 5, 107, 29, 109],
  [30, 132, 5, 92, 62, 10],
  [16, 106, 5, 22, 75, 80],
  [39, 103, 5, 89, 94, 109],
  [20, 81, 5, 59, 82, 45],
  [24, 119, 5, 26, 82, 101],
  [46, 27, 5, 107, 18, 84],
  [12, 35, 5, 22, 114, 34],
  [3, 81, 5, 32, 7, 91],
  [3, 103, 5, 107, 100, 129],
  [30, 133, 5, 22, 98, 74],
  [24, 123, 5, 89, 73, 74],
  [30, 110, 5, 22, 54, 74],
  [24, 66, 5, 92, 90, 45],
  [12, 81, 5, 59, 37, 93],
  [30, 66, 5, 59, 73, 105],
  [12, 135, 5, 59, 18, 51],
  [39, 48, 5, 63, 62, 129],
  [3, 69, 5, 22, 90, 8],
  [24, 31, 5, 32, 10, 8],
  [12, 103, 5, 13, 18, 71],
  [46, 112, 5, 63, 33, 51],
  [20, 142, 5, 32, 54, 121],
  [46, 58, 5, 26, 114, 45],
  [30, 87, 5, 22, 75, 67],
  [20, 35, 5, 107, 18, 122],
  [12, 132, 5, 17, 94, 101],
  [30, 69, 5, 26, 102, 38],
  [16, 141, 5, 17, 124, 78],
  [39, 106, 5, 17, 60, 131],
  [39, 50, 5, 26, 18, 19],
  [3, 43, 5, 17, 29, 67],
  [16, 31, 5, 17, 54, 45],
  [24, 72, 5, 22, 41, 101],
  [39, 113, 5, 17, 29, 65],
  [39, 43, 5, 13, 7, 51],
  [3, 135, 5, 63, 62, 8],
  [39, 135, 5, 26, 18, 97],
  [3, 66, 5, 89, 7, 70],
  [16, 81, 5, 89, 64, 83],
  [24, 57, 5, 36, 82, 95],
  [46, 48, 5, 59, 73, 65],
  [20, 27, 5, 13, 75, 109],
  [39, 48, 5, 32, 82, 78],
  [39, 66, 5, 17, 18, 117],
  [16, 31, 5, 59, 90, 121],
  [12, 119, 5, 6, 90, 117],
  [3, 35, 5, 36, 14, 65],
  [3, 81, 5, 92, 33, 137],
  [24, 127, 5, 92, 90, 117],
  [12, 48, 5, 17, 56, 11],
  [3, 48, 5, 107, 100, 137],
  [20, 58, 5, 107, 18, 111],
  [12, 48, 5, 13, 18, 115],
  [24, 66, 5, 86, 33, 68],
  [12, 40, 5, 53, 54, 68],
  [39, 66, 5, 59, 10, 105],
  [30, 35, 5, 32, 18, 121],
  [30, 127, 5, 26, 73, 61],
  [16, 28, 5, 13, 18, 11],
  [16, 72, 5, 89, 130, 97],
  [24, 81, 5, 13, 94, 118],
  [12, 31, 5, 89, 73, 67],
  [3, 119, 5, 13, 102, 84],
  [30, 79, 5, 13, 75, 131],
  [3, 50, 5, 17, 75, 15],
  [3, 48, 5, 59, 18, 126],
  [3, 103, 5, 92, 130, 74],
  [16, 135, 5, 53, 73, 74],
  [12, 117, 5, 32, 75, 84],
  [3, 31, 5, 36, 124, 65],
  [30, 31, 5, 89, 18, 67],
  [30, 43, 5, 13, 64, 99],
  [30, 58, 5, 13, 37, 115],
  [39, 96, 5, 32, 64, 80],
  [12, 119, 5, 6, 100, 111],
  [39, 57, 5, 13, 64, 15],
  [16, 35, 5, 92, 14, 10],
  [30, 96, 5, 22, 62, 97],
  [3, 123, 5, 13, 56, 122],
  [16, 35, 5, 32, 44, 109],
  [24, 116, 5, 13, 14, 67],
  [16, 57, 5, 17, 64, 8],
  [39, 85, 5, 139, 114, 111],
  [12, 123, 5, 13, 37, 67],
  [12, 106, 5, 13, 18, 47],
  [12, 69, 5, 26, 33, 70],
  [46, 43, 5, 13, 18, 71],
  [24, 85, 5, 92, 18, 74],
  [20, 87, 5, 63, 18, 118],
  [16, 35, 5, 107, 29, 34],
  [20, 106, 5, 59, 7, 10],
  [16, 55, 5, 6, 75, 105],
  [46, 31, 5, 63, 10, 115],
  [24, 52, 5, 22, 90, 143],
  [16, 125, 5, 6, 7, 122],
  [3, 103, 5, 13, 94, 129],
  [39, 119, 5, 22, 37, 99],
  [20, 55, 5, 36, 18, 70],
  [39, 125, 5, 17, 29, 78],
  [39, 128, 5, 89, 100, 80],
  [30, 10, 5, 107, 7, 70],
  [16, 31, 5, 17, 33, 35],
  [16, 96, 5, 107, 7, 129],
  [12, 48, 5, 107, 29, 122],
  [46, 88, 5, 36, 54, 65],
  [16, 116, 5, 22, 54, 71],
  [20, 69, 5, 63, 114, 71],
  [46, 113, 5, 13, 29, 71],
  [20, 103, 5, 107, 90, 80],
  [16, 132, 5, 22, 102, 80],
  [46, 25, 5, 32, 102, 83],
  [12, 48, 5, 26, 18, 95],
  [39, 132, 5, 53, 37, 109],
  [24, 106, 5, 63, 33, 61],
  [46, 28, 5, 13, 18, 15],
  [16, 104, 5, 6, 7, 78],
  [3, 57, 5, 92, 94, 108],
  [39, 81, 5, 17, 7, 61],
  [30, 69, 5, 63, 49, 80],
  [30, 132, 5, 6, 64, 67],
  [46, 125, 5, 22, 90, 68],
  [24, 81, 5, 17, 130, 74],
  [46, 96, 5, 26, 44, 67],
  [20, 55, 5, 17, 94, 68],
  [39, 85, 5, 22, 100, 45],
  [30, 28, 5, 32, 100, 35],
  [39, 35, 5, 17, 7, 91],
  [3, 106, 5, 89, 64, 101],
  [3, 31, 5, 36, 18, 108],
  [16, 66, 5, 17, 130, 76],
  [24, 81, 5, 89, 90, 138],
  [12, 135, 5, 26, 37, 138],
  [20, 31, 5, 63, 18, 80],
  [12, 119, 5, 86, 18, 121],
  [39, 58, 5, 59, 102, 111],
  [24, 31, 5, 36, 18, 138],
  [3, 66, 5, 107, 14, 38],
  [20, 52, 5, 59, 29, 8],
  [46, 106, 5, 22, 18, 38],
  [16, 28, 5, 92, 82, 84],
  [30, 140, 5, 92, 7, 74],
  [30, 103, 5, 6, 18, 47],
  [46, 87, 5, 63, 18, 11],
  [3, 43, 5, 107, 73, 35],
  [39, 48, 5, 26, 29, 117],
  [39, 113, 5, 22, 54, 109],
  [20, 43, 5, 13, 114, 118],
  [12, 119, 5, 26, 102, 65],
  [16, 81, 5, 22, 82, 61],
  [3, 81, 5, 89, 82, 121],
  [3, 135, 5, 36, 114, 35],
  [3, 79, 5, 17, 18, 91],
  [20, 85, 5, 17, 90, 45],
  [3, 135, 5, 89, 37, 108],
  [46, 119, 5, 36, 82, 45],
  [16, 117, 5, 22, 62, 131],
  [3, 113, 5, 63, 56, 76],
  [46, 88, 5, 36, 64, 131],
  [46, 50, 5, 22, 18, 138],
  [3, 35, 5, 89, 114, 91],
  [46, 69, 5, 17, 75, 78],
  [46, 66, 5, 63, 102, 105],
  [12, 132, 5, 13, 64, 10],
  [16, 106, 5, 92, 37, 38],
  [3, 81, 5, 107, 82, 108],
  [16, 103, 5, 89, 44, 91],
  [30, 55, 5, 36, 73, 122],
  [16, 21, 5, 89, 98, 76],
  [12, 81, 5, 59, 102, 78],
  [3, 57, 5, 6, 29, 129],
  [24, 87, 5, 89, 75, 108],
  [39, 25, 5, 92, 7, 138],
  [24, 35, 5, 107, 14, 80],
  [3, 135, 5, 22, 64, 115],
  [24, 58, 5, 59, 33, 34],
  [30, 123, 5, 53, 7, 93],
  [30, 48, 5, 89, 124, 68],
  [12, 66, 5, 22, 90, 97],
  [24, 28, 5, 6, 18, 10],
  [12, 35, 5, 89, 49, 95],
  [30, 48, 5, 59, 18, 121],
  [24, 31, 5, 6, 56, 91],
  [39, 103, 5, 107, 102, 23],
  [16, 9, 5, 22, 100, 38],
  [46, 31, 5, 36, 33, 11],
  [46, 106, 5, 36, 82, 137],
  [3, 103, 5, 63, 114, 74],
  [16, 113, 5, 22, 64, 101],
  [20, 116, 5, 107, 18, 78],
  [16, 28, 5, 22, 10, 88],
  [20, 103, 5, 53, 64, 138],
  [3, 77, 5, 59, 14, 45],
  [12, 117, 5, 32, 130, 61],
  [46, 48, 5, 59, 124, 131],
  [20, 116, 5, 89, 82, 70],
  [24, 125, 5, 36, 33, 134],
  [16, 140, 5, 107, 90, 109],
  [20, 28, 5, 36, 62, 105],
  [39, 66, 5, 63, 60, 11],
  [30, 85, 5, 32, 7, 10],
  [30, 125, 5, 17, 49, 74],
  [30, 28, 5, 63, 37, 137],
  [39, 113, 5, 26, 18, 111],
  [16, 135, 5, 86, 14, 122],
  [20, 135, 5, 13, 130, 67],
  [16, 96, 5, 89, 82, 101],
  [3, 55, 5, 89, 62, 68],
  [30, 43, 5, 36, 18, 45],
  [46, 28, 5, 89, 49, 67],
  [12, 106, 5, 17, 18, 51],
  [39, 103, 5, 59, 102, 76],
  [3, 119, 5, 13, 94, 101],
  [3, 25, 5, 86, 29, 93],
  [39, 48, 5, 22, 33, 101],
  [16, 57, 5, 36, 114, 101],
  [16, 113, 5, 92, 124, 122],
  [39, 88, 5, 36, 14, 71],
  [24, 133, 5, 17, 100, 83],
  [3, 50, 5, 63, 90, 131],
  [46, 127, 5, 107, 62, 121],
  [3, 57, 5, 92, 7, 83],
  [20, 125, 5, 6, 33, 122],
  [3, 35, 5, 139, 44, 111],
  [16, 48, 5, 32, 18, 97],
  [16, 48, 5, 17, 56, 117],
  [12, 104, 5, 32, 75, 108],
  [30, 43, 5, 22, 82, 8],
  [24, 103, 5, 92, 62, 47],
  [3, 104, 5, 59, 56, 111],
  [12, 69, 5, 107, 18, 84],
  [3, 50, 5, 32, 49, 126],
  [3, 110, 5, 59, 75, 111],
  [3, 58, 5, 6, 14, 101],
  [46, 113, 5, 36, 75, 121],
  [20, 28, 5, 17, 33, 11],
  [12, 104, 5, 17, 49, 19],
  [24, 35, 5, 17, 49, 68],
  [3, 112, 5, 63, 73, 121],
  [46, 48, 5, 59, 54, 134],
  [39, 66, 5, 107, 54, 11],
  [46, 52, 5, 36, 114, 74],
  [12, 43, 5, 92, 75, 76],
  [20, 96, 5, 59, 64, 105],
  [3, 66, 5, 92, 75, 45],
  [30, 21, 5, 22, 130, 76],
  [30, 66, 5, 92, 102, 74],
  [30, 69, 5, 26, 18, 91],
  [39, 52, 5, 59, 37, 68],
  [24, 28, 5, 59, 49, 111],
  [39, 31, 5, 59, 18, 131],
  [16, 113, 5, 22, 7, 80],
  [30, 58, 5, 26, 18, 8],
  [3, 116, 5, 107, 54, 122],
  [20, 43, 5, 17, 14, 8],
  [12, 10, 5, 107, 54, 126],
  [39, 87, 5, 53, 49, 74],
  [20, 40, 5, 36, 82, 108],
  [16, 87, 5, 92, 18, 71],
  [12, 132, 5, 107, 33, 91],
  [30, 135, 5, 6, 94, 126],
  [16, 103, 5, 13, 49, 51],
  [30, 119, 5, 13, 33, 35],
  [30, 128, 5, 32, 90, 47],
  [16, 103, 5, 63, 33, 101],
  [3, 35, 5, 92, 64, 93],
  [24, 72, 5, 89, 90, 76],
  [46, 66, 5, 13, 7, 19],
  [20, 140, 5, 107, 75, 74],
  [3, 133, 5, 17, 10, 84],
  [3, 119, 5, 13, 56, 38],
  [39, 72, 5, 92, 102, 122],
  [24, 66, 5, 6, 60, 74],
  [39, 57, 5, 59, 14, 34],
  [39, 113, 5, 92, 18, 74],
  [12, 28, 5, 92, 62, 117],
  [30, 69, 5, 26, 73, 8],
  [12, 81, 5, 22, 114, 42],
  [16, 112, 5, 32, 18, 134],
  [30, 35, 5, 6, 114, 15],
  [39, 88, 5, 63, 98, 99],
  [3, 132, 5, 17, 10, 45],
  [46, 25, 5, 13, 62, 84],
  [30, 103, 5, 13, 62, 80],
  [46, 57, 5, 17, 33, 115],
  [12, 55, 5, 107, 94, 10],
  [20, 112, 5, 22, 102, 67],
  [16, 79, 5, 107, 37, 45],
  [39, 55, 5, 17, 114, 109],
  [16, 72, 5, 6, 73, 76],
  [12, 135, 5, 107, 18, 65],
  [46, 31, 5, 17, 18, 121],
  [12, 69, 5, 53, 64, 11],
  [16, 96, 5, 17, 44, 122],
  [3, 81, 5, 107, 94, 71],
  [46, 43, 5, 17, 7, 76],
  [30, 119, 5, 107, 56, 138],
  [46, 132, 5, 13, 49, 93],
  [12, 28, 5, 22, 64, 45],
  [12, 28, 5, 36, 94, 70],
  [3, 133, 5, 53, 75, 99],
  [3, 104, 5, 89, 18, 121],
  [46, 103, 5, 86, 64, 74],
  [20, 28, 5, 59, 56, 122],
  [24, 85, 5, 59, 29, 111],
  [3, 113, 5, 13, 33, 101],
  [39, 25, 5, 17, 29, 35],
  [24, 27, 5, 92, 18, 76],
  [46, 52, 5, 63, 44, 65],
  [16, 28, 5, 107, 114, 71],
  [16, 135, 5, 6, 54, 101],
  [39, 43, 5, 17, 62, 83],
  [16, 25, 5, 13, 94, 95],
  [39, 140, 5, 63, 18, 129],
  [39, 103, 5, 107, 64, 95],
  [30, 48, 5, 89, 44, 137],
  [30, 40, 5, 6, 75, 76],
  [20, 96, 5, 59, 100, 45],
  [16, 141, 5, 26, 49, 105],
  [46, 57, 5, 17, 94, 131],
  [24, 31, 5, 36, 54, 111],
  [3, 103, 5, 92, 18, 47],
  [39, 110, 5, 32, 75, 105],
  [20, 104, 5, 107, 7, 38],
  [20, 27, 5, 22, 90, 35],
  [3, 85, 5, 63, 54, 71],
  [24, 119, 5, 6, 18, 80],
  [39, 79, 5, 63, 18, 122],
  [20, 55, 5, 89, 49, 101],
  [16, 117, 5, 22, 37, 76],
  [20, 87, 5, 63, 75, 122],
  [46, 69, 5, 6, 33, 115],
  [39, 31, 5, 26, 114, 70],
  [39, 87, 5, 107, 29, 111],
  [20, 106, 5, 32, 37, 10],
  [24, 66, 5, 63, 18, 51],
  [12, 112, 5, 63, 75, 27],
  [46, 106, 5, 53, 14, 76],
  [24, 72, 5, 6, 44, 35],
  [20, 10, 5, 107, 114, 34],
  [30, 81, 5, 13, 7, 8],
  [30, 133, 5, 92, 75, 105],
  [46, 28, 5, 17, 37, 38],
  [16, 81, 5, 32, 114, 93],
  [12, 66, 5, 107, 102, 122],
  [16, 106, 5, 26, 33, 126],
  [39, 106, 5, 107, 18, 126],
  [24, 142, 5, 13, 18, 83],
  [39, 72, 5, 17, 18, 111],
  [20, 132, 5, 92, 82, 78],
  [39, 96, 5, 6, 102, 78],
  [39, 66, 5, 36, 124, 10],
  [12, 112, 5, 26, 90, 71],
  [24, 31, 5, 32, 62, 67],
  [16, 43, 5, 26, 64, 122],
  [20, 133, 5, 107, 37, 131],
  [46, 81, 5, 36, 41, 80],
  [3, 106, 5, 63, 49, 91],
  [39, 69, 5, 63, 62, 111],
  [3, 58, 5, 22, 100, 84],
  [20, 52, 5, 36, 18, 27],
  [46, 9, 5, 26, 73, 34],
  [16, 135, 5, 17, 90, 68],
  [46, 119, 5, 13, 33, 111],
  [3, 106, 5, 13, 18, 138],
  [3, 103, 5, 63, 114, 76],
  [20, 55, 5, 22, 75, 105],
  [39, 96, 5, 36, 114, 78],
  [20, 96, 5, 22, 82, 134],
  [12, 48, 5, 92, 90, 115],
  [30, 35, 5, 89, 82, 131],
  [20, 66, 5, 86, 90, 109],
  [12, 31, 5, 53, 114, 11],
  [20, 31, 5, 13, 114, 105],
  [30, 57, 5, 22, 102, 8],
  [39, 35, 5, 59, 7, 8],
  [24, 81, 5, 32, 29, 111],
  [16, 132, 5, 13, 102, 8],
  [12, 96, 5, 26, 18, 74],
  [39, 103, 5, 36, 56, 95],
  [16, 113, 5, 63, 94, 11],
  [46, 35, 5, 36, 130, 65],
  [16, 113, 5, 107, 7, 121],
  [3, 117, 5, 13, 7, 42],
  [39, 127, 5, 92, 75, 80],
  [46, 48, 5, 36, 75, 80],
  [46, 55, 5, 13, 100, 80],
  [39, 4, 5, 89, 18, 131],
  [46, 69, 5, 32, 18, 108],
  [46, 58, 5, 13, 64, 74],
  [3, 81, 5, 59, 44, 83],
  [20, 69, 5, 26, 49, 95],
  [24, 132, 5, 59, 54, 8],
  [30, 125, 5, 13, 29, 80],
  [30, 103, 5, 59, 18, 34],
  [20, 81, 5, 32, 64, 134],
  [24, 140, 5, 89, 64, 80],
  [16, 9, 5, 32, 33, 111],
  [39, 117, 5, 107, 100, 76],
  [20, 55, 5, 36, 18, 99],
  [3, 79, 5, 86, 114, 80],
  [16, 106, 5, 92, 49, 80],
  [16, 31, 5, 107, 7, 42],
  [12, 57, 5, 53, 18, 84],
  [16, 35, 5, 107, 18, 115],
  [46, 25, 5, 17, 44, 65],
  [16, 72, 5, 53, 18, 10],
  [24, 58, 5, 36, 64, 10],
  [12, 81, 5, 89, 18, 108],
  [12, 28, 5, 13, 90, 122],
  [30, 10, 5, 89, 7, 101],
  [16, 112, 5, 26, 10, 68],
  [16, 135, 5, 107, 90, 122],
  [16, 132, 5, 32, 102, 108],
  [46, 104, 5, 36, 54, 91],
  [39, 31, 5, 59, 18, 74],
  [46, 123, 5, 17, 100, 45],
  [12, 66, 5, 92, 82, 34],
  [46, 43, 5, 13, 18, 108],
  [30, 113, 5, 17, 124, 45],
  [3, 96, 5, 17, 18, 65],
  [12, 112, 5, 26, 100, 34],
  [24, 58, 5, 6, 14, 121],
  [24, 66, 5, 107, 102, 129],
  [3, 4, 5, 107, 7, 19],
  [16, 132, 5, 17, 44, 91],
  [39, 132, 5, 63, 7, 11],
  [39, 27, 5, 17, 33, 47],
  [39, 55, 5, 107, 18, 47],
  [39, 119, 5, 89, 37, 61],
  [20, 50, 5, 53, 18, 91],
  [3, 132, 5, 89, 73, 78],
  [12, 81, 5, 89, 94, 83],
  [16, 113, 5, 92, 130, 108],
  [3, 103, 5, 6, 33, 143],
  [16, 135, 5, 17, 100, 93],
  [20, 132, 5, 6, 37, 11],
  [24, 48, 5, 92, 33, 143],
  [12, 40, 5, 86, 10, 99],
  [39, 50, 5, 107, 7, 76],
  [46, 28, 5, 92, 130, 137],
  [46, 113, 5, 22, 64, 131],
  [30, 79, 5, 22, 102, 38],
  [3, 35, 5, 59, 62, 95],
  [24, 55, 5, 107, 37, 115],
  [3, 31, 5, 36, 54, 61],
  [39, 106, 5, 36, 44, 45],
  [30, 113, 5, 22, 44, 122],
  [39, 31, 5, 36, 10, 68],
  [12, 43, 5, 26, 100, 115],
  [46, 28, 5, 22, 64, 71],
  [12, 106, 5, 13, 33, 11],
  [12, 27, 5, 17, 90, 137],
  [16, 103, 5, 59, 18, 105],
  [16, 81, 5, 22, 130, 83],
  [12, 81, 5, 17, 73, 108],
  [30, 28, 5, 17, 82, 10],
  [12, 21, 5, 32, 54, 109],
  [3, 81, 5, 36, 73, 68],
  [39, 104, 5, 26, 100, 23],
  [39, 140, 5, 92, 90, 65],
  [20, 57, 5, 63, 10, 38],
  [20, 58, 5, 17, 18, 10],
  [39, 125, 5, 59, 82, 74],
  [16, 43, 5, 22, 102, 115],
  [46, 103, 5, 26, 29, 143],
  [39, 132, 5, 89, 18, 47],
  [30, 132, 5, 107, 44, 131],
  [16, 141, 5, 32, 130, 45],
  [3, 25, 5, 26, 33, 76],
  [39, 31, 5, 17, 100, 101],
  [24, 27, 5, 26, 54, 131],
  [12, 48, 5, 107, 100, 118],
  [3, 141, 5, 63, 14, 76],
  [24, 57, 5, 26, 100, 101],
  [24, 31, 5, 53, 44, 74],
  [46, 50, 5, 107, 100, 109],
  [16, 132, 5, 139, 33, 47],
  [12, 21, 5, 22, 44, 70],
  [3, 35, 5, 17, 18, 35],
  [20, 112, 5, 32, 14, 80],
  [24, 55, 5, 13, 18, 74],
  [39, 9, 5, 17, 18, 68],
  [16, 113, 5, 6, 64, 61],
  [12, 104, 5, 17, 29, 74],
  [30, 50, 5, 63, 44, 61],
  [46, 72, 5, 89, 90, 71],
  [30, 140, 5, 13, 29, 8],
  [30, 57, 5, 59, 100, 88],
  [16, 113, 5, 59, 130, 121],
  [46, 66, 5, 36, 18, 95],
  [20, 96, 5, 63, 82, 129],
  [12, 96, 5, 32, 56, 108],
  [12, 119, 5, 17, 90, 67],
  [16, 120, 5, 63, 29, 11],
  [16, 96, 5, 89, 100, 80],
  [46, 55, 5, 89, 54, 101],
  [30, 48, 5, 6, 29, 122],
  [39, 88, 5, 13, 10, 78],
  [3, 50, 5, 32, 18, 91],
  [39, 117, 5, 107, 37, 134],
  [30, 135, 5, 17, 37, 88],
  [46, 117, 5, 22, 18, 27],
  [3, 52, 5, 107, 82, 105],
  [30, 27, 5, 92, 37, 111],
  [30, 69, 5, 53, 18, 117],
  [46, 132, 5, 32, 7, 99],
  [3, 106, 5, 107, 100, 35],
  [24, 96, 5, 59, 64, 34],
  [20, 58, 5, 107, 18, 65],
  [30, 133, 5, 92, 14, 83],
  [3, 58, 5, 36, 37, 115],
  [20, 28, 5, 53, 33, 91],
  [20, 35, 5, 63, 62, 88],
  [16, 27, 5, 36, 54, 35],
  [12, 132, 5, 89, 94, 101],
  [46, 28, 5, 59, 29, 8],
  [20, 31, 5, 59, 102, 121],
  [30, 81, 5, 89, 56, 19],
  [12, 66, 5, 89, 37, 68],
  [3, 58, 5, 107, 14, 8],
  [20, 35, 5, 36, 82, 68],
  [3, 57, 5, 22, 75, 131],
  [30, 117, 5, 22, 98, 71],
  [16, 35, 5, 89, 75, 70],
  [39, 140, 5, 92, 124, 122],
  [3, 57, 5, 59, 73, 71],
  [46, 69, 5, 107, 18, 95],
  [3, 117, 5, 107, 75, 67],
  [30, 69, 5, 26, 44, 8],
  [12, 28, 5, 63, 73, 83],
  [46, 128, 5, 17, 114, 99],
  [3, 120, 5, 32, 14, 65],
  [46, 43, 5, 32, 18, 67],
  [39, 106, 5, 22, 100, 70],
  [20, 31, 5, 92, 102, 67],
  [39, 117, 5, 89, 102, 137],
  [12, 48, 5, 17, 90, 117],
  [39, 66, 5, 6, 54, 76],
  [3, 125, 5, 17, 62, 65],
  [30, 116, 5, 92, 94, 109],
  [16, 103, 5, 13, 14, 83],
  [39, 50, 5, 36, 18, 117],
  [16, 43, 5, 13, 18, 97],
  [46, 103, 5, 6, 56, 80],
  [46, 110, 5, 63, 18, 11],
  [16, 81, 5, 6, 10, 83],
  [46, 132, 5, 26, 62, 101],
  [30, 55, 5, 6, 18, 47],
  [12, 28, 5, 107, 18, 71],
  [24, 132, 5, 32, 62, 101],
  [46, 57, 5, 17, 14, 10],
  [39, 55, 5, 32, 18, 99],
  [16, 142, 5, 13, 33, 42],
  [30, 35, 5, 63, 7, 45],
  [46, 123, 5, 36, 100, 80],
  [12, 85, 5, 6, 100, 101],
  [20, 113, 5, 13, 130, 67],
  [39, 113, 5, 26, 56, 47],
  [30, 72, 5, 89, 114, 76],
  [20, 104, 5, 26, 49, 138],
  [3, 48, 5, 107, 29, 19],
  [39, 35, 5, 26, 102, 108],
  [20, 57, 5, 26, 18, 134],
  [12, 50, 5, 139, 73, 115],
  [30, 119, 5, 17, 82, 35],
  [12, 128, 5, 17, 18, 122],
  [12, 103, 5, 107, 114, 109],
  [12, 48, 5, 107, 14, 122],
  [24, 28, 5, 26, 82, 8],
  [3, 104, 5, 17, 130, 80],
  [16, 50, 5, 32, 18, 101],
  [39, 104, 5, 22, 90, 131],
  [12, 81, 5, 26, 73, 105],
  [20, 127, 5, 13, 29, 129],
  [39, 113, 5, 32, 54, 76],
  [24, 58, 5, 13, 73, 91],
  [30, 87, 5, 32, 130, 105],
  [16, 31, 5, 13, 56, 71],
  [3, 4, 5, 107, 102, 71],
  [46, 119, 5, 59, 14, 71],
  [12, 142, 5, 36, 18, 35],
  [30, 96, 5, 17, 18, 47],
  [20, 57, 5, 107, 94, 65],
  [16, 66, 5, 53, 56, 80],
  [39, 125, 5, 17, 29, 8],
  [46, 132, 5, 53, 102, 131],
  [39, 66, 5, 89, 130, 118],
  [30, 127, 5, 26, 18, 34],
  [20, 85, 5, 26, 114, 122],
  [12, 104, 5, 89, 7, 78],
  [16, 112, 5, 17, 94, 11],
  [46, 119, 5, 17, 14, 15],
  [20, 72, 5, 107, 56, 67],
  [20, 79, 5, 17, 18, 15],
  [16, 106, 5, 6, 54, 138],
  [30, 103, 5, 22, 18, 10],
  [46, 55, 5, 6, 44, 8],
  [30, 104, 5, 107, 18, 10],
  [16, 128, 5, 6, 18, 78],
  [3, 113, 5, 36, 94, 71],
  [16, 58, 5, 13, 18, 47],
  [3, 85, 5, 92, 44, 78],
  [30, 48, 5, 22, 33, 136],
  [3, 35, 5, 13, 62, 51],
  [12, 31, 5, 17, 64, 10],
  [30, 140, 5, 36, 54, 15],
  [24, 132, 5, 17, 75, 80],
  [16, 4, 5, 17, 73, 99],
  [3, 66, 5, 17, 94, 78],
  [39, 35, 5, 6, 10, 67],
  [3, 28, 5, 13, 75, 51],
  [3, 96, 5, 59, 29, 51],
  [30, 132, 5, 6, 7, 137],
  [24, 40, 5, 107, 130, 115],
  [16, 58, 5, 107, 64, 51],
  [3, 103, 5, 92, 18, 84],
  [12, 43, 5, 17, 14, 111],
  [30, 106, 5, 13, 73, 71],
  [20, 43, 5, 17, 54, 11],
  [39, 50, 5, 107, 100, 138],
  [16, 119, 5, 107, 82, 51],
  [30, 133, 5, 13, 102, 10],
  [30, 25, 5, 92, 18, 47],
  [16, 57, 5, 13, 100, 67],
  [16, 96, 5, 89, 18, 137],
  [20, 113, 5, 63, 37, 71],
  [30, 140, 5, 63, 102, 122],
  [12, 31, 5, 36, 18, 105],
  [3, 43, 5, 13, 62, 99],
  [12, 106, 5, 107, 94, 105],
  [16, 9, 5, 13, 54, 35],
  [24, 58, 5, 63, 124, 129],
  [30, 43, 5, 63, 10, 91],
  [16, 103, 5, 36, 56, 108],
  [39, 96, 5, 22, 18, 105],
  [39, 10, 5, 92, 94, 47],
  [46, 55, 5, 17, 18, 117],
  [24, 43, 5, 13, 98, 11],
  [30, 58, 5, 6, 73, 70],
  [16, 31, 5, 107, 18, 34],
  [20, 48, 5, 13, 49, 118],
  [3, 43, 5, 92, 124, 23],
  [39, 35, 5, 13, 56, 101],
  [16, 52, 5, 107, 18, 45],
  [3, 21, 5, 13, 14, 95],
  [24, 106, 5, 89, 18, 118],
  [46, 106, 5, 107, 102, 111],
  [24, 132, 5, 13, 56, 78],
  [3, 57, 5, 6, 37, 8],
  [46, 48, 5, 13, 18, 71],
  [20, 135, 5, 53, 62, 137],
  [16, 132, 5, 86, 29, 47],
  [39, 9, 5, 17, 90, 8],
  [30, 88, 5, 59, 29, 65],
  [39, 31, 5, 36, 130, 76],
  [3, 117, 5, 89, 18, 108],
  [16, 66, 5, 26, 7, 105],
  [46, 132, 5, 63, 18, 74],
  [12, 40, 5, 17, 94, 8],
  [30, 72, 5, 32, 62, 34],
  [30, 43, 5, 17, 10, 105],
  [30, 133, 5, 17, 64, 23],
  [39, 57, 5, 107, 98, 42],
  [16, 106, 5, 32, 7, 137],
  [39, 125, 5, 36, 90, 71],
  [12, 50, 5, 26, 64, 67],
  [12, 117, 5, 13, 14, 99],
  [39, 103, 5, 36, 82, 136],
  [3, 4, 5, 32, 18, 126],
  [39, 112, 5, 17, 75, 99],
  [12, 104, 5, 6, 82, 68],
  [24, 141, 5, 13, 94, 108],
  [30, 104, 5, 92, 94, 70],
  [16, 113, 5, 89, 94, 47],
  [39, 31, 5, 13, 62, 11],
  [12, 113, 5, 32, 54, 91],
  [30, 21, 5, 107, 44, 51],
  [39, 88, 5, 63, 94, 84],
  [3, 113, 5, 63, 41, 105],
  [12, 52, 5, 32, 100, 35],
  [3, 88, 5, 32, 94, 121],
  [16, 116, 5, 36, 56, 109],
  [16, 43, 5, 53, 62, 99],
  [16, 50, 5, 17, 73, 105],
  [30, 140, 5, 22, 114, 65],
  [46, 112, 5, 17, 33, 137],
  [39, 103, 5, 92, 130, 122],
  [39, 106, 5, 22, 33, 68],
  [12, 28, 5, 17, 14, 61],
  [46, 4, 5, 17, 62, 131],
  [46, 28, 5, 32, 29, 10],
  [24, 35, 5, 59, 56, 111],
  [12, 116, 5, 13, 18, 95],
  [12, 140, 5, 107, 18, 101],
  [20, 77, 5, 13, 29, 8],
  [30, 103, 5, 53, 44, 67],
  [16, 123, 5, 53, 94, 71],
  [39, 50, 5, 17, 62, 80],
  [20, 31, 5, 63, 7, 67],
  [24, 72, 5, 59, 102, 84],
  [39, 28, 5, 89, 56, 115],
  [30, 106, 5, 139, 100, 80],
  [30, 113, 5, 32, 10, 68],
  [46, 58, 5, 53, 44, 10],
  [46, 128, 5, 107, 18, 91],
  [39, 87, 5, 32, 100, 117],
  [12, 113, 5, 22, 54, 83],
  [46, 72, 5, 13, 18, 88],
  [3, 9, 5, 92, 18, 8],
  [39, 52, 5, 6, 18, 11],
  [46, 48, 5, 13, 130, 109],
  [46, 96, 5, 59, 33, 34],
  [46, 96, 5, 17, 64, 131],
  [12, 96, 5, 6, 114, 122],
  [16, 142, 5, 17, 18, 95],
  [12, 104, 5, 6, 54, 95],
  [16, 117, 5, 13, 62, 80],
  [16, 81, 5, 53, 75, 45],
  [39, 96, 5, 36, 14, 121],
  [20, 48, 5, 107, 82, 47],
  [46, 112, 5, 53, 44, 47],
  [39, 9, 5, 13, 73, 105],
  [16, 50, 5, 59, 18, 76],
  [20, 66, 5, 92, 62, 122],
  [3, 125, 5, 107, 114, 51],
  [46, 50, 5, 22, 54, 23],
  [20, 27, 5, 59, 62, 121],
  [3, 120, 5, 13, 33, 115],
  [3, 96, 5, 63, 10, 70],
  [3, 96, 5, 107, 18, 34],
  [20, 135, 5, 92, 10, 51],
  [39, 48, 5, 32, 100, 74],
  [3, 40, 5, 13, 18, 101],
  [24, 58, 5, 59, 18, 71],
  [16, 35, 5, 139, 64, 99],
  [12, 132, 5, 17, 82, 93],
  [3, 21, 5, 32, 10, 10],
  [12, 25, 5, 36, 33, 101],
  [20, 103, 5, 22, 18, 80],
  [30, 77, 5, 53, 56, 121],
  [3, 85, 5, 26, 114, 65],
  [39, 72, 5, 63, 54, 108],
  [16, 125, 5, 107, 18, 10],
  [20, 50, 5, 59, 18, 97],
  [20, 135, 5, 53, 7, 67],
  [3, 55, 5, 17, 56, 67],
  [3, 72, 5, 13, 18, 34],
  [16, 132, 5, 63, 94, 88],
  [3, 127, 5, 13, 10, 35],
  [30, 112, 5, 59, 90, 118],
  [16, 35, 5, 86, 90, 11],
  [20, 66, 5, 32, 7, 68],
  [20, 31, 5, 92, 18, 78],
  [39, 43, 5, 22, 49, 122],
  [20, 58, 5, 36, 64, 80],
  [24, 66, 5, 139, 90, 71],
  [16, 106, 5, 32, 90, 71],
  [3, 43, 5, 107, 18, 121],
  [24, 117, 5, 59, 90, 10],
  [20, 28, 5, 92, 64, 137],
  [12, 57, 5, 107, 64, 15],
  [3, 135, 5, 53, 62, 131],
  [16, 104, 5, 13, 18, 131],
  [24, 133, 5, 22, 56, 74],
  [30, 85, 5, 26, 14, 68],
  [30, 135, 5, 17, 73, 111],
  [39, 112, 5, 26, 29, 111],
  [46, 57, 5, 17, 62, 38],
  [24, 96, 5, 92, 56, 45],
  [46, 58, 5, 92, 7, 118],
  [20, 112, 5, 36, 102, 34],
  [24, 81, 5, 89, 49, 138],
  [3, 127, 5, 63, 64, 11],
  [46, 103, 5, 13, 29, 68],
  [12, 81, 5, 17, 29, 11],
  [46, 25, 5, 107, 44, 65],
  [12, 43, 5, 107, 18, 138],
  [3, 50, 5, 59, 102, 8],
  [20, 72, 5, 22, 114, 78],
  [3, 88, 5, 32, 18, 65],
  [3, 113, 5, 59, 82, 8],
  [39, 50, 5, 13, 82, 11],
  [39, 55, 5, 36, 82, 27],
  [46, 87, 5, 6, 102, 38],
  [16, 28, 5, 107, 64, 105],
  [24, 10, 5, 53, 73, 76],
  [46, 119, 5, 13, 56, 99],
  [3, 40, 5, 26, 18, 38],
  [16, 58, 5, 53, 18, 121],
  [20, 69, 5, 89, 54, 80],
  [39, 96, 5, 92, 18, 15],
  [24, 96, 5, 59, 62, 78],
  [46, 119, 5, 13, 130, 111],
  [3, 135, 5, 92, 73, 109],
  [39, 119, 5, 86, 102, 76],
  [39, 43, 5, 22, 102, 84],
  [46, 31, 5, 17, 100, 68],
  [46, 9, 5, 107, 102, 138],
  [46, 125, 5, 63, 102, 91],
  [20, 81, 5, 107, 18, 109],
  [46, 35, 5, 86, 62, 11],
  [12, 66, 5, 13, 37, 19],
  [46, 72, 5, 17, 18, 111],
  [39, 96, 5, 17, 18, 47],
  [24, 27, 5, 86, 18, 115],
  [12, 106, 5, 63, 29, 65],
  [3, 119, 5, 107, 100, 91],
  [3, 133, 5, 32, 33, 83],
  [20, 135, 5, 32, 18, 126],
  [30, 66, 5, 36, 114, 8],
  [3, 104, 5, 13, 14, 117],
  [30, 50, 5, 13, 18, 76],
  [39, 77, 5, 53, 14, 78],
  [24, 117, 5, 92, 130, 74],
  [16, 31, 5, 53, 29, 99],
  [3, 35, 5, 17, 100, 137],
  [24, 31, 5, 22, 37, 71],
  [46, 132, 5, 63, 37, 108],
  [46, 85, 5, 107, 54, 108],
  [24, 52, 5, 63, 14, 11],
  [39, 81, 5, 17, 75, 134],
  [16, 113, 5, 107, 75, 78],
  [12, 66, 5, 17, 29, 71],
  [16, 135, 5, 17, 64, 93],
  [20, 72, 5, 17, 18, 108],
  [24, 96, 5, 53, 37, 76],
  [12, 106, 5, 26, 14, 99],
  [30, 58, 5, 92, 56, 76],
  [16, 31, 5, 92, 18, 8],
  [20, 58, 5, 17, 10, 108],
  [16, 113, 5, 63, 90, 129],
  [30, 119, 5, 13, 102, 83],
  [46, 77, 5, 53, 14, 80],
  [30, 96, 5, 53, 14, 68],
  [39, 27, 5, 36, 100, 93],
  [16, 96, 5, 26, 73, 8],
  [12, 58, 5, 36, 18, 108],
  [12, 48, 5, 53, 64, 131],
  [39, 113, 5, 89, 18, 45],
  [46, 50, 5, 59, 14, 108],
  [20, 25, 5, 6, 33, 76],
  [12, 55, 5, 63, 44, 83],
  [16, 96, 5, 89, 56, 118],
  [16, 87, 5, 36, 124, 65],
  [39, 50, 5, 36, 54, 117],
  [3, 35, 5, 107, 73, 74],
  [16, 110, 5, 36, 54, 27],
  [3, 112, 5, 63, 100, 97],
  [30, 35, 5, 17, 7, 108],
  [24, 113, 5, 92, 33, 47],
  [30, 43, 5, 36, 75, 34],
  [46, 113, 5, 26, 64, 76],
  [39, 58, 5, 63, 54, 115],
  [24, 81, 5, 107, 73, 131],
  [30, 113, 5, 17, 75, 11],
  [3, 112, 5, 6, 29, 35],
  [20, 119, 5, 17, 102, 111],
  [3, 55, 5, 53, 18, 74],
  [16, 58, 5, 89, 82, 11],
  [30, 113, 5, 53, 94, 74],
  [46, 116, 5, 32, 100, 27],
  [39, 57, 5, 22, 29, 68],
  [3, 66, 5, 26, 102, 121],
  [16, 85, 5, 63, 102, 38],
  [3, 119, 5, 17, 102, 111],
  [24, 35, 5, 13, 18, 122],
  [30, 69, 5, 107, 44, 115],
  [16, 104, 5, 139, 100, 76],
  [12, 112, 5, 107, 10, 108],
  [24, 117, 5, 59, 18, 99],
  [16, 31, 5, 59, 18, 71],
  [20, 25, 5, 53, 44, 68],
  [24, 50, 5, 63, 33, 95],
  [16, 31, 5, 89, 18, 122],
  [20, 112, 5, 6, 130, 138],
  [30, 50, 5, 107, 102, 91],
  [46, 110, 5, 53, 114, 61],
  [24, 113, 5, 17, 90, 95],
  [16, 27, 5, 107, 73, 121],
  [30, 43, 5, 6, 56, 71],
  [24, 66, 5, 17, 7, 15],
  [20, 113, 5, 107, 44, 74],
  [3, 132, 5, 92, 100, 84],
  [16, 112, 5, 107, 75, 131],
  [20, 55, 5, 17, 64, 101],
  [20, 96, 5, 86, 75, 115],
  [12, 132, 5, 63, 90, 105],
  [46, 140, 5, 26, 90, 115],
  [30, 132, 5, 92, 7, 129],
  [46, 66, 5, 6, 56, 47],
  [39, 87, 5, 63, 18, 108],
  [24, 113, 5, 36, 94, 137],
  [3, 106, 5, 22, 29, 80],
  [20, 120, 5, 13, 114, 74],
  [20, 69, 5, 107, 94, 129],
  [46, 50, 5, 17, 124, 83],
  [46, 57, 5, 139, 7, 65],
  [20, 112, 5, 107, 82, 45],
  [39, 81, 5, 89, 7, 121],
  [30, 69, 5, 17, 90, 70],
  [30, 123, 5, 32, 18, 138],
  [30, 28, 5, 59, 7, 35],
  [30, 113, 5, 17, 130, 68],
  [16, 106, 5, 13, 7, 99],
  [30, 57, 5, 92, 18, 101],
  [24, 50, 5, 107, 18, 68],
  [12, 81, 5, 36, 18, 101],
  [30, 103, 5, 17, 73, 129],
  [39, 103, 5, 26, 90, 23],
  [30, 55, 5, 22, 100, 68],
  [12, 4, 5, 13, 14, 65],
  [20, 66, 5, 107, 10, 11],
  [46, 104, 5, 17, 64, 68],
  [24, 135, 5, 63, 7, 80],
  [39, 81, 5, 26, 75, 47],
  [30, 4, 5, 63, 94, 101],
  [3, 133, 5, 36, 18, 65],
  [12, 120, 5, 63, 37, 122],
  [39, 66, 5, 26, 44, 93],
  [39, 28, 5, 92, 7, 83],
  [20, 132, 5, 26, 18, 11],
  [12, 35, 5, 36, 62, 38],
  [20, 58, 5, 89, 73, 45],
  [24, 120, 5, 107, 18, 117],
  [46, 133, 5, 86, 14, 101],
  [39, 66, 5, 6, 100, 23],
  [20, 57, 5, 13, 18, 80],
  [12, 27, 5, 6, 37, 129],
  [30, 125, 5, 107, 18, 97],
  [3, 50, 5, 17, 18, 91],
  [16, 103, 5, 13, 37, 99],
  [12, 69, 5, 36, 114, 95],
  [12, 79, 5, 89, 29, 74],
  [3, 96, 5, 59, 7, 97],
  [16, 28, 5, 32, 7, 131],
  [3, 28, 5, 36, 130, 68],
  [16, 31, 5, 59, 18, 71],
  [16, 81, 5, 63, 90, 80],
  [30, 28, 5, 17, 130, 108],
  [30, 25, 5, 92, 18, 76],
  [30, 110, 5, 32, 130, 70],
  [39, 55, 5, 22, 90, 70],
  [16, 35, 5, 26, 102, 105],
  [3, 4, 5, 107, 114, 88],
  [39, 10, 5, 89, 18, 99],
  [39, 103, 5, 32, 54, 78],
  [12, 57, 5, 13, 18, 15],
  [16, 132, 5, 26, 75, 115],
  [3, 140, 5, 89, 73, 115],
  [3, 28, 5, 107, 64, 91],
  [20, 69, 5, 107, 29, 23],
  [20, 119, 5, 59, 18, 71],
  [46, 58, 5, 22, 14, 88],
  [20, 31, 5, 63, 98, 99],
  [3, 106, 5, 63, 29, 68],
  [20, 10, 5, 6, 18, 109],
  [30, 55, 5, 32, 37, 115],
  [46, 106, 5, 107, 94, 35],
  [30, 103, 5, 107, 18, 83],
  [16, 52, 5, 6, 29, 10],
  [39, 112, 5, 13, 82, 88],
  [30, 79, 5, 26, 37, 42],
  [3, 66, 5, 92, 75, 8],
  [12, 69, 5, 22, 54, 45],
  [30, 127, 5, 32, 56, 67],
  [16, 69, 5, 17, 73, 78],
  [16, 72, 5, 63, 82, 99],
  [30, 28, 5, 92, 75, 122],
  [24, 127, 5, 17, 75, 65],
  [12, 43, 5, 17, 18, 47],
  [16, 35, 5, 59, 18, 80],
  [3, 9, 5, 32, 56, 126],
  [24, 27, 5, 36, 29, 74],
  [46, 69, 5, 32, 10, 15],
  [20, 25, 5, 13, 7, 70],
  [39, 72, 5, 17, 37, 108],
  [3, 87, 5, 59, 18, 111],
  [46, 106, 5, 6, 94, 108],
  [16, 72, 5, 17, 18, 65],
  [24, 103, 5, 107, 54, 109],
  [12, 48, 5, 26, 29, 101],
  [20, 116, 5, 26, 75, 11],
  [12, 125, 5, 53, 75, 126],
  [16, 43, 5, 22, 18, 11],
  [3, 35, 5, 32, 56, 121],
  [20, 57, 5, 107, 7, 108],
  [16, 96, 5, 26, 124, 67],
  [30, 21, 5, 22, 37, 95],
  [16, 35, 5, 13, 10, 118],
  [46, 104, 5, 92, 7, 76],
  [24, 57, 5, 13, 18, 65],
  [3, 87, 5, 22, 10, 111],
  [24, 135, 5, 32, 73, 80],
  [46, 27, 5, 86, 94, 68],
  [39, 88, 5, 13, 100, 67],
  [24, 106, 5, 17, 54, 115],
  [3, 128, 5, 92, 33, 99],
  [46, 112, 5, 26, 94, 35],
  [3, 104, 5, 107, 18, 51],
  [12, 52, 5, 59, 18, 118],
  [30, 28, 5, 17, 54, 76],
  [12, 43, 5, 63, 18, 105],
  [16, 104, 5, 6, 18, 83],
  [39, 52, 5, 32, 7, 121],
  [46, 52, 5, 32, 114, 121],
  [24, 25, 5, 107, 102, 83],
  [39, 43, 5, 17, 73, 84],
  [20, 66, 5, 22, 29, 122],
  [39, 43, 5, 89, 75, 23],
  [46, 113, 5, 53, 18, 19],
  [39, 43, 5, 89, 100, 51],
  [16, 104, 5, 22, 90, 34],
  [3, 106, 5, 107, 44, 131],
  [12, 113, 5, 22, 49, 83],
  [46, 85, 5, 59, 62, 47],
  [12, 133, 5, 59, 37, 83],
  [39, 48, 5, 13, 62, 93],
  [24, 132, 5, 92, 82, 47],
  [30, 52, 5, 107, 56, 8],
  [39, 104, 5, 32, 10, 121],
  [39, 116, 5, 89, 18, 108],
  [3, 140, 5, 17, 18, 19],
  [16, 58, 5, 92, 18, 71],
  [20, 135, 5, 13, 18, 91],
  [16, 55, 5, 36, 82, 111],
  [16, 28, 5, 32, 62, 10],
  [3, 81, 5, 92, 18, 95],
  [16, 28, 5, 17, 73, 101],
  [30, 31, 5, 17, 75, 83],
  [12, 31, 5, 63, 56, 34],
  [30, 110, 5, 36, 7, 109],
  [39, 4, 5, 17, 18, 11],
  [3, 40, 5, 36, 102, 70],
  [3, 28, 5, 36, 100, 118],
  [46, 48, 5, 53, 56, 8],
  [12, 10, 5, 17, 90, 131],
  [24, 123, 5, 26, 75, 111],
  [16, 25, 5, 26, 100, 67],
  [46, 96, 5, 92, 62, 80],
  [24, 69, 5, 32, 114, 129],
  [46, 28, 5, 89, 7, 121],
  [16, 43, 5, 17, 114, 84],
  [30, 69, 5, 32, 56, 10],
  [24, 140, 5, 36, 94, 121],
  [30, 117, 5, 32, 75, 71],
  [24, 50, 5, 6, 33, 65],
  [39, 35, 5, 92, 90, 67],
  [16, 66, 5, 26, 44, 45],
  [3, 133, 5, 32, 33, 74],
  [16, 96, 5, 32, 18, 131],
  [3, 50, 5, 17, 73, 11],
  [39, 106, 5, 17, 82, 11],
  [46, 66, 5, 86, 18, 42],
  [16, 28, 5, 89, 62, 80],
  [24, 43, 5, 89, 49, 78],
  [30, 48, 5, 59, 18, 101],
  [30, 57, 5, 17, 29, 11],
  [39, 57, 5, 139, 94, 131],
  [16, 4, 5, 22, 7, 126],
  [20, 119, 5, 13, 29, 136],
  [16, 133, 5, 59, 64, 105],
  [3, 96, 5, 59, 73, 134],
  [3, 113, 5, 139, 18, 67],
  [16, 28, 5, 36, 7, 99],
  [39, 132, 5, 13, 64, 67],
  [3, 57, 5, 59, 18, 65],
  [30, 48, 5, 17, 64, 88],
  [12, 132, 5, 107, 44, 11],
  [12, 25, 5, 13, 18, 47],
  [30, 43, 5, 17, 62, 83],
  [30, 81, 5, 6, 7, 111],
  [39, 55, 5, 26, 49, 68],
  [16, 96, 5, 32, 62, 8],
  [16, 119, 5, 36, 62, 137],
  [12, 87, 5, 36, 7, 83],
  [30, 66, 5, 59, 102, 126],
  [16, 69, 5, 89, 7, 88],
  [24, 43, 5, 107, 124, 76],
  [39, 35, 5, 139, 73, 91],
  [3, 35, 5, 17, 33, 122],
  [20, 120, 5, 6, 37, 122],
  [3, 132, 5, 13, 114, 8],
  [46, 104, 5, 26, 102, 108],
  [46, 132, 5, 107, 7, 19],
  [46, 58, 5, 86, 56, 10],
  [24, 104, 5, 89, 37, 70],
  [30, 96, 5, 63, 62, 99],
  [12, 55, 5, 26, 54, 137],
  [24, 57, 5, 107, 54, 70],
  [30, 128, 5, 6, 18, 8],
  [46, 4, 5, 63, 37, 126],
  [46, 123, 5, 17, 54, 68],
  [30, 72, 5, 26, 102, 115],
  [3, 104, 5, 59, 18, 109],
  [20, 119, 5, 139, 114, 80],
  [39, 55, 5, 22, 7, 42],
  [12, 55, 5, 107, 102, 122],
  [39, 31, 5, 107, 37, 10],
  [30, 104, 5, 26, 56, 121],
  [20, 116, 5, 63, 18, 121],
  [46, 133, 5, 107, 56, 108],
  [3, 48, 5, 13, 44, 80],
  [24, 141, 5, 92, 82, 8],
  [24, 31, 5, 17, 37, 134],
  [46, 81, 5, 107, 124, 108],
  [20, 28, 5, 89, 44, 74],
  [3, 55, 5, 13, 94, 134],
  [24, 52, 5, 22, 41, 76],
  [39, 66, 5, 92, 18, 105],
  [20, 132, 5, 22, 102, 91],
  [16, 113, 5, 17, 64, 126],
  [16, 58, 5, 17, 10, 121],
  [24, 87, 5, 92, 54, 91],
  [16, 9, 5, 22, 14, 10],
  [30, 9, 5, 26, 29, 101],
  [12, 66, 5, 22, 7, 131],
  [16, 27, 5, 107, 75, 80],
  [12, 132, 5, 26, 18, 88],
  [3, 104, 5, 107, 56, 76],
  [3, 119, 5, 63, 18, 80],
  [16, 112, 5, 32, 18, 122],
  [16, 35, 5, 63, 18, 84],
  [20, 21, 5, 36, 37, 101],
  [3, 27, 5, 36, 130, 122],
  [16, 35, 5, 107, 18, 76],
  [12, 48, 5, 36, 18, 70],
  [20, 25, 5, 32, 82, 67],
  [30, 96, 5, 13, 75, 101],
  [30, 103, 5, 53, 54, 131],
  [16, 72, 5, 107, 18, 19],
  [30, 55, 5, 53, 100, 11],
  [24, 43, 5, 107, 114, 108],
  [46, 132, 5, 36, 37, 99],
  [12, 35, 5, 89, 130, 97],
  [46, 106, 5, 22, 56, 95],
  [16, 66, 5, 63, 90, 84],
  [3, 57, 5, 139, 98, 67],
  [16, 28, 5, 36, 54, 76],
  [16, 79, 5, 59, 18, 105],
  [46, 31, 5, 92, 114, 51],
  [30, 104, 5, 107, 82, 138],
  [39, 77, 5, 13, 130, 8],
  [16, 112, 5, 32, 49, 122],
  [46, 55, 5, 107, 7, 83],
  [39, 4, 5, 22, 29, 80],
  [30, 81, 5, 89, 14, 83],
  [3, 43, 5, 22, 94, 126],
  [12, 104, 5, 89, 33, 80],
  [30, 135, 5, 107, 102, 137],
  [3, 116, 5, 13, 54, 74],
  [16, 125, 5, 53, 124, 76],
  [12, 69, 5, 89, 54, 131],
  [16, 50, 5, 13, 18, 78],
  [30, 106, 5, 26, 64, 93],
  [24, 81, 5, 26, 102, 61],
  [16, 25, 5, 89, 102, 111],
  [12, 132, 5, 32, 130, 80],
  [39, 69, 5, 26, 64, 134],
  [3, 79, 5, 59, 18, 11],
  [30, 50, 5, 92, 56, 138],
  [3, 104, 5, 59, 29, 83],
  [12, 28, 5, 17, 90, 122],
  [12, 69, 5, 32, 54, 34],
  [16, 113, 5, 22, 82, 93],
  [16, 113, 5, 59, 90, 108],
  [3, 43, 5, 32, 102, 10],
  [30, 135, 5, 32, 90, 115],
  [46, 28, 5, 89, 56, 99],
  [20, 81, 5, 13, 33, 83],
  [30, 104, 5, 89, 102, 71],
  [3, 57, 5, 13, 29, 95],
  [3, 43, 5, 6, 49, 71],
  [12, 123, 5, 107, 75, 122],
  [12, 104, 5, 36, 18, 34],
  [3, 48, 5, 13, 62, 93],
  [46, 132, 5, 139, 100, 71],
  [16, 103, 5, 59, 44, 76],
  [39, 125, 5, 107, 62, 45],
  [16, 48, 5, 89, 102, 67],
  [16, 132, 5, 107, 75, 74],
  [12, 69, 5, 63, 75, 108],
  [20, 4, 5, 36, 90, 8],
  [12, 106, 5, 22, 130, 8],
  [46, 31, 5, 32, 75, 138],
  [39, 58, 5, 63, 90, 10],
  [12, 133, 5, 17, 33, 10],
  [39, 119, 5, 36, 7, 11],
  [46, 31, 5, 107, 100, 93],
  [12, 57, 5, 32, 18, 42],
  [3, 48, 5, 107, 49, 76],
  [39, 48, 5, 107, 18, 71],
  [16, 31, 5, 6, 73, 27],
  [46, 113, 5, 92, 18, 105],
  [12, 57, 5, 26, 90, 137],
  [3, 50, 5, 17, 75, 83],
  [3, 113, 5, 32, 114, 99],
  [3, 66, 5, 36, 18, 38],
  [3, 43, 5, 36, 124, 34],
  [30, 50, 5, 107, 64, 47],
  [24, 52, 5, 26, 18, 99],
  [3, 119, 5, 107, 54, 95],
  [16, 28, 5, 89, 33, 88],
  [20, 135, 5, 63, 56, 138],
  [30, 116, 5, 107, 18, 65],
  [46, 43, 5, 13, 94, 45],
  [46, 104, 5, 63, 7, 99],
  [3, 28, 5, 92, 18, 115],
  [12, 28, 5, 89, 62, 80],
  [20, 31, 5, 59, 64, 71],
  [12, 66, 5, 89, 100, 78],
  [12, 25, 5, 26, 18, 126],
  [20, 50, 5, 26, 64, 84],
  [3, 110, 5, 107, 73, 101],
  [46, 96, 5, 17, 49, 88],
  [30, 69, 5, 13, 62, 138],
  [30, 81, 5, 92, 75, 83],
  [16, 87, 5, 59, 29, 99],
  [24, 123, 5, 89, 100, 45],
  [16, 132, 5, 22, 64, 131],
  [3, 106, 5, 59, 33, 47],
  [24, 113, 5, 32, 14, 47],
  [3, 125, 5, 36, 130, 51],
  [46, 79, 5, 22, 49, 105],
  [3, 133, 5, 89, 54, 68],
  [30, 35, 5, 26, 94, 78],
  [39, 28, 5, 107, 73, 101],
  [39, 48, 5, 36, 62, 95],
  [46, 106, 5, 36, 18, 34],
  [30, 72, 5, 59, 54, 122],
  [16, 106, 5, 63, 102, 27],
  [16, 140, 5, 107, 7, 78],
  [30, 135, 5, 22, 114, 71],
  [12, 72, 5, 59, 37, 47],
  [46, 35, 5, 63, 49, 121],
  [39, 50, 5, 107, 102, 126],
  [3, 66, 5, 63, 54, 93],
  [30, 66, 5, 92, 33, 129],
  [16, 85, 5, 63, 14, 74],
  [20, 117, 5, 13, 94, 35],
  [20, 106, 5, 107, 102, 74],
  [39, 96, 5, 59, 29, 109],
  [30, 112, 5, 89, 75, 61],
  [3, 96, 5, 63, 62, 131],
  [24, 104, 5, 36, 7, 10],
  [16, 81, 5, 59, 62, 45],
  [39, 31, 5, 17, 100, 76],
  [39, 103, 5, 107, 100, 99],
  [3, 81, 5, 63, 14, 108],
  [12, 43, 5, 13, 54, 47],
  [46, 57, 5, 86, 82, 131],
  [16, 104, 5, 6, 37, 11],
  [3, 31, 5, 32, 90, 11],
  [16, 69, 5, 107, 114, 134],
  [39, 48, 5, 13, 56, 129],
  [12, 57, 5, 63, 62, 47],
  [39, 142, 5, 13, 114, 105],
  [24, 27, 5, 107, 33, 111],
  [12, 79, 5, 26, 124, 122],
  [3, 50, 5, 13, 56, 101],
  [46, 66, 5, 22, 56, 129],
  [30, 69, 5, 107, 56, 11],
  [12, 87, 5, 107, 33, 68],
  [30, 43, 5, 26, 29, 115],
  [3, 85, 5, 17, 100, 65],
  [20, 113, 5, 13, 62, 115],
  [46, 31, 5, 53, 54, 99],
  [16, 72, 5, 17, 29, 65],
  [46, 43, 5, 32, 62, 10],
  [16, 10, 5, 22, 7, 83],
  [16, 135, 5, 36, 18, 129],
  [3, 72, 5, 32, 37, 105],
  [16, 110, 5, 13, 130, 35],
  [3, 72, 5, 22, 124, 101],
  [46, 48, 5, 13, 64, 122],
  [12, 35, 5, 6, 56, 99],
  [46, 127, 5, 92, 98, 122],
  [30, 113, 5, 13, 62, 131],
  [30, 140, 5, 22, 44, 71],
  [16, 25, 5, 89, 10, 23],
  [24, 28, 5, 22, 18, 11],
  [30, 25, 5, 53, 29, 137],
  [46, 106, 5, 59, 10, 91],
  [30, 55, 5, 17, 56, 108],
  [46, 4, 5, 36, 18, 83],
  [16, 52, 5, 139, 54, 101],
  [46, 113, 5, 6, 90, 42],
  [20, 69, 5, 17, 10, 8],
  [20, 128, 5, 17, 18, 70],
  [24, 43, 5, 89, 18, 131],
  [16, 119, 5, 32, 10, 70],
  [30, 140, 5, 13, 114, 115],
  [46, 81, 5, 107, 18, 11],
  [30, 106, 5, 59, 33, 121],
  [24, 106, 5, 22, 82, 8],
  [16, 35, 5, 92, 130, 61],
  [39, 55, 5, 92, 130, 122],
  [24, 81, 5, 17, 18, 19],
  [30, 110, 5, 107, 37, 93],
  [16, 28, 5, 17, 114, 19],
  [24, 112, 5, 17, 29, 67],
  [39, 66, 5, 63, 64, 19],
  [3, 43, 5, 22, 100, 84],
  [16, 119, 5, 89, 102, 65],
  [39, 9, 5, 26, 14, 101],
  [3, 28, 5, 13, 49, 115],
  [20, 21, 5, 107, 33, 78],
  [16, 87, 5, 59, 56, 108],
  [16, 4, 5, 13, 56, 109],
  [39, 85, 5, 13, 49, 65],
  [16, 123, 5, 107, 54, 105],
  [30, 79, 5, 22, 37, 35],
  [16, 28, 5, 32, 33, 61],
  [12, 40, 5, 59, 130, 121],
  [16, 57, 5, 26, 94, 99],
  [3, 85, 5, 107, 37, 45],
  [30, 106, 5, 26, 37, 65],
  [39, 25, 5, 59, 37, 93],
  [39, 106, 5, 89, 41, 11],
  [12, 57, 5, 63, 54, 10],
  [16, 141, 5, 6, 94, 136],
  [46, 69, 5, 63, 18, 91],
  [16, 140, 5, 17, 100, 122],
  [3, 57, 5, 26, 130, 70],
  [39, 57, 5, 17, 90, 38],
  [24, 106, 5, 86, 94, 99],
  [30, 58, 5, 107, 18, 101],
  [16, 120, 5, 107, 18, 108],
  [3, 116, 5, 59, 98, 108],
  [3, 132, 5, 6, 114, 45],
  [30, 43, 5, 22, 64, 70],
  [30, 132, 5, 6, 29, 117],
  [20, 57, 5, 107, 82, 47],
  [3, 50, 5, 107, 44, 117],
  [20, 81, 5, 107, 56, 61],
  [46, 96, 5, 107, 33, 131],
  [16, 106, 5, 17, 90, 80],
  [46, 35, 5, 26, 94, 76],
  [20, 112, 5, 26, 54, 122],
  [3, 128, 5, 36, 18, 68],
  [24, 31, 5, 13, 62, 65],
  [30, 96, 5, 59, 44, 88],
  [24, 106, 5, 53, 14, 84],
  [16, 106, 5, 22, 73, 65],
  [12, 31, 5, 17, 62, 126],
  [24, 48, 5, 107, 94, 10],
  [12, 28, 5, 63, 7, 8],
  [3, 96, 5, 22, 44, 76],
  [39, 58, 5, 59, 18, 38],
  [16, 52, 5, 107, 114, 11],
  [12, 113, 5, 59, 41, 76],
  [24, 123, 5, 13, 130, 67],
  [30, 133, 5, 89, 18, 99],
  [3, 27, 5, 22, 100, 34],
  [46, 43, 5, 17, 90, 71],
  [30, 52, 5, 13, 18, 10],
  [20, 25, 5, 53, 75, 76],
  [16, 133, 5, 107, 100, 126],
  [46, 48, 5, 92, 29, 101],
  [16, 112, 5, 6, 62, 80],
  [16, 106, 5, 17, 114, 67],
  [24, 57, 5, 26, 18, 129],
  [24, 58, 5, 26, 94, 118],
  [12, 4, 5, 89, 14, 35],
  [16, 66, 5, 89, 29, 67],
  [20, 43, 5, 13, 56, 83],
  [16, 106, 5, 89, 29, 115],
  [16, 27, 5, 36, 18, 115],
  [16, 106, 5, 107, 90, 111],
  [39, 113, 5, 13, 37, 10],
  [24, 104, 5, 36, 64, 80],
  [12, 58, 5, 13, 33, 78],
  [46, 77, 5, 59, 18, 84],
  [24, 88, 5, 6, 18, 91],
  [39, 50, 5, 59, 49, 19],
  [16, 55, 5, 107, 18, 65],
  [30, 31, 5, 92, 33, 91],
  [12, 31, 5, 17, 14, 131],
  [30, 132, 5, 92, 18, 34],
  [3, 35, 5, 36, 18, 8],
  [46, 104, 5, 63, 114, 61],
  [3, 103, 5, 63, 94, 91],
  [20, 119, 5, 13, 94, 115],
  [12, 103, 5, 92, 100, 99],
  [46, 69, 5, 63, 33, 126],
  [20, 85, 5, 53, 14, 84],
  [39, 119, 5, 86, 124, 45],
  [39, 106, 5, 63, 94, 108],
  [12, 132, 5, 36, 54, 67],
  [12, 66, 5, 59, 54, 129],
  [39, 69, 5, 17, 90, 122],
  [39, 125, 5, 32, 130, 65],
  [46, 43, 5, 32, 64, 11],
  [46, 35, 5, 107, 73, 109],
  [30, 104, 5, 53, 29, 38],
  [39, 25, 5, 6, 18, 118],
  [16, 88, 5, 107, 18, 80],
  [3, 132, 5, 17, 49, 51],
  [46, 127, 5, 22, 114, 105],
  [12, 132, 5, 17, 14, 109],
  [46, 43, 5, 13, 54, 111],
  [24, 135, 5, 26, 18, 76],
  [24, 106, 5, 6, 94, 78],
  [12, 31, 5, 26, 90, 70],
  [20, 25, 5, 17, 29, 136],
  [20, 72, 5, 22, 18, 74],
  [3, 72, 5, 13, 44, 23],
  [30, 113, 5, 13, 82, 47],
  [30, 9, 5, 26, 7, 99],
  [39, 69, 5, 17, 100, 121],
  [46, 104, 5, 139, 56, 88],
  [20, 57, 5, 32, 54, 76],
  [20, 133, 5, 32, 102, 95],
  [16, 112, 5, 53, 7, 122],
  [3, 135, 5, 6, 100, 109],
  [30, 135, 5, 59, 82, 15],
  [30, 104, 5, 22, 29, 8],
  [16, 4, 5, 22, 18, 67],
  [20, 104, 5, 36, 37, 11],
  [20, 57, 5, 89, 75, 121],
  [46, 25, 5, 53, 18, 19],
  [24, 69, 5, 36, 49, 84],
  [39, 43, 5, 63, 100, 121],
  [24, 31, 5, 22, 29, 10],
  [20, 35, 5, 17, 82, 61],
  [46, 43, 5, 22, 7, 121],
  [20, 103, 5, 26, 73, 101],
  [46, 55, 5, 13, 64, 83],
  [16, 87, 5, 36, 75, 131],
  [20, 69, 5, 32, 14, 65],
  [20, 69, 5, 59, 29, 121],
  [46, 96, 5, 26, 98, 131],
  [46, 50, 5, 53, 7, 143],
  [24, 79, 5, 17, 54, 111],
  [30, 31, 5, 92, 82, 8],
  [20, 85, 5, 36, 14, 137],
  [20, 103, 5, 26, 130, 65],
  [46, 66, 5, 36, 114, 65],
  [39, 57, 5, 17, 102, 76],
  [30, 81, 5, 53, 73, 34],
  [12, 69, 5, 32, 18, 83],
  [46, 120, 5, 22, 114, 131],
  [39, 52, 5, 86, 62, 121],
  [12, 106, 5, 89, 94, 34],
  [3, 28, 5, 107, 14, 111],
  [3, 55, 5, 13, 82, 11],
  [12, 103, 5, 89, 7, 121],
  [3, 57, 5, 13, 130, 76],
  [12, 50, 5, 36, 7, 97],
  [3, 103, 5, 32, 18, 95],
  [16, 52, 5, 6, 33, 47],
  [39, 120, 5, 6, 18, 131],
  [3, 113, 5, 63, 33, 78],
  [16, 43, 5, 107, 124, 138],
  [20, 58, 5, 107, 18, 67],
  [30, 132, 5, 17, 100, 80],
  [16, 50, 5, 36, 29, 105],
  [30, 135, 5, 63, 18, 101],
  [3, 103, 5, 86, 114, 10],
  [24, 35, 5, 63, 33, 74],
  [24, 55, 5, 89, 33, 71],
  [30, 10, 5, 6, 29, 118],
  [46, 113, 5, 59, 73, 111],
  [12, 35, 5, 32, 29, 67],
  [16, 28, 5, 36, 29, 108],
  [39, 112, 5, 107, 37, 117],
  [39, 104, 5, 92, 64, 131],
  [12, 50, 5, 36, 18, 35],
  [30, 31, 5, 13, 62, 121],
  [12, 96, 5, 22, 10, 126],
  [20, 35, 5, 92, 75, 83],
  [39, 55, 5, 92, 7, 121],
  [16, 132, 5, 22, 10, 61],
  [16, 35, 5, 107, 114, 65],
  [16, 103, 5, 13, 18, 74],
  [24, 85, 5, 17, 7, 76],
  [46, 132, 5, 86, 130, 45],
  [3, 113, 5, 107, 114, 122],
  [12, 103, 5, 17, 29, 121],
  [20, 96, 5, 107, 90, 68],
  [20, 119, 5, 17, 10, 99],
  [30, 103, 5, 13, 124, 70],
  [3, 96, 5, 26, 94, 68],
  [46, 106, 5, 32, 56, 108],
  [30, 117, 5, 89, 94, 115],
  [20, 112, 5, 17, 29, 71],
  [12, 31, 5, 22, 100, 83],
  [24, 140, 5, 32, 18, 105],
  [46, 10, 5, 17, 44, 121],
  [39, 48, 5, 6, 18, 99],
  [39, 117, 5, 26, 82, 10],
  [20, 81, 5, 32, 29, 71],
  [16, 28, 5, 89, 18, 95],
  [3, 104, 5, 26, 18, 38],
  [3, 31, 5, 89, 18, 8],
  [30, 85, 5, 6, 94, 74],
  [20, 127, 5, 17, 56, 91],
  [16, 103, 5, 6, 64, 115],
  [3, 57, 5, 92, 130, 122],
  [20, 79, 5, 32, 73, 67],
  [16, 31, 5, 26, 102, 108],
  [39, 112, 5, 17, 94, 11],
  [46, 25, 5, 13, 44, 10],
  [12, 117, 5, 107, 82, 131],
  [39, 103, 5, 59, 33, 137],
  [3, 27, 5, 107, 56, 47],
  [46, 85, 5, 53, 62, 34],
  [39, 106, 5, 22, 18, 121],
  [46, 28, 5, 63, 33, 118],
  [16, 81, 5, 92, 33, 10],
  [16, 43, 5, 6, 29, 67],
  [24, 43, 5, 107, 73, 108],
  [16, 55, 5, 36, 64, 111],
  [39, 66, 5, 36, 114, 70],
  [3, 55, 5, 17, 44, 91],
  [3, 31, 5, 26, 102, 71],
  [16, 28, 5, 107, 37, 80],
  [39, 103, 5, 17, 64, 91],
  [30, 132, 5, 17, 98, 45],
  [3, 40, 5, 59, 44, 61],
  [39, 81, 5, 92, 54, 11],
  [46, 87, 5, 92, 90, 101],
  [30, 96, 5, 53, 100, 68],
  [39, 96, 5, 32, 130, 137],
  [20, 133, 5, 32, 44, 111],
  [20, 28, 5, 92, 73, 8],
  [46, 125, 5, 32, 114, 121],
  [39, 31, 5, 17, 18, 108],
  [46, 48, 5, 89, 33, 122],
  [20, 104, 5, 13, 10, 126],
  [16, 106, 5, 107, 90, 83],
  [24, 69, 5, 59, 18, 122],
  [16, 35, 5, 92, 44, 34],
  [3, 57, 5, 13, 90, 117],
  [46, 57, 5, 26, 18, 115],
  [16, 140, 5, 107, 73, 80],
  [12, 104, 5, 107, 18, 11],
  [3, 104, 5, 13, 75, 109],
  [3, 58, 5, 32, 7, 88],
  [3, 104, 5, 32, 33, 101],
  [30, 96, 5, 36, 130, 83],
  [16, 81, 5, 59, 130, 105],
  [12, 72, 5, 6, 82, 99],
  [20, 125, 5, 63, 29, 83],
  [39, 35, 5, 17, 98, 131],
  [3, 112, 5, 59, 90, 11],
  [16, 55, 5, 107, 10, 8],
  [30, 87, 5, 36, 37, 129],
  [39, 104, 5, 89, 18, 129],
  [20, 79, 5, 6, 54, 51],
  [30, 66, 5, 17, 90, 71],
  [39, 87, 5, 32, 75, 111],
  [39, 112, 5, 36, 44, 99],
  [46, 52, 5, 36, 10, 105],
  [16, 57, 5, 63, 14, 78],
  [12, 132, 5, 92, 18, 11],
  [20, 28, 5, 17, 75, 68],
  [12, 21, 5, 26, 18, 101],
  [16, 31, 5, 17, 7, 45],
  [30, 103, 5, 107, 62, 47],
  [39, 106, 5, 22, 102, 76],
  [46, 87, 5, 63, 54, 83],
  [3, 96, 5, 13, 62, 47],
  [20, 10, 5, 13, 7, 91],
  [3, 25, 5, 32, 54, 101],
  [16, 66, 5, 63, 49, 115],
  [3, 125, 5, 26, 94, 99],
  [16, 31, 5, 63, 18, 97],
  [39, 112, 5, 53, 94, 83],
  [3, 88, 5, 32, 62, 67],
  [12, 77, 5, 107, 18, 122],
  [24, 35, 5, 89, 90, 101],
  [3, 104, 5, 26, 18, 10],
  [39, 50, 5, 13, 102, 78],
  [3, 50, 5, 36, 29, 108],
  [39, 140, 5, 32, 56, 45],
  [46, 4, 5, 107, 33, 67],
  [16, 28, 5, 26, 44, 131],
  [12, 10, 5, 22, 62, 93],
  [39, 119, 5, 92, 64, 38],
  [46, 135, 5, 32, 33, 129],
  [16, 58, 5, 26, 33, 70],
  [30, 43, 5, 32, 44, 68],
  [46, 58, 5, 59, 18, 8],
  [39, 96, 5, 32, 7, 101],
  [24, 87, 5, 32, 33, 8],
  [16, 103, 5, 22, 73, 8],
  [3, 72, 5, 86, 114, 70],
  [39, 132, 5, 59, 100, 105],
  [46, 69, 5, 6, 54, 15],
  [16, 69, 5, 32, 7, 68],
  [39, 40, 5, 13, 94, 115],
  [30, 31, 5, 13, 102, 78],
  [39, 119, 5, 13, 37, 27],
  [46, 96, 5, 13, 33, 137],
  [46, 57, 5, 13, 73, 34],
  [30, 72, 5, 89, 18, 15],
  [46, 35, 5, 17, 75, 99],
  [3, 120, 5, 17, 29, 137],
  [30, 48, 5, 89, 54, 121],
  [3, 28, 5, 53, 33, 91],
  [12, 142, 5, 13, 18, 115],
  [30, 50, 5, 17, 33, 68],
  [3, 112, 5, 26, 18, 76],
  [3, 140, 5, 63, 14, 138],
  [12, 66, 5, 6, 18, 76],
  [12, 25, 5, 22, 100, 45],
  [46, 55, 5, 107, 62, 38],
  [39, 103, 5, 36, 18, 45],
  [12, 119, 5, 32, 98, 121],
  [16, 88, 5, 26, 44, 105],
  [39, 55, 5, 26, 33, 91],
  [46, 58, 5, 92, 18, 108],
  [24, 132, 5, 6, 100, 68],
  [39, 112, 5, 63, 10, 101],
  [16, 113, 5, 22, 60, 122],
  [12, 35, 5, 13, 29, 105],
  [16, 112, 5, 6, 7, 129],
  [24, 28, 5, 107, 14, 19],
  [3, 58, 5, 36, 100, 111],
  [46, 48, 5, 107, 98, 61],
  [3, 104, 5, 89, 82, 71],
  [12, 48, 5, 36, 14, 68],
  [20, 48, 5, 26, 10, 74],
  [24, 116, 5, 86, 18, 131],
  [3, 55, 5, 86, 29, 83],
  [39, 66, 5, 59, 44, 121],
  [12, 57, 5, 59, 33, 61],
  [30, 57, 5, 53, 73, 108],
  [16, 119, 5, 107, 90, 134],
  [24, 79, 5, 107, 102, 91],
  [12, 104, 5, 92, 90, 70],
  [12, 50, 5, 53, 75, 11],
  [16, 77, 5, 17, 54, 47],
  [16, 117, 5, 92, 18, 68],
  [3, 21, 5, 32, 64, 83],
  [46, 43, 5, 32, 56, 51],
  [30, 69, 5, 17, 90, 68],
  [16, 85, 5, 53, 44, 15],
  [20, 31, 5, 107, 56, 27],
  [30, 21, 5, 107, 124, 121],
  [3, 79, 5, 92, 94, 131],
  [39, 119, 5, 89, 44, 38],
  [16, 81, 5, 17, 10, 83],
  [39, 112, 5, 22, 102, 38],
  [3, 28, 5, 63, 64, 101],
  [16, 58, 5, 107, 41, 101],
  [3, 116, 5, 92, 33, 95],
  [20, 119, 5, 59, 124, 10],
  [16, 135, 5, 53, 130, 129],
  [16, 55, 5, 107, 18, 45],
  [20, 85, 5, 13, 100, 47],
  [30, 142, 5, 86, 102, 65],
  [3, 31, 5, 89, 94, 109],
  [46, 116, 5, 36, 14, 122],
  [3, 52, 5, 26, 18, 109],
  [12, 35, 5, 13, 94, 51],
  [30, 28, 5, 36, 18, 91],
  [46, 140, 5, 59, 94, 80],
  [30, 104, 5, 22, 62, 129],
  [3, 72, 5, 32, 73, 65],
  [39, 112, 5, 6, 100, 74],
  [46, 25, 5, 92, 102, 101],
  [20, 35, 5, 13, 14, 91],
  [46, 79, 5, 63, 98, 71],
  [30, 87, 5, 92, 130, 115],
  [30, 66, 5, 36, 18, 91],
  [46, 104, 5, 53, 10, 99],
  [39, 104, 5, 92, 64, 8],
  [30, 104, 5, 17, 41, 11],
  [12, 112, 5, 107, 94, 118],
  [20, 28, 5, 107, 130, 19],
  [30, 110, 5, 32, 18, 68],
  [20, 69, 5, 13, 73, 35],
  [30, 85, 5, 6, 90, 35],
  [16, 48, 5, 13, 130, 99],
  [30, 112, 5, 107, 18, 45],
  [16, 31, 5, 13, 64, 68],
  [24, 103, 5, 17, 44, 76],
  [30, 106, 5, 36, 18, 78],
  [20, 104, 5, 89, 10, 97],
  [30, 66, 5, 6, 37, 35],
  [30, 69, 5, 63, 114, 34],
  [24, 4, 5, 17, 18, 71],
  [12, 123, 5, 53, 18, 121],
  [16, 106, 5, 107, 10, 76],
  [39, 69, 5, 26, 7, 101],
  [39, 57, 5, 107, 54, 121],
  [46, 132, 5, 59, 18, 35],
  [12, 69, 5, 92, 29, 74],
  [12, 141, 5, 17, 18, 74],
  [3, 69, 5, 17, 102, 10],
  [46, 52, 5, 26, 18, 51],
  [46, 85, 5, 89, 29, 109],
  [3, 112, 5, 17, 14, 93],
  [24, 48, 5, 92, 37, 115],
  [12, 106, 5, 107, 114, 78],
  [39, 104, 5, 13, 64, 101],
  [30, 4, 5, 22, 18, 10],
  [30, 135, 5, 17, 54, 34],
  [30, 43, 5, 107, 10, 71],
  [12, 119, 5, 26, 18, 91],
  [3, 66, 5, 92, 124, 101],
  [3, 104, 5, 22, 54, 101],
  [39, 132, 5, 17, 29, 99],
  [20, 48, 5, 36, 18, 83],
  [12, 141, 5, 32, 56, 68],
  [46, 103, 5, 63, 14, 88],
  [46, 135, 5, 22, 75, 11],
  [46, 31, 5, 17, 62, 83],
  [39, 81, 5, 92, 7, 38],
  [3, 104, 5, 22, 75, 71],
  [16, 27, 5, 107, 54, 23],
  [16, 135, 5, 13, 82, 99],
  [16, 28, 5, 13, 29, 109],
  [20, 66, 5, 17, 94, 143],
  [16, 103, 5, 17, 49, 111],
  [20, 120, 5, 107, 64, 138],
  [39, 58, 5, 13, 37, 131],
  [12, 52, 5, 32, 94, 111],
  [39, 43, 5, 17, 82, 74],
  [16, 4, 5, 13, 54, 51],
  [12, 127, 5, 92, 33, 11],
  [46, 103, 5, 53, 49, 10],
  [46, 25, 5, 26, 75, 115],
  [39, 79, 5, 13, 54, 42],
  [20, 103, 5, 59, 130, 121],
  [16, 112, 5, 63, 94, 78],
  [16, 27, 5, 92, 56, 45],
  [3, 57, 5, 59, 18, 70],
  [16, 133, 5, 36, 18, 70],
  [24, 48, 5, 32, 75, 67],
  [39, 113, 5, 36, 73, 99],
  [39, 96, 5, 89, 62, 68],
  [30, 132, 5, 107, 75, 95],
  [39, 55, 5, 17, 102, 71],
  [39, 106, 5, 17, 14, 83],
  [16, 81, 5, 86, 130, 35],
  [20, 106, 5, 13, 94, 76],
  [3, 43, 5, 6, 62, 121],
  [16, 31, 5, 107, 64, 122],
  [30, 55, 5, 26, 62, 105],
  [20, 4, 5, 89, 82, 91],
  [39, 113, 5, 89, 94, 105],
  [30, 132, 5, 17, 64, 83],
  [24, 142, 5, 6, 62, 118],
  [3, 57, 5, 32, 90, 19],
  [46, 135, 5, 32, 82, 111],
  [24, 28, 5, 107, 73, 19],
  [3, 4, 5, 22, 75, 71],
  [30, 35, 5, 17, 33, 83],
  [39, 50, 5, 17, 64, 34],
  [3, 103, 5, 22, 18, 19],
  [30, 66, 5, 139, 54, 109],
  [12, 66, 5, 17, 10, 78],
  [16, 43, 5, 6, 90, 91],
  [20, 58, 5, 32, 114, 134],
  [16, 103, 5, 17, 114, 78],
  [3, 58, 5, 59, 64, 109],
  [39, 81, 5, 63, 100, 118],
  [30, 133, 5, 26, 56, 121],
  [39, 81, 5, 53, 114, 126],
  [24, 66, 5, 17, 90, 122],
  [39, 72, 5, 17, 73, 70],
  [12, 85, 5, 13, 29, 11],
  [12, 104, 5, 26, 73, 11],
  [3, 81, 5, 59, 44, 99],
  [46, 77, 5, 32, 56, 91],
  [16, 88, 5, 13, 130, 80],
  [30, 132, 5, 107, 49, 105],
  [39, 28, 5, 63, 62, 138],
  [24, 142, 5, 22, 94, 65],
  [16, 31, 5, 26, 29, 88],
  [20, 69, 5, 22, 14, 45],
  [3, 66, 5, 13, 114, 47],
  [12, 43, 5, 63, 102, 121],
  [20, 104, 5, 63, 56, 105],
  [3, 106, 5, 32, 130, 10],
  [46, 81, 5, 107, 75, 105],
  [12, 48, 5, 107, 64, 10],
  [39, 69, 5, 59, 18, 121],
  [3, 35, 5, 107, 130, 126],
  [20, 85, 5, 92, 102, 8],
  [39, 48, 5, 107, 18, 47],
  [46, 72, 5, 92, 29, 51],
  [24, 135, 5, 92, 102, 99],
  [3, 69, 5, 36, 54, 121],
  [3, 55, 5, 86, 18, 118],
  [3, 112, 5, 6, 37, 27],
  [16, 40, 5, 6, 94, 105],
  [20, 106, 5, 6, 114, 131],
  [16, 112, 5, 53, 75, 8],
  [46, 133, 5, 63, 37, 121],
  [3, 25, 5, 17, 18, 67],
  [16, 43, 5, 89, 102, 137],
  [20, 127, 5, 17, 64, 121],
  [20, 81, 5, 107, 33, 126],
  [24, 113, 5, 92, 49, 11],
  [12, 4, 5, 13, 54, 78],
  [30, 27, 5, 59, 33, 91],
  [16, 125, 5, 22, 29, 8],
  [46, 132, 5, 17, 56, 93],
  [16, 103, 5, 92, 18, 61],
  [3, 103, 5, 6, 62, 67],
  [3, 133, 5, 36, 18, 105],
  [3, 135, 5, 13, 33, 67],
  [12, 43, 5, 89, 7, 80],
  [16, 87, 5, 36, 114, 121],
  [3, 28, 5, 13, 94, 42],
  [46, 96, 5, 17, 54, 70],
  [3, 77, 5, 13, 73, 101],
  [12, 9, 5, 107, 18, 138],
  [24, 57, 5, 13, 7, 111],
  [16, 106, 5, 17, 54, 65],
  [16, 112, 5, 63, 37, 80],
  [39, 127, 5, 22, 90, 80],
  [46, 69, 5, 63, 90, 71],
  [30, 120, 5, 107, 94, 84],
  [46, 27, 5, 13, 37, 11],
  [3, 113, 5, 36, 102, 23],
  [3, 48, 5, 59, 18, 8],
  [46, 58, 5, 22, 124, 84],
  [20, 21, 5, 17, 44, 111],
  [24, 132, 5, 53, 62, 65],
  [39, 66, 5, 32, 100, 111],
  [39, 85, 5, 22, 73, 38],
  [30, 79, 5, 107, 94, 129],
  [30, 58, 5, 63, 18, 108],
  [46, 35, 5, 13, 100, 80],
  [12, 72, 5, 17, 10, 76],
  [20, 79, 5, 36, 33, 83],
  [39, 104, 5, 13, 130, 71],
  [16, 43, 5, 92, 130, 71],
  [20, 55, 5, 36, 64, 76],
  [16, 50, 5, 13, 64, 136],
  [30, 132, 5, 36, 37, 115],
  [12, 112, 5, 32, 102, 71],
  [20, 28, 5, 36, 124, 11],
  [16, 72, 5, 59, 18, 74],
  [16, 128, 5, 13, 18, 8],
  [16, 57, 5, 13, 33, 34],
  [30, 110, 5, 26, 49, 27],
  [39, 28, 5, 26, 100, 65],
  [30, 69, 5, 13, 18, 35],
  [3, 142, 5, 13, 82, 65],
  [3, 58, 5, 36, 73, 129],
  [30, 123, 5, 89, 75, 122],
  [12, 57, 5, 89, 18, 121],
  [16, 119, 5, 17, 56, 76],
  [30, 117, 5, 107, 82, 27],
  [3, 4, 5, 17, 102, 74],
  [39, 106, 5, 32, 33, 47],
  [39, 43, 5, 59, 64, 8],
  [46, 21, 5, 13, 33, 121],
  [20, 48, 5, 36, 73, 129],
  [3, 52, 5, 17, 100, 122],
  [46, 81, 5, 26, 10, 68],
  [16, 81, 5, 17, 33, 111],
  [16, 28, 5, 107, 18, 121],
  [3, 10, 5, 13, 56, 11],
  [24, 120, 5, 59, 37, 99],
  [46, 10, 5, 53, 54, 80],
  [16, 43, 5, 53, 18, 121],
  [30, 48, 5, 36, 14, 74],
  [16, 112, 5, 17, 94, 19],
  [24, 28, 5, 36, 130, 11],
  [30, 79, 5, 59, 102, 101],
  [24, 112, 5, 107, 18, 19],
  [46, 87, 5, 107, 44, 80],
  [3, 4, 5, 92, 18, 131],
  [12, 43, 5, 107, 14, 137],
  [3, 135, 5, 17, 73, 38],
  [39, 81, 5, 32, 75, 76],
  [12, 96, 5, 92, 18, 138],
  [30, 103, 5, 26, 64, 65],
  [12, 106, 5, 107, 62, 76],
  [30, 69, 5, 13, 114, 47],
  [46, 132, 5, 107, 130, 65],
  [30, 104, 5, 92, 64, 111],
  [39, 28, 5, 32, 90, 99],
  [30, 55, 5, 26, 73, 101],
  [12, 85, 5, 32, 29, 42],
  [16, 103, 5, 59, 73, 8],
  [24, 117, 5, 92, 49, 78],
  [12, 66, 5, 22, 75, 34],
  [20, 81, 5, 107, 130, 68],
  [30, 48, 5, 17, 29, 76],
  [3, 103, 5, 107, 33, 67],
  [30, 9, 5, 32, 94, 34],
  [12, 66, 5, 22, 44, 74],
  [20, 52, 5, 53, 29, 71],
  [16, 58, 5, 13, 49, 76],
  [146, 146, 146, 146, 146, 146],
  [46, 52, 5, 22, 114, 101],
  [46, 133, 5, 13, 44, 83],
  [30, 28, 5, 63, 73, 74],
  [46, 104, 5, 36, 102, 91],
  [39, 103, 5, 36, 29, 109],
  [3, 135, 5, 17, 49, 105],
  [46, 88, 5, 59, 18, 122],
  [39, 52, 5, 36, 124, 117],
  [24, 69, 5, 13, 18, 70],
  [20, 110, 5, 92, 18, 65],
  [16, 28, 5, 13, 130, 45],
  [16, 43, 5, 63, 73, 93],
  [39, 96, 5, 13, 75, 109],
  [16, 25, 5, 53, 10, 71],
  [12, 127, 5, 36, 18, 78],
  [46, 35, 5, 59, 18, 136],
  [46, 119, 5, 36, 29, 10],
  [3, 28, 5, 92, 18, 34],
  [20, 141, 5, 63, 114, 11],
  [16, 85, 5, 59, 75, 35],
  [16, 87, 5, 22, 75, 70],
  [12, 52, 5, 22, 33, 80],
  [46, 79, 5, 17, 18, 121],
  [30, 4, 5, 26, 18, 111],
  [20, 31, 5, 59, 56, 45],
  [16, 40, 5, 59, 73, 11],
  [3, 4, 5, 32, 114, 129],
  [39, 132, 5, 17, 14, 8],
  [12, 57, 5, 32, 37, 118],
  [12, 103, 5, 89, 14, 101],
  [12, 96, 5, 89, 18, 105],
  [20, 35, 5, 13, 10, 131],
  [12, 43, 5, 17, 18, 45],
  [30, 133, 5, 17, 7, 101],
  [39, 132, 5, 92, 56, 108],
  [16, 50, 5, 6, 73, 115],
  [20, 58, 5, 6, 33, 93],
  [30, 28, 5, 13, 75, 76],
  [3, 48, 5, 53, 102, 45],
  [46, 81, 5, 36, 82, 108],
  [16, 27, 5, 107, 56, 101],
  [46, 104, 5, 6, 98, 80],
  [46, 55, 5, 107, 18, 80],
  [46, 81, 5, 59, 73, 115],
  [24, 96, 5, 63, 90, 51],
  [16, 119, 5, 17, 14, 45],
  [16, 133, 5, 107, 44, 101],
  [16, 28, 5, 13, 29, 71],
  [12, 58, 5, 89, 44, 109],
  [39, 55, 5, 26, 94, 131],
  [3, 96, 5, 86, 75, 138],
  [30, 104, 5, 89, 54, 134],
  [39, 125, 5, 63, 44, 74],
  [12, 69, 5, 59, 75, 61],
  [3, 104, 5, 6, 94, 115],
  [20, 87, 5, 13, 54, 76],
  [24, 123, 5, 32, 73, 83],
  [46, 87, 5, 32, 41, 76],
  [30, 48, 5, 6, 18, 45],
  [24, 87, 5, 89, 114, 68],
  [24, 57, 5, 13, 18, 80],
  [39, 55, 5, 32, 62, 47],
  [16, 28, 5, 22, 33, 101],
  [20, 113, 5, 89, 33, 83],
  [12, 28, 5, 6, 130, 70],
  [39, 10, 5, 63, 56, 122],
  [20, 132, 5, 36, 18, 105],
  [46, 66, 5, 6, 56, 67],
  [12, 113, 5, 6, 82, 74],
  [20, 58, 5, 22, 29, 61],
  [16, 88, 5, 59, 14, 126],
  [16, 35, 5, 17, 14, 70],
  [16, 110, 5, 17, 18, 105],
  [20, 55, 5, 92, 18, 115],
  [46, 117, 5, 59, 100, 8],
  [16, 104, 5, 17, 33, 8],
  [39, 106, 5, 89, 18, 51],
  [30, 28, 5, 89, 73, 19],
  [20, 96, 5, 13, 14, 45],
  [16, 43, 5, 36, 29, 105],
  [39, 10, 5, 53, 64, 51],
  [39, 96, 5, 6, 100, 108],
  [20, 81, 5, 107, 62, 34],
  [3, 66, 5, 92, 33, 34],
  [30, 96, 5, 36, 33, 108],
  [30, 52, 5, 26, 18, 105],
  [24, 81, 5, 22, 130, 137],
  [16, 113, 5, 26, 18, 99],
  [30, 58, 5, 26, 114, 71],
  [30, 96, 5, 53, 54, 35],
  [16, 43, 5, 17, 100, 122],
  [30, 48, 5, 36, 102, 138],
  [12, 28, 5, 13, 18, 10],
  [16, 116, 5, 107, 100, 19],
  [46, 43, 5, 92, 7, 117],
  [16, 27, 5, 86, 94, 101],
  [39, 119, 5, 32, 14, 108],
  [30, 132, 5, 63, 44, 83],
  [16, 57, 5, 13, 10, 34],
  [16, 66, 5, 26, 73, 121],
  [46, 135, 5, 92, 100, 109],
  [12, 87, 5, 22, 10, 83],
  [3, 103, 5, 13, 90, 84],
  [3, 106, 5, 32, 18, 35],
  [16, 140, 5, 17, 64, 115],
  [30, 72, 5, 59, 114, 8],
  [12, 57, 5, 13, 100, 129],
  [3, 57, 5, 36, 75, 8],
  [46, 43, 5, 53, 14, 115],
  [16, 103, 5, 26, 102, 83],
  [16, 103, 5, 53, 37, 101],
  [46, 28, 5, 59, 10, 34],
  [46, 88, 5, 26, 37, 45],
  [3, 117, 5, 36, 44, 105],
  [39, 43, 5, 32, 56, 74],
  [20, 66, 5, 6, 54, 67],
  [46, 96, 5, 26, 100, 65],
  [39, 106, 5, 59, 56, 83],
  [20, 48, 5, 36, 54, 121],
  [30, 128, 5, 22, 64, 74],
  [39, 28, 5, 63, 94, 78],
  [30, 43, 5, 26, 44, 105],
  [12, 132, 5, 13, 82, 74],
  [39, 87, 5, 63, 33, 78],
  [12, 25, 5, 53, 82, 117],
  [46, 58, 5, 107, 37, 83],
  [39, 116, 5, 107, 130, 99],
  [39, 106, 5, 53, 64, 88],
  [24, 104, 5, 36, 62, 137],
  [16, 104, 5, 22, 130, 84],
  [12, 103, 5, 17, 18, 8],
  [20, 79, 5, 22, 7, 109],
  [12, 96, 5, 17, 94, 91],
  [39, 119, 5, 63, 64, 67],
  [3, 132, 5, 26, 18, 74],
  [30, 132, 5, 17, 7, 51],
  [3, 31, 5, 107, 62, 11],
  [16, 117, 5, 17, 94, 88],
  [20, 132, 5, 107, 82, 67],
  [39, 72, 5, 59, 37, 95],
  [46, 132, 5, 13, 54, 97],
  [16, 31, 5, 92, 14, 78],
  [46, 35, 5, 17, 56, 99],
  [24, 87, 5, 107, 18, 78],
  [39, 123, 5, 26, 90, 95],
  [3, 69, 5, 22, 62, 78],
  [3, 81, 5, 13, 130, 111],
  [30, 72, 5, 59, 130, 8],
  [39, 66, 5, 107, 62, 118],
  [30, 81, 5, 6, 90, 105],
  [3, 35, 5, 26, 18, 143],
  [3, 125, 5, 17, 114, 47],
  [16, 113, 5, 17, 10, 117],
  [20, 69, 5, 107, 54, 68],
  [20, 119, 5, 13, 75, 47],
  [46, 135, 5, 89, 82, 65],
  [20, 9, 5, 63, 18, 34],
  [3, 106, 5, 13, 7, 121],
  [16, 31, 5, 13, 18, 51],
  [39, 77, 5, 13, 82, 38],
  [46, 81, 5, 13, 29, 70],
  [3, 132, 5, 17, 82, 76],
  [20, 31, 5, 107, 56, 76],
  [20, 43, 5, 13, 90, 76],
  [3, 103, 5, 63, 124, 105],
  [3, 87, 5, 22, 7, 83],
  [39, 104, 5, 59, 130, 97],
  [16, 120, 5, 17, 18, 68],
  [16, 57, 5, 36, 18, 111],
  [46, 135, 5, 36, 33, 129],
  [12, 104, 5, 36, 18, 99],
  [20, 79, 5, 53, 14, 61],
  [24, 112, 5, 36, 75, 121],
  [46, 69, 5, 22, 73, 105],
  [24, 123, 5, 89, 56, 47],
  [20, 52, 5, 63, 90, 11],
  [20, 35, 5, 17, 44, 117],
  [39, 40, 5, 26, 18, 65],
  [46, 103, 5, 92, 100, 91],
  [46, 55, 5, 36, 56, 101],
  [20, 72, 5, 13, 62, 11],
  [24, 31, 5, 26, 73, 111],
  [30, 69, 5, 26, 94, 65],
  [16, 96, 5, 26, 18, 105],
  [30, 103, 5, 59, 18, 74],
  [20, 112, 5, 17, 90, 51],
  [3, 35, 5, 17, 54, 121],
  [30, 10, 5, 59, 130, 74],
  [39, 72, 5, 26, 33, 61],
  [3, 55, 5, 63, 62, 11],
  [20, 72, 5, 36, 33, 11],
  [20, 35, 5, 32, 56, 126],
  [39, 58, 5, 92, 90, 65],
  [46, 72, 5, 92, 56, 11],
  [12, 77, 5, 6, 124, 97],
  [3, 58, 5, 59, 62, 115],
  [3, 50, 5, 107, 94, 83],
  [16, 43, 5, 6, 54, 84],
  [46, 69, 5, 36, 64, 35],
  [30, 81, 5, 92, 18, 76],
  [24, 50, 5, 26, 29, 101],
  [39, 103, 5, 92, 56, 99],
  [30, 119, 5, 53, 7, 131],
  [12, 57, 5, 59, 130, 115],
  [3, 117, 5, 22, 102, 80],
  [46, 50, 5, 26, 18, 42],
  [30, 132, 5, 53, 114, 45],
  [3, 103, 5, 17, 54, 78],
  [46, 132, 5, 32, 18, 70],
  [3, 31, 5, 17, 54, 61],
  [46, 72, 5, 59, 54, 27],
  [39, 116, 5, 13, 18, 34],
  [39, 123, 5, 59, 18, 68],
  [24, 112, 5, 13, 29, 47],
  [16, 110, 5, 59, 18, 45],
  [46, 106, 5, 32, 75, 134],
  [39, 28, 5, 32, 37, 122],
  [20, 28, 5, 59, 37, 11],
  [3, 55, 5, 36, 130, 65],
  [46, 88, 5, 26, 33, 115],
  [30, 123, 5, 26, 130, 91],
  [46, 106, 5, 26, 54, 129],
  [39, 140, 5, 22, 94, 70],
  [30, 57, 5, 13, 114, 76],
  [16, 40, 5, 6, 90, 65],
  [30, 106, 5, 13, 62, 108],
  [30, 66, 5, 6, 18, 97],
  [12, 55, 5, 53, 33, 65],
  [39, 81, 5, 32, 37, 121],
  [39, 66, 5, 63, 7, 126],
  [46, 135, 5, 6, 56, 67],
  [16, 31, 5, 32, 90, 47],
  [39, 132, 5, 32, 10, 15],
  [30, 119, 5, 53, 75, 95],
  [30, 57, 5, 26, 64, 109],
  [24, 48, 5, 59, 56, 80],
  [24, 81, 5, 36, 18, 27],
  [39, 25, 5, 92, 33, 109],
  [30, 81, 5, 36, 10, 83],
  [3, 48, 5, 17, 82, 47],
  [12, 132, 5, 32, 18, 61],
  [3, 57, 5, 22, 100, 137],
  [46, 85, 5, 107, 18, 11],
  [30, 28, 5, 13, 54, 108],
  [12, 87, 5, 92, 44, 35],
  [12, 43, 5, 63, 10, 67],
  [39, 72, 5, 63, 102, 115],
  [30, 106, 5, 53, 44, 65],
  [20, 104, 5, 36, 102, 65],
  [30, 117, 5, 17, 54, 78],
  [30, 57, 5, 6, 18, 108],
  [20, 31, 5, 13, 7, 68],
  [20, 119, 5, 53, 62, 109],
  [12, 81, 5, 59, 18, 88],
  [3, 57, 5, 17, 18, 97],
  [3, 50, 5, 63, 98, 93],
  [39, 66, 5, 26, 73, 121],
  [12, 116, 5, 17, 18, 121],
  [39, 58, 5, 13, 73, 91],
  [16, 43, 5, 13, 100, 122],
  [30, 79, 5, 22, 18, 10],
  [24, 28, 5, 107, 14, 101],
  [39, 85, 5, 32, 62, 68],
  [12, 85, 5, 63, 7, 91],
  [16, 31, 5, 22, 64, 34],
  [20, 69, 5, 59, 49, 122],
  [24, 104, 5, 32, 18, 126],
  [12, 81, 5, 32, 33, 84],
  [30, 106, 5, 59, 130, 35],
  [39, 123, 5, 92, 90, 76],
  [24, 119, 5, 17, 44, 10],
  [20, 81, 5, 36, 18, 137],
  [3, 69, 5, 63, 62, 126],
  [30, 140, 5, 36, 33, 129],
  [3, 104, 5, 86, 82, 109],
  [16, 141, 5, 13, 82, 95],
  [30, 112, 5, 63, 7, 10],
  [12, 57, 5, 63, 18, 91],
  [3, 77, 5, 26, 29, 23],
  [46, 69, 5, 92, 18, 42],
  [46, 81, 5, 17, 62, 80],
  [24, 35, 5, 92, 7, 71],
  [12, 141, 5, 13, 33, 131],
  [16, 106, 5, 26, 18, 134],
  [12, 9, 5, 26, 64, 35],
  [46, 88, 5, 13, 100, 51],
  [30, 66, 5, 139, 18, 143],
  [30, 104, 5, 17, 37, 76],
  [46, 85, 5, 6, 82, 34],
  [46, 106, 5, 89, 33, 76],
  [16, 103, 5, 59, 54, 101],
  [30, 55, 5, 17, 73, 10],
  [24, 140, 5, 13, 18, 111],
  [3, 132, 5, 17, 82, 101],
  [3, 72, 5, 17, 102, 115],
  [39, 27, 5, 89, 18, 19],
  [39, 127, 5, 6, 90, 34],
  [39, 43, 5, 32, 90, 111],
  [30, 106, 5, 92, 14, 45],
  [20, 58, 5, 17, 37, 42],
  [30, 112, 5, 32, 62, 71],
  [16, 135, 5, 6, 49, 95],
  [39, 112, 5, 89, 33, 19],
  [12, 66, 5, 13, 7, 10],
  [3, 135, 5, 17, 54, 11],
  [16, 31, 5, 59, 124, 83],
  [20, 72, 5, 59, 54, 74],
  [3, 103, 5, 107, 90, 122],
  [12, 52, 5, 139, 102, 91],
  [46, 113, 5, 86, 64, 27],
  [16, 112, 5, 107, 56, 76],
  [3, 141, 5, 13, 130, 76],
  [3, 4, 5, 26, 29, 131],
  [16, 127, 5, 36, 64, 101],
  [16, 81, 5, 36, 49, 108],
  [24, 106, 5, 89, 29, 15],
  [12, 28, 5, 6, 37, 91],
  [39, 85, 5, 13, 75, 118],
  [3, 103, 5, 6, 75, 137],
  [16, 55, 5, 6, 18, 115],
  [16, 58, 5, 107, 102, 91],
  [30, 133, 5, 107, 94, 67],
  [46, 66, 5, 86, 114, 95],
  [12, 104, 5, 13, 14, 70],
  [39, 106, 5, 17, 100, 47],
  [12, 113, 5, 17, 37, 45],
  [16, 48, 5, 22, 90, 88],
  [16, 113, 5, 107, 44, 70],
  [16, 52, 5, 107, 14, 121],
  [20, 58, 5, 17, 44, 61],
  [12, 127, 5, 86, 14, 117],
  [12, 87, 5, 6, 102, 71],
  [16, 128, 5, 89, 94, 47],
  [3, 66, 5, 107, 62, 10],
  [16, 57, 5, 107, 29, 78],
  [16, 113, 5, 107, 29, 47],
  [12, 106, 5, 17, 37, 126],
  [12, 69, 5, 107, 124, 115],
  [16, 135, 5, 63, 102, 83],
  [12, 128, 5, 63, 64, 122],
  [16, 127, 5, 6, 73, 101],
  [16, 112, 5, 36, 62, 122],
  [46, 96, 5, 89, 94, 80],
  [16, 28, 5, 36, 7, 42],
  [3, 55, 5, 36, 49, 65],
  [24, 96, 5, 36, 18, 68],
  [24, 103, 5, 86, 29, 115],
  [16, 25, 5, 13, 44, 34],
  [12, 110, 5, 26, 14, 122],
  [16, 132, 5, 6, 18, 131],
  [12, 55, 5, 107, 82, 80],
  [20, 106, 5, 59, 73, 108],
  [3, 28, 5, 26, 37, 121],
  [24, 113, 5, 107, 64, 115],
  [46, 132, 5, 92, 33, 101],
  [46, 119, 5, 13, 62, 88],
  [16, 119, 5, 22, 44, 84],
  [3, 66, 5, 36, 10, 108],
  [39, 113, 5, 36, 102, 117],
  [46, 43, 5, 32, 124, 45],
]

export { tokenIdAttributeTuple }
