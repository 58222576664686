export enum TOKEN_SYMBOL {
  GMX = 'GMX',
  GLP = 'GLP',

  ETH = 'ETH',
  WETH = 'WETH',
  UNI = 'UNI',
  LINK = 'LINK',
  AVAX = 'AVAX',
  WAVAX = 'WAVAX',

  USDT = 'USDT',
  DAI = 'DAI',
  USDC = 'USDC',
  FRAX = 'FRAX',
  MIM = 'MIM',
  WBTC = 'WBTC',
  BTCB = 'BTCB',
  BTC = 'BTC',
  USDCE = 'USDCE',
  WBTCE = 'WBTCE',
  ESGMX = 'ESGMX',
}
